import React, {useContext, useMemo} from "react";
import {isEmpty} from "../Utils";
import moment from "moment";
import {MyContext} from "../../contexts/MyContext";

const Header = () => {
    const { rootState } = useContext(MyContext);
    const { theUser } = rootState;

    const getYearDiff = useMemo(() => {
        let datebirth = theUser.datebirth;
        if (!isEmpty(datebirth)) {
            return moment().diff(datebirth, 'years',false);
        } else {
            return false;
        }
    }, [theUser])

    return (
        <div className="banner">
            <div className="banner-container">
                <div className="item">
                    <img
                        src={`${process.env.PUBLIC_URL}/img/footages/profile-bg.jpg`}
                        width="100"
                        alt="Banner athlete"
                    />
                    <div className="dots">
                        <img
                            src={`${process.env.PUBLIC_URL}/img/decorations/banner/dots1.png`}
                            alt="decoration elements"
                        />
                        <img
                            src={`${process.env.PUBLIC_URL}/img/decorations/banner/profile_transparent.png`}
                            alt="decoration elements"
                        />
                    </div>
                </div>
                <div className="header-data">

                    <div className="avatar d-flex">
                        <div className="logo-container">
                            <img src={!isEmpty(theUser.avatar) ? theUser.avatar : ""} alt="logo"/>
                        </div>
                        <div className="my-auto">
                            <div className="name">{theUser.account_type === "athlete" ? theUser.firstname : theUser.firstName} {theUser.account_type === "athlete" ? theUser.name : theUser.lastName}</div>
                            <div className="mt-2 year">{getYearDiff ? `AD ${getYearDiff}Y` : ""}</div>
                        </div>
                    </div>

                    {!isEmpty(theUser.associate_club) && (
                        <div className="club-container mt-2">
                            <img src={theUser.associate_club.club_logo}
                                 alt="logo"/>
                            <div className="club-name">{theUser.associate_club.club_name}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
};

export default Header;