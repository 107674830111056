import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { isEmpty } from "../../components/Utils";
import WebTVS from "./WebTVS";
import Playlists from "./Playlists";
import Highlights from "./Highlight/Highlights";
import Banner from "./Banner";

import parse from "html-react-parser";

const SportPage = () => {
	const sports = useSelector((state) => state.sportsReducer.sportsTypesReducer);
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);
	const [ openBlock, setOpenBlock ] = useState(null);
	const [sportSelected, setSportSelected] = useState(null);

	useEffect(() => {
		if (!isEmpty(sportSelected)) {
			document
				.querySelectorAll(".bo-sport-page > header> ul>li")
				.forEach((elm) => elm.classList.remove("selected"));

			document
				.querySelector(`.li-${sportSelected.name}`)
				.classList.add("selected");
		}
	}, [sportSelected]);

	const handleMenu = (elm, block) => {
		let actual = document.querySelector(".homepage>ul>li>span.hover");
		if (!isEmpty(actual)) {
			actual.classList.remove("hover");
			if (actual !== elm) {
				elm.classList.add("hover");
			}
		} else {
			elm.classList.add("hover");
		}
		setOpenBlock(block)
	};

	return (
		<div className="bo-sport-page">
			<header>
				<h1>Choisir un sport :</h1>
				<ul>
					{!isEmpty(sports) &&
						sports.map((sport, index) => {
							return (
								<li
									className={`li-${sport.name}`}
									key={sport.name}
									onClick={() => setSportSelected(sport)}
								>
									{translations && !isEmpty(translations[sport.name])
										? parse(translations[sport.name])
										: sport.name}
								</li>
							);
						})}
				</ul>
			</header>

			{!isEmpty(sportSelected) && (
				<nav className="homepage">
					<ul>
						<li>
							<span onClick={(e) => handleMenu(e.target, "Banner")}>Bannière</span>
							{openBlock === "Banner" && (
								<Banner selectedSport={sportSelected} />
							)}
						</li>
						<li>
							<span onClick={(e) => handleMenu(e.target, "WebTVS1")}>Parcourir les Webs TVS</span>
							{openBlock === "WebTVS1" && (
								<WebTVS selectedSport={sportSelected}/>
							)}
						</li>
						<li>
							<span onClick={(e) => handleMenu(e.target, "Playlists")}>Playlists</span>
							{openBlock === "Playlists" && (
								<Playlists selectedSport={sportSelected} />
							)}
						</li>
						<li>
							<span onClick={(e) => handleMenu(e.target, "Highlights")}>Immanquables du Mois</span>
							{openBlock === "Highlights" && (
								<Highlights selectedSport={sportSelected} />
							)}
						</li>
					</ul>
				</nav>
			)}
		</div>
	);
};

export default SportPage;
