import { GET_GROUPS } from "../actions/group.action";

const initialState = [];

export default function groupReducer(state = initialState, action) {
	switch (action.type) {
		case GET_GROUPS:
			return action.payload;
		default:
			return state;
	}
}
