import React, {useState, useMemo, useContext, useEffect, Fragment} from "react";
import {useSelector} from "react-redux";
import axios from "axios";
import {isEmpty} from "../../Utils";
import parse from "html-react-parser";
import {MyContext} from "../../../contexts/MyContext";
import {useHistory} from "react-router";

const GroupSelect = () => {
    const {updateSelectedGroupClub} = useContext(MyContext);
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translations
    );
    const stripeReducer = useSelector((state) => state.stripeReducer);
    const activePlan = stripeReducer.getActiveCustomerProductReducer;
    const [clubs, setClubs] = useState({});
    const history = useHistory();
    const [checkedGroupId, setCheckedGroupId] = useState(null);
    const [checkedClubId, setCheckedClubId] = useState(null);
    const { rootState, isLoggedIn } = useContext(MyContext);
    const { theUser } = rootState;
    const [needToSetGroup, setNeedToSetGroup] = useState("no");
    const [needToSetClub, setNeedToSetClub] = useState("no");
    const [groups, setGroups] = useState([
        {key: 3, value: 'Group A'},
        {key: 6, value: 'Group B'},
        {key: 7, value: 'Group C'},
        {key: 10, value: 'Group D'}
    ]);

    useEffect(() => {
        if (needToSetGroup !== "no" || needToSetClub !== "no") {
            if (checkedGroupId !== null && ((checkedClubId !== null && needToSetClub !== "no") || needToSetClub === "no")) {
                handleSelect();
            }
        }

    }, [checkedGroupId, checkedClubId]);

    const handleSelect = async () => {
        let res = await updateSelectedGroupClub(needToSetGroup !== "no" ? checkedGroupId : null, needToSetClub !== "no" ? checkedClubId : null);
        isLoggedIn();
        history.push("/profile/info");
        if (res.result !== "ok") {
            console.log("Update Error!");
        }
    };


    useEffect(() => {
        if (!isEmpty(activePlan) && !isEmpty(theUser)) {
            if (
                (activePlan.need_to_set_group && (activePlan.need_to_set_group === "no" || activePlan.need_to_set_group !== "no" && !isEmpty(theUser.selected_group))) &&
                (activePlan.need_to_set_club && (activePlan.need_to_set_club === "no" || activePlan.need_to_set_club !== "no" && !isEmpty(theUser.selected_club)))
            ) {
                history.push("/profile/info");
            } else {
                if (activePlan.need_to_set_group && activePlan.need_to_set_group !== "no" && isEmpty(theUser.selected_group)) {
                    setNeedToSetGroup(activePlan.need_to_set_group);
                }
                if (activePlan.need_to_set_club && activePlan.need_to_set_club !== "no" && isEmpty(theUser.selected_club)) {
                    setNeedToSetClub(activePlan.need_to_set_club);
                }

            }

            setCheckedGroupId(theUser.selected_group)
        }
    }, [activePlan, theUser]);

    useMemo(() => {
        let array = {...clubs};
        for (let i = 0; i < groups.length; i++) {
            axios.get(`${process.env.REACT_APP_API_URL}getWebTVS&n=100&g=` + groups[i].key).then((res) => {
                if (res.data.result === "ok" && res.data.webTVS) {
                    array = {
                        ...array,
                        [groups[i].key]: {
                            ...array[groups[i].key],
                            value: groups[i].value,
                            data: res.data.webTVS,
                            id: groups[i].key,
                        }
                    };
                } else {
                    array = {
                        ...array,
                        [groups[i].key]: {
                            ...array[groups[i].key],
                            value: groups[i].value,
                            data: [],
                            id: groups[i].key,
                        }
                    };
                }
                setClubs(array);
            })
        }
    }, [groups]);

    return (
        <Fragment>
            <h2 style={{"textAlign": "center"}}>{checkedGroupId ? "Select club" : "Select Group"}</h2>
            <div className={`groups ${checkedGroupId && needToSetClub !== "no" ? "single" : ""}`}>
                {clubs && Object.values(clubs).filter((group) => checkedGroupId !== null && needToSetClub !== "no" ? group.id.toString() === checkedGroupId.toString() : true).map((club) => {
                    return (
                        <div className="group" key={club.value}>
                            <div className="clubs">
                                <h2>{club.value}</h2>
                                {club.data.map(index => (
                                    <div className="club" key={index.id}
                                         onClick={() => checkedGroupId && needToSetClub !== "no" ? setCheckedClubId(index.id) : console.log(index.id)}
                                    >
                                        <img src={index.logo} width="17" height="24" alt="Club logo"/>
                                        <div className="name">
                                            {index.name}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {checkedGroupId === null && (
                                <button
                                    className="btn btn-stripe mt-2"
                                    onClick={() => setCheckedGroupId(club.id)}
                                >
                                    {translations && !isEmpty(translations["select"])
                                        ? parse(translations["select"])
                                        : "Select"}
                                </button>
                            )}
                        </div>
                    )
                })}
            </div>
        </Fragment>
    );
};

export default GroupSelect;
