import React from "react";

const EmptyEventStyle = (props) => {
	const { event } = props;
	return (
		<section data-event={`event-${event.id}`} className="event display-4">
			<img src={event.img} width="100" alt="banner event" />

		</section>
	);
};

export default EmptyEventStyle;
