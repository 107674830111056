import React, {Fragment, useContext, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "../../Utils";
import parse from "html-react-parser";
import {toggleModal} from "../../../actions/modal.action";
import {MyContext} from "../../../contexts/MyContext";
import axios from "axios";
import AsyncSelect from "react-select/async";

const ClubSelect = ({onClose}) => {
    const {createClubRecord, isLoggedIn} = useContext(MyContext);
    const dispatch = useDispatch();
    const [wait, setWait] = useState(false);
    const [checkedClubId, setCheckedClubId] = useState(null);
    const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);

	const loadClubOptions = (value) => {
		if (value.length >= 2) {
			return axios
				.get(`${process.env.REACT_APP_API_URL}getWebTVS&lkn=${encodeURI(value)}`)
				.then((res) => {
					if (res.data.webTVS.length > 0) {
						return res.data.webTVS.filter((club) => {
							return club.is_web_tv === "0";
						});
					}
				});
		}
	};

    const handleSelectClub = async (event) => {
        event.preventDefault();
        if (!isEmpty(checkedClubId)) {

            let res = await createClubRecord(checkedClubId);

            if(res.result === "ok") {
                dispatch(toggleModal(
                    {
                        opened: true,
                        success: true,
                        text: "Select Club Success"
                    })
                );
                onClose(true);
            } else {
                dispatch(toggleModal(
                    {
                        opened: true,
                        success: false,
                        text: res.error
					})
                );
            }
	        isLoggedIn();
        }
    }

	const customStyles = {
		container: (provided, state) => ({
			...provided,
			width: "100%",
			cursor: "pointer",
			borderRadius: "5px",
			border: "1px solid #8d8d8d",
		}),
		option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
			...provided,
			color: isDisabled ? undefined : isSelected ? "#ffffff" : isFocused ? "black" : undefined,
			cursor: "pointer",
			backgroundColor: isDisabled ? undefined : isSelected ? "#49546c" : isFocused ? "white" : undefined,
			":hover": {
				...provided[":hover"],
				color: isSelected ? "#ffffff" : "black"
			},
		}),
		control: (provided, state) => ({
			...provided,
			borderStyle: "none",
			borderWidth: "unset",
			minHeight: "2.5rem",
			color: "#ffffff",
			boxShadow: "none",
		}),
		singleValue: (provided, state) => ({
			...provided,
			color: "#000000",
			fontSize: "1.6rem"
		}),
		menu: (provided, state) => ({
			...provided,
			color: "#ffffff",
			backgroundColor: "#1b283c",
			marginTop: 0,
			borderRadius: "0 0 4px 4px",
		}),
		indicatorSeparator: (provided) => ({
			...provided,
			display: "none"
		})
	};


	return (
        <Fragment>
            <div className="background position-fixed top-left bottom-right" style={{backgroundColor: "rgb(0 0 0 / 51%)"}}/>

            <div className="absolute-center modal">
                {wait && (
                    <div className="success-animation">
                        <div className="loader">
                            <div className={wait === "succeeded" ? "circle-loader load-complete" : "circle-loader"}>
                                <div className="checkmark draw"/>
                            </div>
                        </div>
                    </div>
                )}
                <h3 className="p-4">                              
                    {translations && !isEmpty(translations["choose_club"])
                            ? parse(translations["choose_club"])
                            : "Choose club"}
                </h3>
                <div className="mx-4">
	                <AsyncSelect
		                getOptionValue={ x => x["id"]}
		                getOptionLabel={ x => x["name"]}
		                styles={customStyles}
		                loadOptions={loadClubOptions}
		                isSearchable
                        placeholder={
                            translations && !isEmpty(translations["type_club"])
                            ? parse(translations["type_club"])
                            : "Type club"
                        }
		                isClearable={false}
		                onChange={(option) => setCheckedClubId(option['id'])}
	                />
                </div>

                <div className="d-flex float-right mt-4 mr-4 ml-7 mb-2">

                    <button className="btn btn-stripe"
                            onClick={handleSelectClub}
                            style={{background: "#06132D"}}
                    >Choose</button>
                </div>
            </div>
        </Fragment>
    );
}

export default ClubSelect;
