import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { dateRender, isEmpty } from "../Utils";
import { MyContext } from "../../contexts/MyContext";
import { useHistory } from "react-router";
import parse from "html-react-parser";
import { useSelector } from "react-redux";

const VideoPreview = ({ video }) => {
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translations
    );
    const { rootState } = useContext(MyContext);
    const { isAuth } = rootState;
    const history = useHistory();

    return (
        <div className="video" key={video.id}>
            {isEmpty(video.access) ||
            (!isEmpty(video.access) &&
            video.access === "yes") &&
            isAuth ? (
                <NavLink
                    exact
                    rel="noopener noreferrer"
                    to={`/player/${video.id}`}
                    onClick={() => window.scrollTo(0, 0)}
                >
                    <div className="video_img_block">
                        <img
                            src={video.img}
                            alt={`Apercu de ${video.name}`}
                            width="100"
                            height="66"
                        />
                        {video.time > 0 && (
                            <span>{dateRender(video.time)}</span>
                        )}
                    </div>

                    {((!isEmpty(video.subTitle) && video.subTitle.length > 1) ||
                        (!isEmpty(video.name) && video.name.length > 1)) && (
                        <div className="title-container">
                            <p>{video.subTitle}</p>
                            <h4>{video.name}</h4>
                        </div>
                    )}
                </NavLink>
            ) : (
                <div className="video-restrited">
                    <div className="video_img_block">
                        <img
                            src={video.img}
                            alt={`Apercu de ${video.name}`}
                            width="100"
                            height="66"
                        />
                        {video.time > 0 && (
                            <span>{dateRender(video.time)}</span>
                        )}
                    </div>
                    {((!isEmpty(video.subTitle) && video.subTitle.length > 1) ||
                        (!isEmpty(video.name) && video.name.length > 1)) && (
                        <div className="title-container">
                            <p>{video.subTitle}</p>
                            <h4>{video.name}</h4>
                        </div>
                    )}
                    <div className="video-restricted-overlay">
                        <span className="padlock-icon">
                            <svg
                                width="38"
                                height="50"
                                viewBox="0 0 38 50"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M5.00001 49.3333C3.71668 49.3333 2.61845 48.8768 1.70534 47.9636C0.790676 47.049 0.333344 45.95 0.333344 44.6666V21.3333C0.333344 20.05 0.790676 18.951 1.70534 18.0363C2.61845 17.1232 3.71668 16.6666 5.00001 16.6666H7.33334V12C7.33334 8.7722 8.47123 6.02042 10.747 3.74465C13.0212 1.47042 15.7722 0.333313 19 0.333313C22.2278 0.333313 24.9796 1.47042 27.2553 3.74465C29.5296 6.02042 30.6667 8.7722 30.6667 12V16.6666H33C34.2833 16.6666 35.3823 17.1232 36.297 18.0363C37.2101 18.951 37.6667 20.05 37.6667 21.3333V44.6666C37.6667 45.95 37.2101 47.049 36.297 47.9636C35.3823 48.8768 34.2833 49.3333 33 49.3333H5.00001ZM19 37.6666C20.2833 37.6666 21.3823 37.2101 22.297 36.297C23.2101 35.3823 23.6667 34.2833 23.6667 33C23.6667 31.7166 23.2101 30.6176 22.297 29.703C21.3823 28.7899 20.2833 28.3333 19 28.3333C17.7167 28.3333 16.6185 28.7899 15.7053 29.703C14.7907 30.6176 14.3333 31.7166 14.3333 33C14.3333 34.2833 14.7907 35.3823 15.7053 36.297C16.6185 37.2101 17.7167 37.6666 19 37.6666ZM12 16.6666H26V12C26 10.0555 25.3195 8.40276 23.9583 7.04165C22.5972 5.68053 20.9445 4.99998 19 4.99998C17.0556 4.99998 15.4028 5.68053 14.0417 7.04165C12.6806 8.40276 12 10.0555 12 12V16.6666Z"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        {isAuth ? (
                            <div className="video-restricted-text">
                                {translations &&
                                !isEmpty(translations["videoRestricted"])
                                    ? parse(translations["videoRestricted"])
                                    : "This content is only available to Fuchs-Sports subscribers"}
                            </div>
                        ) : (
                            <div className="video-restricted-text">
                                {translations &&
                                !isEmpty(translations["loginToView"])
                                    ? parse(translations["loginToView"])
                                    : "You must be logged in to view this content"}
                            </div>
                        )}

                        {isAuth ? (
                            <div className="d-flex">
                                <NavLink
                                    to="/profile/plans"
                                    className="video-restricted-link"
                                >
                                    {translations &&
                                    !isEmpty(translations["annualSubscribe"])
                                        ? parse(translations["annualSubscribe"])
                                        : "Upgrade your plan"}
                                </NavLink>
                                {/*<NavLink
                                    to="/profile/billing-info"
                                    className="video-restricted-link"
                                >
                                    {translations &&
                                    !isEmpty(translations["instantPay"])
                                        ? parse(translations["instantPay"])
                                        : "pay - 2.99€"}
                                </NavLink>*/}
                            </div>
                        ) : (
                            <div className="d-flex">
                                <NavLink
                                    to="/account/register"
                                    className="video-restricted-link"
                                >
                                    {translations &&
                                    !isEmpty(translations["register"])
                                        ? parse(translations["register"])
                                        : "register"}
                                </NavLink>
                                <NavLink
                                    to="/account/login"
                                    className="video-restricted-link"
                                >
                                    {translations &&
                                    !isEmpty(translations["login"])
                                        ? parse(translations["login"])
                                        : "login"}
                                </NavLink>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default VideoPreview;
