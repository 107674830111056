import React, {Fragment, useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAdvantage} from "../../actions/stripeAdmin.action";
import {isEmpty} from "../../components/Utils";
import {AdminContext} from "../../contexts/AdminContext";

const EditAdvantage = ({id, successCreate}) => {
	const advantage = useSelector((state) => state.stripeAdminReducer.getAdvantageReducer);
	const [activeElement, setActiveElement] = useState(null)
	const [errors, setErrors] = useState({});
	const [updated, setUpdated] = useState(false)
	const [data, setData] = useState({});
	const {updateAdvantage} = useContext(AdminContext);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!isEmpty(id)){
			dispatch(getAdvantage(id));
		}
	}, [dispatch, id]);

	useEffect(() => {
		setData(advantage.advantage);
	}, [advantage]);

	useEffect(() => {
		if (isEmpty(data.restriction_by) && !isEmpty(data.restriction_number)) {
			setData((prevState) => {
				const { restriction_number: removedProperty, ...rest } = prevState;
				return rest;
			});
		}
	}, [data?.restriction_by]);

	useEffect(() => {
		if (isEmpty(data.restrict_views) && !isEmpty(data.restrict_views_count)) {
			setData((prevState) => {
				const { restrict_views_count: removedProperty, ...rest } = prevState;
				return rest;
			});
			setData((prevState) => {
				const { restrict_views_by: removedProperty, ...rest } = prevState;
				return rest;
			});
		}
	}, [data?.restrict_views]);

	const changeAdvantagesHandler = (event) => {
		if (isEmpty(event.target.value)) {
			setData((prevState) => {
				const { [event.target.name]: removedProperty, ...rest } = prevState;
				return rest;
			});
		} else {
			setData(prevState => ({...prevState, [event.target.name]: event.target.value}));
		}
	};

	const validateForm = async () => {
		let valid = {};
		if (isEmpty(data.title_en) || data.title_en.length < 1) {
			setErrors(prevState => ({...prevState, title_en: true}));
			valid["title_en"] = true;
		} else {
			setErrors(prevState => ({...prevState, title_en: false}));
			valid["title_en"] = false;
		}
		if (isEmpty(data.description_en) || data.description_en.length < 1) {
			setErrors(prevState => ({...prevState, description_en: true}));
			valid["description_en"] = true;
		} else {
			setErrors(prevState => ({...prevState, description_en: false}));
			valid["description_en"] = false;
		}
		if (isEmpty(data.title_fr) || data.title_fr.length < 1) {
			setErrors(prevState => ({...prevState, title_fr: true}));
			valid["title_fr"] = true;
		} else {
			setErrors(prevState => ({...prevState, title_fr: false}));
			valid["title_fr"] = false;
		}
		if (isEmpty(data.description_fr) || data.description_fr.length < 1) {
			setErrors(prevState => ({...prevState, description_fr: true}));
			valid["description_fr"] = true;
		} else {
			setErrors(prevState => ({...prevState, description_fr: false}));
			valid["description_fr"] = false;
		}
		if (isEmpty(data.title_es) || data.title_es.length < 1) {
			setErrors(prevState => ({...prevState, title_es: true}));
			valid["title_es"] = true;
		} else {
			setErrors(prevState => ({...prevState, title_es: false}));
			valid["title_es"] = false;
		}
		if (isEmpty(data.description_es) || data.description_es.length < 1) {
			setErrors(prevState => ({...prevState, description_es: true}));
			valid["description_es"] = true;
		} else {
			setErrors(prevState => ({...prevState, description_es: false}));
			valid["description_es"] = false;
		}
		if (isEmpty(data.type_of_advantage) || data.type_of_advantage.length < 1) {
			setErrors(prevState => ({...prevState, type_of_advantage: true}));
			valid["type_of_advantage"] = true;
		} else {
			setErrors(prevState => ({...prevState, type_of_advantage: false}))
			valid["type_of_advantage"] = false;

			if (isEmpty(data.playlist) || data.playlist.length < 1) {
				setErrors(prevState => ({...prevState, playlist: true}));
				valid["playlist"] = true;
			} else {
				setErrors(prevState => ({...prevState, playlist: false}))
				valid["playlist"] = false;
			}
		}
		if (!isEmpty(data.restriction_by) && data.restriction_by.length >= 1) {
			if (isEmpty(data.restriction_number) || data.restriction_number.length < 1) {
				setErrors(prevState => ({...prevState, restriction_number: true}));
				valid["restriction_number"] = true;
			} else {
				setErrors(prevState => ({...prevState, restriction_number: false}))
				valid["restriction_number"] = false;
			}
		}
		if (!isEmpty(data.restrict_views) && data.restrict_views.length >= 1 && data.restrict_views !== "no") {
			if (isEmpty(data.restrict_views_count) || data.restrict_views_count.length < 1) {
				setErrors(prevState => ({...prevState, restrict_views_count: true}));
				valid["restrict_views_count"] = true;
			} else {
				setErrors(prevState => ({...prevState, restrict_views_count: false}))
				valid["restrict_views_count"] = false;
			}
			if (isEmpty(data.restrict_views_by) || data.restrict_views_by.length < 1) {
				setErrors(prevState => ({...prevState, restrict_views_by: true}));
				valid["restrict_views_by"] = true;
			} else {
				setErrors(prevState => ({...prevState, restrict_views_by: false}))
				valid["restrict_views_by"] = false;
			}
		}
		return valid;
	}

	const submitForm = async (event) => {
		event.preventDefault();
		let valid = await validateForm();

		if (Object.values(valid).includes(true)) {
			return false
		}

		const res = await updateAdvantage(data);

		if (!isEmpty(res)) {
			setUpdated(true);
			if (res.result === "ok") {
				successCreate();
			} else {
				if (res.error) console.log(res.error);
			}
		}

		setUpdated(false);
	};

	return (
		<div className="edit_sub">
			<Fragment>
				<article>
					<form onSubmit={submitForm} noValidate>
						<div className="double-grid">
							<div
								className={`input ${activeElement === "title_en" ? "select" : ""} ${errors.title_en ? "error" : ""}`}>
								<label>Title English</label>
								<input
									type="text"
									id="title_en"
									name="title_en"
									autoFocus
									autoComplete="given-name"
									defaultValue={data?.title_en}
									onBlur={(e) => setActiveElement(null)}
									onClick={(e) => setActiveElement(e.target.id)}
									onChange={(e) =>  changeAdvantagesHandler(e)}
								/>
							</div>
							<div
								className={`input ${activeElement === "description_en" ? "select" : ""} ${errors.description_en ? "error" : ""}`}>
								<label>Description English</label>
								<input
									type="text"
									id="description_en"
									name="description_en"
									defaultValue={data?.description_en}
									onBlur={(e) => setActiveElement(null)}
									onClick={(e) => setActiveElement(e.target.id)}
									onChange={(e) =>  changeAdvantagesHandler(e)}
								/>
							</div>
						</div>
						<div className="double-grid">
							<div
								className={`input ${activeElement === "title_fr" ? "select" : ""} ${errors.title_fr ? "error" : ""}`}>
								<label>Title French</label>
								<input
									type="text"
									id="title_fr"
									name="title_fr"
									autoComplete="given-name"
									defaultValue={data?.title_fr}
									onBlur={(e) => setActiveElement(null)}
									onClick={(e) => setActiveElement(e.target.id)}
									onChange={(e) =>  changeAdvantagesHandler(e)}
								/>
							</div>
							<div
								className={`input ${activeElement === "description_fr" ? "select" : ""} ${errors.description_fr ? "error" : ""}`}>
								<label>Description French</label>
								<input
									type="text"
									id="description_fr"
									name="description_fr"
									defaultValue={data?.description_fr}
									onBlur={(e) => setActiveElement(null)}
									onClick={(e) => setActiveElement(e.target.id)}
									onChange={(e) =>  changeAdvantagesHandler(e)}
								/>
							</div>
						</div>
						<div className="double-grid">
							<div
								className={`input ${activeElement === "title_es" ? "select" : ""} ${errors.title_es ? "error" : ""}`}>
								<label>Title Spain</label>
								<input
									type="text"
									id="title_es"
									name="title_es"
									autoComplete="given-name"
									defaultValue={data?.title_es}
									onBlur={(e) => setActiveElement(null)}
									onClick={(e) => setActiveElement(e.target.id)}
									onChange={(e) =>  changeAdvantagesHandler(e)}
								/>
							</div>
							<div
								className={`input ${activeElement === "description_es" ? "select" : ""} ${errors.description_es ? "error" : ""}`}>
								<label>Description Spain</label>
								<input
									type="text"
									id="description_es"
									name="description_es"
									defaultValue={data?.description_es}
									onBlur={(e) => setActiveElement(null)}
									onClick={(e) => setActiveElement(e.target.id)}
									onChange={(e) =>  changeAdvantagesHandler(e)}
								/>
							</div>
						</div>

						<hr/>
						<div style={{clear: "both"}}/>

						<div className="double-grid">
							<div className={`input ${activeElement === "type_of_advantage" ? "select" : ""} ${errors.type_of_advantage ? "error" : ""}`}>
								<label>Type of advantage</label>
								<select
									id="type_of_advantage"
									name="type_of_advantage"
									onChange={(e) =>  changeAdvantagesHandler(e)}
									value={!isEmpty(data) && data.type_of_advantage !== null ? data.type_of_advantage : ""}
								>
									<option/>
									<option value="playlist">Playlist</option>
								</select>
							</div>

							{data?.type_of_advantage === "playlist" && (
								<div className={`input ${activeElement === "playlist" ? "select" : ""} ${errors.playlist ? "error" : ""}`}>
									<label>Playlist</label>
									<select
										id="playlist"
										name="playlist"
										onChange={(e) =>  changeAdvantagesHandler(e)}
										value={!isEmpty(data) && data.playlist !== null ? data.playlist : ""}
									>
										<option/>
										<option value="resume">Résumé</option>
										<option value="replay">Replay</option>
										<option value="top_but">Top But</option>
										<option value="top_arret">Top Arrêt</option>
										<option value="shfc_top_buts_group">SNFC “Top buts Groupe”</option>
										<option value="shfc_top_arrets_group">SNFC “Top Arrêts Groupe”</option>
									</select>
								</div>
							)}
						</div>

						<hr/>
						<div style={{clear: "both"}}/>

						<div className="double-grid">
							<div className={`input ${activeElement === "restriction_by" ? "select" : ""} ${errors.restriction_by ? "error" : ""}`}>
								<label>Restriction by ...</label>
								<select
									id="restriction_by"
									name="restriction_by"
									onChange={(e) =>  changeAdvantagesHandler(e)}
									value={!isEmpty(data) && data.restriction_by !== null ? data.restriction_by : ""}
								>
									<option value="">NO</option>
									<option value="club">Club</option>
									<option value="group">Group</option>
								</select>
							</div>

							{data?.restriction_by && (
								<div
									className={`input ${activeElement === "restriction_number" ? "select" : ""} ${errors.restriction_number ? "error" : ""}`}>
									<label>Restriction count</label>
									<input
										type="number"
										id="restriction_number"
										name="restriction_number"
										autoComplete="given-name"
										defaultValue={data?.restriction_number}
										onBlur={(e) => setActiveElement(null)}
										onClick={(e) => setActiveElement(e.target.id)}
										onChange={(e) =>  changeAdvantagesHandler(e)}
									/>
								</div>
							)}
						</div>

						<hr/>
						<div style={{clear: "both"}}/>

						<div className="triple-grid">
							<div className={`input ${activeElement === "restrict_views" ? "select" : ""} ${errors.restrict_views ? "error" : ""}`}>
								<label>Restrict views</label>
								<select
									id="restrict_views"
									name="restrict_views"
									onChange={(e) =>  changeAdvantagesHandler(e)}
									value={!isEmpty(data) && data.restrict_views !== null ? data.restrict_views : ""}
								>
									<option value="no">NO</option>
									<option value="yes">YES</option>
								</select>
							</div>

							{data?.restrict_views === "yes" && (
								<Fragment>
									<div className={`input ${activeElement === "restrict_views_count" ? "select" : ""} ${errors.restrict_views_count ? "error" : ""}`}>
										<label>Restrict views count</label>
										<input
											type="number"
											id="restrict_views_count"
											name="restrict_views_count"
											autoComplete="given-name"
											defaultValue={data?.restrict_views_count}
											onBlur={(e) => setActiveElement(null)}
											onClick={(e) => setActiveElement(e.target.id)}
											onChange={(e) =>  changeAdvantagesHandler(e)}
										/>
									</div>
									<div className={`input ${activeElement === "restrict_views_by" ? "select" : ""} ${errors.restrict_views_by ? "error" : ""}`}>
										<label>Restrict view by</label>
										<select
											id="restrict_views_by"
											name="restrict_views_by"
											onChange={(e) =>  changeAdvantagesHandler(e)}
											value={!isEmpty(data) && data.restrict_views_by !== null ? data.restrict_views_by : ""}
										>
											<option/>
											<option value="day">day</option>
											<option value="season">season</option>
										</select>
									</div>
								</Fragment>
							)}
						</div>

						<hr/>
						<div style={{clear: "both"}}/>

						<div className="submit">
							<input
								type="submit"
								value="Edit advantage"
							/>
							<div id="validUpdate" className={`${updated ? "display" : ""}`}>
								Le advantage a été ajouté
							</div>
						</div>
					</form>
				</article>
			</Fragment>
		</div>
	);
};

export default EditAdvantage;