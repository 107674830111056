import React, {useContext, useEffect, useState} from "react";
import Iframe from "react-iframe";
import { useDispatch } from "react-redux";
import { clearReducerVideo, getVideo } from "../actions/video.action";
import {Redirect} from "react-router";
import {MyContext} from "../contexts/MyContext";
import {isEmpty} from "../components/Utils";
import {Helmet} from "react-helmet-async";

const IframeVideo = ({ match }) => {
	const {
		params: { videoID },
	} = match;
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(clearReducerVideo());
		dispatch(getVideo(videoID));
	}, [dispatch, videoID]);

	const { rootState, isLoginAuth } = useContext(MyContext);
	const { isAuth, theUser } = rootState;
	const [ state, setState] = useState(null);
	useEffect(() => {
		async function auth() {
			setState(await isLoginAuth());
		}
		auth();
		// eslint-disable-next-line
	}, [isAuth]);

	return (
		<>
			<Helmet>
				<title>Fuchs Sports International</title>
				<meta property="og:image" content={`${process.env.REACT_APP_CACHE_SERVER}/img/icons/logo_fuchs_sports.png`} />
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
			</Helmet>

			<div>
				{state === false && <Redirect to="/account" />}
				<Iframe
					id="player"
					title="video"
					width="100%"
					height="100%"
					url={`https://mytvchain.com/videoe.php?vID=${videoID}&banner=0&domain=fuchs-sports&ai=${!isEmpty(theUser) && theUser.id}`}
					loading="eager"
					allow="fullscreen;autoplay;encrypted-media;geolocation"
					className="iframe-only"
				>
					Your browser doesn't support player functionnality...
				</Iframe>
			</div>
		</>
	);
};

export default IframeVideo;
