import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Redirect, useLocation} from "react-router";
import Events from "../components/Events";
import Playlist from "../components/Playlist";
import Search from "../components/Search";
import Banner from "../components/sports/Banner";
import { isEmpty, removeTags } from "../components/Utils";
import WebTVS from "../components/WebTVS";
import Highlight from "../components/Highlight";
import { getHighlights } from "../actions/highlights.action";
import { getEvents } from "../actions/events.action";
import { toggleSearch } from "../actions/search.action";
import {getAllWebTvs, getWebTVS} from "../actions/webTVS.action";
import { getFuchsPlaylists } from "../actions/playlist.action";
import {getLeagues} from "../actions/leagues.action";
import {getDivisions} from "../actions/division.action";
import {getGroups} from "../actions/group.action";
import {Helmet} from "react-helmet-async";
import RedirectApp from "../components/RedirectApp";

const Sports = ({ match }) => {
	const {
		params: { sportName, type },
	} = match;
	let param = sportName;

	const location = useLocation();
	const dispatch = useDispatch();
	const [sport, setSport] = useState("");
	const [matchSport, setMatchSport] = useState(true);
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const selectedLanguage = translationsReducer.language;
	const events = useSelector(
		(state) => state.eventsReducer.eventsAllReducer
	);
	const playlistsVideo = useSelector(
		(state) => state.playlistAllReducer.fuchsPlaylistsReducer
	);
	const highlights = useSelector(
		(state) => state.highlightReducer
	);
	const sports = useSelector(
		(state) => state.sportsReducer.sportsTypesReducer
	);
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);
	const searchState = useSelector(
		(state) => state.searchReducer.searchToggleReducer
	);
	const webTVS = useSelector(
		(state) => state.webTVSReducer
	);
	const allWebTVS = useSelector(
		(state) => state.allWebTvs
	);
	//CHECK IF PARAM ARE SUPPORTED SPORT
	// eslint-disable-next-line
	async function findSport(sports) {
		return await sports.filter((sport) => sport.name === param);
	}

	useEffect(() => {
		if (sports.length > 0) {
			sports.find((obj) => {
				if (obj.name === param) {
					setSport(obj);
				}
				return false;
			});
			findSport(sports).then((resolve) => {
				setMatchSport(resolve.length > 0);
			});
		}
	}, [sports, param, findSport]);

	useEffect(() => {
		if (searchState) {
			dispatch(toggleSearch(false));
		}
		// eslint-disable-next-line
	}, [location, dispatch]);

	useEffect(() => {
		if (!isEmpty(sport.id)) {
			dispatch(getEvents(100, sport.id));
			dispatch(getLeagues("", sport.id, "", selectedLanguage));
			dispatch(getDivisions("", "", sport.id, "", selectedLanguage));
			dispatch(getGroups("", "", "", sport.id, "", selectedLanguage));
			dispatch(getHighlights(sportName + "_sport_page"));
			dispatch(getFuchsPlaylists(sportName + "_sport_page", "yes"));
			dispatch(getWebTVS(1, 20, sportName + "_sport_page", 1));
			dispatch(getAllWebTvs(1, 20, sportName + "_sport_page", 2));
		}
		// eslint-disable-next-line
	}, [sport.id, selectedLanguage, sportName]);

	const onSelectFilter = (leagId, divId, groupId) => {
		if (sport) dispatch(getEvents(100, sport.id, leagId, divId, groupId));
	};

	const onWebTvSelectFilter = (leagueId, divisionId, groupId) => {
		dispatch(getWebTVS(1, 20, sportName + "_sport_page", 1, leagueId, divisionId, groupId));
	};

	const onAllWebTvSelectFilter = (leagueId, divisionId, groupId) => {
		dispatch(getAllWebTvs(1, 20, sportName + "_sport_page", 2, leagueId, divisionId, groupId));
	};

	return (
		<>
			<Helmet>
				{!isEmpty(translations) && !isEmpty(translations[sport.name]) && (
					<title>{`Fuchs Sports International - ${removeTags(translations[sport.name])}`}</title>
				)}

				{!isEmpty(translations) && !isEmpty(translations[sport.name]) && (
					<meta name="title" content={`Fuchs Sports International - ${removeTags(translations[sport.name])}`} />
				)}

				{!isEmpty(translations) && !isEmpty(translations[sport.name]) && (
					<meta name="description" content={`Fuchs Sports International - ${removeTags(translations[sport.name])}`} />
				)}

				{!isEmpty(translations) && !isEmpty(translations[sport.name]) && (
					<meta property="og:title" content={`Fuchs Sports International - ${removeTags(translations[sport.name])}`} />
				)}

				{!isEmpty(translations) && !isEmpty(translations[sport.name]) && (
					<meta property="og:description" content={`Fuchs Sports International - ${removeTags(translations[sport.name])}`} />
				)}
			</Helmet>

			<RedirectApp page="sport"/>

			<div>
				{!matchSport && <Redirect to="/" />}
				{isEmpty(param) && <Redirect to="/" />}

				<Banner
					title={
						translations && sport.name && !isEmpty(translations[sport.name]) ? removeTags(translations[sport.name]) : param
					}
					bannerPatch={sport.bannerPatch}
				/>
				{searchState && <Search />}

				{!isEmpty(param) && (isEmpty(type) || type === "events") && (
					<Events
						events={events}
						filter={true}
						onSelectFilter={onSelectFilter}
						sportId={sport.id}
					/>
				)}

				{isEmpty(type) && (
					<WebTVS
						webTVS={webTVS}
						listNumber="1"
						pageType={sportName + "_sport_page"}
						titleValue={"champTitle"}
						onSelectFilter={onWebTvSelectFilter}
					/>
				)}

				{isEmpty(type) && (
					<WebTVS
						webTVS={allWebTVS}
						listNumber="2"
						pageType={sportName + "_sport_page"}
						titleValue={"webTVSTitle"}
						onSelectFilter={onAllWebTvSelectFilter}
					/>
				)}

				{!isEmpty(playlistsVideo) &&
					(isEmpty(type) || type === "videos") &&
					Object.keys(playlistsVideo.playlists)
						.map((key, index) => {
							return (
								<Playlist
									key={index}
									data={playlistsVideo["playlists"][key]}
									color={index}
									playlistId={key}
									sportId={sport.id}
									clubPlayerId={""}
								/>
							);
						})
				}
				{!isEmpty(param) && (isEmpty(type) || type === "events") && (
					<Highlight
						highlights={
							!isEmpty(highlights) &&
							!isEmpty(highlights.highlight) &&
							highlights.highlight
								.sort((a, b) => a.sport_order - b.sport_order)
						}
					/>
				)}
			</div>
		</>
	);
};

export default Sports;
