import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllTranslations } from "../../actions/translations.action";
import { isEmpty } from "../../components/Utils";
import { AdminContext } from "../../contexts/AdminContext";
import {
	Editor,
	EditorState,
	RichUtils,
	convertFromHTML,
	ContentState,
} from "draft-js";
import "draft-js/dist/Draft.css";
import { stateToHTML } from "draft-js-export-html";

import parse from "html-react-parser";

const Translation = () => {
	const translations = useSelector(
		(state) => state.langReducer.translationsAllReducer
	);
	const supportedLanguages = useSelector(
		(state) => state.langReducer.languagesReducer
	);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAllTranslations());
	}, [dispatch]);

	const [page, setPage] = useState(0);
	const [offset, setOffset] = useState(10);

	const [translationArray, setTranslationArray] = useState({});
	useEffect(() => {
		if (!isEmpty(translations)) {
			setTranslationArray(translations);
		}
	}, [translations]);
	//PAGE
	const handlePage = (mode) => {
		let pageNumber = Math.ceil(Object.entries(translationArray).length / offset);
		if (mode === "next") {
			setPage(page + 1 > pageNumber - 1 ? 0 : page + 1);
		} else {
			setPage(page - 1 < 0 ? pageNumber - 1 : page - 1);
		}
	};

	//SEARCH
	const handleSearch = (input) => {
		// console.log(translationArray);
		let array = Object.entries(translations);
		array = array.filter(
			(elm) =>
				elm[0].toLowerCase().includes(input.toLowerCase()) ||
				elm[1][0].value.toLowerCase().includes(input.toLowerCase()) ||
				elm[1][1].value.toLowerCase().includes(input.toLowerCase())
		);
		setTranslationArray(Object.fromEntries(array));
		setPage(0);
	};

	//EDITOR

	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const [editorToggle, setEditorToggle] = useState(false);
	const [actualEdit, setActualEdit] = useState("");
	const handleKeyCommand = (command) => {
		const newState = RichUtils.handleKeyCommand(editorState, command);
		if (newState) {
			setEditorState(newState);
			return "handled";
		}
		return "not-handled";
	};

	const onItalicClick = () => {
		setEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
	};
	const onBoldClick = () => {
		setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
	};
	const onUnderlineClick = () => {
		setEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
	};

	const { updateVariable } = useContext(AdminContext);

	const validate = async () => {
		let html = stateToHTML(editorState.getCurrentContent());
		let variable = actualEdit;
		variable.value = html;
		let test = await updateVariable(variable);
		console.log(test);
		setEditorToggle(false);
		dispatch(getAllTranslations());
	};

	const sendToEditor = (input) => {
		const blocksFromHTML = convertFromHTML(input);
		const state = ContentState.createFromBlockArray(
			blocksFromHTML.contentBlocks,
			blocksFromHTML.entityMap
		);

		setEditorState(EditorState.createWithContent(state));
	};

	return (
		<div className="bo-translations">
			<h2>Translations</h2>
			<div className="controls">
				<div className="search">
					<label htmlFor="search">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
						>
							<path d="M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z" />
						</svg>
					</label>
					<input
						type="search"
						autoComplete="off"
						name="search"
						id="search"
						placeholder="Chercher une variable"
						onInput={(e) => handleSearch(e.target.value)}
					/>
				</div>
				<div className="pages">
					<div className="buttons">
						<button onClick={() => handlePage("prev")}>Page précédente</button>
						<button onClick={() => handlePage("next")}>Page suivante</button>
					</div>

					<p>
						Page {page + 1}/{Math.ceil(Object.entries(translationArray).length / offset)}
					</p>
				</div>
				<div className="offset">
					<label htmlFor="offset">Nombre d'éléments affichés</label>
					<input
						type="number"
						name="offset"
						step="10"
						value={offset}
						onChange={(e) => setOffset(parseInt(e.currentTarget.value))}
						min="10"
						max={
							!isEmpty(Math.ceil(Object.entries(translationArray).length))
								? Math.ceil(Object.entries(translationArray).length) + 10
								: 100
						}
					/>
				</div>
			</div>
			<div className="translations-list">
				<table>
					<thead>
						<tr>
							<th>Nom de variable</th>
							{!isEmpty(supportedLanguages) &&
								supportedLanguages.languages
									.sort((a) => {
										return a.shortLang === "es" ? 0 : (a.shortLang === "en" ? 1 : -1);
									})
									.map((language) => (
										<th key={language.longLang}>{language.longLang}</th>
									))}
						</tr>
					</thead>
					<tbody>
						{!isEmpty(translationArray) &&
							Object.entries(translationArray)
								.slice(page * offset, offset + page * offset)
								.map((translate) => {
									return (
										<tr key={translate[0]}>
											<td>{translate[0]}</td>
											{Array.from(translate[1])
												.sort((a) => {
													return a.language === "es" ? 0 : (a.language === "en" ? 1 : -1);
												})
												.map((translation, index) => {
													let { value, language } = translation;

													return (
														<td
															onClick={() => {
																setActualEdit({
																	variableName: translate[0],
																	value: value,
																	language: language,
																});
																sendToEditor(value);
																setEditorToggle(true);
															}}
															key={index}
														>
															{!isEmpty(value) && parse(value)}
														</td>
													);
												})}
										</tr>
									);
								})}
					</tbody>
				</table>
			</div>
			{editorToggle && (
				<div className="editor">
					<div className="data-controls">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							onClick={() => setEditorToggle(false)}
						>
							<path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" />
						</svg>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							onClick={validate}
						>
							<path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z" />
						</svg>
					</div>
					<div className="controls">
						<button onClick={onItalicClick} className="italic">
							I
						</button>
						<button onClick={onBoldClick} className="bold">
							B
						</button>
						<button onClick={onUnderlineClick} className="underline">
							U
						</button>
					</div>

					<Editor
						editorState={editorState}
						onChange={setEditorState}
						handleKeyCommand={handleKeyCommand}
					/>
				</div>
			)}
		</div>
	);
};

export default Translation;
