import React from "react";

const EventStyle3 = (props) => {
	const { event } = props;
	return (
		<section data-event={`event-${event.id}`} className="event display-3">
			<div className="title">
				<div className="main-title">
					<h1>
						<span>{event.title}</span>
					</h1>
				</div>
			</div>
			<a href={event.url} target="_blank" rel="noopener noreferrer">
				<div className="play">
					<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/play.svg`} alt="play button" />
				</div>
			</a>

			<img src={event.img} width="100" alt="banner event" />
			<div className="dots">
				<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/dots1.png`} alt="decoration elements" />
				<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/dots2.png`} alt="decoration elements" />
			</div>
			<div className="decorations">
				<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/lb1.svg`} alt="decoration elements" />
				<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/lb3.svg`} alt="decoration elements" />
				<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/lb2.svg`} alt="decoration elements" />
				<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/rb.svg`} alt="decoration elements" />
			</div>
		</section>
	);
};

export default EventStyle3;
