import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import AccountSection from "./header/AccountSection";
import NavBar from "./header/NavBar";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { isEmpty } from "./Utils";

const Header = () => {
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translations
    );
    const status = useSelector((state) => state.statusReducer);
    const [closeFilter, setCloseFilter] = useState(true);

    const handleClickOutside = (event) => {
        if (event.target.closest(".event-modal") === null) {
            setCloseFilter(true);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    return (
        <header className="main-header">
            <div className="hide-background" />
            <div className="hide-background-not" />
            <div className="logo">
                <NavLink exact to="/">
                    <img
                        src={`${process.env.REACT_APP_CACHE_SERVER}/img/icons/2021_11_Logo_Fuchs_Sports_Vecto.png`}
                        alt="logo fuchs"
                    />
                </NavLink>
            </div>

            <NavBar />

            <div
                className="mobile-menu"
                onClick={() =>
                    document
                        .querySelector(".main-header")
                        .classList.contains("menu-open")
                        ? document
                              .querySelector(".main-header")
                              .classList.remove("menu-open")
                        : document
                              .querySelector(".main-header")
                              .classList.add("menu-open")
                }
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                >
                    <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 17h-12v-2h12v2zm0-4h-12v-2h12v2zm0-4h-12v-2h12v2z" />
                </svg>
            </div>

            <AccountSection />
            {!isEmpty(status) &&
                status.success === 1 &&
                status.message.value !== "<li><br></li>" && (
                    <div
                        className="status"
                        onClick={() => setCloseFilter(!closeFilter)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z" />
                        </svg>
                        <h4 className="event-modal">
                            {translations &&
                            !isEmpty(translations["statusTitle"])
                                ? parse(translations["statusTitle"])
                                : "statusTitle"}
                        </h4>

                        {!closeFilter && (
                            <ul className="active">
                                {parse(status.message.value)}
                            </ul>
                        )}
                    </div>
                )}
        </header>
    );
};

export default Header;
