import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleSearch } from "../../actions/search.action";
import LanguagesSelection from "./LanguagesSelection";
import { MyContext } from "../../contexts/MyContext";
import { isEmpty } from "../Utils";
import {
	toggleNotification,
	toogleParameter,
} from "../../actions/account.action";

import parse from "html-react-parser";

const AccountSection = () => {
	const dispatch = useDispatch();

	const { rootState, logoutUser } = useContext(MyContext);
	const { isAuth, theUser } = rootState;
	const [menuState, setMenuState] = useState(false);
	const [countNotification, setCountNotification] = useState("0");
	const notificationsReducer = useSelector(
		(state) => state.notificationsReducer.standardNotificationsReducer
	);
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);

	useEffect(() => {
		setCountNotification(notificationsReducer.totalNotRead);
	}, [notificationsReducer]);

	const dropdownHover = (e) => {
		if (e.target.classList.contains("onClick")) {
			setMenuState(!menuState);
		}
	};

	//PARAMETER
	const parameters = useSelector((state) => state.accountReducer.paramReducer);
	const notificationToggleReducer = useSelector(
		(state) => state.accountReducer.notificationReducer
	);

	useEffect(() => {
		if (isAuth) {
			if (!isEmpty(parameters)) {
				if (parameters) {
					document.querySelector(".parameters-container").classList.add("display");
					document.querySelector(".hide-background").classList.add("hover");
				} else {
					document
						.querySelector(".parameters-container")
						.classList.remove("display");
					document.querySelector(".hide-background").classList.remove("hover");
				}
			}
		}
	}, [parameters, isAuth]);

	const handleClickOutside = (event) => {
		if (!event.target.classList.contains("onClick")) {
			setMenuState(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	});

	return (
		<div className="account-section">
			<LanguagesSelection />
			<div className="search-button" onClick={() => dispatch(toggleSearch(true))}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
				>
					<path d="M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z" />
				</svg>
			</div>
			{isAuth && (
				<div
					className="notifications"
					onClick={() => dispatch(toggleNotification(!notificationToggleReducer))}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
					>
						<path d="M15.137 3.945c-.644-.374-1.042-1.07-1.041-1.82v-.003c.001-1.172-.938-2.122-2.096-2.122s-2.097.95-2.097 2.122v.003c.001.751-.396 1.446-1.041 1.82-4.667 2.712-1.985 11.715-6.862 13.306v1.749h20v-1.749c-4.877-1.591-2.195-10.594-6.863-13.306zm-3.137-2.945c.552 0 1 .449 1 1 0 .552-.448 1-1 1s-1-.448-1-1c0-.551.448-1 1-1zm3 20c0 1.598-1.392 3-2.971 3s-3.029-1.402-3.029-3h6z" />
					</svg>
					<div className="notifications-number">
						<span>{countNotification ? countNotification : "0"}</span>
					</div>
				</div>
			)}
			<div className="account">
				{!isAuth && (
					<div className="buttons">
						<NavLink to="/account" className="not-button">
							<div className="login mobile-menu">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm.707 13.946l-1.035 1.054h-.672v1h-1v1h-3v-2.292l3.146-3.185c.496 1.111 1.419 1.988 2.561 2.423zm5.293-4.279c0 2.025-1.642 3.667-3.667 3.667-2.024 0-3.666-1.642-3.666-3.667s1.642-3.667 3.666-3.667c2.025 0 3.667 1.642 3.667 3.667zm-1.375-1.375c0-.506-.41-.917-.917-.917s-.916.411-.916.917.409.917.916.917.917-.411.917-.917z" />
								</svg>
							</div>
						</NavLink>

						<NavLink to="/account/register" id="register-button">
							{translations && !isEmpty(translations["register"])
								? parse(translations["register"])
								: "register"
							}
						</NavLink>
						<NavLink to="/account/login" id="login-button">
							{translations && !isEmpty(translations["login"])
								? parse(translations["login"])
								: "login"
							}
						</NavLink>
					</div>
				)}
				{isAuth && (
					<div className="account-menu onClick" onClick={(e) => dropdownHover(e)}>
						<img
							className="onClick"
							src={theUser.avatar}
							alt="account"
							width="30"
							onClick={(e) => dropdownHover(e)}
						/>
						<span className="onClick" onClick={(e) => dropdownHover(e)}>
							{theUser.username}
						</span>

						<div className={ menuState ? "hover sub-menu" : "sub-menu" }>
							<ul>
								<li
									onClick={() => {
										dispatch(toggleNotification(!notificationToggleReducer));
									}}
								>
									<div
										className="notifications"
										onClick={() =>
											dispatch(toggleNotification(!notificationToggleReducer))
										}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
										>
											<path d="M15.137 3.945c-.644-.374-1.042-1.07-1.041-1.82v-.003c.001-1.172-.938-2.122-2.096-2.122s-2.097.95-2.097 2.122v.003c.001.751-.396 1.446-1.041 1.82-4.667 2.712-1.985 11.715-6.862 13.306v1.749h20v-1.749c-4.877-1.591-2.195-10.594-6.863-13.306zm-3.137-2.945c.552 0 1 .449 1 1 0 .552-.448 1-1 1s-1-.448-1-1c0-.551.448-1 1-1zm3 20c0 1.598-1.392 3-2.971 3s-3.029-1.402-3.029-3h6z"/>
										</svg>
										<div className="notifications-number">
											<span>{countNotification ? countNotification : "0"}</span>
										</div>
										<span>
											{translations
												? parse(translations["notifications"])
												: "notifications"}
										</span>
									</div>
								</li>
								<li
									onClick={() => {
										dispatch(toogleParameter(true));
									}}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
									>
										<path d="M24 13.616v-3.232c-1.651-.587-2.694-.752-3.219-2.019v-.001c-.527-1.271.1-2.134.847-3.707l-2.285-2.285c-1.561.742-2.433 1.375-3.707.847h-.001c-1.269-.526-1.435-1.576-2.019-3.219h-3.232c-.582 1.635-.749 2.692-2.019 3.219h-.001c-1.271.528-2.132-.098-3.707-.847l-2.285 2.285c.745 1.568 1.375 2.434.847 3.707-.527 1.271-1.584 1.438-3.219 2.02v3.232c1.632.58 2.692.749 3.219 2.019.53 1.282-.114 2.166-.847 3.707l2.285 2.286c1.562-.743 2.434-1.375 3.707-.847h.001c1.27.526 1.436 1.579 2.019 3.219h3.232c.582-1.636.75-2.69 2.027-3.222h.001c1.262-.524 2.12.101 3.698.851l2.285-2.286c-.744-1.563-1.375-2.433-.848-3.706.527-1.271 1.588-1.44 3.221-2.021zm-12 2.384c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z"/>
									</svg>
									<span>
										{translations && !isEmpty(translations["settings"])
											? parse(translations["settings"])
											: "settings"
										}
									</span>
								</li>
								<li
									onClick={() => {
										logoutUser();
									}}
								>
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M15.5 8.05762L10.5417 4.2977V6.40483H4.75694V9.7104H10.5417V11.7887L15.5 8.05762ZM2.27778 2.2729H8.88889V0.620117H2.27778C1.36875 0.620117 0.625 1.36388 0.625 2.2729V13.8423C0.625 14.7514 1.36875 15.4951 2.27778 15.4951H8.88889V13.8423H2.27778V2.2729Z"
											fill="white"
										/>
									</svg>
									<span>
										{translations && !isEmpty(translations["logout"])
											? parse(translations["logout"])
											: "logout"
										}
									</span>
								</li>
							</ul>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default AccountSection;
