import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { getProducts} from "../../actions/stripeAdmin.action";
import { isEmpty } from "../../components/Utils";
import { AdminContext } from "../../contexts/AdminContext";
import EditSubscription from "./EditSubscription";

const AllSubscriptions = () => {
	const {archiveProduct} = useContext(AdminContext);
	const dispatch = useDispatch();
	const products = useSelector((state) => state.stripeAdminReducer.getProductsReducer);
	const [page, setPage] = useState(0);
	const [offset, setOffset] = useState(10);
	const [subsArray, setSubsArray] = useState({});
	const [editComponent, setEditComponent] = useState( null );

	useEffect(() => {
		dispatch(getProducts(1));
	}, [dispatch]);

	useEffect(() => {
		if (!isEmpty(products)) {
			setSubsArray(products);
		}
	}, [products]);

	const handleEdit = async (productId) => {
		if(editComponent === null) {
			setEditComponent(<EditSubscription id={productId}/>)
		} else {
			setEditComponent(null);
		}
	};
	const handleDelete = async (id) => {
		const data = await archiveProduct(id);
		if (data.active ===false) {
			dispatch(getProducts(1));
		} else {
			console.log(data);
		}
	};

	//PAGE
	const handlePage = (mode) => {
		let pageNumber = Math.ceil(subsArray.length / offset);
		if (mode === "next") {
			setPage(page + 1 > pageNumber - 1 ? 0 : page + 1);
		} else {
			setPage(page - 1 < 0 ? pageNumber - 1 : page - 1);
		}
	};

	//SEARCH
	const handleSearch = (input) => {
		let array = [...products];
		array = array.filter(
			elm => {
				return elm.id.toLowerCase().includes(input.toLowerCase()) ||
				elm.name?.toLowerCase().includes(input.toLowerCase()) ||
				elm.description?.toLowerCase().includes(input.toLowerCase())
			}
		);
		setSubsArray(array);
		setPage(0);
	};

	return (
		<div className="bo-all_subs">
             <div className="controls">
				<div className="search">
					<label htmlFor="search">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
						>
							<path d="M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z" />
						</svg>
					</label>
					<input
						type="search"
						autoComplete="off"
						name="search"
						id="search"
						placeholder="Chercher une variable"
						onInput={(e) => handleSearch(e.target.value)}
					/>
				</div>
				<div className="pages">
					<div className="buttons">
						<button onClick={() => handlePage("prev")}>Page précédente</button>
						<button onClick={() => handlePage("next")}>Page suivante</button>
					</div>

					<p>
						Page {page + 1}/{Math.ceil(subsArray.length / offset)}
					</p>
				</div>
				<div className="offset">
					<label htmlFor="offset">Nombre d'éléments affichés</label>
					<input
						type="number"
						name="offset"
						step="10"
						value={offset}
						onChange={(e) => setOffset(parseInt(e.currentTarget.value))}
						min="10"
						max={
							!isEmpty(Math.ceil(Object.entries(subsArray).length))
								? Math.ceil(Object.entries(subsArray).length) + 10
								: 100
						}
					/>
				</div>
			</div> 
			<div className="subs-list">
				<table>
					<thead>
						<tr>
							<th>ID</th>
							<th>Titre</th>
							<th>User Type</th>
							<th>Product Type</th>
							<th>Contenu</th>
							<th>Actions</th>
							
						</tr>
					</thead>
					<tbody>
						{!isEmpty(subsArray) &&
							subsArray
								.slice(page * offset, offset + page * offset)
								.map((products, index) => {
									return (
										<tr key={index}>
											<td>{products.id}</td>
											<td>{products.name}</td>
											<td>{products.metadata.type_of_user}</td>
											<td>{products.metadata.type_of_product}</td>
											<td>
												{!isEmpty(products.prices) &&
													products.prices.map(price => {
														return(
															<span key={price.id}>
																<span className="mr-1">{(price.unit_amount / 100).toLocaleString('eu-EU', {style: 'currency',currency: 'EUR'})}/</span>
																<span>{!isEmpty(price?.recurring?.interval) ? price.recurring.interval : "one-time"}</span>
															</span>
														);
													})
												}
											</td>
											<td className="d-flex">
												<button onClick={() => handleEdit(products.id)}>Edit</button>
												<button onClick={() => handleDelete(products.id)}>Archiver la souscription</button>
											</td>
										</tr>
									);
								})
						}
					</tbody>
				</table>
			</div>
			{editComponent}
		</div>
	);
};

export default AllSubscriptions;
