import React, { useContext, useEffect } from "react";
import Header from "../Header";
import Contact from "../Contact";
import Footer from "../Footer";

import Parameters from "../account/Parameters";
import { MyContext } from "../../contexts/MyContext";
import Popup from "../account/Popup";
import Notification from "../header/Notification";
import {useHistory} from "react-router";

const Home = (props) => {
	const { rootState, isLoggedIn } = useContext(MyContext);
	const { isAuth } = rootState;
	const history = useHistory();

	useEffect(() => {
		async function fetchData() {
			await isLoggedIn();
		}
		fetchData();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (isAuth !== null) {
			let urlRedirect = localStorage.getItem('loginRedirectUrl')
			if (isAuth === true && urlRedirect) {
				history.push(urlRedirect);
				localStorage.removeItem('loginRedirectUrl');
			}
		}
	}, [isAuth]);

	return (
		<>
			<Header />
			{isAuth && <Parameters />}
			{isAuth && <Notification />}
			{isAuth && <Popup />}
			{props.children}
			<Contact />
			<Footer />
		</>
	);
};

export default Home;
