import axios from "axios";
import React, { useEffect, useState } from "react";
import {isEmpty, removeTags} from "./Utils";
import SuccessError from "./account/SuccessError";
import { useDispatch, useSelector } from "react-redux";
import { toggleContactForm } from "../actions/account.action";

import parse from "html-react-parser";

const Contact = () => {
	const [state, setState] = useState(true);
	const [subject, setSubject] = useState(null);
	const [message, setMessage] = useState(null);
	const [name, setName] = useState(null);
	const [email, setEmail] = useState(null);
	const [popup, setPopup] = useState(false);
	const dispatch = useDispatch();
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);
	const contactForm = useSelector(
		(state) => state.accountReducer.contactFormReducer
	);
	const [popupContent, setPopupContent] = useState({
		title: "",
		text: "",
		textButton: "",
		type: "",
	});
	useEffect(() => {
		if (!contactForm && !popup) {
			document.querySelector(".hide-background").classList.remove("hover");
			document.querySelector(".hide-background-not").classList.remove("hover");
		} else {
			document.querySelector(".hide-background").classList.add("hover");
			document.querySelector(".hide-background-not").classList.add("hover");
		}
	}, [contactForm, popup]);
	useEffect(() => {
		if (popup) {
			dispatch(toggleContactForm(false));
			switch (popup) {
				case 1:
					//success
					setPopupContent({
						title: translations && !isEmpty(translations["success"])
							? parse(translations["success"])
							: "",
						text: translations && !isEmpty(translations["sendMessageSuccess"])
							? parse(translations["sendMessageSuccess"])
							: "",
						textButton: translations && !isEmpty(translations["loginSubmit"])
							? parse(translations["loginSubmit"])
							: "loginSubmit",
						type: "success",
					});
					break;
				case 2:
					//fail
					setPopupContent({
						title: translations && !isEmpty(translations["fail"])
							? parse(translations["fail"])
							: "",
						text: translations && !isEmpty(translations["sendMessageFail"])
							? parse(translations["sendMessageFail"])
							: "",
						textButton: translations && !isEmpty(translations["loginSubmit"])
							? parse(translations["loginSubmit"])
							: "loginSubmit",
						type: "fail",
					});
					break;
				default:
					return false;
			}
		} else {
			setPopupContent({ title: "", text: "", textButton: "", type: "" });
		}
	}, [popup, translations, dispatch]);

	const contactSubmit = async (event) => {
		event.preventDefault();
		if (state) {
			if (isEmpty(message)) {
				document.querySelector("#message").parentElement.classList.remove("error");
				setTimeout(() => {
					document.querySelector("#message").parentElement.classList.add("error");
				}, 10);
			}
		}
		if (isEmpty(name))
			document.querySelector("#name").parentElement.classList.add("error");
		if (isEmpty(email))
			document
				.querySelector("#email-contact")
				.parentElement.classList.add("error");
		subject === "none" || subject === null
			? document.querySelector("#subject").parentElement.classList.add("error")
			: document.querySelector("#subject").parentElement.classList.remove("error");

		if (document.querySelectorAll("#contact-form .error").length > 0) {
			document.querySelectorAll("#contact-form .error").forEach((elm) => {
				elm.classList.remove("error");
				setTimeout(() => {
					elm.classList.add("error");
				}, 10);
			});
		} else {
			const data = await axios.post(`${process.env.REACT_APP_API_URL}contact`, {
				subject,
				name,
				email,
				message,
			});

			if (data.data.success) {
				setPopup(1);
				console.log("message envoyé");
			} else {
				setPopup(2);
				console.log("message annulé");
			}
		}
	};
	//HANDLE ERRORS
	useEffect(() => {
		if (!isEmpty(name)) {
			name.length < 2 || !name.match(/^[a-zA-ZÀ-ÿ\s-]+$/)
				? document.querySelector("#name").parentElement.classList.add("error")
				: document.querySelector("#name").parentElement.classList.remove("error");
		}

		if (!isEmpty(email)) {
			email.length < 2 ||
			!email.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
				? document
						.querySelector("#email-contact")
						.parentElement.classList.add("error")
				: document
						.querySelector("#email-contact")
						.parentElement.classList.remove("error");
		}
		if (!state) {
			isEmpty(message)
				? document.querySelector("#message").parentElement.classList.add("error")
				: document
						.querySelector("#message")
						.parentElement.classList.remove("error");
		}
		setState(false);
		// eslint-disable-next-line
	}, [name, email, message]);
	useEffect(() => {
		contactForm &&
			document.querySelector("#subject").parentElement.classList.remove("error");
	}, [subject, contactForm]);
	return (
		<>
			{popup && <SuccessError content={popupContent} close={setPopup} />}
			{contactForm && (
				<section id="contact-form" className="display">
					<div
						className="cross"
						onClick={() => {
							dispatch(toggleContactForm(!contactForm));
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
						>
							<path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
						</svg>
					</div>
					<div className="title">
						<h3 className="decor">
							<span>
								{translations && !isEmpty(translations["contactUs"])
									? parse(translations["contactUs"])
									: "contactUs"
								}
							</span>
						</h3>
					</div>

					<form onSubmit={contactSubmit} noValidate>
						<div className="input">
							<label htmlFor="subject">
								{translations && !isEmpty(translations["object"])
									? parse(translations["object"])
									: "object"
								} *
							</label>
							<select
								id="subject"
								name="subject"
								onChange={(e) => setSubject(e.target.value)}
							>
								<option value="none">
									{translations && !isEmpty(translations["contactFormDefaultService"])
										? removeTags(
												translations["contactFormDefaultService"]
										  )
										: "contactFormDefaultService"}
								</option>
								<option value="commmercial">
									{translations && !isEmpty(translations["contactFormCommercial"])
										? removeTags(
											translations["contactFormCommercial"]
										)
										: "contactFormCommercial"}
								</option>
								<option value="technic">
									{translations && !isEmpty(translations["contactFormTechnic"])
										? removeTags(
											translations["contactFormTechnic"]
										)
										: "contactFormTechnic"}
								</option>
							</select>
						</div>
						<div className="input">
							<label htmlFor="message">
								{translations && !isEmpty(translations["contactMessage"])
									? parse(translations["contactMessage"])
									: "contactMessage"}{" "}
								*
							</label>
							<textarea
								id="message"
								name="message"
								onChange={(e) => setMessage(e.target.value)}
							/>
						</div>
						<div className="input">
							<label htmlFor="name">
								{translations && !isEmpty(translations["yourName"])
									? parse(translations["yourName"])
									: "yourName"
								} *
							</label>
							<input
								type="text"
								autoComplete="name"
								id="name"
								name="name"
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
						<div className="input">
							<label htmlFor="email-contact">
								{translations && !isEmpty(translations["registerEmail"])
									? parse(translations["registerEmail"])
									: "registerEmail"
								}
								{" "}
								*
							</label>
							<input
								type="email"
								autoComplete="email"
								id="email-contact"
								name="email-contact"
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>

						<input
							type="submit"
							value={
								translations && !isEmpty(translations["send"])
									? removeTags(translations["send"])
									: "send"
							}
						/>
					</form>
				</section>
			)}
		</>
	);
};

export default Contact;
