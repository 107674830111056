import {
	GET_EVENTS,
	GET_REMINDME_EVENTS,
	GET_EVENTS_SPORTS,
	CLEAR_EVENTS,
	GET_EVENTS_LEAGUES,
	GET_DAYS,
	GET_EVENTS_POPULAR,
	GET_ONE_EVENT,
} from "../actions/events.action";
import { combineReducers } from "redux";

const initialState = [];

function eventsAllReducer(state = initialState, action) {
	switch (action.type) {
		case GET_EVENTS:
			return action.payload;
		case GET_ONE_EVENT:
			return action.payload;
		default:
			return state;
	}
}

function eventsRemindMeReducer(state = initialState, action) {
	switch (action.type) {
		case GET_REMINDME_EVENTS:
			return action.payload;
		default:
			return state;
	}
}

function eventsPopularReducer(state = initialState, action) {
	switch (action.type) {
		case GET_EVENTS_POPULAR:
			return action.payload;
		default:
			return state;
	}
}

function distinctDaysReducer(state = initialState, action) {
	switch (action.type) {
		case GET_DAYS:
			return action.payload;
		default:
			return state;
	}
}

function eventsGroupByReducer(state = initialState, action) {
	switch (action.type) {
		case GET_EVENTS_SPORTS:
			//const newSportsEvents = state[action.sportId] ? [...state[action.sportId], ...action.payload] : action.payload; //push new data in the same key array
			return {
				...state,
				[action.sportId]: action.payload, //newSportsEvents
			};
		case GET_EVENTS_LEAGUES:
			return {
				...state,
				[action.leagueId]: action.payload,
			};
		case CLEAR_EVENTS:
			return initialState;
		default:
			return state;
	}
}
export default combineReducers({
	eventsAllReducer,
	eventsRemindMeReducer,
	eventsGroupByReducer,
	distinctDaysReducer,
	eventsPopularReducer,
});
