import React, {useCallback, useContext, useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {toggleModal} from "../../../actions/modal.action";
import {getCustomer} from "../../../actions/stripeAdmin.action";
import { AdminContext } from "../../../contexts/AdminContext";
import {isEmpty} from "../../../components/Utils";
import AsyncSelectInput from "../../../components/partials/AsyncSelectInput";
import {useHistory} from "react-router";

const Summary = ({stripe_id, user}) => {
    const dispatch = useDispatch();
    const customer = useSelector((state) => state.stripeAdminReducer.getCustomerReducer);
    const { updateStripeCustomer } = useContext(AdminContext);
    const [errorName, setErrorName] = useState(null);
    const [errorCountry, setErrorCountry] = useState(null);
    const [errorCity, setErrorCity] = useState(null);
    const [errorRegion, setErrorRegion] = useState(null);
    const [errorStreet, setErrorStreet] = useState(null);
    const [errorHouse, setErrorHouse] = useState(null);
    const [errorPostalCode, setErrorPostalCode] = useState(null);
    const [canUpdate, setCanUpdate] = useState(true);
    const [customerData, setCustomerData] = useState({});
    const [defaultCountryOption, setDefaultCountryOption] = useState(false);
    const history = useHistory();

    const getCountries = (like = "", code = "") => {
        return axios.get(`${process.env.REACT_APP_API_URL}getAllCountries&l=${like}&c=${code}`).then((result) => {
            if (result.data.result === "ok" && result.data.countries) {
                return result.data.countries;
            }
        });

    };

    const loadCountryOptions =  useCallback(async (inputValue, callback) => {
        if (inputValue.length >= 2) {
            callback(await getCountries(inputValue))
        }
    }, []);

    useEffect(async () => {
        if (!isEmpty(customerData) && !isEmpty(customerData.shipping.address.country)) {
            await getCountries("", customerData.shipping.address.country).then((res) => {
                if (!isEmpty(res[0])) {
                    setDefaultCountryOption(res[0])
                }
            });
        }

    }, [customerData]);

    useEffect(() => {
        if (!isEmpty(stripe_id)) {
            dispatch(getCustomer(stripe_id));
        }
    }, [stripe_id]);

    useEffect(() => {
        if(!isEmpty(customer) && isEmpty(customer.deleted) && user) {
            const newObj = {...customer};
            newObj["account_type"] = user.account_type;
            setCustomerData(newObj);
        }
    }, [customer, user]);

    useEffect(() => {
        if(!isEmpty(customerData)){
            if (!isEmpty(customerData.shipping.name)) {
                if (customerData.shipping.name.length < 2 || !customerData.shipping.name.match(/^[a-zA-ZÀ-ÿ\s-]*$/)) {
                    setErrorName(true);
                } else {
                    setErrorName(false)
                }
            }
            if (!isEmpty(customerData.shipping.address.country)) {
                if (customerData.shipping.address.country.length < 2) {
                    setErrorCountry(true);
                } else {
                    setErrorCountry(false);
                }
            }
            if (!isEmpty(customerData.shipping.address.city)) {
                if (customerData.shipping.address.city.length < 2 || !customerData.shipping.address.city.match(/^[a-zA-ZÀ-ÿ\s-]*$/)) {
                    setErrorCity(true);
                } else {
                    setErrorCity(false)
                }
            }
            if (!isEmpty(customerData.shipping.address.state)) {
                if (customerData.shipping.address.state.length < 2 || !customerData.shipping.address.state.match(/^[a-zA-ZÀ-ÿ\s-]*$/)) {
                    setErrorRegion(true);
                } else {
                    setErrorRegion(false)
                }
            }
            if (!isEmpty(customerData.shipping.address.line1)) {
                if (customerData.shipping.address.line1.length < 2) {
                    setErrorStreet(true);
                } else {
                    setErrorStreet(false)
                }
            }
            if (!isEmpty(customerData.shipping.address.line2)) {
                if (customerData.shipping.address.line2.length < 1) {
                    setErrorHouse(true);
                } else {
                    setErrorHouse(false)
                }
            }
            if (!isEmpty(customerData.shipping.address.postal_code)) {
                if (customerData.shipping.address.postal_code.length < 1 || !customerData.shipping.address.postal_code.match(/^[0-9_]*$/)) {
                    setErrorPostalCode(true);
                } else {
                    setErrorPostalCode(false)
                }
            }
        }
    }, [customerData]);

    useEffect(() => {
        if (errorName === false && errorCountry === false && errorCity === false && errorRegion === false && errorStreet === false && errorHouse === false && errorPostalCode === false) {
            setCanUpdate(true)
        } else {
            setCanUpdate(false)
        }
    }, [errorName, errorCountry, errorCity, errorRegion, errorStreet, errorHouse, errorPostalCode]);

    const updateCustomer = async () => {
        if (stripe_id !== null && !isEmpty(stripe_id) && canUpdate) {
            setCanUpdate(false);
            await updateStripeCustomer(stripe_id, customerData).then((result) => {
                if(result.result === "ok") {
                    dispatch(getCustomer(stripe_id));
                    dispatch(toggleModal({opened: true, success: true, text: "Customer data updated"}));
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                } else if (result.result === "bad" && result.error) {
                    dispatch(toggleModal({opened: true, success: false, text: result.error}));
                }
            }).catch((error) => {
                console.log(error)
            })
        }
        setCanUpdate(true);
    };

    return (
        <div className="summary">
            <div>
                <div className={`input mt-4 mb-0 ${errorName ? "error" : ""}`}>
                    <label htmlFor="name">Full Name*</label>
                    <input type="text" id="name" className="border" defaultValue={customerData?.shipping?.name} onChange={(e) => setCustomerData(prevState => ({...prevState, shipping: { ...customerData.shipping, name: e.target.value}}))}/>
                </div>

                <AsyncSelectInput
                    name="country"
                    defaultValue={defaultCountryOption}
                    classes="mt-2 mb-0"
                    handleChange={(name, value) => setCustomerData(prevState => ({...prevState, shipping: { ...customerData.shipping, address : { ...customerData.shipping.address, country: value}}}))}
                    optionValue="iso"
                    optionLabel="nicename"
                    isMulti={false}
                    isError={errorCountry}
                    loadOptions={loadCountryOptions}
                    label="Country*"
                />

                <div className="input mt-2 mb-0 ">
                    <label>Type of user</label>
                    <select
                        id="type"
                        required
                        name="account_type"
                        onChange={(e) => setCustomerData(prevState => ({...prevState, account_type: e.target.value}))}
                        value={customerData.account_type}
                    >
                        <option value="fan">Fan</option>
                        <option value="athlete">Athlete</option>
                        <option value="club">Club</option>
                        <option value="media">Media</option>
                    </select>
                </div>

                <div className={`input mt-2 mb-0 ${errorCity ? "error" : ""}`}>
                    <label htmlFor="city">City*</label>
                    <input type="text" id="city" className="border" defaultValue={customerData?.shipping?.address.city}
                           onChange={(e) => setCustomerData(prevState => ({...prevState, shipping: { ...customerData.shipping, address : { ...customerData.shipping.address, city: e.target.value}}}))}
                    />
                </div>
                <div className={`input mt-2 mb-0 ${errorRegion ? "error" : ""}`}>
                    <label htmlFor="region">Province or region*</label>
                    <input type="text" id="region" className="border" defaultValue={customerData?.shipping?.address.state} onChange={(e) => setCustomerData(prevState => ({...prevState, shipping: { ...customerData.shipping, address : { ...customerData.shipping.address, region: e.target.value}}}))}/>
                </div>
                <div className={`input mt-2 mb-0 ${errorStreet ? "error" : ""}`}>
                    <label htmlFor="line1">Street*</label>
                    <input type="text" id="line1" className="border" defaultValue={customerData?.shipping?.address.line1} onChange={(e) => setCustomerData(prevState => ({...prevState, shipping: { ...customerData.shipping, address : { ...customerData.shipping.address, line1: e.target.value}}}))}/>
                </div>

                <div className={`input mt-2 mb-0 ${errorHouse ? "error" : ""}`}>
                    <label htmlFor="line2">Apartment or house*</label>
                    <input type="text" id="line2" className="border" defaultValue={customerData?.shipping?.address.line2} onChange={(e) => setCustomerData(prevState => ({...prevState, shipping: { ...customerData.shipping, address : { ...customerData.shipping.address, line2: e.target.value}}}))}/>
                </div>
                <div className={`input mt-2 mb-0 ${errorPostalCode ? "error" : ""}`}>
                    <label htmlFor="postal_code">Postal code*</label>
                    <input type="text" id="postal_code" className="border" defaultValue={customerData?.shipping?.address.postal_code} onChange={(e) => setCustomerData(prevState => ({...prevState, shipping: { ...customerData.shipping, address : { ...customerData.shipping.address, postal_code: e.target.value}}}))}/>
                </div>

                <div className="d-flex float-right mt-4">
                    <button className="btn btn-stripe"
                            disabled={!canUpdate}
                            style={{background: ""}}
                            onClick={updateCustomer}
                    >Save</button>
                </div>

            </div>
        </div>
    );
}

export default Summary;