import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Redirect, useLocation} from "react-router";
import Events from "../components/Events";
import Playlist from "../components/Playlist";
import WebTVS from "../components/WebTVS";
import Search from "../components/Search";
import Banner from "../components/sports/Banner";
import {isEmpty, removeTags} from "../components/Utils";
import Highlight from "../components/Highlight";
import { getHighlights } from "../actions/highlights.action";
import { getEvents } from "../actions/events.action";
import { toggleSearch } from "../actions/search.action";
import {getAllWebTvs, getWebTVS} from "../actions/webTVS.action";
import { getFuchsPlaylists } from "../actions/playlist.action";
import {getLeagues} from "../actions/leagues.action";
import {getDivisions} from "../actions/division.action";
import {getGroups} from "../actions/group.action";
import parse from "html-react-parser";
import {Helmet} from "react-helmet-async";
import RedirectApp from "../components/RedirectApp";

const Country = ({ match }) => {
	const {
		params: { countryName, type },
	} = match;
	let param = countryName;
	//GET STORES
	const dispatch = useDispatch();
	const location = useLocation();
	const events = useSelector(
		(state) => state.eventsReducer.eventsAllReducer
	);
	const playlistsVideo = useSelector(
		(state) => state.playlistAllReducer.fuchsPlaylistsReducer
	);
	const webTVS = useSelector(
		(state) => state.webTVSReducer
	);
	const allWebTVS = useSelector(
		(state) => state.allWebTvs
	);
	const highlights = useSelector(
		(state) => state.highlightReducer
	);
	const countries = useSelector(
		(state) => state.countryReducer.countries
	);
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const searchState = useSelector(
		(state) => state.searchReducer.searchToggleReducer
	);
	const [country, setCountry] = useState("");
	const [matchCountry, setMatchCountry] = useState(true);
	const translations = translationsReducer.translations;
	const selectedLanguage = translationsReducer.language;
	//CHECK IF PARAM ARE SUPPORTED SPORT
	// eslint-disable-next-line
	async function findSport(countries) {
		return await countries.filter((country) => country.countryName === param);
	}

	useEffect(() => {
		if (!isEmpty(countries) && countries.length > 0) {
			countries.find((obj) => {
				if (obj.countryName === param) {
					setCountry(obj);
				}
				return false;
			});
			findSport(countries).then((resolve) => {
				setMatchCountry(resolve.length > 0);
			});
		}
	}, [countries, param, findSport]);

	useEffect(() => {
		if (searchState) {
			dispatch(toggleSearch(false));
		}
		// eslint-disable-next-line
	}, [location]);

	useEffect(() => {
		if (!isEmpty(country.id)) {
			dispatch(getHighlights(countryName + "_country_page"));
			dispatch(getFuchsPlaylists(param + "_country_page", "yes", selectedLanguage, country.id));
			dispatch(getEvents(100, "", "", "", "", "", "", country.id));
			dispatch(getLeagues("", "", country.id, selectedLanguage));
			dispatch(getDivisions("", "", "", country.id, selectedLanguage));
			dispatch(getGroups("", "", "", "", country.id, selectedLanguage));
			dispatch(getWebTVS(1, 20, countryName + "_country_page", 1));
			dispatch(getAllWebTvs(1, 20, countryName + "_country_page", 2));
		}
		// eslint-disable-next-line
	}, [country, selectedLanguage, countryName, param]);

	const onSelectFilter = (leagId, divId, groupId) => {
		if (country) {
			dispatch(getEvents(100, "", leagId, divId, groupId, "", "", country.id));
		}
	};

	const onWebTvSelectFilter = (leagueId, divisionId, groupId) => {
		dispatch(getWebTVS(1, 20, countryName + "_country_page", 1, leagueId, divisionId, groupId));
	};

	const onAllWebTvSelectFilter = (leagueId, divisionId, groupId) => {
		dispatch(getAllWebTvs(1, 20, countryName + "_country_page", 2, leagueId, divisionId, groupId));
	};

	return (
		<>
			<Helmet>
				<>
					{!isEmpty(translations) && !isEmpty(translations[country.countryName]) && (
						<title>{`Fuchs Sports International - ${removeTags(translations[country.countryName])}`}</title>
					)}

					{!isEmpty(translations) && !isEmpty(translations[country.countryName]) && (
						<meta name="title" content={`Fuchs Sports International - ${removeTags(translations[country.countryName])}`} />
					)}

					{!isEmpty(translations) && !isEmpty(translations[country.countryName]) && (
						<meta name="description" content={`Fuchs Sports International - ${removeTags(translations[country.countryName])}`} />
					)}

					{!isEmpty(translations) && !isEmpty(translations[country.countryName]) && (
						<meta property="og:title" content={`Fuchs Sports International - ${removeTags(translations[country.countryName])}`} />
					)}

					{!isEmpty(translations) && !isEmpty(translations[country.countryName]) && (
						<meta property="og:description" content={`Fuchs Sports International - ${removeTags(translations[country.countryName])}`} />
					)}
				</>
			</Helmet>

			<RedirectApp page="country"/>

			<div>
				{!matchCountry && <Redirect to="/" />}
				{isEmpty(param) && <Redirect to="/" />}

				<Banner
					title={
						translations
							? parse(
								translations[country.countryName]
									? translations[country.countryName]
									: ""
							  )
							: param
					}
					bannerPatch={country.bannerPatch}
				/>
				{searchState && <Search />}

				{!isEmpty(param) && (isEmpty(type) || type === "events") && (
					<Events
						events={events}
						filter={true}
						onSelectFilter={onSelectFilter}
						countryId={country.id}
					/>
				)}

				{isEmpty(type) && (
					<WebTVS
						webTVS={webTVS}
						listNumber="1"
						pageType={countryName + "_country_page"}
						titleValue={"champTitle"}
						onSelectFilter={onWebTvSelectFilter}
					/>
				)}

				{isEmpty(type) && (
					<WebTVS
						webTVS={allWebTVS}
						listNumber="2"
						pageType={countryName + "_country_page"}
						titleValue={"webTVSTitle"}
						onSelectFilter={onAllWebTvSelectFilter}
					/>
				)}

				{!isEmpty(playlistsVideo) && (isEmpty(type) || type === "videos") &&
				Object.keys(playlistsVideo.playlists)
					.sort((a, b) => {
						return (
							parseInt(playlistsVideo["playlists"][b].sort_order) -
							parseInt(playlistsVideo["playlists"][a].sort_order)
						);
					})
					.map((key, index) => {
						return (
							<Playlist
								key={index}
								data={playlistsVideo["playlists"][key]}
								color={index}
								playlistId={key}
								clubPlayerId={""}
							/>
						);
					})}

				{!isEmpty(param) && (isEmpty(type) || type === "events") && (
					<Highlight
						highlights={
							!isEmpty(highlights) &&
							!isEmpty(highlights.highlight) &&
							highlights.highlight
								.sort((a, b) => a.sport_order - b.sport_order)
						}
					/>
				)}
			</div>
		</>
	);
};

export default Country;
