import { useLocation } from "react-router-dom";

import moment from "moment";

export const isEmpty = (value) => {
	//CHECK IF INPUT IS EMPTY
	return (
		value === undefined ||
		value === null ||
		(typeof value === "object" && Object.keys(value).length === 0) ||
		(typeof value === "string" && value.trim().length === 0)
	);
};

export const isFunction = (value) => {
	return typeof value === "function";
};

export const removeTags = (value) => {
	return value.replace(/<[^>]+>/g, '');
};

export const useQuery = () => {
	//GET URL PARAMS
	return new URLSearchParams(useLocation().search);
};

export const addResolution = (resolution, imgPath) => {
	let resolutions = resolution;
	let img = imgPath.split("/");
	let src = imgPath.split("/");
	src.pop();
	src = src.join("/");
	let img2 = [];
	resolutions.map((res) => {
		img2.push(
			src +
			"/" +
			img[img.length - 1].split(".")[0] +
			"-" +
			res +
			"." +
			img[img.length - 1].split(".")[1]
		);
		return true;
	});
	return img2;
};

export const queryString = (queryData) => {
	//GET strung query from obj
	if (queryData instanceof Object) {
		return Object.keys(queryData)
			.map((key) => {
				return encodeURIComponent(key) + "=" + encodeURIComponent(queryData[key]);
			})
			.join("&");
	} else {
		return queryData;
	}
};

export const removeNull = (object) => {
	//REMOVE value from obj where is null
	let newObj = {};
	for (let key in object) {
		if (
			object.hasOwnProperty(key) &&
			object[key] !== null &&
			!isEmpty(object[key])
		) {
			newObj[key] = object[key];
		}
	}
	return newObj;
};

export const hexToHSL = (H) => {
	// Convert hex to RGB first
	let r = 0,
		g = 0,
		b = 0;
	if (H.length === 4) {
		r = "0x" + H[1] + H[1];
		g = "0x" + H[2] + H[2];
		b = "0x" + H[3] + H[3];
	} else if (H.length === 7) {
		r = "0x" + H[1] + H[2];
		g = "0x" + H[3] + H[4];
		b = "0x" + H[5] + H[6];
	}
	// Then to HSL
	r /= 255;
	g /= 255;
	b /= 255;
	let cmin = Math.min(r, g, b),
		cmax = Math.max(r, g, b),
		delta = cmax - cmin,
		h = 0,
		s = 0,
		l = 0;

	if (delta === 0) h = 0;
	else if (cmax === r) h = ((g - b) / delta) % 6;
	else if (cmax === g) h = (b - r) / delta + 2;
	else h = (r - g) / delta + 4;

	h = Math.round(h * 60);

	if (h < 0) h += 360;

	l = (cmax + cmin) / 2;
	s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
	s = +(s * 100).toFixed(1);
	l = +(l * 100).toFixed(1);

	return { h, s, l };
};
export const RGBToHex = (r, g, b) => {
	r = r.toString(16);
	g = g.toString(16);
	b = b.toString(16);

	if (r.length === 1) r = "0" + r;
	if (g.length === 1) g = "0" + g;
	if (b.length === 1) b = "0" + b;

	return "#" + r + g + b;
};

export const capitalizeFirstLetter = (string) => {
	string = string.toLowerCase();
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isNumeric = (value) => {
	return /^-?\d+$/.test(value);
}

//RENDER DATE
export const dateRender = (rawDate) => {
	//RENDER A DATE IN MINUTES AND SECOND
	let duration = moment.duration(rawDate, "seconds");
	return duration.format("hh:mm:ss");
};

export const isMobileDevise = () => {
	return /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent);
};