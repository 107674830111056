import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty, removeTags } from "../Utils";

import parse from "html-react-parser";
import {useLocation} from "react-router";

const FilterRow = (props) => {
	const selectFilter = props.onSelectFilter;
	const leagueId = props.leagueId;
	const sportId = props.sportId;
	const countryId = props.countryId;
	const setIsFiltered = props.setIsFiltered;
	const slider = props.slider;
	const location = useLocation();
	const translationsReducer = useSelector((state) => state.langReducer.translationsReducer);
	const translations = translationsReducer.translations;
	const [champMenu, setChampMenu] = useState(false);
	const [divisionMenu, setDivisionMenu] = useState(false);
	const [groupMenu, setGroupMenu] = useState(false);
	const leagues = useSelector((state) => state.leaguesReducer.leagues);
	const divisions = useSelector((state) => state.divisionReducer.divisions);
	const groups = useSelector((state) => state.groupReducer.groups);

	const [filterDivisions, setFilterDivisions] = useState([]);
	const [filterGroups, setFilterGroups] = useState([]);

	const [leagueSelect, setLeagueSelect] = useState("");
	const [divisionSelect, setDivisionSelect] = useState("");
	const [groupSelect, setGroupSelect] = useState("");

	useEffect(() => {
		setFilterDivisions(divisions);
		setFilterGroups(groups);
	}, [divisions, groups]);

	useEffect(() => {
		!isEmpty(leagueId) && setLeagueSelect(leagueId);
	}, [leagueId]);

	useEffect(() => {
		setLeagueSelect("");
		setDivisionSelect("");
		setGroupSelect("");
	}, [sportId, countryId, location.pathname]);

	useEffect(() => {
		setDivisionSelect("");
		setGroupSelect("");
	}, [leagueSelect]);

	useEffect(() => {
		setGroupSelect("");
	}, [divisionSelect]);

	const getNameLeague = (id) => {
		let name = "";
		leagues.forEach((league) => {
			if (league.id === id) {
				name = !isEmpty(league.name) ? removeTags(league.name) : league.flig_uname
			}
		});

		return name;
	};

	const getNameDivision = (id) => {
		let name = "";
		divisions.forEach((division) => {
			if (division.id === id) {
				name = !isEmpty(division.name) ? removeTags(division.name) : division.id
			}
		});

		return name;
	};

	const getNameGroup = (id) => {
		let name = "";
		groups.forEach((group) => {
			if (group.id === id) {
				name = !isEmpty(group.name) ? removeTags(group.name) : group.id
			}
		});

		return name;
	};

	const onSelectFilter = (menu, id) => {
		setChampMenu(false);
		setDivisionMenu(false);
		setGroupMenu(false);
		setIsFiltered(true);
		if (!isEmpty(slider)) {
			slider.slickGoTo(0)
		}
		switch (menu) {
			case "league":
				setLeagueSelect(id);
				selectFilter(id, "", "", sportId, leagueId);
				break;
			case "division":
				setDivisionSelect(id);
				selectFilter(leagueSelect, id, "", sportId, leagueId);
				break;
			case "groups":
				setGroupSelect(id);
				selectFilter(leagueSelect, divisionSelect, id, sportId, leagueId);
				break;
			default:
				return;
		}
	};

	const dropdownClicked = (menu) => {
		switch (menu) {
			case "champ":
				divisionMenu && setDivisionMenu(!divisionMenu);
				groupMenu && setGroupMenu(!groupMenu);
				setChampMenu(!champMenu);
				break;
			case "division":
				champMenu && setChampMenu(!champMenu);
				groupMenu && setGroupMenu(!groupMenu);
				setDivisionMenu(!divisionMenu);
				break;
			case "groups":
				champMenu && setChampMenu(!champMenu);
				divisionMenu && setDivisionMenu(!divisionMenu);
				setGroupMenu(!groupMenu);
				break;
			default:
				setGroupMenu(false);
				setDivisionMenu(false);
				setChampMenu(false);
				return;
		}
	};


	const handleClickOutside = (event) => {
		if (event.target.closest('.sport-filter') === null) {
			setGroupMenu(false);
			setDivisionMenu(false);
			setChampMenu(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	});

	return (
		<div className="sport-filter">
			{isEmpty(leagueId) && (
				<div className="dropdown">
					<button className={`${champMenu ? "open dropbtn" : "dropbtn"}`} onClick={() => dropdownClicked("champ")}>
						{!isEmpty(leagueSelect)
							? getNameLeague(leagueSelect)
							: translations && !isEmpty(translations["championship"])
								? parse(translations["championship"])
								: "Championship"}
					</button>
					{champMenu && (
						<ul className="dropdown-content">
							<li onClick={() => onSelectFilter("league", "")}>
								{translations && !isEmpty(translations["allFilters"])
									? parse(translations["allFilters"])
									: "allFilters"}
							</li>
							{leagues &&
							leagues.map((league) => {
								if (!isEmpty(sportId)) {
									if (sportId === league.sport_id) {
										return (
											<li
												key={league.id}
												onClick={() => onSelectFilter("league", league.id)}
											>
												{!isEmpty(league.name) ? removeTags(league.name) : league.flig_uname}
											</li>
										);
									}
								} else {
									return (
										<li
											key={league.id}
											onClick={() => onSelectFilter("league", league.id)}
										>
											{!isEmpty(league.name) ? removeTags(league.name) : league.flig_uname}
										</li>
									);
								}
								return null;
							})}
						</ul>
					)}
				</div>
			)}
			<div className="dropdown">
				<button className={`${divisionMenu && !isEmpty(leagueSelect) ? "open dropbtn" : "dropbtn"}`} onClick={() => dropdownClicked("division")}>
					{!isEmpty(divisionSelect)
						? getNameDivision(divisionSelect)
						: translations && !isEmpty(translations["divisions"])
							? parse(translations["divisions"])
							: "Divisions"}
				</button>
				{divisionMenu && !isEmpty(leagueSelect) && (
					<ul className="dropdown-content">
						<li onClick={() => onSelectFilter("division", "")}>
							{translations && !isEmpty(translations["allFilters"])
								? parse(translations["allFilters"])
								: "allFilters"}
						</li>
						{filterDivisions &&
						filterDivisions.map((division) => {
							if (!isEmpty(leagueSelect)) {
								if (division.league === leagueSelect) {
									return (
										<li
											key={division.id}
											onClick={() => onSelectFilter("division", division.id)}
										>
											{!isEmpty(division.name) ? removeTags(division.name) : division.id}
										</li>
									);
								}
							} else if (!isEmpty(sportId)) {
								if (sportId === division.sport_id) {
									return (
										<li
											key={division.id}
											onClick={() => onSelectFilter("division", division.id)}
										>
											{!isEmpty(division.name) ? removeTags(division.name) : division.id}
										</li>
									);
								}
							} else {
								return (
									<li
										key={division.id}
										onClick={() => onSelectFilter("division", division.id)}
									>
										{!isEmpty(division.name) ? removeTags(division.name) : division.id}
									</li>
								);
							}
							return null;
						})}
					</ul>
				)}
			</div>

			<div className="dropdown">
				<button className={`${groupMenu && !isEmpty(divisionSelect) ? "open dropbtn" : "dropbtn"}`} onClick={() => dropdownClicked("groups")}>
					{!isEmpty(groupSelect)
						? getNameGroup(groupSelect)
						: translations && !isEmpty(translations["groups"])
							? parse(translations["groups"])
							: "Groups"}
				</button>
				{groupMenu && !isEmpty(divisionSelect) && (
					<ul className="dropdown-content">
						<li onClick={() => onSelectFilter("groups", "")}>
							{translations && !isEmpty(translations["allFilters"])
								? parse(translations["allFilters"])
								: "allFilters"}
						</li>
						{filterGroups &&
						filterGroups.map((group) => {
							if (!isEmpty(leagueSelect) && !isEmpty(divisionSelect)) {
								if (
									group.league === leagueSelect &&
									group.division === divisionSelect
								) {
									return (
										<li
											key={group.id}
											onClick={() => onSelectFilter("groups", group.id)}
										>
											{!isEmpty(group.name) ? removeTags(group.name) : group.id}
										</li>
									);
								}
							} else if (!isEmpty(leagueSelect) || !isEmpty(divisionSelect)) {
								if (
									group.league === leagueSelect ||
									group.division === divisionSelect
								) {
									return (
										<li
											key={group.id}
											onClick={() => onSelectFilter("groups", group.id)}
										>
											{!isEmpty(group.name) ? removeTags(group.name) : group.id}
										</li>
									);
								}
							} else if (!isEmpty(sportId)) {
								if (sportId === group.sport_id) {
									return (
										<li
											key={group.id}
											onClick={() => onSelectFilter("groups", group.id)}
										>
											{!isEmpty(group.name) ? removeTags(group.name) : group.id}
										</li>
									);
								}
							} else {
								return (
									<li key={group.id} onClick={() => onSelectFilter("groups", group.id)}>
										{!isEmpty(group.name) ? removeTags(group.name) : group.id}
									</li>
								);
							}
							return null;
						})}
					</ul>
				)}
			</div>
		</div>
	);
};

export default FilterRow;