import axios from "axios";
import {FastAverageColor} from "fast-average-color";
import React, { useContext, useEffect, useRef, useState } from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useDispatch, useSelector} from "react-redux";
import { hexToHSL, isEmpty } from "../../components/Utils";
import { AdminContext } from "../../contexts/AdminContext";
import {getWebTVS} from "../../actions/webTVS.action";
const WebTVSALL = (props) => {
	const openBlock = props.openBlock;
	const dispatch = useDispatch();
	const result = useRef(false);
	const search = useRef(false);
	const { setWebTVS } = useContext(AdminContext);
	const [selectedWebTVS, setSelectedWebTVS] = useState([]);
	const [list, setList] = useState(null);

	const webTVS = useSelector(
		(state) => state.webTVSReducer
	);

	useEffect(() => {
		dispatch(getWebTVS(1, 20, "custom_colour"));
	}, [dispatch, openBlock]);

	useEffect(() => {
		setSelectedWebTVS(webTVS);
	}, [webTVS]);

	const colorize = (img) => {
		const fac = new FastAverageColor();
		let image = new Image();
		image.crossOrigin = "anonymous";
		image.src = img.src;
		fac
			.getColorAsync(image)
			.then((color) => {
				let hsl = hexToHSL(color.hex);
				hsl.s = hsl.s * 4;
				img.parentElement.style.backgroundColor = `hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)`;
			})
			.catch((err) => console.log(err));
	};

	//DRAG AND DROP

	const handleDrag = (result) => {
		const { destination } = result;
		if (!destination) {
			return;
		}
		const items = Array.from(selectedWebTVS)
			.sort((a, b) => {
				return a.sort_order - b.sort_order;
			});
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		let web = items;
		web = web.map((webTV, index) => {
			webTV.sort_order = index + 1;
			webTV.type_page = "custom_colour";
			return webTV;
		});
		setSelectedWebTVS(web);
	};

	//SEARCH
	const handleSearch = (input) => {
		if (input.length >= 2) {
			axios
				.get(`${process.env.REACT_APP_API_URL}getWebTVS&lkn=${encodeURI(input)}`)
				.then((res) => {
					if (res.data.webTVS.length > 0) {
						let webTvs = res.data.webTVS.filter(
							(tv) => !selectedWebTVS.map( el => el.id ).includes(tv.id)
						);
						setList(webTvs);
					}
				});
			result.current.classList.add("use")
		} else {
			result.current.classList.remove("use");
		}
	};

	//ADD TO LIST
	const addToWebTVS = (webTV) => {
		search.current.value = "";
		result.current.classList.remove("use");
		selectedWebTVS.push(webTV);

		let web = selectedWebTVS.map((webTV, index) => {
			webTV.type_page = "custom_colour";
			webTV.sort_order = index + 1;
			return webTV;
		});
		setSelectedWebTVS(web);
	};

	//REMOVE TO LIST
	const removeToWebTVS = (removedItem) => {
		let webTvs = selectedWebTVS.filter(
			(tv) => tv.id !== removedItem.id
		).map((webTV, index) => {
			webTV.type_page = "custom_colour";
			webTV.sort_order = index + 1;
			return webTV;
		});

		setSelectedWebTVS(webTvs);
	};
	//////////
	//EDITOR//
	//////////
	const changeColor = (tv, elm) => {
		let color = elm.value;
		let index = selectedWebTVS.findIndex((webTV) => webTV.id === tv.id);
		elm.parentElement.parentElement.parentElement.setAttribute(
			"style",
			"background-color:" + color + " !important"
		);
		let webTVSClone = selectedWebTVS;
		webTVSClone[index].color = color;
		setSelectedWebTVS(webTVSClone);
	};

	const eraseColor = (tv, elm) => {
		let index = selectedWebTVS.findIndex((webTV) => webTV.id === tv.id);
		let webTVSClone = selectedWebTVS;
		webTVSClone[index].color = null;

		colorize(elm.parentElement.parentElement.querySelector("img"));

		setSelectedWebTVS(webTVSClone);
	};

	//UPDATE
	const updateWebTVS = async () => {
		document.querySelector(".loading-webtv svg").classList.add("display");
		document.querySelector(".loading-webtv span").innerHTML = "Uploaded";
		const data = await setWebTVS(selectedWebTVS, "custom_colour");
		if (data.success) {
			document.querySelector(".loading-webtv span").innerHTML = "Modifié avec succès";
		} else {
			console.log(data);
		}
		if (document.querySelector(".loading-webtv svg")) {
			setTimeout(() => {
				document.querySelector(".loading-webtv span").innerHTML = "Mettre à jour les WebTVS";
				document.querySelector(".loading-webtv svg").classList.remove("display");
			}, 2000);
		}
	};
	return (
		<div className="bo-webTVS-container">
			<div className="bo-webTVS">
				<div className="sticky">
					<div className="submit">
						<button onClick={updateWebTVS} className="loading-webtv">
							<span>Mettre à jour les WebTVS</span>
							<div>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path d="M13.75 22c0 .966-.783 1.75-1.75 1.75s-1.75-.784-1.75-1.75.783-1.75 1.75-1.75 1.75.784 1.75 1.75zm-1.75-22c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm10 10.75c.689 0 1.249.561 1.249 1.25 0 .69-.56 1.25-1.249 1.25-.69 0-1.249-.559-1.249-1.25 0-.689.559-1.25 1.249-1.25zm-22 1.25c0 1.105.896 2 2 2s2-.895 2-2c0-1.104-.896-2-2-2s-2 .896-2 2zm19-8c.551 0 1 .449 1 1 0 .553-.449 1.002-1 1-.551 0-1-.447-1-.998 0-.553.449-1.002 1-1.002zm0 13.5c.828 0 1.5.672 1.5 1.5s-.672 1.501-1.502 1.5c-.826 0-1.498-.671-1.498-1.499 0-.829.672-1.501 1.5-1.501zm-14-14.5c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2zm0 14c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2z" />
								</svg>
							</div>
						</button>
					</div>
				</div>
				<div className="list">
					<div className="search">
						<label htmlFor="search">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
							>
								<path d="M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z" />
							</svg>
							<svg
								onClick={() => {
									search.current.value = "";
									result.current.classList.remove("use");
								}}
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								className="cross"
							>
								<path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" />
							</svg>
						</label>
						<input
							ref={search}
							type="search"
							autoComplete="off"
							name="search"
							id="search"
							placeholder="Chercher une webTV"
							onInput={(e) => handleSearch(e.target.value)}
						/>
						<div className="result" ref={result}>
							<ul>
								{!isEmpty(list) &&
								list.map((item) =>
									<li key={item.id} onClick={(e) => addToWebTVS(item)}>
										{item.name}
									</li>
								)}
							</ul>
						</div>
					</div>
					<DragDropContext onDragEnd={handleDrag}>
						<Droppable droppableId="webtvs" direction="horizontal">
							{(provided) => (
								<div
									className="webtvs"
									{...provided.droppableProps}
									ref={provided.innerRef}
								>
									{!isEmpty(selectedWebTVS) &&
									selectedWebTVS
										.sort((a, b) => {
											return a.sort_order - b.sort_order;
										})
										.map((tv, index) => {
											return (
												<Draggable key={tv.id} draggableId={tv.id} index={index}>
													{(provided) => (
														<div
															data-number={index + 1}
															className="tv"
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
														>
															<div className="edit">
																<svg
																	onClick={() => removeToWebTVS(tv)}
																	className="cross"
																	xmlns="http://www.w3.org/2000/svg"
																	width="24"
																	height="24"
																	viewBox="0 0 24 24"
																>
																	<path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" />
																</svg>
																<div className="color">
																	<input
																		type="color"
																		name="color"
																		defaultValue={!isEmpty(tv.color) && tv.color}
																		onInput={(e) => changeColor(tv, e.currentTarget)}
																	/>
																</div>
																<div
																	className="clear"
																	onClick={(e) => eraseColor(tv, e.currentTarget)}
																>
																	<svg
																		width="24"
																		height="24"
																		xmlns="http://www.w3.org/2000/svg"
																		fillRule="evenodd"
																		clipRule="evenodd"
																	>
																		<path d="M5.662 23l-5.369-5.365c-.195-.195-.293-.45-.293-.707 0-.256.098-.512.293-.707l14.929-14.928c.195-.194.451-.293.707-.293.255 0 .512.099.707.293l7.071 7.073c.196.195.293.451.293.708 0 .256-.097.511-.293.707l-11.216 11.219h5.514v2h-12.343zm3.657-2l-5.486-5.486-1.419 1.414 4.076 4.072h2.829zm.456-11.429l-4.528 4.528 5.658 5.659 4.527-4.53-5.657-5.657z" />
																	</svg>
																</div>
															</div>
															<img
																src={tv.logo}
																alt={`${tv.name} logo`}
																title={`${tv.name} logo`}
																width="50"
																height="50"
																onLoad={(e) => {
																	isEmpty(tv.color)
																		? colorize(e.target)
																		: (e.target.parentElement.style.backgroundColor = tv.color);
																}}
															/>
															<h4>{tv.name}</h4>
														</div>
													)}
												</Draggable>
											);
										})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			</div>
		</div>
	);
};

export default WebTVSALL;
