import { GET_COUNTRIES } from "../actions/country.action";

const initialState = [];

export default function countryReducer(state = initialState, action) {
	switch (action.type) {
		case GET_COUNTRIES:
			return action.payload;
		default:
			return state;
	}
}
