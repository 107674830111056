import React, { useState, useEffect, useCallback, useContext } from "react";
import AthleteInfo from "./athleteSubmenu/AthleteInfo";
import Shop from "./common/Shop";
import AthleteVideos from "./athleteSubmenu/AthleteVideos";
import Orders from "./common/Orders";
import VideoPlayer from "./common/VideoPlayer";
import Plans from "./common/Plans";
import { NavLink } from "react-router-dom";
import parse from "html-react-parser";
import { isEmpty } from "../Utils";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import GroupSelect from "./common/GroupSelect";
import BillingInfo from "./common/BillingInfo";
import AthletePlans from "./athleteSubmenu/AthletePlans";
import { MyContext } from "../../contexts/MyContext";
import { useMediaQuery } from "react-responsive";

const Athlete = () => {
    let match = useRouteMatch();

    const {
        params: { type },
    } = match;

    const history = useHistory();
    const [actualSubMenu, setActualSubMenu] = useState("info");
    const [actualSubMenuComponent, setActualSubMenuComponent] = useState(
        <AthleteInfo />
    );
    const [countFill, setCountFill] = useState(0);
    const { rootState } = useContext(MyContext);
    const { theUser } = rootState;
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translations
    );

    useEffect(() => {
        let accessType = [
            "info",
            "shop",
            "videos",
            "orders",
            "billing-info",
            "plans",
            "video-player",
            "group-select",
        ].includes(type);
        if (accessType) {
            setActualSubMenu(type);
        } else {
            history.push("/profile/info");
        }
    }, [type]);

    useEffect(() => {
        setCountFill(theUser?.required_count_fill);
    }, [theUser]);

    useEffect(() => {
        switch (actualSubMenu) {
            case "info":
                setActualSubMenuComponent(<AthleteInfo />);
                break;
            case "shop":
                setActualSubMenuComponent(<Shop />);
                break;
            case "videos":
                setActualSubMenuComponent(<AthleteVideos />);
                break;
            case "orders":
                setActualSubMenuComponent(<Orders />);
                break;
            case "billing-info":
                setActualSubMenuComponent(<BillingInfo />);
                break;
            case "plans":
                setActualSubMenuComponent(<AthletePlans />);
                break;
            case "video-player":
                setActualSubMenuComponent(<VideoPlayer />);
                break;
            case "group-select":
                setActualSubMenuComponent(<GroupSelect />);
                break;
            default:
                setActualSubMenuComponent(<AthleteInfo />);
                break;
        }
    }, [actualSubMenu]);

    const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

    return (
        <div className="athlete-page">
            <section>
                <div className="submenu">
                    <span
                        className={`counter-complete ${
                            countFill === 17 ? "bg-success" : ""
                        }`}
                    >
                        {translations &&
                        !isEmpty(translations["profile_completed"])
                            ? parse(translations["profile_completed"])
                            : "Profile completed"}{" "}
                        {countFill}/17
                    </span>
                    <ul className="nav-tabs">
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/profile/info"
                                activeClassName=""
                                className={
                                    actualSubMenu === "info"
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                onClick={() => {
                                    setActualSubMenu("info");
                                    if (isMobile) {
                                        document
                                            .querySelector(".submenu")
                                            .classList.remove("menu-open");
                                    }
                                }}
                            >
                                {translations &&
                                !isEmpty(translations["my_info"])
                                    ? parse(translations["my_info"])
                                    : "My Information"}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/profile/shop"
                                activeClassName=""
                                className={
                                    actualSubMenu === "shop"
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                onClick={() => {
                                    setActualSubMenu("shop");
                                    if (isMobile) {
                                        document
                                            .querySelector(".submenu")
                                            .classList.remove("menu-open");
                                    }
                                }}
                            >
                                {translations && !isEmpty(translations["shop"])
                                    ? parse(translations["shop"])
                                    : "Shop"}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/profile/videos"
                                activeClassName=""
                                className={
                                    actualSubMenu === "videos"
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                onClick={() => {
                                    setActualSubMenu("videos");
                                    if (isMobile) {
                                        document
                                            .querySelector(".submenu")
                                            .classList.remove("menu-open");
                                    }
                                }}
                            >
                                {translations &&
                                !isEmpty(translations["myVideos"])
                                    ? parse(translations["myVideos"])
                                    : "My Videos"}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/profile/orders"
                                activeClassName=""
                                className={
                                    actualSubMenu === "orders"
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                onClick={() => {
                                    setActualSubMenu("orders");
                                    if (isMobile) {
                                        document
                                            .querySelector(".submenu")
                                            .classList.remove("menu-open");
                                    }
                                }}
                            >
                                {translations &&
                                !isEmpty(translations["myOrders"])
                                    ? parse(translations["myOrders"])
                                    : "My Orders"}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/profile/billing-info"
                                activeClassName=""
                                className={
                                    actualSubMenu === "billing-info"
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                onClick={() => {
                                    setActualSubMenu("billing-info");
                                    if (isMobile) {
                                        document
                                            .querySelector(".submenu")
                                            .classList.remove("menu-open");
                                    }
                                }}
                            >
                                {translations &&
                                !isEmpty(translations["billing"])
                                    ? parse(translations["billing"])
                                    : "Billing"}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/profile/plans"
                                activeClassName=""
                                className={
                                    actualSubMenu === "plans"
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                onClick={() => {
                                    setActualSubMenu("plans");
                                    if (isMobile) {
                                        document
                                            .querySelector(".submenu")
                                            .classList.remove("menu-open");
                                    }
                                }}
                            >
                                {translations && !isEmpty(translations["plans"])
                                    ? parse(translations["plans"])
                                    : "Plans"}
                            </NavLink>
                        </li>
                        {/* <li className="nav-item">
                            <NavLink
                                exact
                                to="/profile/video-player"
                                activeClassName=""
                                className={actualSubMenu === "video-player" ? "nav-link active" : "nav-link"}
                                onClick={() => {
                                    setActualSubMenu("video-player");
                                }}
                            >
                                {translations && !isEmpty(translations["videoPlayer"])
                                    ? parse(translations["videoPlayer"])
                                    : "VideoPlayer"
                                }
                            </NavLink>
                        </li> */}
                    </ul>
                    <div
                        className="mobile-menu"
                        onClick={() =>
                            document
                                .querySelector(".submenu")
                                .classList.contains("menu-open")
                                ? document
                                      .querySelector(".submenu")
                                      .classList.remove("menu-open")
                                : document
                                      .querySelector(".submenu")
                                      .classList.add("menu-open")
                        }
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 17h-12v-2h12v2zm0-4h-12v-2h12v2zm0-4h-12v-2h12v2z" />
                        </svg>
                    </div>
                </div>
                <article>{actualSubMenuComponent}</article>
            </section>
        </div>
    );
};

export default Athlete;
