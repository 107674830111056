import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {NavLink} from "react-router-dom";
import {isEmpty } from "../Utils";

import parse from "html-react-parser";

const Sports = () => {
	const sports = useSelector((state) => state.sportsReducer.sportsTypesReducer);
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);

	const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

	return (
		<div className="sports">
			<ul>
				{!isEmpty(sports) && !isEmpty(translations) &&
					sports.map((sport) => {
						return (
							<li key={sport.name}>
								<NavLink
									exact
									to={`/sport/${sport.name}`}
									className="item-menu"
									onClick={(e) =>
										isMobile &&
										document.querySelector(".main-header").classList.remove("menu-open")
									}
								>
									<img
										src={sport.iconPatch ? sport.iconPatch : `${process.env.REACT_APP_CACHE_SERVER}/img/icons/default.svg`}
										alt={sport.name}
										width="40"
										height="40"
									/>

									<span>
										{translations[sport.name] && !isEmpty(translations[sport.name])
											? parse(translations[sport.name])
											: sport.name}
									</span>
								</NavLink>
							</li>
						);
					})}
			</ul>
		</div>
	);
};

export default Sports;
