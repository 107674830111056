import React, {Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Input from "../../partials/Input";
import {isEmpty, removeTags} from "../../Utils";
import InputData from "../../partials/InputData";
import AsyncSelectInput from "../../partials/AsyncSelectInput";
import axios from "axios";
import SelectInput from "../../partials/SelectInput";
import moment from "moment";
import {MyContext} from "../../../contexts/MyContext";
import parse from "html-react-parser";
import Avatar from "../../account/Avatar";
import UploadId from "../common/UploadId";
import Delete from "../../account/Delete";
import Checkbox from "react-custom-checkbox";

const AthleteInfo = () => {
    const [infoContent, setInfoContent] = useState("personal")
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translations
    );
    const [athleteData, setAthleteData] = useState({});
    const [defaultCountryBirth, setDefaultCountryBirth] = useState(false);
    const [defaultCountryOption, setDefaultCountryOption] = useState(false);
    const [defaultLanguagesOption, setDefaultLanguagesOption] = useState(false);
    const [defaultClubOption, setDefaultClubOption] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [defaultNationalAchievements, setDefaultNationalAchievements] = useState({});
    const [defaultCareers, setDefaultCareers] = useState({});
    const [careers, setCareers] = useState([{"id": moment().unix()}]);
    const [achievements, setAchievements] = useState([{"id": moment().unix()}]);
    const [nationalAchievements, setNationalAchievements] = useState([{"id": moment().unix()}]);
    const [diplomas, setDiplomas] = useState([{"id": moment().unix()}]);
    const ref = useRef(); //hack for clear await when unmount component
    const [errors, setErrors] = useState({});
    const {rootState, updateInfoUser, isLoggedIn} = useContext(MyContext);
    const {isAuth, theUser} = rootState;

    const requiredFields = useMemo(() => {
        return [
            "name",
            "firstname",
            "email",
            "birthdate",
            "birth_place",
            "nationality",
            "passports",
            "languages",
            "size",
            "weight",
            "position",
            "strong_foot",
            "current_division",
            "current_club",
            "contract_end_date",
        ];
    }, []);

    const positionOptions = useMemo(() => {
        return [
            {value: "Goalkeeper"},
            {value: "Right fullback"},
            {value: "Left fullback"},
            {value: "Right center back (4d.)"},
            {value: "Left center back (4d.)"},
            {value: "Center back (3d.)"},
            {value: "Right center back (3d.)"},
            {value: "Left center back (3d.)"},
            {value: "Right wingback"},
            {value: "Left wingback"},
            {value: "Right winger"},
            {value: "Left winger"},
            {value: "Right defensive midfielder"},
            {value: "Left defensive midfielder"},
            {value: "Attacking midfielder"},
            {value: "Striker"},
            {value: "Right forward"},
            {value: "Left forward"}
        ];
    }, []);

    const strongFootOptions = useMemo(() => {
        return [
            {value: "left"},
            {value: "right"}
        ];
    }, []);

    const divisionsOptions = useMemo(() => {
        return [
            {value: "N2"},
            {value: "N3"}
        ];
    }, []);

    const achievementsOptions = useMemo(() => {
        return [
            {value: "championship"},
            {value: "cup"}
        ];
    }, []);

    const decrementCareer = (key) => {
        let copyCareers = [...careers];
        copyCareers.splice(key, 1)
        setCareers(copyCareers);
    };

    const incrementCareer = () => {
        setCareers([
            ...careers,
            {"id": moment().unix()}
        ]);
    };

    const decrementAchievements = (key) => {
        let copyAchievements = [...achievements];
        copyAchievements.splice(key, 1)
        setAchievements(copyAchievements);
    };

    const incrementAchievements = (key) => {
        setAchievements([
            ...achievements,
            {"id": moment().unix()}
        ]);
    };

    const decrementNationalAchievements = (key) => {
        let copyNationalAchievements = [...nationalAchievements];
        copyNationalAchievements.splice(key, 1)
        setNationalAchievements(copyNationalAchievements);
    };

    const incrementNationalAchievements = (key) => {
        setNationalAchievements([
            ...nationalAchievements,
            {"id": moment().unix()}
        ]);
    };

    const decrementDiploma = (key) => {
        let copyDiplomas = [...diplomas];
        copyDiplomas.splice(key, 1)
        setDiplomas(copyDiplomas);
    };

    const incrementDiploma = (key) => {
        setDiplomas([
            ...diplomas,
            {"id": moment().unix()}
        ]);
    };

    const getCountries = useCallback(async (like = "", code = "") => {
        return await axios.get(`${process.env.REACT_APP_API_URL}getAllCountries&l=${like}&c=${code}`).then((result) => {
            if (result.data.result === "ok" && result.data.countries) {
                return result.data.countries;
            }
        });
    }, []);

    const getLanguages = useCallback(async (like = "", code = "") => {
        return await axios.get(`${process.env.REACT_APP_API_URL}getAllLanguages&l=${like}&c=${code}`).then((result) => {
            if (result.data.result === "ok" && result.data.languages) {
                return result.data.languages;
            }
        });

    }, []);

    const getClubs = useCallback(async (input, id = "") => {
        return await axios.get(`${process.env.REACT_APP_API_URL}getWebTVS&lkn=${encodeURI(input)}&i=${id}`).then((result) => {
            if (result.data.result === "ok" && result.data.webTVS) {
                return result.data.webTVS;
            }
        });

    }, []);

    const loadLanguagesOptions = async (inputValue, callback) => {
        if (inputValue.length >= 2) {
            callback(await getLanguages(inputValue))
        }
    };

    const loadClubsOptions = async (inputValue, callback) => {
        if (inputValue.length >= 2) {
            callback(await getClubs(inputValue))
        }
    };

    const handleInputs = (name, value) => {
        setAthleteData(prevState => ({...prevState, [name]: value}));
    };

    const handleCheckBox = (name) => {
        setAthleteData(prevState => ({...prevState, ["share_information"]: name ? "yes" : "no"}));
    };

    const loadCountryOptions = useCallback(async (inputValue, callback) => {
        if (inputValue.length >= 2) {
            callback(await getCountries(inputValue))
        }
    }, []);

    const handleCareers = useCallback((name, value, key) => {
        let copyCareers = careers.map((item, index) => {
            if (index === key) {
                return {...item, [name]: value};
            } else {
                return item;
            }
        });
        setCareers(copyCareers);
    }, [careers]);

    const handleAchievements = useCallback((name, value, key) => {
        let copyAchievements = [...achievements];
        copyAchievements[key][name] = value;
        setAchievements(copyAchievements);
    }, [achievements]);

    const handleNationalAchievements = useCallback((name, value, key) => {
        let copyNationalAchievements = [...nationalAchievements];
        copyNationalAchievements[key][name] = value;
        setNationalAchievements(copyNationalAchievements);
    }, [nationalAchievements]);

    const handleDiploma = useCallback((name, value, key) => {
        let copyDiploma = [...diplomas];
        copyDiploma[key][name] = value;
        setDiplomas(copyDiploma);
    }, [diplomas]);

    const validate = async () => {
        let valid = {};
        await requiredFields.forEach((field) => {
            if (isEmpty(athleteData[field]) || athleteData[field].length < 1) {
                setErrors((prevState) => ({...prevState, [field]: true}));
                valid[field] = true;
            } else {
                setErrors(prevState => {
                    const copy = {...prevState};
                    delete copy[field];
                    return copy;
                });
                delete valid[field];
            }
        });
        return valid;
    };

    useEffect(() => {
        setAthleteData(theUser);
        if (!isEmpty(theUser.achievements)) {
            setAchievements(theUser.achievements);
        }
        if (!isEmpty(theUser.achievements_national)) {
            setNationalAchievements(theUser.achievements_national);

            theUser.achievements_national.map((element, key) => {
                getCountries("", element.country).then((res) => {
                    setDefaultNationalAchievements(prevState => ({...prevState, [key]: res[0]}))
                });
            });
        }
        if (!isEmpty(theUser.careers)) {
            setCareers(theUser.careers);

            theUser.careers.map((element, key) => {
                getCountries("", element.country).then((res) => {
                    setDefaultCareers(prevState => ({...prevState, [key]: res[0]}))
                });
            });
        }
        if (!isEmpty(theUser.diplomas)) {
            setDiplomas(theUser.diplomas);
        }

        if (!isEmpty(theUser.nationality)) {
            getCountries("", theUser.nationality).then((res) => {
                if (!isEmpty(res) && !isEmpty(res[0])) {
                    setDefaultCountryBirth(res[0]);
                }
            });
        }
        if (!isEmpty(theUser.passports)) {
            getCountries("", theUser.passports.join()).then((res) => {
                if (!isEmpty(res) && !isEmpty(res)) {
                    setDefaultCountryOption(res);
                }
            });
        }
        if (!isEmpty(theUser.languages)) {
            getLanguages("", theUser.languages.join()).then((res) => {
                if (!isEmpty(res) && !isEmpty(res)) {
                    setDefaultLanguagesOption(res);
                }
            });
        }

        if (!isEmpty(theUser.current_club)) {
            getClubs("", theUser.current_club).then((res) => {
                if (!isEmpty(res) && !isEmpty(res)) {
                    setDefaultClubOption(res);
                }
            });
        }
    }, [theUser]);

    const submitHandle = async () => {
        setWaiting(true);
        await validate().then((result) => {
            if (isAuth !== null && isAuth && Object.keys(result).length < 1 && !waiting) {
                const copyData = {
                    ...athleteData,
                    careers: careers,
                    achievements: achievements,
                    achievements_national: nationalAchievements,
                    diplomas: diplomas
                }
                updateInfoUser(copyData).then((result) => {
                    if (result.success === 1) {
                        setWaiting("succeeded");
                        isLoggedIn();
                        setTimeout(() => {
                            document
                                .querySelector("#validUpdate")
                                .classList.add("display");
                        }, 10);

                        setTimeout(() => {
                            if (document.querySelector("#validUpdate")) {
                                document
                                    .querySelector("#validUpdate")
                                    .classList.remove("display");
                            }
                        }, 3000);
                    } else if (result.success === 0 && result.errors && !isEmpty(result.errors.required)) {
                        const errorArray = result.errors.required.reduce((obj, cur) => ({...obj, [cur]: true}), {});
                        setErrors(errorArray);
                    }

                }).catch((error) => console.log(error));
            }
        });
        setWaiting(false);
    };

    return (
        <Fragment>
            {waiting && (
                <Fragment>
                    <div className="background position-fixed top-left bottom-right"
                         style={{backgroundColor: "rgb(0 0 0 / 51%)"}}/>
                    <div className="absolute-center ppv">
                        <div className="success-animation">
                            <div className="loader">
                                <div className={waiting === "succeeded" ? "circle-loader load-complete" : "circle-loader"}>
                                    <div className="checkmark draw"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
            <div ref={ref}>
                <div className="row">
                    <h2 className="title">
                        {translations && !isEmpty(translations["player_info"])
                            ? parse(translations["player_info"])
                            : "My player information"
                        }
                    </h2>
                </div>
                <div className="d-flex mt-4 justify-content-space-between">
                    <ul className="d-flex">
                        <li className={`btn pl-0 ${infoContent === "personal" ? "" : "grey"}`}
                            onClick={() => setInfoContent("personal")}>
                                {translations && !isEmpty(translations["personal_info"])
                                    ? parse(translations["personal_info"])
                                    : "Personal informations"
                                }
                        </li>
                        <li
                            className={`btn ${infoContent === "sport" ? "" : "grey"}`}
                            onClick={() => setInfoContent("sport")}>
                                {translations && !isEmpty(translations["sport_info"])
                                    ? parse(translations["sport_info"])
                                    : "Sport informations"
                                }
                            {(errors?.height || errors?.width || errors?.position || errors?.strong_foot || errors?.current_division || errors?.contract_end_date) &&
                            <span className="red ml-1">(Check values here)</span>}
                        </li>
                    </ul>
                    <div className="submit">
                        <div id="validUpdate">
                            {translations && !isEmpty(translations["updated"])
                                ? parse(translations["updated"])
                                : "updated"
                            }
                        </div>
                        <div className="btn btn-transparent" onClick={() => submitHandle()}>
                            {translations && !isEmpty(translations["modify_profile"])
                                ? parse(translations["modify_profile"])
                                : "Modify the profile"
                            }
                        </div>
                    </div>
                </div>


                <div className={infoContent === "personal" ? "d-block" : "d-none"}>
                    <div className="d-flex mt-4 mb-5">
                        <div className="image d-flex">
                            <Avatar />
                        </div>
                        <div className="image d-flex ml-4">
                            <UploadId typeAccount="athlete"/>
                        </div>
                        <div className="image d-flex ml-4">
                            <Delete />
                        </div>
                    </div>
                    <div className="row-inputs mt-4">
                        <Input
                            type="text"
                            name="firstname"
                            regex="^[a-zA-ZÀ-ÿ\\s-]*$"
                            value={athleteData?.firstname}
                            handleChange={handleInputs}
                            error={errors.firstname}
                            label={translations && !isEmpty(translations["registerFirstName"])
                                ? removeTags(translations["registerFirstName"])
                                : "registerFirstName"}
                        />
                        <Input
                            type="text"
                            name="name"
                            regex="^[a-zA-ZÀ-ÿ\\s-]*$"
                            value={athleteData?.name}
                            error={errors.name}
                            handleChange={handleInputs}
                            label={translations && !isEmpty(translations["registerLastName"])
                                ? removeTags(translations["registerLastName"])
                                : "registerLastName"}
                        />
                        <Input
                            type="text"
                            name="email"
                            regex='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
                            value={athleteData?.email}
                            disabled={true}
                            error={errors.email}
                            handleChange={handleInputs}
                            label={translations && !isEmpty(translations["registerEmail"])
                                ? removeTags(translations["registerEmail"])
                                : "registerEmail"}
                        />
                        <Input
                            type="text"
                            name="zipCode"
                            value={athleteData?.zipCode}
                            error={errors.zipCode}
                            handleChange={handleInputs}
                            label={translations && !isEmpty(translations["zipcodePH"])
                                ? removeTags(translations["zipcodePH"])
                                : "zipcodePH"
                            }
                        />
                        <Input
                            type="text"
                            name="mobile"
                            regex="^[\+]?[1-9]\d{1,14}$"
                            value={athleteData?.mobile}
                            error={errors.mobile}
                            handleChange={handleInputs}
                            label={translations && !isEmpty(translations["mobilePhone"])
                                ? removeTags(translations["mobilePhone"])
                                : "mobilePhone"}
                        />

                        <InputData
                            type="text"
                            name="birthdate"
                            value={athleteData?.birthdate}
                            handleChange={handleInputs}
                            error={errors?.birthdate}
                            minDate={new Date(1930, 1, 1)}
                            maxDate={new Date()}
                            label={translations && !isEmpty(translations["registerBirthdate"])
                                ? removeTags(translations["registerBirthdate"])
                                : "registerBirthdate"}
                        />
                        <Input
                            type="text"
                            name="birth_place"
                            value={athleteData?.birth_place}
                            error={errors?.birth_place}
                            handleChange={handleInputs}
                            label={translations && !isEmpty(translations["placeOfBirth"])
                                ? removeTags(translations["placeOfBirth"])
                                : "placeOfBirth"}
                        />
                        <AsyncSelectInput
                            name="nationality"
                            defaultValue={defaultCountryBirth}
                            handleChange={handleInputs}
                            optionValue="iso"
                            optionLabel="nicename"
                            isMulti={false}
                            error={errors?.nationality}
                            loadOptions={loadCountryOptions}
                            label={translations && !isEmpty(translations["nationality"])
                                ? removeTags(translations["nationality"])
                                : "nationality"}
                        />
                        <AsyncSelectInput
                            name="passports"
                            defaultValue={defaultCountryOption}
                            handleChange={handleInputs}
                            optionValue="iso"
                            optionLabel="nicename"
                            isMulti={true}
                            maxSelected={5}
                            error={errors?.passports}
                            loadOptions={loadCountryOptions}
                            label={translations && !isEmpty(translations["nationalityPassports"])
                                ? removeTags(translations["nationalityPassports"])
                                : "nationalityPassports"}
                        />
                        <AsyncSelectInput
                            name="languages"
                            defaultValue={defaultLanguagesOption}
                            handleChange={handleInputs}
                            optionValue="639_1"
                            optionLabel="name"
                            error={errors?.languages}
                            isMulti={true}
                            maxSelected={5}
                            loadOptions={loadLanguagesOptions}
                            label={translations && !isEmpty(translations["languages"])
                                ? removeTags(translations["languages"])
                                : "languages"}
                        />
                    </div>
                    <div className="inputs mt-4 checkbox">
                        <Checkbox
                            name="share_information"
                            checked={athleteData?.share_information === "yes"}
                            onChange={(event) => handleCheckBox(event)}
                            borderColor="#4f49a1"
                            borderWidth="1px"
                            containerClassName="d-block"
                            icon={
                                <svg fill="#4caf50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>
                            }
                            label={translations && !isEmpty(translations["share_info"])
                            ? removeTags(translations["share_info"])
                            : "I agree to share this information with Fuchs Sports and the club to which I belong"}
                        />
                    </div>
                </div>

                <div className={infoContent === "sport" ? "d-block" : "d-none"}>
                    <div className="row-inputs mt-4">
                        <Input
                            type="text"
                            name="size"
                            value={athleteData?.size}
                            error={errors?.size}
                            handleChange={handleInputs}
                            placeholder="0.00"
                            label={translations && !isEmpty(translations["height"])
                                ? removeTags(translations["height"])
                                : "height"}
                        />
                        <Input
                            type="text"
                            name="weight"
                            placeholder="kg"
                            error={errors?.weight}
                            value={athleteData?.weight}
                            handleChange={handleInputs}
                            label={translations && !isEmpty(translations["weight"])
                                ? removeTags(translations["weight"])
                                : "weight"}
                        />
                        <SelectInput
                            type="text"
                            name="position"
                            defaultValue={{value: athleteData?.position}}
                            handleChange={handleInputs}
                            optionValue="value"
                            optionLabel="value"
                            error={errors?.position}
                            options={positionOptions}
                            label={translations && !isEmpty(translations["position"])
                                ? removeTags(translations["position"])
                                : "position"}
                        />
                        <SelectInput
                            type="text"
                            name="strong_foot"
                            defaultValue={{value: athleteData?.strong_foot}}
                            handleChange={handleInputs}
                            optionValue="value"
                            optionLabel="value"
                            error={errors?.strong_foot}
                            options={strongFootOptions}
                            label={translations && !isEmpty(translations["strongFoot"])
                                ? removeTags(translations["strongFoot"])
                                : "strongFoot"}
                        />
                    </div>
                    <div className="row-inputs mt-4">
                        <Input
                            type="number"
                            name="vma"
                            value={athleteData?.vma}
                            error={errors?.vma}
                            handleChange={handleInputs}
                            label={translations && !isEmpty(translations["VMA"])
                                ? removeTags(translations["VMA"])
                                : "VMA"}
                        />
                        <SelectInput
                            type="text"
                            name="current_division"
                            defaultValue={{value: athleteData?.current_division}}
                            handleChange={handleInputs}
                            optionValue="value"
                            optionLabel="value"
                            error={errors?.current_division}
                            options={divisionsOptions}
                            label={translations && !isEmpty(translations["currentDivision"])
                                ? removeTags(translations["currentDivision"])
                                : "currentDivision"}
                        />

                        <AsyncSelectInput
                            name="current_club"
                            defaultValue={defaultClubOption}
                            handleChange={handleInputs}
                            optionValue="id"
                            optionLabel="name"
                            isMulti={false}
                            error={errors?.current_club}
                            loadOptions={loadClubsOptions}
                            label={translations && !isEmpty(translations["currentClub"])
                                ? removeTags(translations["currentClub"])
                                : "currentClub"}
                        />
                    </div>
                    <div className="row-inputs mt-4">
                        <Input
                            type="text"
                            name="current_coach"
                            value={athleteData?.current_coach}
                            error={errors?.current_coach}
                            handleChange={handleInputs}
                            label={translations && !isEmpty(translations["currentCoach"])
                                ? removeTags(translations["currentCoach"])
                                : "currentCoach"}
                        />

                        <InputData
                            type="text"
                            name="contract_end_date"
                            value={athleteData?.contract_end_date}
                            handleChange={handleInputs}
                            error={errors?.contract_end_date}
                            minDate={new Date()}
                            label={translations && !isEmpty(translations["dateEndContract"])
                                ? removeTags(translations["dateEndContract"])
                                : "dateEndContract"}
                        />
                        <Input
                            type="text"
                            name="player_agent"
                            value={athleteData?.player_agent}
                            error={errors?.player_agent}
                            handleChange={handleInputs}
                            label={translations && !isEmpty(translations["playerAgent"])
                                ? removeTags(translations["playerAgent"])
                                : "playerAgent"}
                        />
                    </div>
                    <div className="row-inputs mt-4">
                        <div className="career">
                            <div className="input">
                                <label>
                                    {translations && !isEmpty(translations["career"])
                                        ? removeTags(translations["career"])
                                        : "career"}
                                </label>
                                {careers.length > 0 && careers.map((career, key) => {
                                    return (
                                        <div className="inputs-block" key={career.id}>
                                            <Input
                                                type="text"
                                                name="label"
                                                value={!isEmpty(careers[key]) ? careers[key]["label"] : undefined}
                                                handleChange={(name, value) => handleCareers(name, value, key)}
                                                placeholder={translations && !isEmpty(translations["label"])
                                                    ? removeTags(translations["label"])
                                                    : "label"}
                                            />
                                            <AsyncSelectInput
                                                name="country"
                                                defaultValue={!isEmpty(defaultCareers[key]) ? defaultCareers[key] : undefined}
                                                handleChange={(name, value) => handleCareers(name, value, key)}
                                                optionValue="iso"
                                                optionLabel="nicename"
                                                isMulti={false}
                                                backgroundColor={false}
                                                border={false}
                                                loadOptions={loadCountryOptions}
                                                placeholder={translations && !isEmpty(translations["country"])
                                                    ? removeTags(translations["country"])
                                                    : "country"}
                                            />
                                            <Input
                                                type="text"
                                                name="location"
                                                value={!isEmpty(careers[key]) ? careers[key]["location"] : ""}
                                                handleChange={(name, value) => handleCareers(name, value, key)}
                                                placeholder={translations && !isEmpty(translations["location"])
                                                    ? removeTags(translations["location"])
                                                    : "location"}
                                            />
                                            <Input
                                                type="text"
                                                name="division"
                                                value={!isEmpty(careers[key]) ? careers[key]["division"] : ""}
                                                handleChange={(name, value) => handleCareers(name, value, key)}
                                                placeholder={translations && !isEmpty(translations["division"])
                                                    ? removeTags(translations["division"])
                                                    : "division"}
                                            />
                                            <Input
                                                type="number"
                                                name="start_year"
                                                value={!isEmpty(careers[key]) ? careers[key]["start_year"] : ""}
                                                handleChange={(name, value) => handleCareers(name, value, key)}
                                                placeholder={translations && !isEmpty(translations["start_year"])
                                                    ? removeTags(translations["start_year"])
                                                    : "start_year"}
                                            />
                                            <Input
                                                type="text"
                                                name="end_year"
                                                value={!isEmpty(careers[key]) ? careers[key]["end_year"] : ""}
                                                handleChange={(name, value) => handleCareers(name, value, key)}
                                                placeholder={translations && !isEmpty(translations["and_year"])
                                                    ? removeTags(translations["and_year"])
                                                    : "and_year"}
                                            />

                                            <div className="input">
                                                {key > 0 && (
                                                    <input
                                                        type="button"
                                                        value={translations && !isEmpty(translations["remove"])
                                                        ? removeTags(translations["remove"])
                                                        : "remove"}
                                                        onClick={() => decrementCareer(key)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="submit">
                                <input
                                    type="button"
                                    value={translations && !isEmpty(translations["addToCareer"])
                                    ? removeTags(translations["addToCareer"])
                                    : "addToCareer"}
                                    onClick={incrementCareer}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="row-inputs mt-4">
                        <div className="achievement">
                            <div className="input">
                                <label>
                                    {translations && !isEmpty(translations["achievements"])
                                        ? removeTags(translations["achievements"])
                                        : "achievements"}
                                </label>
                                {achievements.length > 0 && achievements.map((achievement, key) => {
                                    return (
                                        <div className="inputs-block" key={achievement.id}>
                                            <SelectInput
                                                type="text"
                                                name="type"
                                                defaultValue={{value: !isEmpty(achievements[key]) ? achievements[key]["type"] : ""}}
                                                handleChange={(name, value) => handleAchievements(name, value, key)}
                                                optionValue="value"
                                                optionLabel="value"
                                                backgroundColor={false}
                                                border={false}
                                                options={achievementsOptions}
                                                placeholder={translations && !isEmpty(translations["country"])
                                                    ? removeTags(translations["country"])
                                                    : "country"}
                                            />
                                            <Input
                                                type="text"
                                                name="label"
                                                value={!isEmpty(achievements[key]) ? achievements[key]["label"] : ""}
                                                handleChange={(name, value) => handleAchievements(name, value, key)}
                                                placeholder={translations && !isEmpty(translations["label"])
                                                    ? removeTags(translations["label"])
                                                    : "label"}
                                            />

                                            <div className="input">
                                                {key > 0 && (
                                                    <input
                                                        type="button"
                                                        value="Remove"
                                                        onClick={() => decrementAchievements(key)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="submit">
                                <input
                                    type="button"
                                    value="Add Achievements"
                                    onClick={incrementAchievements}
                                />
                            </div>
                        </div>


                        <div className="national-achievement">
                            <div className="input">
                                <label>National achievements</label>
                                {nationalAchievements.length > 0 && nationalAchievements.map((achievement, key) => {
                                    return (
                                        <div className="inputs-block" key={achievement.id}>

                                            <Input
                                                type="text"
                                                name="label"
                                                value={!isEmpty(nationalAchievements[key]) ? nationalAchievements[key]["label"] : ""}
                                                handleChange={(name, value) => handleNationalAchievements(name, value, key)}
                                                placeholder={translations && !isEmpty(translations["label"])
                                                    ? removeTags(translations["label"])
                                                    : "label"}
                                            />

                                            <AsyncSelectInput
                                                name="country"
                                                defaultValue={!isEmpty(defaultNationalAchievements[key]) ? defaultNationalAchievements[key] : undefined}
                                                handleChange={(name, value) => handleNationalAchievements(name, value, key)}
                                                optionValue="iso"
                                                optionLabel="nicename"
                                                isMulti={false}
                                                backgroundColor={false}
                                                border={false}
                                                loadOptions={loadCountryOptions}
                                                placeholder={translations && !isEmpty(translations["country"])
                                                    ? removeTags(translations["country"])
                                                    : "country"}
                                            />

                                            <div className="input">
                                                {key > 0 && (
                                                    <input
                                                        type="button"
                                                        value="Remove"
                                                        onClick={() => decrementNationalAchievements(key)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="submit">
                                <input
                                    type="button"
                                    value="Add National Achievements"
                                    onClick={incrementNationalAchievements}
                                />
                            </div>
                        </div>

                    </div>


                    <div className="row-inputs mt-4">
                        <div className="diplomas">
                            <div className="input">
                                <label>Diplomas</label>
                                {diplomas.length > 0 && diplomas.map((career, key) => {
                                    return (
                                        <div className="inputs-block" key={career.id}>
                                            <Input
                                                type="text"
                                                name="label"
                                                value={!isEmpty(diplomas[key]) ? diplomas[key]["label"] : ""}
                                                handleChange={(name, value) => handleDiploma(name, value, key)}
                                                placeholder={translations && !isEmpty(translations["label"])
                                                    ? removeTags(translations["label"])
                                                    : "label"}
                                            />
                                            <Input
                                                type="text"
                                                name="location"
                                                value={!isEmpty(diplomas[key]) ? diplomas[key]["location"] : ""}
                                                handleChange={(name, value) => handleDiploma(name, value, key)}
                                                placeholder={translations && !isEmpty(translations["location"])
                                                    ? removeTags(translations["location"])
                                                    : "location"}
                                            />
                                            <Input
                                                type="number"
                                                name="start_year"
                                                value={!isEmpty(diplomas[key]) ? diplomas[key]["start_year"] : ""}
                                                handleChange={(name, value) => handleDiploma(name, value, key)}
                                                placeholder={translations && !isEmpty(translations["start_year"])
                                                    ? removeTags(translations["start_year"])
                                                    : "start_year"}
                                            />
                                            <Input
                                                type="text"
                                                name="end_year"
                                                value={!isEmpty(diplomas[key]) ? diplomas[key]["end_year"] : ""}
                                                handleChange={(name, value) => handleDiploma(name, value, key)}
                                                placeholder={translations && !isEmpty(translations["and_year"])
                                                    ? removeTags(translations["and_year"])
                                                    : "and_year"}
                                            />

                                            <div className="input">
                                                {key > 0 && (
                                                    <input
                                                        type="button"
                                                        value="Remove"
                                                        onClick={() => decrementDiploma(key)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="submit">
                                <input
                                    type="button"
                                    value="Add Diploma"
                                    onClick={incrementDiploma}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>
    );
};

export default AthleteInfo;
