import React, { useState } from "react";
import Playlists from "./Playlists";
import WebTVS from "./WebTVS";
import { isEmpty } from "../../components/Utils";
const EmissionPage = () => {
	const [ openBlock, setOpenBlock ] = useState(null);
	const handleMenu = (elm, block) => {
		let actual = document.querySelector(".homepage>ul>li>span.hover");
		if (!isEmpty(actual)) {
			actual.classList.remove("hover");
			if (actual !== elm) {
				elm.classList.add("hover");
			}
		} else {
			elm.classList.add("hover");
		}
		setOpenBlock(block)
	};
	return (
		<nav className="homepage">
			<ul>
                <li>
					<span onClick={(e) => handleMenu(e.target, "WebTVS1")}>Parcourir les Web TVS</span>
					{openBlock === "WebTVS1" && (
						<WebTVS listNumber="1"/>
					)}
				</li>
				<li>
					<span onClick={(e) => handleMenu(e.target, "WebTVS2")}>Parcourir les Web TVS (2ème liste)</span>
					{openBlock === "WebTVS2" && (
						<WebTVS listNumber="2" />
					)}
				</li>
				<li>
					<span onClick={(e) => handleMenu(e.target, "Playlists")}>Playlists</span>
					{openBlock === "Playlists" && (
						<Playlists />
					)}
				</li>
			</ul>
		</nav>
	);
};

export default EmissionPage;
