import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty, removeTags } from "./Utils";

import parse from "html-react-parser";
import {useLocation} from "react-router";

const FilterRow = (props) => {
    const selectFilter = props.onSelectFilter;
    const leagueId = props.leagueId;
    const sportId = props.sportId;
    const countryId = props.countryId;
    const setIsFiltered = props.setIsFiltered;
    const location = useLocation();
    const translationsReducer = useSelector((state) => state.langReducer.translationsReducer);
    const translations = translationsReducer.translations;
    const [champMenu, setChampMenu] = useState(false);
    const [groupMenu, setGroupMenu] = useState(false);
    const leagues = useSelector((state) => state.leaguesReducer.leagues);
    const groups = useSelector((state) => state.groupReducer.groups);

    const [filterGroups, setFilterGroups] = useState([]);

    const [leagueSelect, setLeagueSelect] = useState("");
    const [groupSelect, setGroupSelect] = useState("");

    useEffect(() => {
        setFilterGroups(groups);
    }, [groups]);

    useEffect(() => {
        !isEmpty(leagueId) && setLeagueSelect(leagueId);
    }, [leagueId]);

    useEffect(() => {
        setLeagueSelect("");
        setGroupSelect("");
    }, [sportId, countryId, location.pathname]);

    useEffect(() => {
        setGroupSelect("");
    }, [leagueSelect]);

    const getNameLeague = (id) => {
        let name = "";
        leagues.forEach((league) => {
            if (league.id === id) {
                name = !isEmpty(league.name) ? removeTags(league.name) : league.flig_uname
            }
        });

        return name;
    };


    const getNameGroup = (id) => {
        let name = "";
        groups.forEach((group) => {
            if (group.id === id) {
                name = !isEmpty(group.name) ? removeTags(group.name) : group.id
            }
        });

        return name;
    };

    const onSelectFilter = (menu, id) => {
        setChampMenu(false);
        setGroupMenu(false);
        setIsFiltered(true);
        switch (menu) {
            case "league":
                setLeagueSelect(id);
                selectFilter(id, "", "", sportId, leagueId);
                break;
            case "groups":
                setGroupSelect(id);
                selectFilter(leagueSelect, "", id, sportId, leagueId);
                break;
            default:
                return;
        }
    };

    const dropdownClicked = (menu) => {
        switch (menu) {
            case "champ":
                groupMenu && setGroupMenu(!groupMenu);
                setChampMenu(!champMenu);
                break;
            case "groups":
                champMenu && setChampMenu(!champMenu);
                setGroupMenu(!groupMenu);
                break;
            default:
                setGroupMenu(false);
                setChampMenu(false);
                return;
        }
    };


    const handleClickOutside = (event) => {
        if (event.target.closest('.sport-filter') === null) {
            setGroupMenu(false);
            setChampMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return (
        <div className="sport-filter">
            {isEmpty(leagueId) && (
                <div className="dropdown">
                    <button className={`${champMenu ? "open dropbtn" : "dropbtn"}`} onClick={() => dropdownClicked("champ")}>
                        {!isEmpty(leagueSelect)
                            ? getNameLeague(leagueSelect)
                            : translations && !isEmpty(translations["championship"])
                                ? parse(translations["championship"])
                                : "Championship"}
                    </button>
                    {champMenu && (
                        <ul className="dropdown-content">
                            <li onClick={() => onSelectFilter("league", "")}>
                                {translations && !isEmpty(translations["allFilters"])
                                    ? parse(translations["allFilters"])
                                    : "allFilters"}
                            </li>
                            {leagues &&
                            leagues.map((league) => {
                                if (!isEmpty(sportId)) {
                                    if (sportId === league.sport_id) {
                                        return (
                                            <li
                                                key={league.id}
                                                onClick={() => onSelectFilter("league", league.id)}
                                            >
                                                {!isEmpty(league.name) ? removeTags(league.name) : league.flig_uname}
                                            </li>
                                        );
                                    }
                                } else {
                                    return (
                                        <li
                                            key={league.id}
                                            onClick={() => onSelectFilter("league", league.id)}
                                        >
                                            {!isEmpty(league.name) ? removeTags(league.name) : league.flig_uname}
                                        </li>
                                    );
                                }
                                return null;
                            })}
                        </ul>
                    )}
                </div>
            )}

            <div className="dropdown">
                <button className={`${groupMenu && !isEmpty(leagueSelect) ? "open dropbtn" : "dropbtn"}`} onClick={() => dropdownClicked("groups")}>
                    {!isEmpty(groupSelect)
                        ? getNameGroup(groupSelect)
                        : translations && !isEmpty(translations["groups"])
                            ? parse(translations["groups"])
                            : "Groups"}
                </button>
                {groupMenu && !isEmpty(leagueSelect) && (
                    <ul className="dropdown-content">
                        <li onClick={() => onSelectFilter("groups", "")}>
                            {translations && !isEmpty(translations["allFilters"])
                                ? parse(translations["allFilters"])
                                : "allFilters"}
                        </li>
                        {filterGroups &&
                        filterGroups.map((group) => {
                            if (!isEmpty(leagueSelect) && !isEmpty(leagueSelect)) {
                                if (
                                    group.league === leagueSelect
                                ) {
                                    return (
                                        <li
                                            key={group.id}
                                            onClick={() => onSelectFilter("groups", group.id)}
                                        >
                                            {!isEmpty(group.name) ? removeTags(group.name) : group.id}
                                        </li>
                                    );
                                }
                            } else if (!isEmpty(sportId)) {
                                if (sportId === group.sport_id) {
                                    return (
                                        <li
                                            key={group.id}
                                            onClick={() => onSelectFilter("groups", group.id)}
                                        >
                                            {!isEmpty(group.name) ? removeTags(group.name) : group.id}
                                        </li>
                                    );
                                }
                            } else {
                                return (
                                    <li key={group.id} onClick={() => onSelectFilter("groups", group.id)}>
                                        {!isEmpty(group.name) ? removeTags(group.name) : group.id}
                                    </li>
                                );
                            }
                            return null;
                        })}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default FilterRow;