import React, {useContext, useState} from "react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import {isEmpty} from "../Utils";
import {MyContext} from "../../contexts/MyContext";

const Delete = () => {
    const translationsReducer = useSelector(
        (state) => state.langReducer.translationsReducer
    );
    const { removeAccount, logoutUser, removeAvatar, isLoggedIn } = useContext(MyContext);
    const selectedLanguage = translationsReducer.language;
    const [modalRemoveAccount, setModalRemoveAccount] = useState(false);
    const [modalRemoveAvatar, setModalRemoveAvatar] = useState(false);
    const [password, setPassword] = useState(null);
    const [errorPasswordLogin, setErrorPasswordLogin] = useState(false);
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translations
    );

    const remove = async (event) => {
        event.preventDefault();
        if (!isEmpty(password) && password.length > 3 && selectedLanguage) {
            const data = await removeAccount(password, selectedLanguage);
            if (data.success) {
                logoutUser();
                window.location.reload();
            } else {
                setErrorPasswordLogin(true);
                document.querySelector("#password").parentElement.classList.add("error");
            }
        }
    };

    const deleteAvatar = async (event) => {
        event.preventDefault();
        const data = await removeAvatar();
        if (data.success) {
            isLoggedIn();
            console.log("Success!");
        } else {
            console.log("Error!");
        }
        setModalRemoveAvatar(false);
    };

    const onChangeValue = (password) => {
        setPassword(password);
        setErrorPasswordLogin(false);
        document.querySelector("#password").parentElement.classList.remove("error");
    };

    return (
        <div className="delete-buttons">
            <div id="delete">
                {!modalRemoveAccount && !modalRemoveAvatar &&
                    <button onClick={() => setModalRemoveAccount(!modalRemoveAccount)}>
                        {translations && !isEmpty(translations["deleteAccount"])
                            ? parse(translations["deleteAccount"])
                            : "deleteAccount"
                        }
                    </button>
                }

                {modalRemoveAccount &&
                    <div className="modal-delete">
                        <span>
                            {translations && !isEmpty(translations["confirmDeleteText"]) ?
                                parse(translations["confirmDeleteText"])
                                : "confirmDeleteText"
                            }
                        </span>


                        <div className="input select">
                            <label>
                                {translations && !isEmpty(translations["registerPassword"])
                                    ? parse(translations["registerPassword"])
                                    : "registerPassword"}{" "}
                                *
                            </label>
                            <input
                                type="password"
                                name="password"
                                id="password"
                                placeholder={
                                    translations && !isEmpty(translations["registerPasswordPH"])
                                        ? parse(translations["registerPasswordPH"]
                                            .replace("<p>", "")
                                            .replace("</p>", "")
                                        )
                                        : "registerPasswordPH"
                                }
                                required
                                autoFocus
                                onChange={(e) => onChangeValue(e.target.value)}
                            />
                            {errorPasswordLogin && (
                                <div className="errorDisplay">
                                    <p>Password Error !</p>
                                </div>
                            )}
                        </div>

                        <div className="button-block">
                            <button onClick={() => setModalRemoveAccount(!modalRemoveAccount)} className="cancel">Cancel</button>
                            <button onClick={remove}>Delete</button>
                        </div>

                    </div>
                }
            </div>
            <div id="delete-avatar">
                {!modalRemoveAccount && !modalRemoveAvatar &&
                    <button onClick={() => setModalRemoveAvatar(!modalRemoveAvatar)}>
                        {translations && !isEmpty(translations["deleteAvatar"])
                            ? parse(translations["deleteAvatar"])
                            : "deleteAvatar"
                        }
                    </button>
                }
                {modalRemoveAvatar &&
                    <div className="modal-delete">
                        <span>
                            {translations && !isEmpty(translations["confirmDeleteAvatar"]) ?
                                parse(translations["confirmDeleteAvatar"])
                                : "confirmDeleteAvatar"
                            }
                        </span>
                        <div className="button-block">
                            <button onClick={() => setModalRemoveAvatar(!modalRemoveAvatar)} className="cancel">Cancel</button>
                            <button onClick={deleteAvatar}>Delete</button>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Delete;
