import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Playlist from "../components/Playlist";
import Search from "../components/Search";
import Banner from "../components/sports/Banner";
import { isEmpty, removeTags } from "../components/Utils";
import WebTVS from "../components/WebTVS";
import { toggleSearch } from "../actions/search.action";
import {getAllWebTvs, getWebTVS} from "../actions/webTVS.action";
import { getFuchsPlaylists } from "../actions/playlist.action";
import {getLeagues} from "../actions/leagues.action";
import {getDivisions} from "../actions/division.action";
import {getGroups} from "../actions/group.action";
import {Helmet} from "react-helmet-async";
import RedirectApp from "../components/RedirectApp";

const Emission = () => {
	const dispatch = useDispatch();
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const selectedLanguage = translationsReducer.language;

	const playlistsVideo = useSelector(
		(state) => state.playlistAllReducer.fuchsPlaylistsReducer
	);
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);
	const searchState = useSelector(
		(state) => state.searchReducer.searchToggleReducer
	);
	const webTVS = useSelector(
		(state) => state.webTVSReducer
	);
	const allWebTVS = useSelector(
		(state) => state.allWebTvs
	);

	useEffect(() => {
		if (searchState) {
			dispatch(toggleSearch(false));
		}
		// eslint-disable-next-line
	}, [dispatch]);

	useEffect(() => {
		dispatch(getLeagues("", "", "", selectedLanguage));
		dispatch(getDivisions("", "", "", "", selectedLanguage));
		dispatch(getGroups("", "", "", "", "", selectedLanguage));
		dispatch(getFuchsPlaylists("emission_page", "yes"));
		dispatch(getWebTVS(1, 20, "emission_page", 1));
		dispatch(getAllWebTvs(1, 20, "emission_page", 2));
	}, [dispatch, selectedLanguage]);

	const onWebTvSelectFilter = (leagueId, divisionId, groupId) => {
		dispatch(getWebTVS(1, 20, "emission_page", 1, leagueId, divisionId, groupId));
	};

	const onAllWebTvSelectFilter = (leagueId, divisionId, groupId) => {
		dispatch(getAllWebTvs(1, 20, "emission_page", 2, leagueId, divisionId, groupId));
	};

	return (
		<>
			<Helmet>
				<title>Fuchs Sports International</title>
				<meta property="og:image" content={`${process.env.REACT_APP_CACHE_SERVER}/img/icons/logo_fuchs_sports.png`} />
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
			</Helmet>

			<RedirectApp page="emissions"/>

			<div>
				<Banner
					title={
						translations && !isEmpty(translations["emissionTitle"]) ? removeTags(translations["emissionTitle"]) : "emissionTitle"
					}
				/>
				{searchState && <Search />}

				<WebTVS
					webTVS={webTVS}
					listNumber="1"
					pageType="emission_page"
					titleValue={"champTitle"}
					onSelectFilter={onWebTvSelectFilter}
				/>

				<WebTVS
					webTVS={allWebTVS}
					listNumber="2"
					pageType="emission_page"
					titleValue={"webTVSTitle"}
					onSelectFilter={onAllWebTvSelectFilter}
				/>

				{!isEmpty(playlistsVideo) &&
					Object.keys(playlistsVideo.playlists)
						.map((key, index) => {
							return (
								<Playlist
									key={index}
									data={playlistsVideo["playlists"][key]}
									color={index}
									playlistId={key}
									clubPlayerId={""}
								/>
							);
						})
				}
			</div>
		</>
	);
};

export default Emission;
