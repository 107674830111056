import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "../../actions/country.action";
import { getAllSports } from "../../actions/sports.action";
import { addResolution, isEmpty } from "../../components/Utils";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AdminContext } from "../../contexts/AdminContext";

const NaviguationBar = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getCountries("all"));
		dispatch(getAllSports());
	}, [dispatch]);

	let countries = useSelector((state) => state.countryReducer.countries);
	let sports = useSelector((state) => state.sportsReducer.sportsTypesReducer);

	function handleDNDCountry(result) {
		if (!result.destination) return;

		const items = Array.from(countries);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		countries = items;

		countries = countries.map((country, index) => {
			country.order = index + 1;
			return country;
		});
	}

	function handleDNDSport(result) {
		if (!result.destination) return;
		const items = Array.from(sports);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		sports = items;
		sports = sports.map((sport, index) => {
			sport.order = index + 1;
			return sport;
		});
	}

	function handleCheckCountry(target) {
		let index = countries.findIndex(
			(country) => country.countryName === target.id
		);
		if (!isEmpty(index))
			target.checked
				? (countries[index].visible = "yes")
				: (countries[index].visible = "no");
	}
	function handleCheckSport(target) {
		let index = sports.findIndex((sport) => sport.name === target.id);
		if (!isEmpty(index))
			target.checked
				? (sports[index].visible = "yes")
				: (sports[index].visible = "no");
	}

	const { updateNaviguationBar } = useContext(AdminContext);
	const submitForm = async () => {
		await updateNaviguationBar(sports, countries);
	};
	return (
		<div className="naviguation-bar">
			<div className="submit">
				<button onClick={submitForm}>Enregistrer</button>
			</div>
			<article>
				<h2>Pays</h2>

				<DragDropContext onDragEnd={handleDNDCountry}>
					<Droppable droppableId="countries-container" direction="horizontal">
						{(provided) => (
							<div
								className="countries-container"
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{!isEmpty(countries) &&
									countries.map((country, index) => {
										let imgs = addResolution(["500", "250"], country.img);

										return (
											<Draggable key={country.id} draggableId={country.id} index={index}>
												{(provided) => (
													<div
														className="checkbox"
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
													>
														<input
															type="checkbox"
															name={country.countryName}
															id={country.countryName}
															defaultChecked={country.visible === "yes" ? true : false}
															onChange={(e) => handleCheckCountry(e.target)}
														/>
														<label htmlFor={country.countryName}>
															<img
																src={imgs[0] ? imgs[0] : `${process.env.REACT_APP_CACHE_SERVER}/img/icons/default.svg`}
																alt={country.countryName}
																srcSet={`${imgs[0]} 500w, ${imgs[1]} 250w`}
																width="40"
																height="40"
															/>
														</label>
													</div>
												)}
											</Draggable>
										);
									})}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</article>

			<article>
				<h2>Sport</h2>
				<DragDropContext onDragEnd={handleDNDSport}>
					<Droppable droppableId="sports-container" direction="horizontal">
						{(provided) => (
							<div
								className="sports-container"
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{!isEmpty(sports) &&
									sports.map((sport, index) => {
										let imgs = addResolution(["500", "250"], sport.iconPatch);

										return (
											<Draggable key={sport.id} draggableId={sport.id} index={index}>
												{(provided) => (
													<div
														className="checkbox"
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
													>
														<input
															type="checkbox"
															name={sport.name}
															id={sport.name}
															defaultChecked={sport.visible === "yes" ? true : false}
															onChange={(e) => handleCheckSport(e.target)}
														/>
														<label htmlFor={sport.name}>
															<img
																src={imgs[0] ? imgs[0] : `${process.env.REACT_APP_CACHE_SERVER}/img/icons/default.svg`}
																alt={sport.name}
																srcSet={`${imgs[0]} 500w, ${imgs[1]} 250w`}
																width="40"
																height="40"
															/>
														</label>
													</div>
												)}
											</Draggable>
										);
									})}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</article>
		</div>
	);
};

export default NaviguationBar;
