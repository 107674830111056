import React, { useContext, useEffect, useState } from "react";
import { isEmpty } from "../../../components/Utils";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import { AdminContext } from "../../../contexts/AdminContext";
import { useDispatch } from "react-redux";

import { Redirect } from "react-router";
import { getHighlights } from "../../../actions/highlights.action";
import {timeout} from "workbox-core/_private";

const HighlightEditor = ({ highlight, isNew }) => {
	const dispatch = useDispatch();
	const { updateHighlight, createHighlight, delHighlight } = useContext(AdminContext);

	const [deleteButton, setDeleteButton] = useState(
		"Supprimer la slide (définitif)"
	);
	const [deleteSlide, setDeleteSlide] = useState(false);
	const [suppress, setSuppress] = useState(false);
	const [redirect, setRedirect] = useState(false);
	const [id, setId] = useState(highlight.id);
	const [name, setName] = useState(highlight.hightlightName);
	const [link, setLink] = useState(highlight.link);
	const [desktopImg, setDesktopImg] = useState(highlight.img);
	const [mobileImg, setMobileImg] = useState(highlight.imgMobile);
	const [desktopFile, setDesktopFile] = useState(null);
	const [mobileFile, setMobileFile] = useState(null);
	const [description, setDescription] = useState(highlight.description);

	//UPDATE SLIDE IN REAL TIME
	useEffect(() => {
		setSuppress(false);

		setId(highlight.id);
		setName(highlight.highlightName);
		setLink(highlight.link);
		setDesktopImg(highlight.img);
		setMobileImg(highlight.imgMobile);
		setDescription(highlight.description);
		setRedirect(false);
	}, [highlight, redirect]);

	const updateDesktopBanner = (file) => {
		let tpm = URL.createObjectURL(file);

		setDesktopFile(file);
		setDesktopImg(tpm);
	};

	const updateMobileBanner = (file) => {
		let tpmMobile = URL.createObjectURL(file);

		setMobileFile(file);
		setMobileImg(tpmMobile);
	};

	//GRAPHIC INPUT
	const activeInput = (id) => {
		let inputs = document.querySelectorAll(".slide-editor .input");
		inputs.forEach((input) => {
			input.classList.remove("select");
		});

		let input = document.getElementById(id);
		input.parentElement.classList.add("select");
	};

	const updateSlideForm = async () => {
		document
			.querySelector(".bo-highlights .loading svg")
			.classList.add("display");

		const formData = new FormData();

		formData.append("id", id);
		formData.append("name", name);
		formData.append("link", link);
		formData.append("description", description);

		formData.append("banner", desktopFile);
		formData.append("bannerMobile", mobileFile);

		if (desktopFile === null && mobileFile === null) {
			formData.append("mode", "no")
		} else if (desktopFile !== null && mobileFile !== null) {
			formData.append("mode", "updateAllBanner");
		} else if (desktopFile !== null){
			formData.append("mode", "updateDesktopBanner");
		} else {
			formData.append("mode", "updateMobileBanner");
		}


		const data = isNew
			? await createHighlight(formData)
			: await updateHighlight(formData);

		if (data.success) {
			console.log(data);
			dispatch(getHighlights());
			isNew && setRedirect(true);
		} else {
			console.log(data);
			dispatch(getHighlights());
		}
		document
			.querySelector(".bo-highlights .loading svg")
			.classList.remove("display");
	};

	const deleteSlideForm = async (id) => {
		const data = await delHighlight(id);
		if (data.success) {
			console.log(data);
			dispatch(getHighlights());
			setSuppress(true);
		} else {
			dispatch(getHighlights());
			console.log(data);
		}
	};

	const handleDelete = () => {
		if (!deleteSlide) {
			setDeleteButton("Cliquez encore une fois pour confirmer (définitif !)");
			setDeleteSlide(true);
		} else {
			setDeleteButton("Supprimer la slide (définitif)");
			deleteSlideForm(id);
			setDeleteSlide(false);
		}
	};

	return (
		<SimpleBar autoHide={false} className="slide-editor">
			{redirect && <Redirect push="/admin" to="/admin" />}
			{!suppress && (
				<>
					<div className="sticky">
						<div className="submit">
							<button onClick={updateSlideForm}>
								<span>Mettre à jour l'immanquable du mois</span>
								<div className="loading">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
									>
										<path d="M13.75 22c0 .966-.783 1.75-1.75 1.75s-1.75-.784-1.75-1.75.783-1.75 1.75-1.75 1.75.784 1.75 1.75zm-1.75-22c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm10 10.75c.689 0 1.249.561 1.249 1.25 0 .69-.56 1.25-1.249 1.25-.69 0-1.249-.559-1.249-1.25 0-.689.559-1.25 1.249-1.25zm-22 1.25c0 1.105.896 2 2 2s2-.895 2-2c0-1.104-.896-2-2-2s-2 .896-2 2zm19-8c.551 0 1 .449 1 1 0 .553-.449 1.002-1 1-.551 0-1-.447-1-.998 0-.553.449-1.002 1-1.002zm0 13.5c.828 0 1.5.672 1.5 1.5s-.672 1.501-1.502 1.5c-.826 0-1.498-.671-1.498-1.499 0-.829.672-1.501 1.5-1.501zm-14-14.5c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2zm0 14c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2z" />
									</svg>
								</div>
							</button>
						</div>
						{!isNew && (
							<div className="delete">
								<button onClick={handleDelete}>{deleteButton}</button>
							</div>
						)}
					</div>

					<section className="title ">
						<h2>Titre</h2>
						<div className="input">
							<label htmlFor="title">Titre de l'immanquable*</label>
							<input
								type="text"
								name="title"
								id="title"
								value={name || ''}
								onClick={(e) => activeInput(e.target.id)}
								required
								onFocus={(e) => activeInput(e.target.id)}
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
					</section>
					<section className="description">
						<h2>Description</h2>
						<div className="input">
							<label htmlFor="description">Description de l'immanquable *</label>
							<input
								type="text"
								name="description"
								id="description"
								value={description || ''}
								onClick={(e) => activeInput(e.target.id)}
								required
								onFocus={(e) => activeInput(e.target.id)}
								onChange={(e) => setDescription(e.target.value)}
							/>
						</div>
					</section>

					<section className="link">
						<h2>Lien du slide</h2>
						<div className="input">
							<label htmlFor="url">URL *</label>
							<input
								type="text"
								name="url"
								id="url"
								value={link || ''}
								onClick={(e) => activeInput(e.target.id)}
								required
								onFocus={(e) => activeInput(e.target.id)}
								onChange={(e) => setLink(e.target.value)}
							/>
						</div>
					</section>
					<section className="img">
						<h2>Bannière du slide</h2>

						<div className="input">
							<input
								type="file"
								name="desktopImg"
								id="desktopImg"
								onChange={(e) => updateDesktopBanner(e.target.files[0])}
							/>
							<label htmlFor="desktopImg">Cliquez ici pour modifier la bannière (desktop)</label>
							<div className="warnings">
								<p>Image (png ou jpg), pas de transparence.</p>
								<p>Taille minimum : 1440 x 900 px</p>
								<p>Poids Max : 5 Mo</p>
							</div>
							{!isEmpty(desktopImg) && (
								<div className="image">
									<img
										src={desktopImg}
										alt=""
									/>
								</div>

							)}
						</div>

						<div className="input">
							<input
								type="file"
								name="mobileImg"
								id="mobileImg"
								onChange={(e) => updateMobileBanner(e.target.files[0])}
							/>
							<label htmlFor="mobileImg">Cliquez ici pour modifier la bannière (mobile)</label>
							<div className="warnings">
								<p>Image (png ou jpg), pas de transparence.</p>
								<p>Taille minimum : 375 x 600 px</p>
								<p>Poids Max : 5 Mo</p>
							</div>
							{!isEmpty(mobileImg) && (
								<div className="image">
									<img
										src={mobileImg}
										alt=""
									/>
								</div>
							)}
						</div>

					</section>
				</>
			)}
			{suppress && <h2>Suppression réussie !</h2>}
		</SimpleBar>
	);
};

export default HighlightEditor;
