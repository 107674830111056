import React, {useEffect, useState} from "react";
import {isEmpty, isFunction} from "../Utils";

const Input = ({handleClick, handleChange, placeholder, name, value, label, type, errorText, regex, disabled = false, error: propError = false}) => {
    const [selected, setSelected] = useState(false);
    const [error, setError] = useState();
    const [localValue, setLocalValue] = useState();

    const onClick = (event) => {

    };

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    useEffect(() => {
        setError(propError)
    }, [propError]);

    useEffect(() => {
        if (localValue !== undefined) {
            setError((!isEmpty(regex) && localValue && !localValue.match(regex)) || propError)
            if(isFunction(handleChange)) {
                handleChange(name, localValue)
            }
        }
    }, [localValue, propError]);

    return (
        <div className={`input ${selected ? "select" : ""} ${error ? "error" : ""}`}>
            {label && (
                <label>
                    {label}
                </label>
            )}
            <input
                type={type}
                onClick={(e) => onClick(e)}
                value={isEmpty(value) ? "" : value}
                placeholder={!isEmpty(placeholder) ? placeholder : undefined}
                onFocus={() => setSelected(true)}
                onBlur={() => setSelected(false)}
                disabled={disabled}
                onInput={(e) => setLocalValue(e.target.value)}
            />
            {error && (
                <div className="errorDisplay">
                    {errorText}
                </div>
            )}
        </div>
    );
};

export default Input;