import { combineReducers } from "redux";
import {
	GET_CUSTOMER, GET_LIST_PAYMENTS, GET_PRODUCT,
	GET_INVOICES, GET_PRODUCTS, GET_USERS_INFO,
	GET_COUPONS, GET_COUPON, HANDLE_SUBSCRIPTION_RECORD,
	GET_ADVANTAGES, GET_ADVANTAGE
} from "../actions/stripeAdmin.action";
const initialState = [];

function getCustomerReducer(state = initialState, action) {
	switch (action.type) {
		case GET_CUSTOMER:
			return action.payload;
		default:
			return state;
	}
}

function getCouponsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_COUPONS:
			return action.payload;
		default:
			return state;
	}
}

function getCouponReducer(state = initialState, action) {
	switch (action.type) {
		case GET_COUPON:
			return action.payload;
		default:
			return state;
	}
}

function getUsersInfoReducer(state = initialState, action) {
	switch (action.type) {
		case GET_USERS_INFO:
			return action.payload;
		default:
			return state;
	}
}

function handleSubscriptionRecordReducer(state = initialState, action) {
	switch (action.type) {
		case HANDLE_SUBSCRIPTION_RECORD:
			return action.payload;
		default:
			return state;
	}
}

function getListPaymentsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_LIST_PAYMENTS:
			return action.payload;
		default:
			return state;
	}
}

function getProductReducer(state = initialState, action) {
	switch (action.type) {
		case GET_PRODUCT:
			return action.payload;
		default:
			return state;
	}
}
function getProductsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_PRODUCTS:
			return action.payload;
		default:
			return state;
	}
}

function getInvoicesReducer(state = initialState, action) {
	switch (action.type) {
		case GET_INVOICES:
			return action.payload;
		default:
			return state;
	}
}

function getAdvantagesReducer(state = initialState, action) {
	switch (action.type) {
		case GET_ADVANTAGES:
			return action.payload;
		default:
			return state;
	}
}

function getAdvantageReducer(state = "", action) {
	switch (action.type) {
		case GET_ADVANTAGE:
			return action.payload;
		default:
			return state;
	}
}


export default combineReducers({
	getCustomerReducer,
	getListPaymentsReducer,
	getProductReducer,
	getInvoicesReducer,
    getUsersInfoReducer,
	getProductsReducer,
	getAdvantagesReducer,
	getAdvantageReducer,
	handleSubscriptionRecordReducer,
	getCouponsReducer,
	getCouponReducer
});
