import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { MyContext } from "../../contexts/MyContext";

import parse from "html-react-parser";
import {isEmpty, removeTags} from "../Utils";

const Avatar = () => {
	const { rootState, updateAvatar, isLoggedIn } = useContext(MyContext);
	const { theUser } = rootState;
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);
	const [upload, setUpload] = useState(false);

	const uploadAvatar = async (file) => {
		const formData = new FormData();
		formData.append("avatar", file);
		setUpload(true);
		const data = await updateAvatar(formData);

		if (data.success) {
			isLoggedIn();
			if (document.querySelector("#avatar #validUpdate")) {
				setTimeout(() => {
					document.querySelector("#avatar #validUpdate").innerHTML = translations
					&& !isEmpty(translations["avatarGood"])
						? removeTags(translations["avatarGood"])
						: "avatarGood";
					document
						.querySelector("#avatar #validUpdate")
						.classList.add("great", "display");
                    setUpload(false);
				}, 10);

				setTimeout(() => {
					document
						.querySelector("#avatar #validUpdate")
						.classList.remove("great", "display");
				}, 3000);
			}
		} else {
			setTimeout(() => {
				document.querySelector("#avatar #validUpdate").innerHTML = translations && !isEmpty(translations["errorOccur"])
					? removeTags(translations["errorOccur"])
					: "errorOccur";
				document
					.querySelector("#avatar #validUpdate")
					.classList.add("error", "display");
                setUpload(false);
			}, 10);

			setTimeout(() => {
				document
					.querySelector("#avatar #validUpdate")
					.classList.remove("error", "display");
			}, 3000);
			console.log(data.message);
		}
	};
	return (
		<div id="avatar">
			<img src={upload ? `${process.env.REACT_APP_CACHE_SERVER}/img/footages/preloader.gif` : theUser.avatar} alt="account" width="100" />
			<div className="data">
				<h4>
					{translations && !isEmpty(translations["accountAvatar"])
						? removeTags(translations["accountAvatar"])
						: "accountAvatar"
					}
				</h4>
				{translations && !isEmpty(translations["avatarParams"])
					? parse(translations["avatarParams"])
					: "avatarParams"
				}
				<form>
					<input
						type="file"
						name="avatarUpload"
						id="avatarUpload"
						onChange={(e) => uploadAvatar(e.target.files[0])}
					/>
					<label htmlFor="avatarUpload">
						{translations && !isEmpty(translations["avatarUpdate"])
							? parse(translations["avatarUpdate"])
							: "avatarUpdate"
						}
					</label>
					<div id="validUpdate" className="great"/>
				</form>
			</div>
		</div>
	);
};

export default Avatar;
