import React, { useEffect, useState } from "react";
import { isEmpty, removeTags } from "../Utils";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import axios from "axios";

const NewFilter = (props) => {
    const { onSelectFilter, sportId, countryId, playlistId, leagueId } = props;
	const translationsReducer = useSelector((state) => state.langReducer.translationsReducer);
	const translations = translationsReducer.translations;
    const selectedLanguage = translationsReducer.language;
	const [daysMenu, setDaysMenu] = useState(false);
	const [groupMenu, setGroupMenu] = useState(false);
	const [isFiltered, setIsFiltered] = useState(false);
	const [groups, setGroups] = useState([]);
	const [days, setDays] = useState([]);
	const [groupSelect, setGroupSelect] = useState("");
	const [daySelect, setDaySelect] = useState("");

	useEffect(() => {
		setGroupSelect("");
		setDaySelect("");
	}, [sportId, countryId, leagueId, playlistId]);

    useEffect(() => {
        setGroupMenu(false);
        setDaysMenu(false);
        if (isFiltered) {
            onSelectFilter("", "", groupSelect, "", daySelect);
        }
        // eslint-disable-next-line
    }, [groupSelect, daySelect]);

	useEffect(() => {
		setDaySelect("");
        fetchDays();
		return () => {
			setDays([]);
		}
		// eslint-disable-next-line
	}, [groupSelect]);

    useEffect(() => {
        fetchGroups();
        return () => {
			setGroups([]);
		}
		// eslint-disable-next-line
    }, [playlistId, selectedLanguage, leagueId]);

    const fetchGroups = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}getGroups&lig=${leagueId}&l=${leagueId}`)
            .then((res) => {
                if (res.data.result === "ok" && res.data.groups) {
                    setGroups(res.data.groups);
                } else {
                    setGroups([]);
                }
            })
            .catch((error) => {
                console.log(error)
            })
    };

    const fetchDays = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}getVideosDistinctDays&pl=${playlistId}&idl=${leagueId}&idg=${groupSelect}`)
            .then((res) => {
                if (res.data.result === "ok" && res.data.days) {
                    setDays(res.data.days);
                } else {
                    setDays([]);
                }
            })
            .catch((error) => {
                console.log(error)
            })
    };

	const getNameGroup = (id) => {
		let name = "";
		groups.forEach((group) => {
			if (group.id === id) {
				name = !isEmpty(group.name) ? removeTags(group.name) : group.id
			}
		});

		return name;
	};

	const dropdownClicked = (menu) => {
        setIsFiltered(true);
		switch (menu) {
            case "groups":
                daysMenu && setDaysMenu(!daysMenu);
                setGroupMenu(!groupMenu);
                break;
            case "days":
                groupMenu && setGroupMenu(!groupMenu);
                setDaysMenu(!daysMenu);
                break;
			default:
                setGroupMenu(false);
                setDaysMenu(false);
				return;
		}
	};


	const handleClickOutside = (event) => {
		if (event.target.closest('.sport-filter') === null) {
			setDaysMenu(false);
			setGroupMenu(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	});

	return(
		<div className="sport-filter">
			{(process.env.REACT_APP_PLAYLIST_REPLAY_N2 === playlistId || process.env.REACT_APP_PLAYLIST_REPLAY_N3 === playlistId || process.env.REACT_APP_PLAYLIST_RESUMES === playlistId) && (
				<div className="dropdown">
					<button className={`${groupMenu ? "open dropbtn" : "dropbtn"}`} onClick={() => dropdownClicked("groups")}>
						{!isEmpty(groupSelect)
							? getNameGroup(groupSelect)
							: translations && !isEmpty(translations["groups"])
								? parse(translations["groups"])
								: "Groups"}
					</button>
					{groupMenu && (
						<ul className="dropdown-content">
							<li onClick={() => setGroupSelect("")}>
								{translations && !isEmpty(translations["allFilters"])
									? parse(translations["allFilters"])
									: "allFilters"}
							</li>
							{groups &&
                            groups.map((group) => {
                                return (
                                    <li
                                        key={group.id}
                                        onClick={() => setGroupSelect(group.id)}
                                    >
                                        {!isEmpty(group.name) ? removeTags(group.name) : group.id}
                                    </li>
                                );
							})}
						</ul>
					)}
				</div>
			)}

			<div className="dropdown">
				<button className={`${daysMenu && (groupSelect || !(process.env.REACT_APP_PLAYLIST_REPLAY_N2 === playlistId || process.env.REACT_APP_PLAYLIST_REPLAY_N3 === playlistId || process.env.REACT_APP_PLAYLIST_RESUMES === playlistId)) ? "open dropbtn" : "dropbtn"}`} onClick={() => dropdownClicked("days")}>
                    {!isEmpty(daySelect)
                        ? daySelect
                        : translations && !isEmpty(translations["days"])
                            ? parse(translations["days"])
                            : "Days"}
				</button>
				{daysMenu && (groupSelect || !(process.env.REACT_APP_PLAYLIST_REPLAY_N2 === playlistId || process.env.REACT_APP_PLAYLIST_REPLAY_N3 === playlistId || process.env.REACT_APP_PLAYLIST_RESUMES === playlistId)) && (
					<ul className="dropdown-content">
						<li onClick={() => setDaySelect("")}>
							{translations && !isEmpty(translations["allFilters"])
							? parse(translations["allFilters"])
							: "allFilters"}
						</li>
						{days &&
                        days.map((day) => {
                            return (
                                <li
                                    key={day.match_number}
                                    onClick={() => setDaySelect(day.match_number)}
                                >
                                    {day.match_number}
                                </li>
                            );
                        })}
					</ul>
				)}
			</div>
		</div>
	);
};

export default NewFilter;