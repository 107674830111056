import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import parse from "html-react-parser";
import {isEmpty} from "../Utils";

const Submenu = () => {
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);
	const sports = useSelector((state) => state.sportsReducer.sportsTypesReducer);

	return (
		<div className="container filter">
			<section className="flex-between">
				<div className="sport-menu m-auto event-submenu">
					<NavLink exact to="/events">
						<span>
							{translations && !isEmpty(translations["allEvents"])
								? parse(translations["allEvents"])
								: "allEvents"
							}
						</span>
					</NavLink>

					{sports &&
						sports.map((value, index) => {
							return (
								<NavLink key={value.id} exact to={`/events/${value.name}`}>
									<span>
										{translations && !isEmpty(translations[value.name])
											? parse(translations[value.name])
											: value.name
										}
									</span>
								</NavLink>
							);
						})}
				</div>
			</section>
		</div>
	);
};

export default Submenu;
