import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "../../../components/Utils";
import { getUsersInfo } from "../../../actions/stripeAdmin.action";
import EditAccount from "./EditAccount";

const Accounts = () => {
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);
	const [offset, setOffset] = useState(10);
	const [totalUsers, setTotalUsers] = useState(0);
	const infoUsers = useSelector((state) => state.stripeAdminReducer.getUsersInfoReducer);
	const [usersArray, setUsersArray] = useState([]);
	const [editUser, setEditUser] = useState(false);

    useEffect(() => {
		if (!isEmpty(offset) && !isEmpty(page)) {
			dispatch(getUsersInfo(page, offset));
		}
	}, [offset, page]);

	useEffect(() => {
		if (!isEmpty(infoUsers.accounts)) {
			setUsersArray(infoUsers.accounts);
			setTotalUsers(infoUsers.totalRows);
		}
	}, [infoUsers.accounts]);

	//PAGE
	const handlePage = (mode) => {
		if (mode === "next") {
			setPage((page) => page + 1);
		} else {
			setPage((page) => page - 1);
		}
	};

	//SEARCH
	const handleSearch = (input) => {
		if (input.length > 3) {
			dispatch(getUsersInfo(1, offset, input))
		}
		setPage(0);
	};

	return (
		<div className="bo-all_accounts">
            <div className="controls">
				<div className="search">
					<label htmlFor="search">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
						>
							<path d="M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z" />
						</svg>
					</label>
					<input
						type="search"
						autoComplete="off"
						name="search"
						id="search"
						placeholder="Chercher une variable"
						onInput={(e) => handleSearch(e.target.value)}
					/>
				</div>
				<div className="pages">
					<div className="buttons">
						<button onClick={() => handlePage("prev")}>Page précédente</button>
						<button onClick={() => handlePage("next")}>Page suivante</button>
					</div>

					<p>
						Page {page}/{Math.ceil(totalUsers / offset)}
					</p>
				</div>
				<div className="offset">
					<label htmlFor="offset">Nombre d'éléments affichés</label>
					<input
						type="number"
						name="offset"
						step="10"
						value={offset}
						onChange={(e) => setOffset(parseInt(e.currentTarget.value))}
						min="10"
						max={
							!isEmpty(Math.ceil(Object.entries(usersArray).length))
								? Math.ceil(Object.entries(usersArray).length) + 10
								: 100
						}
					/>
				</div>
			</div> 
			<div className="accounts-list">
				<table>
					<thead>
						<tr>
							<th>ID</th>
							<th>Email</th>
							<th>Stripe ID</th>
							<th>User type</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{!isEmpty(usersArray) &&
							usersArray
								.map((user, index) => {
									return (
										<tr key={index}>
											<td>{user.id}</td>
											<td>{user.email}</td>
											<td>{user.stripe_id === null ? 0 : user.stripe_id}</td>
											<td>{user.account_type}</td>
											<td>
												<button onClick={() => setEditUser(oldUser => oldUser?.id === user.id ? false : user)}>Edit</button>
											</td>
										</tr>
									);
								})
						}
					</tbody>
				</table>
			</div>
			{editUser && (
				<EditAccount user={editUser}/>
			)}
		</div>
	);
};

export default Accounts;
