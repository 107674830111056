

const Loader = () => {

    return (
        <div id="loaderPage" className="center">
            <div className="wave"/>
            <div className="wave"/>
            <div className="wave"/>
            <div className="wave"/>
            <div className="wave"/>
            <div className="wave"/>
            <div className="wave"/>
            <div className="wave"/>
            <div className="wave"/>
            <div className="wave"/>
        </div>
    )
};

export default Loader;