import axios from "axios";

export const GET_HIGHLIGHTS = "GET_HIGHLIGHTS";

export const getHighlights = (page = null) => {
	return async (dispatch) => {
		let link;
		if (page === null) {
			link = `${process.env.REACT_APP_NOT_CACHED_API_URL}getHighlightEvent`;
		} else {
			link = `${process.env.REACT_APP_NOT_CACHED_API_URL}getHighlightEvent&pt=${page}`;
		}

		await axios.get(link)
			.then((res) => dispatch({ type: GET_HIGHLIGHTS, payload: res.data }))
			.catch((error) => {
				console.log(error)
			});

	};
};

export const REORDER_HIGHLIGHTS = "REORDER_HIGHLIGHTS";
export const reorderHighlights = (highlights) => {
	return (dispatch) => {
		dispatch({ type: REORDER_HIGHLIGHTS, payload: highlights });
	};
};
