import React, {useEffect, useRef, useState} from "react";
import {NavLink} from "react-router-dom";
import {dateRender, isEmpty} from "./Utils";
import Slider from "react-slick";
import axios from 'axios';
import RightArrow from "./slider/RightArrow";
import LeftArrow from "./slider/LeftArrow";
import {useDispatch, useSelector} from "react-redux";
import parse from "html-react-parser";
import FirstDefault from "./playlist/FirstDefault";
import FavouriteClubsFilter from "./playlist/FavouriteClubsFilter";
import {getFavouriteClubsPlaylist} from "../actions/playlist.action";
import useMeasure from "react-use-measure";
import VideoPreview from "./partials/VideoPreview";

const FavouriteClubsPlaylist = (props) => {
	const clubPlayerId = props.clubPlayerId;
	const playlistVideo = props.playlistVideo;
	const slider = useRef();
	const dispatch = useDispatch();
	const [videos, setVideos] = useState(null);
	const [countPage, setCountPage] = useState(1);
	const [divisionId, setDivisionId] = useState("");
	const [groupId, setGroupId] = useState("");
	const [clubId, setClubId] = useState(clubPlayerId);
	const [sliderRef, {height, width}] = useMeasure();

	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);

	useEffect(() => {
		setVideos(playlistVideo);
	}, [playlistVideo]);

	const fetchPlaylist = async (page, leagueId = "", groupId = "", clubId = "") => {
		const Axios = axios.create();
		const loginToken = localStorage.getItem("loginToken");
		//Adding JWT token to axios default header
		if (loginToken) {
			Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;
			if (process.env.REACT_APP_ENV === "production") {
				Axios.defaults.headers.common["Authorization"] = "Bearer " + loginToken;
			}
		}
        await Axios
            .get(`${process.env.REACT_APP_NOT_CACHED_API_URL}getFavouriteClubsPlaylist&n=20&p=${page}&lig=${leagueId}&gr=${groupId}&cl=${clubId}`)
            .then((res) => {
            	if (res.data.playlistVideo) {
					let responseVideos = res.data.playlistVideo;
            		if (page === 1) {
						setVideos(responseVideos)
					} else {
						setVideos([...videos, ...responseVideos])
					}
				}
            })
            .catch((error) => {
                console.log(error)
            });
    };

	let settings = {
		infinite: false,
		lazyLoad: false,
		speed: 500,
		slidesToShow: (!isEmpty(width) && width !== 0) ? Math.floor(width / 400) : 4,
		slidesToScroll: (!isEmpty(width) && width !== 0) ? Math.floor(width / 400) : 4,
		prevArrow: <LeftArrow />,
		nextArrow: <RightArrow />,
		swipeToSlide: false,
		initialSlide: 0,
		centerMode: false,
		afterChange: () => {
			if(document.querySelector(".d-none.arrow-slideshow.right")){
				setCountPage(countPage + 1);
				fetchPlaylist(countPage + 1, divisionId, groupId, clubId);
			}
		},
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				}
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			}
		]

	};

	const onSelectFilter = (divisionSelectId, groupSelectId, clubSelectId) => {
		dispatch(getFavouriteClubsPlaylist(divisionSelectId, groupSelectId, clubSelectId));
		setDivisionId(divisionSelectId);
		setGroupId(groupSelectId);
		setClubId(clubSelectId);
		setCountPage(1);
		setTimeout(() => {
			if (slider) {
				slider.current.slickGoTo(0);
			}
		}, 1000);
	};

	return (
		<div className="playlist-videos filter white">
			<div className="titles">
				<section
					className={"flex-between m-auto filter"}
				>
					<h3>{translations && !isEmpty(translations["favouritePlaylist"])
						? parse(translations["favouritePlaylist"])
						: "favouritePlaylist"}</h3>

						<FavouriteClubsFilter
							onSelectFilter={onSelectFilter}
						/>

				</section>
			</div>
			<div className="playlist-slider">

				<FirstDefault width={width} height={height} name={translations && !isEmpty(translations["favouritePlaylist"])
					? parse(translations["favouritePlaylist"])
					: "favouritePlaylist"} image={"https://apifuchs-sports.mytvchain.com/img/decorations/playlist/emission-bg.png"}/>

				<div ref={sliderRef} >
					<Slider ref={slider} {...settings} className="videos-container hide-scroll customScroll">
						{!isEmpty(videos) &&
						videos.map((video) => {
							return (
								<VideoPreview video={video} key={video.id}/>
							);
						})}
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default FavouriteClubsPlaylist;
