import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router";
import Header from "../components/clubs/Header";
import { useDispatch, useSelector } from "react-redux";
import {
	clearClubPlaylists,
	clearPlaylists,
	getClubPlaylist, getParentCommandPlaylist, clearParentCommandPlaylists,
} from "../actions/playlist.action";
import {clearEvents, getEvents} from "../actions/events.action";
import { getHighlights } from "../actions/highlights.action";
import Events from "../components/Events";
import Playlist from "../components/Playlist";
import { isEmpty } from "../components/Utils";
import Search from "../components/Search";
import { toggleSearch } from "../actions/search.action";
import { clearWebTVData, getWebTVData } from "../actions/webTVS.action";
import Filter from "../components/clubs/Filter";
import { Helmet } from "react-helmet-async";
import RedirectClubApp from "../components/RedirectClubApp";

const Club = () => {
	//GET DATA

	const { clubURL } = useParams();
	const dispatch = useDispatch();
	const clubs = useSelector((state) => state.webTVData);
	const events = useSelector((state) => state.eventsReducer.eventsAllReducer);
	const playlistsCommandVideo = useSelector(
		(state) => state.playlistAllReducer.playlistParentCommandReducer
	);
	const playlistsLigueVideo = useSelector(
		(state) => state.playlistAllReducer.clubPlaylistsReducer
	);
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const selectedLanguage = translationsReducer.language;
	const [type, setType] = useState("all");
	const [club, setClub] = useState("");
	const [matchClub, setMatchClub] = useState(true);
	const [isFiltered, setIsFiltered] = useState(false);
	const searchState = useSelector(
		(state) => state.searchReducer.searchToggleReducer
	);

	//VERIFY THE CLUB
	//GET STORE
	useEffect(() => {
		dispatch(clearEvents());
		dispatch(clearWebTVData());
		dispatch(clearPlaylists());
		if (!isEmpty(clubURL)) {
			dispatch(getWebTVData(clubURL));
		}
		dispatch(getHighlights());
	}, [dispatch, clubURL]);

	useEffect(() => {
		if (!isEmpty(club)) {
			dispatch(clearParentCommandPlaylists());
			dispatch(clearClubPlaylists());
			dispatch(getEvents(100, "", club.is_ligue, "", "", "",  club.is_ligue === null ? club.id : ""));
			if (club.is_ligue !== null) {
				dispatch(getClubPlaylist(club.id));
			} else {
				dispatch(getParentCommandPlaylist(club.id, selectedLanguage));
			}
		}
		// eslint-disable-next-line
	}, [club, clubURL]);

	useEffect(() => {
		if (clubs === "bad" || (!isEmpty(clubs[0]) && clubs[0].show_in_fuchs_platform === "0")) {
			setClub(null);
			setMatchClub(false);
		} else if (clubs.length > 0) {
			setMatchClub(clubs.length > 0);
			setClub(clubs[0]);
		}
		// eslint-disable-next-line
	}, [clubs]);

	const onSelectFilter = (divId, groupId, daySelect) => {
		if (!isEmpty(club)) {
			dispatch(
				getEvents(100, "", club.is_ligue, divId, groupId, daySelect, club.is_ligue === null ? club.id : "")
			);
		}
	};

	const onSelectType = (type) => {
		setType(type);
	};

	useEffect(() => {
		if (searchState) {
			dispatch(toggleSearch(false));
		}
		// eslint-disable-next-line
	}, [location, dispatch]);

	return (
		<>
			<Helmet>
				{!isEmpty(club) && (
					<>
						{!isEmpty(club.name) && (
							<title>{`Fuchs Sports International - ${club.name}`}</title>
						)}

						{!isEmpty(club.name) && (
							<meta name="title" content={`Fuchs Sports International - ${club.name}`} />
						)}

						{!isEmpty(club.name) && (
							<meta name="description" content={`Fuchs Sports International - ${club.name}`} />
						)}

						{!isEmpty(club.name) && (
							<meta property="og:title" content={`Fuchs Sports International - ${club.name}`} />
						)}

						{!isEmpty(club.name) && (
							<meta property="og:description" content={`Fuchs Sports International - ${club.name}`} />
						)}
						{!isEmpty(club.logo) && (
							<meta property="og:image" content={`${club.logo}`} />
						)}
					</>
				)}
			</Helmet>

			{club && <RedirectClubApp page="club" club={club} />}

			<div className="club-page">
				{!matchClub && <Redirect to="/" />}
				{searchState && <Search />}


				{!isEmpty(club) && (
					<Header
						id={club.id}
						logo={club.logo}
						name={club.name}
						cover={club.cover}
						fb={club.fb}
						ins={club.ins}
						tw={club.tw}
						web={club.web}
						followers={club.followers}
						color={club.color}
						url={club.url}
					/>
				)}

				{!isEmpty(club) && (!isEmpty(events) || isFiltered) ? (
					<Filter
						onSelectType={onSelectType}
						onSelectFilter={onSelectFilter}
						leagueId={club.is_ligue}
						isLeague={club.is_ligue !== null}
						setIsFiltered={() => setIsFiltered(true)}
						clubId={club.is_ligue === null && !isEmpty(club) ? club.id : ""}
					/>
				) : ""}

				{(type === "all" || type === "events") && <Events events={events} />}
				{!isEmpty(club) && club.is_ligue === null &&
					(type === "all" || type === "videos") &&
					!isEmpty(playlistsCommandVideo.playlistVideo) &&
					Object.keys(playlistsCommandVideo.playlistVideo).map((key, index) => {
						return (
							<Playlist
								key={index}
								data={playlistsCommandVideo.playlistVideo[key]}
								color={index}
								playlistId={key}
								clubPlayerId={club.id}
							/>
						);
					})}
				{!isEmpty(club) && club.is_ligue !== null &&
					(type === "all" || type === "videos") &&
					!isEmpty(playlistsLigueVideo.playlistVideo) &&
					Object.keys(playlistsLigueVideo.playlistVideo).map((key, index) => {
						return (
							<Playlist
								key={index}
								data={playlistsLigueVideo.playlistVideo[key]}
								color={index}
								playlistId={key}
								clubPlayerId={""}
							/>
						);
					})}
			</div>
		</>
	);
};

export default Club;
