import axios from "axios";
import {isEmpty} from "../components/Utils";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_LIST_PAYMENTS = "GET_LIST_PAYMENTS";
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_INVOICES = "GET_INVOICES";
export const GET_USERS_INFO = "GET_USERS_INFO";
export const GET_COUPONS = "GET_COUPONS";
export const GET_COUPON = "GET_COUPON";
export const HANDLE_SUBSCRIPTION_RECORD = "HANDLE_SUBSCRIPTION_RECORD";
export const GET_ADVANTAGES = "GET_ADVANTAGES";
export const GET_ADVANTAGE = "GET_ADVANTAGE";
const Axios = axios.create();

export const getCustomer = (id) => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginAdminToken");
		if (loginToken) {
			try {
                Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;
				let formData = new FormData();
				formData.append("id", id);
				await Axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}getCustomer`, formData)
					.then((res) => {
						if (res.data.result === "ok") {
							dispatch({type: GET_CUSTOMER, payload: res.data.response})
						} else {
							dispatch({type: GET_CUSTOMER, payload: []})
						}
					})
					.catch((error) => {
						console.log(error)
					});

			} catch (e) {
				console.log(e);
			}
		}
	};
};

export const getUsersInfo = (page = 1, total = 50, query = "") => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginAdminToken");
		if (loginToken) {
			try {
				let form = new FormData();
				form.append("page", JSON.stringify(page));
				form.append("total", JSON.stringify(total));
				form.append("query", query);
                Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;
				await Axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}getUsersInfo`, form).then((res) => {
						if (res.data.result === "ok") {
							dispatch({type: GET_USERS_INFO, payload: res.data});
						} else {
							dispatch({type: GET_USERS_INFO, payload: []})
						}
					})
					.catch((error) => {
						console.log(error)
					});

			} catch (e) {
				console.log(e);
			}
		}
	};
};

export const handleSubscriptionRecord = (title, description, productId, mode) => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginAdminToken");
		if (loginToken) {
			try {
				let form = new FormData();
				form.append("title", title);
				form.append("description", description);
				form.append("productId", productId);
				form.append("mode", mode);
                Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;
				await Axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}handleSubscriptionRecord`, form).then((res) => {
						if (!isEmpty(res)) {
							console.log(res);
							dispatch({type: HANDLE_SUBSCRIPTION_RECORD, payload: res});
						} else {
							dispatch({type: HANDLE_SUBSCRIPTION_RECORD, payload: res})
						}
					})
					.catch((error) => {
						console.log(error)
					});

			} catch (e) {
				console.log(e);
			}
		}
	};
};

export const getProduct = (productToken, includePrices, includeAdvantages) => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginAdminToken");
		if (loginToken) {
			try {
                Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;
				let formData = new FormData();
				formData.append("source", productToken);
				if (includePrices) {
					formData.append("include_prices", includePrices);
				}
				if (includeAdvantages) {
					formData.append("include_advantages", includeAdvantages);
				}
				await Axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}getProduct`, formData)
					.then((res) => {
						if (res.data.result === "ok" && !isEmpty(res.data.response)) {
							dispatch({type: GET_PRODUCT, payload: res.data.response})
						} else {
							dispatch({type: GET_PRODUCT, payload: []})
						}
					})
					.catch((error) => {
						console.log(error)
					});

			} catch (e) {
				console.log(e);
			}
		}
	}
};

export const getCoupon = (id) => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginAdminToken");
		if (loginToken) {
			try {
                Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;
				let formData = new FormData();
				formData.append("id", id);
				await Axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}getCoupon`, formData)
					.then((res) => {
						console.log(res);
						if (res.data.result === "ok" && !isEmpty(res.data.response)) {
							dispatch({type: GET_COUPON, payload: res.data.response})
						} else {
							dispatch({type: GET_COUPON, payload: []})
						}
					})
					.catch((error) => {
						console.log(error)
					});

			} catch (e) {
				console.log(e);
			}
		}
	}
};

export const getProducts = (includePrices, typeOfProduct) => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginAdminToken");
		if (loginToken) {
			try {
                Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;
                let formData = new FormData();
				if (includePrices) {
					formData.append("include_prices", includePrices);
				}
				if (typeOfProduct) {
					formData.append("type_product", typeOfProduct);
				}
				await Axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}getProducts`, formData)
					.then((res) => {
						if (res.data.result === "ok" && !isEmpty(res.data.response)) {
							dispatch({type: GET_PRODUCTS, payload: res.data.response})
						} else {
							dispatch({type: GET_PRODUCTS, payload: []})
						}
					})
					.catch((error) => {
						console.log(error)
					});

			} catch (e) {
				console.log(e);
			}
		}
	}
};

export const getCoupons = () => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginAdminToken");
		if (loginToken) {
			try {
                Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;
				await Axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}getCoupons`)
					.then((res) => {
						console.log(res);
						if (res.data.result === "ok" && !isEmpty(res.data.response)) {
							dispatch({type: GET_COUPONS, payload: res.data.response})
						} else {
							dispatch({type: GET_COUPONS, payload: []})
						}
					})
					.catch((error) => {
						console.log(error)
					});

			} catch (e) {
				console.log(e);
			}
		}
	}
};

export const getInvoices = (id, page) => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginAdminToken");
		if (loginToken) {
			try {
                Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;
				let formData = new FormData();
				formData.append("id", id);				
				if (page) {
					formData.append("startFrom", page);
				}
				await Axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}getInvoices`, formData)
					.then((res) => {
						if (res.data.result === "ok" && !isEmpty(res.data.response)) {
							dispatch({type: GET_INVOICES, payload: res.data.response})
						} else {
							dispatch({type: GET_INVOICES, payload: []})
						}
					})
					.catch((error) => {
						console.log(error)
					});

			} catch (e) {
				console.log(e);
			}
		}
	}
};

export const getListPaymentsMethods = (id) => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginAdminToken");
		if (loginToken) {
			try {
                Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;
				let formData = new FormData();
				formData.append("id", id);
				await Axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}getListPaymentsMethods`, formData)
					.then((res) => {
						if (res.data.result === "ok" && !isEmpty(res.data.response)) {
							dispatch({type: GET_LIST_PAYMENTS, payload: res.data.response})
						} else {
							dispatch({type: GET_LIST_PAYMENTS, payload: []})
						}
					})
					.catch((error) => {
						console.log(error)
					});

			} catch (e) {
				console.log(e);
			}
		}
	}
};

export const getAdvantages = (page, total, query, typeUser = "all") => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginAdminToken");
		if (loginToken) {
			try {
				Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;

				let formData = new FormData();
				if (page) {
					formData.append("page", page);
				}
				if (total) {
					formData.append("total", total);
				}
				if (query) {
					formData.append("query", query);
				}
				await Axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}getAdvantages`, formData)
					.then((res) => {
						if (res.data.result === "ok" && !isEmpty(res.data)) {
							dispatch({type: GET_ADVANTAGES, payload: res.data})
						} else {
							dispatch({type: GET_ADVANTAGES, payload: []})
						}
					})
					.catch((error) => {
						console.log(error)
					});

			} catch (e) {
				console.log(e);
			}
		}
	}
};

export const getAdvantage = (id) => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginAdminToken");
		if (loginToken) {
			try {
				Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;
				let formData = new FormData();
				formData.append("id", id);
				await Axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}getAdvantage`, formData)
					.then((res) => {
						if (res.data.result === "ok" && !isEmpty(res.data)) {
							dispatch({type: GET_ADVANTAGE, payload: res.data})
						} else {
							dispatch({type: GET_ADVANTAGE, payload: ""})
						}
					})
					.catch((error) => {
						console.log(error)
					});

			} catch (e) {
				console.log(e);
			}
		}
	}
};