import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MyContext } from "../../contexts/MyContext";
import { toogleParameter } from "../../actions/account.action";
import { isEmpty } from "../Utils";

import parse from "html-react-parser";
import {useHistory} from "react-router";

const Popup = () => {
	const dispatch = useDispatch();
	const { rootState } = useContext(MyContext);
	const { theUser } = rootState;
	const parameters = useSelector((state) => state.accountReducer.paramReducer);
	const [popup, setPopup] = useState(false);
	const history = useHistory();
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);

	useEffect(() => {
		checkPopupStatusSession();
		// eslint-disable-next-line
	}, [dispatch]);

	useEffect(() => {
		if (parameters) {
			setPopup(false);
		}
	}, [parameters]);

	const hidePopup = () => {
		const minutes = 30;
		const now = new Date();
		const item = {
			value: "hidePopup",
			expiry: now.getTime() + minutes * 60000,
		};
		localStorage.setItem("hidePopup", JSON.stringify(item));
		setPopup(false);
	};

	const checkPopupStatusSession = () => {
		if (
			isEmpty(theUser["avatar"]) ||
			isEmpty(theUser["country"]) ||
			isEmpty(theUser["gender"]) ||
			isEmpty(theUser["datebirth"]) ||
			isEmpty(theUser["zipCode"])
		) {
			const itemStr = localStorage.getItem("hidePopup");
			if (!itemStr) {
				setPopup(true);
				return null;
			}
			const item = JSON.parse(itemStr);
			const now = new Date();
			if (now.getTime() > item.expiry) {
				localStorage.removeItem("hidePopup");
				setPopup(true);
			} else {
				setPopup(false);
			}
		}
	};

	return (
		popup && (
			<div className="popup">
				<div className="title">
					<div className="icon">
						<svg
							width="13"
							height="16"
							viewBox="0 0 13 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M6.0967 7.37829C6.83789 7.37829 7.56243 7.16187 8.17868 6.75642C8.79492 6.35096 9.2752 5.77468 9.55875 5.10046C9.8423 4.42625 9.91638 3.68438 9.77164 2.9687C9.6269 2.25303 9.26982 1.59569 8.74558 1.07983C8.22134 0.563974 7.55349 0.212772 6.82649 0.0706478C6.0995 -0.0714764 5.34602 0.00186183 4.66136 0.281386C3.97671 0.56091 3.39163 1.03406 2.98014 1.641C2.56864 2.24793 2.34921 2.96139 2.34961 3.69111C2.3512 4.66887 2.74655 5.60611 3.44898 6.29731C4.15141 6.98851 5.10358 7.37725 6.0967 7.37829Z"
								fill="white"
							/>
							<path
								d="M1.59876 16.0002H10.5918C11.0158 16.0002 11.4224 15.8343 11.7223 15.5392C12.0221 15.244 12.1905 14.8436 12.1905 14.4261V14.0661C12.1498 12.5011 11.4898 11.0137 10.3511 9.92104C9.21249 8.82834 7.68533 8.2168 6.09526 8.2168C4.5052 8.2168 2.97804 8.82834 1.83939 9.92104C0.700749 11.0137 0.0407073 12.5011 0 14.0661V14.4261C0 14.8436 0.16844 15.244 0.468265 15.5392C0.616723 15.6853 0.792969 15.8013 0.986939 15.8804C1.18091 15.9595 1.38881 16.0002 1.59876 16.0002Z"
								fill="white"
							/>
						</svg>
					</div>
					<span>
						{translations && !isEmpty(translations["completeProfile"])
							? parse(translations["completeProfile"])
							: "Complete profile"}
					</span>
				</div>
				<div className="content">
					<ul>
						{isEmpty(theUser["avatar"]) && (
							<li>
								<i className="icon" />
								{translations && !isEmpty(translations["accountAvatar"])
									? parse(translations["accountAvatar"])
									: "accountAvatar"
								}
							</li>
						)}
						{isEmpty(theUser["country"]) && (
							<li>
								<i className="icon" />
								{translations && !isEmpty(translations["country"])
									? parse(translations["country"])
									: "country"
								}
							</li>
						)}
						{isEmpty(theUser["zipCode"]) && (
							<li>
								<i className="icon" />
								{translations && !isEmpty(translations["zipcode"])
									? parse(translations["zipcode"])
									: "zipcode"
								}
							</li>
						)}
						{isEmpty(theUser["gender"]) && (
							<li>
								<i className="icon" />
								{translations && !isEmpty(translations["gender"])
									? parse(translations["gender"])
									: "gender"
								}
							</li>
						)}
						{isEmpty(theUser["datebirth"]) && (
							<li>
								<i className="icon" />
								{translations && !isEmpty(translations["datebirth"])
									? parse(translations["datebirth"])
									: "datebirth"
								}
							</li>
						)}
					</ul>
				</div>
				<div className="row">
					<button
						className="grey"
						onClick={() => {
							hidePopup();
						}}
					>
						{translations && !isEmpty(translations["later"])
							? parse(translations["later"])
							: "Later"
						}
					</button>
					<button
						className="primary"
						onClick={() => history.push("/profile/info")}
					>
						{translations && !isEmpty(translations["completeMyProfile"])
							? parse(translations["completeMyProfile"])
							: "Complete my profile"}
					</button>
				</div>
			</div>
		)
	);
};

export default Popup;
