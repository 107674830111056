import axios from "axios";

export const GET_LEAGUES = "GET_LEAGUES";

export const getLeagues = (id = '', sportId = '', country = '', lang = 'EN', playlist = '') => {
	return async (dispatch) => {
		await axios
			.get(`${process.env.REACT_APP_API_URL}getLeagues&i=${id}&idsp=${sportId}&country=${country}&l=${lang}&plid=${playlist}`)
			.then((res) => dispatch({type: GET_LEAGUES, payload: res.data}))
			.catch((error) => {
				console.log(error)
			});
	};
};
