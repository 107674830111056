import React from "react";

const EventStyle2 = (props) => {
	const { event } = props;
	return (
		<section data-event={`event-${event.id}`} className="event display-2">
			<div className="title">
				<h1>
					<div>
						<span className="team">{event.team1}</span>
						<div className="white-strip-top"></div>
						<div className="white-strip-bottom"></div>
						<ul className="decorations">
							<li></li>
							<li></li>
							<li></li>
							<li></li>
							<li></li>
						</ul>
					</div>
					<div>
						<span className="team">{event.team2}</span>
						<div className="white-strip-top"></div>
						<div className="white-strip-bottom"></div>
						<ul className="decorations">
							<li></li>
							<li></li>
							<li></li>
							<li></li>
							<li></li>
						</ul>
					</div>
				</h1>

				<h2>{event.title}</h2>
			</div>
			<a href={event.url} target="_blank" rel="noopener noreferrer">
				<div className="play">
					<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/play.svg`} alt="play button" />
				</div>
			</a>
			<div className="dots">
				<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/dots1.png`} alt="decoration elements" />
				<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/dots2.png`} alt="decoration elements" />
			</div>
			<img src={event.img} width="100" alt="banner event" />
		</section>
	);
};

export default EventStyle2;
