import React, {useState} from "react";
import Highlights from "./Highlight/Highlights";
import NaviguationBar from "./NaviguationBar";
import Playlists from "./Playlists";
import Slideshow from "./Slideshow/Slideshow";
import WebTVS from "./WebTVS";
import { isEmpty } from "../../components/Utils";
import Status from "./Status";
import WebTVSALL from "./WebTVSALL";
const Homepage = () => {

	const [ openBlock, setOpenBlock ] = useState(null);

	const handleMenu = (elm, block) => {
		let actual = document.querySelector(".homepage>ul>li>span.hover");
		if (!isEmpty(actual)) {
			actual.classList.remove("hover");
			if (actual !== elm) {
				elm.classList.add("hover");
			}
		} else {
			elm.classList.add("hover");
		}
		setOpenBlock(block)
	};

	return (
		<nav className="homepage">
			<ul>
				<li>
					<span onClick={(e) => handleMenu(e.target, "NaviguationBar")}>Barre de Navigation</span>
					{openBlock === "NaviguationBar" && (
						<NaviguationBar />
					)}
				</li>
				<li>
					<span onClick={(e) => handleMenu(e.target, "Slideshow")}>Slideshow</span>
					{openBlock === "Slideshow" && (
						<Slideshow />
					)}
				</li>

				<li>
					<span onClick={(e) => handleMenu(e.target, "WebTVS1")}>Parcourir les Web TVS</span>
					{openBlock === "WebTVS1" && (
						<WebTVS openBlock={openBlock}/>
					)}
				</li>
				<li>
					<span onClick={(e) => handleMenu(e.target, "WebTVS2")}>Couleur personnalisée pour les Web TVS</span>
					{openBlock === "WebTVS2" && (
						<WebTVSALL openBlock={openBlock}/>
					)}
				</li>
				<li>
					<span onClick={(e) => handleMenu(e.target, "Playlists")}>Playlists</span>
					{openBlock === "Playlists" && (
						<Playlists />
					)}
				</li>
				<li>
					<span onClick={(e) => handleMenu(e.target, "Highlights")}>Immanquables du Mois</span>
					{openBlock === "Highlights" && (
						<Highlights />
					)}
				</li>
				<li>
					<span onClick={(e) => handleMenu(e.target, "Status")}>Status des matchs</span>
					{openBlock === "Status" && (
						<Status />
					)}
				</li>
			</ul>
		</nav>
	);
};

export default Homepage;
