import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {getInvoices} from "../../../actions/stripeAdmin.action";

const Invoice = (props) => {
    const {stripe_id} =props;
    const dispatch = useDispatch();
    const stripeReducer = useSelector((state) => state.stripeAdminReducer);
    const invoices = stripeReducer.getInvoicesReducer;

    useEffect(() => {
        if (stripe_id) {
            dispatch(getInvoices(stripe_id));
        }
    }, [stripe_id]);

    console.log(invoices);

    return (
        <div className="invoices">
                <div className="element invoices">
                    <table>
                        <thead>
                        <tr>
                            <th className="text-bold">Montant</th>
                            <th>Description</th>
                            <th>Date</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {invoices && (
                            invoices.map((invoice) => {
                                return (

                                    <tr key={invoice.id}>
                                        <td className="text-bold">{(invoice.total / 100).toLocaleString('eu-EU', {style: 'currency',currency: 'EUR'})}</td>
                                        <td>{invoice.account_name}</td>
                                        <td>
                                            {invoice?.status === "paid"
                                                ? moment.unix(invoice.status_transitions.paid_at).format("DD-MM-YYYY HH:mm")
                                                : (invoice?.status === "open" ? <a href={invoice.hosted_invoice_url} target='_blank' rel="noreferrer" style={{color: "black"}}>{invoice?.status}</a> : invoice?.status)
                                            }
                                        </td>
                                        <td className="download" onClick={() => window.open(invoice.invoice_pdf)}>
                                            <img src="/img/icons/download.svg" alt="download"/>
                                        </td>
                                    </tr>
                                )
                            })
                        )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="4">1-5 of 13 <span>left</span><span>right</span></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
        </div>
    );
}

export default Invoice;