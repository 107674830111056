import React, {Fragment, useContext, useEffect, useState} from "react";
import {isEmpty} from "../../Utils";
import {useDispatch, useSelector} from "react-redux";
import {MyContext} from "../../../contexts/MyContext";
import {Elements} from "@stripe/react-stripe-js";
import {getActiveCustomerProduct, getProducts, getCoupons} from "../../../actions/stripe.action";
import {loadStripe} from "@stripe/stripe-js";
import parse from "html-react-parser";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC}`);

const Plans = () => {
    const dispatch = useDispatch();
    const stripeReducer = useSelector((state) => state.stripeReducer);
    const products = stripeReducer.getProductsReducer;
    const activePlan = stripeReducer.getActiveCustomerProductReducer;
    const coupons = stripeReducer.getCouponsReducer;
    const [prices, setPrices] = useState([]);
    const [openWarning, setOpenWarning] = useState(false);
    const [openPpvBlock, setOpenPpvBlock] = useState(false);
    const [checkedProductId, setCheckedProductId] = useState(null);
    const [validCoupon, setValidCoupon] = useState(null);
    const [wait, setWait] = useState(false);
    const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const translations = translationsReducer.translations;
	const selectedLanguage = translationsReducer.language;
    const {rootState} = useContext(MyContext);
    const {theUser} = rootState;

    useEffect(() => {
        dispatch(getProducts(1, 1, "subscription", theUser.account_type));
        dispatch(getActiveCustomerProduct());
        dispatch(getCoupons());
    }, [theUser]);

    console.log(validCoupon);

    useEffect(() => {
        if(!isEmpty(checkedProductId) && !isEmpty(coupons)){
            coupons.map((elem) => {
                Object.keys(elem).map((e) => {
                    if(e==="applies_to" && elem[e]["products"].includes(checkedProductId)) 
                    {
                        setValidCoupon(true);
                    }
            })    
            });
        }
    }, [coupons, checkedProductId]);

    const clickToPay = (product) => {
        if (!isEmpty(product.id)) {
            setCheckedProductId(product.id);
            if(!isEmpty(activePlan) && activePlan.product.id !== checkedProductId){
                setOpenWarning(true);
            } else if (isEmpty(activePlan)) {
                setOpenPpvBlock(true);
            }
            const price = product.prices.map((item) => {
                return {
                    value: item.id,
                    label: (item.unit_amount / 100).toLocaleString('eu-EU', {
                        style: 'currency',
                        currency: 'EUR'
                    }), ...item
                };
            });
            setPrices(price);
        }
    }

    const successHandler = () => {
        setOpenPpvBlock(!openPpvBlock);
        setTimeout(() => {
             window.location.reload();
        }, 1000);
    };

    const handleOpenedBlocks = () => {
        setOpenWarning(false);
        setOpenPpvBlock(true);
    };

    return (
        <Fragment>

            {openPpvBlock && (
                <Elements stripe={stripePromise}>
                    <CheckoutForm
                        cancelOrder={() => setOpenPpvBlock(!openPpvBlock)}
                        resetValidationCoupon={() => setValidCoupon(null)}
                        onClose={() => successHandler()}
                        prices={prices}
                        checkedProduct={checkedProductId}
                        validCoupon={validCoupon}
                    />
                </Elements>
            )}

        {openWarning && (
            
            <div className="absolute-center modal mw-25">
                {wait && (
                    <div className="success-animation">
                        <div className="loader">
                            <div className={wait === "succeeded" ? "circle-loader load-complete" : "circle-loader"}>
                                <div className="checkmark draw"/>
                            </div>
                        </div>
                    </div>
                )}
                <h2 className="p-4 d-flex justify-content-center"
                 style={{color: "#FF0000"}}>                              
                    {translations && !isEmpty(translations["warning"])
                            ? parse(translations["warning"])
                            : "Warning"}
                </h2>

                <h3 className="p-4 d-flex justify-content-center lh-6"
                >                              
                    {translations && !isEmpty(translations["warning_message"])
                            ? parse(translations["warning_message"])
                            : 'By clicking the "Next" button, you agree to change the already existing subscription you paid for.'}
                </h3>

                <div className="d-flex float-right mt-4 mr-4 ml-3 mb-2">

                <button
                    className="btn-stripe mt-1 ml-4 ml-4 mb-1"
                    style={{background: "#06132D", color: "#FFFFFF", border: "1px solid", borderColor: "#FFFFFF"}}
                    onClick={() => handleOpenedBlocks()}
                >Next</button>
                <button
                    className="btn-stripe mt-1 ml-4 mb-1"
                    style={{background: "#FFFFFF", color: "#000000", border: "1px solid", borderColor: "#98A2B3"}}
                    onClick={() => setOpenWarning(false)}
                >Cancel</button>
                </div>
            </div>
            )}

            <div className="d-grid plans">
                {products && products.map((product) => {
                    return (
                        <div className="element plan" key={product.id} id={product.id}>
                            <div className="d-flex justify-content-space-between mb-2">
                                <div className="d-flex">
                                    <div className="content">
                                        <h2 className="my-2">{product.metadata['title_'+selectedLanguage]}</h2>
                                        <p className="mb-2 white">
                                        {product.metadata['description_'+selectedLanguage]}
                                        </p>
                                    </div>
                                </div>

                                <div className="price">
                                    {!isEmpty(product.prices) && product.prices.sort((a, b) => !isEmpty(b?.recurring?.interval) && !isEmpty(a?.recurring?.interval) ? a.recurring.interval < b.recurring.interval ? -1 : 0 : 0).map(price => {
                                        return (
                                            <span key={price.id}
                                                  className={`${price?.recurring?.interval ? price?.recurring?.interval : "season" } my-2`}>{(price.unit_amount / 100).toLocaleString('eu-EU', {
                                                style: 'currency', currency: 'EUR'
                                            })}<br/>{!isEmpty(price?.recurring?.interval) ? price.recurring.interval : "season"}</span>
                                        )
                                    })}
                                </div>
                            </div>

                            <div className="advantages white">
                                <h3 className="my-4">
                                    {translations && !isEmpty(translations["packfan_advantage1"])
                                        ? parse(translations["packfan_advantage1"])
                                        : "Packfan Descr"}
                                </h3>
                                {product.description && (
                                    <p>{product.description}</p>
                                )}
                                {product.advantages && product.advantages.map((advantage, key) => {
                                    return (
                                        <p key={key}>
                                            <img src="/img/icons/check.svg" alt="check"/>
                                            {advantage.title_fr} {!isEmpty(advantage.description_fr) && advantage.description_fr.length > 0 && `(${advantage.description_fr})`}
                                        </p>
                                    );
                                }
                                )}
                            </div>
                            {isEmpty(activePlan) || (!isEmpty(activePlan) && activePlan.product.id !== product.id) ? (
                                <button
                                    className="btn btn-stripe mt-2"
                                    onClick={() => clickToPay(product)}
                                    style={{background: "#EF2F2A", borderRadius: "20px"}}
                                >
                                    {translations && !isEmpty(translations["subscribe2"])
                                        ? parse(translations["subscribe2"])
                                        : "Sub"}
                                </button>
                            ) : (
                                <button
                                    className="btn btn-stripe mt-2"
                                    style={{background: "green", borderRadius: "20px"}}
                                >
                                    {translations && !isEmpty(translations["current_subscribe"])
                                        ? parse(translations["current_subscribe"])
                                        : "current_subscribe"}
                                </button>
                            )}

                        </div>
                    )
                })}
            </div>
        </Fragment>
    );

}

export default Plans;