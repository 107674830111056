import React from "react";
import {useSelector} from "react-redux";
import {useMediaQuery} from "react-responsive";
import {addResolution, isEmpty, removeTags} from "../Utils";
import {NavLink} from "react-router-dom";
import parse from "html-react-parser";




const MenuSportCountry = () => {
    const sports = useSelector((state) => state.sportsReducer.sportsTypesReducer);
    const countries = useSelector((state) => state.countryReducer.countries);
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translations
    );

    const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

    return (
        <div className="under-menu">
            <div className="sports">
                <h3>{translations && !isEmpty(translations["watchFavoriteSport"])
                    ? parse(translations["watchFavoriteSport"])
                    : "watchFavoriteSport"}</h3>
                <ul>
                    {!isEmpty(sports) && !isEmpty(translations) &&
                    sports.map((sport) => {
                        return (
                            <li key={sport.name}>
                                <NavLink
                                    exact
                                    to={`/sport/${sport.name}`}
                                    className="item-menu"
                                    onClick={(e) =>
                                        isMobile &&
                                        document.querySelector(".main-header").classList.remove("menu-open")
                                    }
                                >
                                    <img
                                        src={sport.iconPatch ? sport.iconPatch : `${process.env.REACT_APP_CACHE_SERVER}/img/icons/default.svg`}
                                        alt={sport.name}
                                        width="40"
                                        height="40"
                                    />

                                    <span>
										{translations[sport.name] && !isEmpty(translations[sport.name])
                                            ? parse(translations[sport.name])
                                            : sport.name}
									</span>
                                </NavLink>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className="country">
                <h3>{translations && !isEmpty(translations["discoverOurOfferCountry"])
                    ? parse(translations["discoverOurOfferCountry"])
                    : "discoverOurOfferCountry"}</h3>
                <ul>
                    {!isEmpty(countries) &&
                    !isEmpty(translations) &&
                    countries.map((country) => {
                        let imgs = addResolution(["500", "250"], country.img);
                        return (
                            <li key={country.id}>
                                <NavLink
                                    exact
                                    to={`/country/${country.countryName}`}
                                    className="item-menu"
                                    onClick={(e) =>
                                        isMobile &&
                                        document.querySelector(".main-header").classList.remove("menu-open")
                                    }
                                >
                                    <img
                                        src={imgs[0] ? imgs[0] : `${process.env.REACT_APP_CACHE_SERVER}/img/icons/default.svg`}
                                        alt={country.countryName}
                                        srcSet={`${imgs[0]} 500w, ${imgs[1]} 250w`}
                                        width="40"
                                        height="40"
                                    />
                                    <span>
										{translations[country.countryName]
                                            ? removeTags(translations[country.countryName])
                                            : country.countryName}
									</span>
                                </NavLink>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default MenuSportCountry;