import React from "react";
import { isEmpty } from "../Utils";

const FirstDefault = (props) => {

    const width = props.width;
    const height = props.height;
    const name = props.name;
    const image = props.image;
    return (
        <div className="title-thumbnail emission" style={{height: height + "px"}}>
            <img
                src={image}
                width={!isEmpty(width) ? width / (Math.floor(width / 400) + 1) - 10 : 300}
                alt="background"
                className="img-bg"
            />
            {name && (
                <div className="bg-block">
                    <span>{name}</span>
                </div>
            )}
        </div>
    );
};

export default FirstDefault;
