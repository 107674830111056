import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import parse from "html-react-parser";
import {isEmpty} from "../../Utils";
import {getInvoices, getCustomer} from "../../../actions/stripe.action";
import moment from "moment";

const Orders = () => {
    const dispatch = useDispatch();
    const stripeReducer = useSelector((state) => state.stripeReducer);
    const invoices = stripeReducer.getInvoicesReducer;
    const customer = stripeReducer.getCustomerReducer;
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translations
    );
    useEffect(() => {
        dispatch(getCustomer());
    }, [dispatch])

    useEffect(() => {
        if (customer) {
            dispatch(getInvoices(3));
        }
    }, [customer]);

    return (
        <div className="orders-page">
            <h3>
                {translations && !isEmpty(translations["your_commands"])
                    ? parse(translations["your_commands"])
                    : "Your Commands"}
            </h3>
            <div className="orders">
                {invoices && (
                    invoices.map((invoice) => {
                        return (
                            <div className="invoice" key={invoice.id}>
                                <div className="content">
                                    <div className="title ml-4">{"Command N° " + invoice.number}</div>
                                    <div className="descr ml-4">{invoice.account_name}</div>
                                    <div className="info ml-4">
                                        <div>
                                            {invoice?.status === "paid"
                                                ? moment.unix(invoice.status_transitions.paid_at).format("DD/MM/YYYY")
                                                : (invoice?.status === "open" ?
                                                    <a href={invoice.hosted_invoice_url} target='_blank'
                                                       rel="noreferrer"
                                                       style={{color: "black"}}>{invoice?.status}</a> : invoice?.status)
                                            }
                                        </div>
                                        <div className="price">{(invoice.total / 100).toLocaleString('eu-EU', {
                                            style: 'currency',
                                            currency: 'EUR'
                                        })}</div>
                                    </div>
                                    <button
                                        className="btn btn-stripe ml-4 mt-4"
                                        onClick={() => window.open(invoice.invoice_pdf)}
                                    >
                                        {translations && !isEmpty(translations["see_order"])
                                            ? parse(translations["see_order"])
                                            : "See order"}
                                    </button>
                                </div>
                            </div>
                        )
                    })
                )}

            </div>
        </div>
    );
};

export default Orders;
