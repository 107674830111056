import { combineReducers } from "redux";
import {
	GET_FAVORITE,
} from "../actions/favorite.action";

const initialState = [];
function favoriteReducer(state = initialState, action) {
	switch (action.type) {
		case GET_FAVORITE:
			return action.payload;
		default:
			return state;
	}
}

export default combineReducers({ favoriteReducer });
