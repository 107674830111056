import React, { useState } from "react";
import EditHighlight from "./EditHighlight";
import HandleHighlights from "./HandleHighlights";
import HighlightEditor from "./HighlightEditor";

const Highlights = () => {
	const [actualComponent, setActualComponent] = useState(<HandleHighlights page="home_page" />);

	const defaultHighlight = {
		id: null,
		highlightName: "Highlight Title",
		link: "",
		img: null,
		description: "Highlight description",
		sport_id: "1",
		visible: "yes",
	};
	return (
		<div className="bo-highlights">
			<nav>
				<ul>
					<li
						className="selected"
						onClick={(e) => {
							setActualComponent(<HandleHighlights page="home_page" />);
							document
								.querySelectorAll(".bo-highlights > nav>ul>li")
								.forEach((elm) => {
									e.target === elm
										? elm.classList.add("selected")
										: elm.classList.remove("selected");
								});
						}}
					>
						Gérer le slideshow
					</li>
					<li
						onClick={(e) => {
							setActualComponent(
								<HighlightEditor highlight={defaultHighlight} isNew={true} />
							);
							document
								.querySelectorAll(".bo-highlights > nav>ul>li")
								.forEach((elm) => {
									e.target === elm
										? elm.classList.add("selected")
										: elm.classList.remove("selected");
								});
						}}
					>
						Créer un slide
					</li>
					<li
						onClick={(e) => {
							setActualComponent(<EditHighlight page="home_page"/>);
							document
								.querySelectorAll(".bo-highlights > nav>ul>li")
								.forEach((elm) => {
									e.target === elm
										? elm.classList.add("selected")
										: elm.classList.remove("selected");
								});
						}}
					>
						Editer une slide
					</li>
				</ul>
			</nav>
			<div className="bo-highlight-component">{actualComponent}</div>
		</div>
	);
};

export default Highlights;
