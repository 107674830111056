import React, {useEffect, useState, useContext} from "react";
import Header from "../components/profile/Header";
import Athlete from "../components/profile/Athlete";
import Fan from "../components/profile/Fan";
import Club from "../components/profile/Club";
import Media from "../components/profile/Media";
import {useHistory, useRouteMatch} from "react-router";
import {isEmpty} from "../components/Utils";
import {MyContext} from "../contexts/MyContext";
import {getActiveCustomerProduct} from "../actions/stripe.action";
import {useDispatch, useSelector} from "react-redux";
import Search from "../components/Search";


const Profile = () => {
    let match = useRouteMatch();
    const {
        params: { type },
    } = match;
    const stripeReducer = useSelector((state) => state.stripeReducer);
    const searchState = useSelector(
        (state) => state.searchReducer.searchToggleReducer
    );
    const activePlan = stripeReducer.getActiveCustomerProductReducer;
    const { rootState, isLoggedIn } = useContext(MyContext);
    const { theUser } = rootState;
    const [typeOfUser, setTypeOfUser] = useState("athlete");
    const [actualTypeComponent, setActualTypeComponent] = useState(<Athlete/>);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        isLoggedIn();
    }, []);

    useEffect(() => {
        dispatch(getActiveCustomerProduct());
    }, [theUser]);

    useEffect(() => {
        setTypeOfUser(theUser.account_type)
    }, [theUser]);

    useEffect(() => {
        if (!isEmpty(activePlan) && !isEmpty(theUser)
            && (
                (activePlan.need_to_set_group && activePlan.need_to_set_group !== "no" && isEmpty(theUser.selected_group)) ||
                (activePlan.need_to_set_club && activePlan.need_to_set_club !== "no" && isEmpty(theUser.selected_club))
            )) {
            history.push("/profile/group-select");
        }
    }, [activePlan, typeOfUser, type, theUser]);

    useEffect(() => {
        if (!isEmpty(setTypeOfUser)) {
            switch (typeOfUser) {
                case "athlete":
                    setActualTypeComponent(<Athlete/>);
                    break;
                case "fan":
                    setActualTypeComponent(<Fan/>);
                    break;
                case "club":
                    setActualTypeComponent(<Club/>);
                    break;
                case "media":
                    setActualTypeComponent(<Media/>);
                    break;
                default:
                    break;
            }
        }
    }, [typeOfUser, type]);

    return (
        <div className="profile">
            <Header

            />
            {searchState && <Search />}
            {actualTypeComponent}
        </div>
    );
};

export default Profile;
