import { combineReducers } from "redux";
import {
	CLEAR_REDUCER_VIDEO,
	GET_VIDEO,
	GET_VIDEOS_CLUB,
	GET_VIDEOS_COMMAND,
} from "../actions/video.action";

const initialState = {};

function videoReducer(state = initialState, action) {
	switch (action.type) {
		case GET_VIDEO:
			return action.payload;
		case CLEAR_REDUCER_VIDEO:
			return action.payload;
		default:
			return state;
	}
}

function videosClubReducer(state = initialState, action) {
	switch (action.type) {
		case GET_VIDEOS_CLUB:
			return action.payload;
		case CLEAR_REDUCER_VIDEO:
			return action.payload;
		default:
			return state;
	}
}

function videosCommandReducer(state = initialState, action) {
	switch (action.type) {
		case GET_VIDEOS_COMMAND:
			return action.payload;
		case CLEAR_REDUCER_VIDEO:
			return action.payload;
		default:
			return state;
	}
}

export default combineReducers({ videoReducer, videosClubReducer, videosCommandReducer });
