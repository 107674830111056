import React, { useContext, useEffect } from "react";
import { hexToHSL, isEmpty } from "../Utils";
import {FastAverageColor} from "fast-average-color";
import { useState } from "react";
import { MyContext } from "../../contexts/MyContext";
import { useSelector } from "react-redux";

import parse from "html-react-parser";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {useHistory} from "react-router";

const Header = (props) => {
	const { id, logo, name, cover, fb, ins, tw, web, followers, color, url } = props;
	//TRANSLATION
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);

	//FOLLOW
	const [className, setClassName] = useState(null);
	const [followHTML, setFollowHTML] = useState(translations && !isEmpty(translations["subscribe"])
		? translations["subscribe"]
		: "subscribe"
	);
	const { rootState, toggleFollow, isLoggedIn } = useContext(MyContext);
	const { isAuth, theUser } = rootState;
	const history = useHistory();

	useEffect(() => {
		if (className == null) {
			setFollowHTML(translations && !isEmpty(translations["subscribe"])
				? translations["subscribe"]
				: "subscribe"
			);
		} else {
			setFollowHTML(translations && !isEmpty(translations["follow"])
				? translations["follow"]
				: "follow"
			);
		}
	}, [translations, className]);

	useEffect(() => {
		if (!isEmpty(theUser)) {
			if (theUser.id_club === null) {
				setClassName(null);
				setFollowHTML(translations && !isEmpty(translations["subscribe"])
					? translations["subscribe"]
					: "subscribe"
				);
			} else if (theUser.id_club.includes(id)) {
				setClassName("follow");
				setFollowHTML(translations && !isEmpty(translations["follow"])
					? translations["follow"]
					: "follow"
				);
			} else {
				setClassName(null);
				setFollowHTML(translations && !isEmpty(translations["subscribe"])
					? translations["subscribe"]
					: "subscribe"
				);
			}
		}
	}, [setClassName, id, theUser, translations]);

	const onHover = (e) => {
		if (!isEmpty(theUser)) {
			if (!isEmpty(theUser.id_club) && theUser.id_club.includes(id)) {
				if (e.type === "mouseenter") {
					setFollowHTML(translations && !isEmpty(translations["unfollow"])
						? translations["unfollow"]
						: "unfollow"
					);
				} else {
					setFollowHTML(translations && !isEmpty(translations["follow"])
						? translations["follow"]
						: "follow"
					);
				}
			}
		}
	};
	const handleFollow = async (id) => {
		let followNumber = document.querySelector(".name-follow .count-followers");
		if (isAuth !== null && isAuth === true) {
			//UNFOLLOW
			if (theUser.id_club !== null && theUser.id_club.includes(id)) {
				const data = await toggleFollow(id, "unfollow");

				if (data.success) {
					setClassName(null);
					followNumber.innerHTML = parseInt(followNumber.innerHTML, 10) - 1;
				} else {
					console.log(data.message);
				}
			} else {
				//FOLLOW

				const data = await toggleFollow(id, "follow");

				if (data.success) {
					setClassName("follow");
					followNumber.innerHTML = parseInt(followNumber.innerHTML, 10) + 1;
				} else {
					console.log(data.message);
				}
			}
			isLoggedIn();
		} else {
			localStorage.setItem('loginRedirectUrl', window.location);
			history.push("/account/login")
		}
	};

	//COLORIZE LOGO
	const colorize = (img) => {
		const fac = new FastAverageColor();

		let image = new Image();
		image.crossOrigin = "anonymous";
		image.src = img.src;
		fac
			.getColorAsync(image)
			.then((color) => {
				let hsl = hexToHSL(color.hex);
				hsl.s = hsl.s * 4;
				img.parentElement.style.backgroundColor = `hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)`;
			})
			.catch((err) => console.log(err));
	};

	const [copied, setCopied] = useState(false);
	const handleCopy = () => {
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 2500);
	};

	return (
		<div className="header-clubs">
			<div className="cover">
				<img src={cover} alt={`${name} banner`} />
				<div className="dots">
					<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/dots1.png`} alt="decoration elements" />
					<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/dots2.png`} alt="decoration elements" />
					<img
						src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/sport_transparent.png`}
						alt="decoration elements"
					/>
				</div>
			</div>
			<div className="header-data">
				<div className="logo-container">
					<img
						src={logo}
						alt="logo"
						onLoad={(e) => {
							isEmpty(color)
								? colorize(e.target)
								: (e.target.parentElement.style.backgroundColor = color);
						}}
					/>
				</div>
				<div className="name-follow">
					<h2>{name}</h2>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
					>
						<path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z" />
					</svg>
					<span className="count-followers">{followers}</span>{" "}
					{translations && !isEmpty(translations["followers"])
						? parse(translations["followers"])
						: "followers"
					}
					<button
						className={className}
						onMouseEnter={(e) => {
							onHover(e);
						}}
						onMouseLeave={(e) => onHover(e)}
						onClick={() => {
							handleFollow(id);
						}}
					>
						{parse(followHTML)}
					</button>
				</div>

				<div className="social">
					<ul className="icons">
						{!isEmpty(fb) && (
							<a href={fb} target="_blank" rel="noopener noreferrer">
								<li>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
									>
										<path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
									</svg>
								</li>
							</a>
						)}
						{!isEmpty(tw) && (
							<a href={tw} target="_blank" rel="noopener noreferrer">
								<li>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
									>
										<path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
									</svg>
								</li>
							</a>
						)}
						{!isEmpty(ins) && (
							<a href={ins} target="_blank" rel="noopener noreferrer">
								<li>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
									>
										<path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
									</svg>
								</li>
							</a>
						)}
						{!isEmpty(web) && (
							<a href={web} target="_blank" rel="noopener noreferrer">
								<li>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
									>
										<path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 16.057v-3.057h2.994c-.059 1.143-.212 2.24-.456 3.279-.823-.12-1.674-.188-2.538-.222zm1.957 2.162c-.499 1.33-1.159 2.497-1.957 3.456v-3.62c.666.028 1.319.081 1.957.164zm-1.957-7.219v-3.015c.868-.034 1.721-.103 2.548-.224.238 1.027.389 2.111.446 3.239h-2.994zm0-5.014v-3.661c.806.969 1.471 2.15 1.971 3.496-.642.084-1.3.137-1.971.165zm2.703-3.267c1.237.496 2.354 1.228 3.29 2.146-.642.234-1.311.442-2.019.607-.344-.992-.775-1.91-1.271-2.753zm-7.241 13.56c-.244-1.039-.398-2.136-.456-3.279h2.994v3.057c-.865.034-1.714.102-2.538.222zm2.538 1.776v3.62c-.798-.959-1.458-2.126-1.957-3.456.638-.083 1.291-.136 1.957-.164zm-2.994-7.055c.057-1.128.207-2.212.446-3.239.827.121 1.68.19 2.548.224v3.015h-2.994zm1.024-5.179c.5-1.346 1.165-2.527 1.97-3.496v3.661c-.671-.028-1.329-.081-1.97-.165zm-2.005-.35c-.708-.165-1.377-.373-2.018-.607.937-.918 2.053-1.65 3.29-2.146-.496.844-.927 1.762-1.272 2.753zm-.549 1.918c-.264 1.151-.434 2.36-.492 3.611h-3.933c.165-1.658.739-3.197 1.617-4.518.88.361 1.816.67 2.808.907zm.009 9.262c-.988.236-1.92.542-2.797.9-.89-1.328-1.471-2.879-1.637-4.551h3.934c.058 1.265.231 2.488.5 3.651zm.553 1.917c.342.976.768 1.881 1.257 2.712-1.223-.49-2.326-1.211-3.256-2.115.636-.229 1.299-.435 1.999-.597zm9.924 0c.7.163 1.362.367 1.999.597-.931.903-2.034 1.625-3.257 2.116.489-.832.915-1.737 1.258-2.713zm.553-1.917c.27-1.163.442-2.386.501-3.651h3.934c-.167 1.672-.748 3.223-1.638 4.551-.877-.358-1.81-.664-2.797-.9zm.501-5.651c-.058-1.251-.229-2.46-.492-3.611.992-.237 1.929-.546 2.809-.907.877 1.321 1.451 2.86 1.616 4.518h-3.933z" />
									</svg>
								</li>
							</a>
						)}
					</ul>
					<div className="share">
						<CopyToClipboard onCopy={handleCopy} text={`https://fuchs-sports.com/${url}`}>
							<span>
								{translations && !isEmpty(translations["share"])
									? parse(translations["share"])
									: "share"
								}
							</span>
						</CopyToClipboard>
						{copied && (
							<ul>
								<li className="copy-message">
									Copy in clipboard !
								</li>
							</ul>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
