import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { isEmpty } from "../../components/Utils";
import WebTVS from "./WebTVS";
import Playlists from "./Playlists";
import Banner from "./Banner";

import parse from "html-react-parser";
import Highlights from "../country_page/Highlight/Highlights";

const CountryPage = () => {
	const countries = useSelector(
		(state) => state.countryReducer.countries
	);
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);
	const [ openBlock, setOpenBlock ] = useState(null);
	const [countrySelected, setCountrySelected] = useState(null);

	useEffect(() => {
		if (!isEmpty(countrySelected)) {
			document
				.querySelectorAll(".bo-country-page > header> ul>li")
				.forEach((elm) => elm.classList.remove("selected"));

			document
				.querySelector(`.li-${countrySelected.countryName}`)
				.classList.add("selected");
		}
	}, [countrySelected]);

	const handleMenu = (elm, block) => {
		let actual = document.querySelector(".homepage>ul>li>span.hover");
		if (!isEmpty(actual)) {
			actual.classList.remove("hover");
			if (actual !== elm) {
				elm.classList.add("hover");
			}
		} else {
			elm.classList.add("hover");
		}
		setOpenBlock(block)
	};

	return (
		<div className="bo-country-page">
			<header>
				<h1>Choisir un pays :</h1>
				<ul>
					{!isEmpty(countries) &&
						countries.map((country, index) => {
							return (
								<li
									className={`li-${country.countryName}`}
									key={country.countryName}
									onClick={() => setCountrySelected(country)}
								>
									{translations[country.countryName]
										? parse(translations[country.countryName])
										: country.countryName}
								</li>
							);
						})}
				</ul>
			</header>

			{!isEmpty(countrySelected) && (
				<nav className="homepage">
					<ul>
						<li>
							<span onClick={(e) => handleMenu(e.target, "Banner")}>Bannière</span>
							{openBlock === "Banner" && (
								<Banner selectedCountry={countrySelected} />
							)}
						</li>
						<li>
							<span onClick={(e) => handleMenu(e.target, "WebTVS1")}>Parcourir les Webs TVS</span>
							{openBlock === "WebTVS1" && (
								<WebTVS selectedCountry={countrySelected}/>
							)}
						</li>
						<li>
							<span onClick={(e) => handleMenu(e.target, "Playlists")}>Playlists</span>
							{openBlock === "Playlists" && (
								<Playlists selectedCountry={countrySelected} />
							)}
						</li>
						<li>
							<span onClick={(e) => handleMenu(e.target, "Highlights")}>Immanquables du Mois</span>
							{openBlock === "Highlights" && (
								<Highlights selectedCountry={countrySelected} />
							)}
						</li>
					</ul>
				</nav>
			)}
		</div>
	);
};

export default CountryPage;
