import React, {useState, useEffect} from "react";
import AthleteVideos from "./athleteSubmenu/AthleteVideos";
import Orders from "./common/Orders";
import BillingInfo from "./common/BillingInfo";
import Plans from "./common/Plans";
import {isEmpty} from "../Utils";
import parse from "html-react-parser";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {useHistory, useRouteMatch} from "react-router";
import Info from "./common/Info";
import GroupSelect from "./common/GroupSelect";

const Fan = () => {
    let match = useRouteMatch();

    const {
        params: {type},
    } = match;

    const history = useHistory();
    const [actualSubMenu, setActualSubMenu] = useState("info");
    const [actualSubMenuComponent, setActualSubMenuComponent] = useState(<Info/>);
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translations
    );

    useEffect(() => {
        let accessType = ["info", "videos", "orders", "billing-info", "plans", "group-select"].includes(type);
        if (accessType) {
            setActualSubMenu(type);
        } else {
            history.push("/profile/info")
        }
    }, [type]);

    useEffect(() => {
        switch (actualSubMenu) {
            case "info":
                setActualSubMenuComponent(<Info/>);
                break;
            case "videos":
                setActualSubMenuComponent(<AthleteVideos/>);
                break;
            case "orders":
                setActualSubMenuComponent(<Orders/>);
                break;
            case "billing-info":
                setActualSubMenuComponent(<BillingInfo/>);
                break;
            case "plans":
                setActualSubMenuComponent(<Plans />);
                break;
            case "group-select":
                setActualSubMenuComponent(<GroupSelect />);
                break;
            default:
                setActualSubMenuComponent(<Info/>);
                break;
        }
    }, [actualSubMenu]);

    return (
        <div className="athlete-page">
            <section>
                <div className="submenu">
                    <ul className="nav-tabs">
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/profile/info"
                                activeClassName=""
                                className={actualSubMenu === "info" ? "nav-link active" : "nav-link"}
                                onClick={() => {
                                    setActualSubMenu("info");
                                }}
                            >
                                {translations && !isEmpty(translations["my_info"])
                                    ? parse(translations["my_info"])
                                    : "My Information"
                                }
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/profile/videos"
                                activeClassName=""
                                className={actualSubMenu === "videos" ? "nav-link active" : "nav-link"}
                                onClick={() => {
                                    setActualSubMenu("videos");
                                }}
                            >
                                {translations && !isEmpty(translations["myVideos"])
                                    ? parse(translations["myVideos"])
                                    : "My Videos"
                                }
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/profile/orders"
                                activeClassName=""
                                className={actualSubMenu === "orders" ? "nav-link active" : "nav-link"}
                                onClick={() => {
                                    setActualSubMenu("orders");
                                }}
                            >
                                {translations && !isEmpty(translations["myOrders"])
                                    ? parse(translations["myOrders"])
                                    : "My Orders"
                                }
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/profile/billing-info"
                                activeClassName=""
                                className={actualSubMenu === "billing-info" ? "nav-link active" : "nav-link"}
                                onClick={() => {
                                    setActualSubMenu("billing-info");
                                }}
                            >
                                {translations && !isEmpty(translations["billing"])
                                    ? parse(translations["billing"])
                                    : "Billing"
                                }
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/profile/plans"
                                activeClassName=""
                                className={actualSubMenu === "plans" ? "nav-link active" : "nav-link"}
                                onClick={() => {
                                    setActualSubMenu("plans");
                                }}
                            >
                                {translations && !isEmpty(translations["plans"])
                                    ? parse(translations["plans"])
                                    : "Plans"
                                }
                            </NavLink>
                        </li>

                    </ul>
                </div>
                <article>
                    {actualSubMenuComponent}
                </article>
            </section>
        </div>
    );
};

export default Fan;
