import axios from "axios";

export const GET_GROUPS = "GET_GROUPS";

export const getGroups = (id = '', leagueId = '', divId = '', sportId = '', country = '', lang = 'EN') => {
	return async (dispatch) => {
		await axios
			.get(`${process.env.REACT_APP_API_URL}getGroups&i=${id}&lig=${leagueId}&div=${divId}&idsp=${sportId}&country=${country}&l=${lang}`)
			.then((res) => dispatch({type: GET_GROUPS, payload: res.data}))
			.catch((error) => {
				console.log(error)
			});
	};
};
