import axios from "axios";

export const GET_EVENTS = "GET_EVENTS";
export const GET_REMINDME_EVENTS = "GET_REMINDME_EVENTS";
export const GET_EVENTS_SPORTS = "GET_EVENTS_SPORTS";
export const GET_EVENTS_LEAGUES = "GET_EVENTS_LEAGUES";
export const CLEAR_EVENTS = "CLEAR_EVENTS";
export const GET_DAYS = "GET_DAYS";
export const GET_EVENTS_POPULAR = "GET_EVENTS_POPULAR";
export const GET_ONE_EVENT = "GET_ONE_EVENT";

export const getEvents = (
	quantity = "30",
	ids = "",
	idl = "",
	idiv = "",
	idg = "",
	day = "",
	pl = "",
	country = ""
) => {
	return (dispatch) => {
		try {
			let quantityUrl = encodeURI(quantity);
			let sportUrl = encodeURI(ids);
			let leagueUrl = encodeURI(idl);
			let divisionUrl = encodeURI(idiv);
			let groupUrl = encodeURI(idg);
			let dayUrl = encodeURI(day);
			let plUrl = encodeURI(pl);
			let countryUrl = encodeURI(country);
		
			const Axios = axios.create();
			const loginToken = localStorage.getItem("loginToken");

			if (loginToken) {
				Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;
			}
			Axios.get(`${process.env.REACT_APP_NOT_CACHED_API_URL}getEvents&n=${quantityUrl}&ids=${sportUrl}&idl=${leagueUrl}&idiv=${divisionUrl}&idg=${groupUrl}&day=${dayUrl}&pl=${plUrl}&country=${countryUrl}`)
				.then((res) => {
					dispatch({ type: GET_EVENTS, payload: res.data.events });
				}).catch((error) => {
					dispatch({ type: GET_EVENTS, payload: [] });
				});
		} catch (err) {
			return console.log(err);
		}
	};
};

export const getRemindMeEvents = (
	quantity = "30",
	ids = "",
	idl = "",
	idiv = "",
	idg = "",
	day = "",
	pl = "",
	country = "",
) => {
	return async (dispatch) => {
		try {
			let quantityUrl = encodeURI(quantity);
			let sportUrl = encodeURI(ids);
			let leagueUrl = encodeURI(idl);
			let divisionUrl = encodeURI(idiv);
			let groupUrl = encodeURI(idg);
			let dayUrl = encodeURI(day);
			let plUrl = encodeURI(pl);
			let countryUrl = encodeURI(country);
		
			const Axios = axios.create();
			const loginToken = localStorage.getItem("loginToken");
			Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;

			if (loginToken) {
				Axios.get(`${process.env.REACT_APP_NOT_CACHED_API_URL}getRemindMeEvents&n=${quantityUrl}&ids=${sportUrl}&idl=${leagueUrl}&idiv=${divisionUrl}&idg=${groupUrl}&day=${dayUrl}&pl=${plUrl}&country=${countryUrl}`
				).then((res) => {
					dispatch({ type: GET_REMINDME_EVENTS, payload: res.data.events });
				}).catch((error) => {
					dispatch({ type: GET_REMINDME_EVENTS, payload: [] });
				});

			} else {
				dispatch({ type: GET_REMINDME_EVENTS, payload: [] });
			}

		} catch (err) {
			return console.log(err);
		}
	};
};

export const getEventsSport = (
	ids,
	idl = "",
	idiv = "",
	idg = "",
	quantity = "10"
) => {
	return async (dispatch) => {
		try {
			let quantityUrl = encodeURI(quantity);
			let sportIdUrl = encodeURI(ids);
			let leagueUrl = encodeURI(idl);
			let divisionUrl = encodeURI(idiv);
			let groupUrl = encodeURI(idg);
			const res = await axios.get(
				`${process.env.REACT_APP_NOT_CACHED_API_URL}getEvents&n=${quantityUrl}&ids=${sportIdUrl}&idl=${leagueUrl}&idiv=${divisionUrl}&idg=${groupUrl}`
			);
			dispatch({
				type: GET_EVENTS_SPORTS,
				payload: res.data.events,
				sportId: sportIdUrl,
			});
		} catch (err) {
			return console.log(err);
		}
	};
};

export const getEventsPopular = (quantity = "10") => {
	return async (dispatch) => {
		try {
			let quantityUrl = encodeURI(quantity);
			const res = await axios.get(
				`${process.env.REACT_APP_NOT_CACHED_API_URL}getEventsPopular&n=${quantityUrl}`
			);
			dispatch({ type: GET_EVENTS_POPULAR, payload: res.data.events });
		} catch (err) {
			return console.log(err);
		}
	};
};

export const getEventsLeague = (
	idl,
	ids = "",
	idiv = "",
	idg = "",
	quantity = "10"
) => {
	return async (dispatch) => {
		try {
			let leagueIdUrl = encodeURI(idl);
			let sportIdUrl = encodeURI(ids);
			let divisionUrl = encodeURI(idiv);
			let groupUrl = encodeURI(idg);
			let quantityUrl = encodeURI(quantity);

			const res = await axios.get(
				`${process.env.REACT_APP_NOT_CACHED_API_URL}getEvents&n=${quantityUrl}&ids=${sportIdUrl}&idl=${leagueIdUrl}&idiv=${divisionUrl}&idg=${groupUrl}`
			);
			dispatch({
				type: GET_EVENTS_LEAGUES,
				payload: res.data.events,
				leagueId: leagueIdUrl,
			});
		} catch (err) {
			return console.log(err);
		}
	};
};

export const getDistinctDays = (idl = "", idiv = "", idg = "", cl = "") => {
	return async (dispatch) => {
		try {
			let leagueUrl = encodeURI(idl);
			let divisionUrl = encodeURI(idiv);
			let groupUrl = encodeURI(idg);
			let clubUrl = encodeURI(cl);
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}getDistinctDays&idl=${leagueUrl}&idiv=${divisionUrl}&idg=${groupUrl}&cl=${clubUrl}`
			);
			dispatch({ type: GET_DAYS, payload: res.data.days });
		} catch (err) {
			return console.log(err);
		}
	};
};

export const clearEvents = () => {
	return (dispatch) => {
		return dispatch({ type: CLEAR_EVENTS, payload: null });
	};
};

export const getOneEvent = (id) => {
	return async (dispatch) => {
		await axios
			.get(`${process.env.REACT_APP_NOT_CACHED_API_URL}getEvents&evtID=${id}`)
			.then((res) => dispatch({type: GET_ONE_EVENT, payload: res.data.events}))
			.catch((error) => {
				console.log(error)
			});
	};
};
