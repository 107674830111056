import React, {Fragment, useContext, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "../../Utils";
import EditPayment from "./EditPayment";
import EditBillingInfo from "./EditBillingInfo";
import {loadStripe} from "@stripe/stripe-js";
import {MyContext} from "../../../contexts/MyContext";
import {Elements} from "@stripe/react-stripe-js";
import {getCustomer, getListPaymentsMethods, getActiveCustomerProduct} from "../../../actions/stripe.action";
import {toggleModal} from "../../../actions/modal.action";
import Switch from "react-switch";
import moment from "moment";
import parse from "html-react-parser";
import {useHistory} from "react-router";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC}`);

const BillingInfo = ({changeActualComponent}) => {
    const {detachPaymentMethod, updateDefaultPaymentMethod} = useContext(MyContext);
    const stripeReducer = useSelector((state) => state.stripeReducer);
    const customer = stripeReducer.getCustomerReducer;
    const payments = stripeReducer.getListPaymentsReducer;
    const activePlan = stripeReducer.getActiveCustomerProductReducer;
    const dispatch = useDispatch();
    const [openEditPayment, setOpenEditPayment] = useState(false);
    const [openEditBillingInfo, setOpenEditBillingInfo] = useState(false);
    const [firsAsDefault, setFirsAsDefault] = useState(false);
    const [customerPlan, setCustomerPlan] = useState(false);
    const history = useHistory();
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translations
    );

    useEffect(() => {
        dispatch(getCustomer());
        dispatch(getActiveCustomerProduct());
    }, [dispatch]);

    useEffect(() => {
        if (!isEmpty(customer)) {
            dispatch(getListPaymentsMethods(customer.id));
        }
    }, [customer]);


    useEffect(() => {
        if (!isEmpty(payments) && payments.length > 0) {
            setFirsAsDefault(false);
        } else {
            setFirsAsDefault(true);
        }
    }, [payments])

    useEffect(() => {
        if (!isEmpty(activePlan)) {
            setCustomerPlan(activePlan)
        }
    }, [activePlan]);

    const removeCard = async (paymentId) => {
        if (window.confirm("Are you sure you want to delete it?")) {
            if (!isEmpty(customer.id) && paymentId) {
                let result = await detachPaymentMethod(customer.id, paymentId);
                if (result.result === "ok") {
                    dispatch(getListPaymentsMethods(customer.id));
                    dispatch(toggleModal({opened: true, success: true, text: "Payments data updated"}));
                } else if (result.result === "bad" && result.error) {
                    dispatch(toggleModal({opened: true, success: false, text: result.error}));
                }
            }
        }
    };

    const setDefault = async (paymentId) => {
        if (!isEmpty(customer.id) && paymentId) {
            let result = await updateDefaultPaymentMethod(customer.id, paymentId);
             console.log(result);
            if (result.result === "ok") {
                dispatch(getCustomer())
                dispatch(toggleModal({opened: true, success: true, text: "Payments data updated"}));
            } else if (result.result === "bad" && result.error) {
                dispatch(toggleModal({opened: true, success: false, text: result.error}));
            }
        }
    };

    const defaultCard = useMemo(() => {
        // eslint-disable-next-line array-callback-return
        let card = payments.filter((item) => {
            if (customer) {
                if (customer.invoice_settings.default_payment_method === item.id || customer.default_source === item.id) {
                    return item.id
                }
            }
        })
        return card[0]?.id
    }, [customer, customer.default_source, customer.invoice_settings, payments]);


    return (
        <div className="info-page">
            <div className="billing">
                <div className="container">
                    <h3 className="mt-4">
                        {translations && !isEmpty(translations["billing_info"])
                            ? parse(translations["billing_info"])
                            : "Billing Information"}
                    </h3>
                    <div className="method">
                        <div className="card d-flex mt-4 mb-4">
                            <div className="ml-4">
                                <img src="/img/icons/location.svg" alt=""/>
                            </div>
                            {customer && !isEmpty(customer.shipping) && (
                                <div className="my-auto ml-4 d-grid">
                                    <span className="number">{customer.shipping?.name}</span>
                                    <span className="expire mt-2">

                                        {(!isEmpty(customer.shipping?.address.postal_code) && customer.shipping?.address.postal_code.length > 1) || (!isEmpty(customer.shipping?.address.line1) && customer.shipping?.address.line1.length > 1) || (!isEmpty(customer.shipping?.address.city) && customer.shipping?.address.city.length > 1) || (!isEmpty(customer.shipping?.address.state) && customer.shipping?.address.state.length > 1) || (!isEmpty(customer.shipping?.address.country) && customer.shipping?.address.country.length > 1) ? (
                                            <Fragment>
                                                {!isEmpty(customer.shipping?.address.postal_code) && customer.shipping?.address.postal_code.length > 1 && customer.shipping?.address.postal_code}

                                                {!isEmpty(customer.shipping?.address.line1) && customer.shipping?.address.line1.length > 1 && `, ${customer.shipping?.address.line1} ${customer.shipping?.address.line2}`}

                                                <br/>

                                                {!isEmpty(customer.shipping?.address.city) && customer.shipping?.address.city.length > 1 && customer.shipping?.address.city}

                                                {!isEmpty(customer.shipping?.address.state) && customer.shipping?.address.state.length > 1 && `, ${customer.shipping?.address.state}`}

                                                {!isEmpty(customer.shipping?.address.country) && customer.shipping?.address.country.length > 1 && `, ${customer.shipping?.address.country}`}
                                            </Fragment>
                                        ) : (
                                            <span className="no-bill">
                                                {translations && !isEmpty(translations["no_billing_info"])
                                                    ? parse(translations["no_billing_info"])
                                                    : "No Billing Information"}
                                            </span>
                                        )}

                                    </span>

                                </div>
                            )}
                        </div>
                        <div className="edit my-auto mr-4">
                            <img src="/img/icons/edit.svg" width="21" length="21" alt=""
                                 onClick={() => setOpenEditBillingInfo(!openEditBillingInfo)}/>
                        </div>
                    </div>
                </div>


                <div className="container">
                    <h3 className="mt-4">{translations && !isEmpty(translations["means_payment"])
                        ? parse(translations["means_payment"])
                        : "Means of Payment"}
                    </h3>
                    <div>
                        {payments && (
                            payments.map((elem, index) => {
                                return (
                                    <div className={`${defaultCard === elem.id ? "default method" : "method"}`}
                                         key={elem.id}>
                                        <div className="card d-flex mt-4">
                                            <div className="image ml-4">
                                                <img src={`/img/icons/cards/${elem.card.brand}.svg`} alt=""/>
                                            </div>
                                            <div className="my-auto mx-2 d-grid">
                                                <span className="number">{elem.card.brand} *** {elem.card.last4}</span>
                                                <span
                                                    className="expire mt-2">Expire le {elem.card.exp_month < 10 ? `0${elem.card.exp_month}` : elem.card.exp_month}/{elem.card.exp_year}</span>
                                            </div>
                                            <div className="d-grid ml-4 mb-2">
                                                <label htmlFor={`default_${elem.id}`}>Default</label>
                                                <Switch
                                                    onChange={() => setDefault(elem.id)}
                                                    checked={defaultCard === elem.id}
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    offColor="#d6d6d6"
                                                    onColor="#679bff"
                                                    id={`default_${elem.id}`}
                                                />
                                            </div>
                                        </div>

                                        <div className="edit my-auto mr-4">
                                            <img src="/img/icons/delete.svg" alt=""
                                                 onClick={() => removeCard(elem.id)}/>
                                        </div>
                                    </div>
                                )
                            })
                        )}

                        <button
                            className="btn btn-stripe mt-2 small"
                            onClick={() => setOpenEditPayment(!openEditPayment)}
                            style={{background: "#06132D"}}
                        >Add method
                        </button>
                    </div>
                </div>


                <div className="container">
                    {customerPlan && (
                        <Fragment>
                            <h3 className="mt-4">
                                {translations && !isEmpty(translations["subscription"])
                                    ? parse(translations["subscription"])
                                    : "Subscription"}
                            </h3>
                            <div className="method">
                                <div className="card d-flex mt-4">
                                    <div className="ml-4">
                                        <img src="/img/icons/logo_fuchs.svg" alt=""/>
                                    </div>
                                    <div className="my-auto ml-4 d-grid">
                                        <span
                                            className="number">{!isEmpty(customerPlan.product?.metadata?.title) ? customerPlan.product?.metadata?.title : ""}</span>
                                        <span className="expire mt-2">
                                            {customerPlan.type === "recurring" ? (customerPlan.recurring.interval === "year" ? "Yearly Subscription" : "Monthly Subscription") : "One-time payment"} {customerPlan.type === "recurring" ? "| Next bill: " + moment.unix(customerPlan.next_direct).format("DD-MM-YYYY") : ""} | {(customerPlan.unit_amount / 100).toLocaleString('eu-EU', {style: 'currency', currency: 'EUR'})}
                                        </span>
                                    </div>
                                </div>
                                <div className="edit my-auto mr-4" onClick={() => history.push("/profile/plans")}>
                                    <img src="/img/icons/edit.svg" alt=""/>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>


                {openEditPayment && (
                    <Elements stripe={stripePromise}>
                        <EditPayment
                            onClose={() => setOpenEditPayment(!openEditPayment)}
                            onSuccess={() => console.log("add success")}
                            setAsDefault={firsAsDefault}
                        />
                    </Elements>
                )}
                {openEditBillingInfo && customer && (
                    <EditBillingInfo
                        onClose={() => setOpenEditBillingInfo(!openEditBillingInfo)}
                    />
                )}

            </div>
        </div>
    );
};

export default BillingInfo;
