import React from "react";
import { isEmpty } from "../Utils";
import { NavLink } from "react-router-dom";

const Banner = (props) => {
    const { title, bannerPatch, name } = props;
    return (
        <div className="banner">
            <section className="item">
                <div className="title">
                    <NavLink exact to={`/sport/${name}`}>
                        <h1 className="decor">
                            <span>{title}</span>
                        </h1>
                    </NavLink>
                </div>
                <img
                    src={
                        !isEmpty(bannerPatch) ? bannerPatch : `${process.env.REACT_APP_CACHE_SERVER}/img/footages/banner-event.png`
                    }
                    width="100"
                    alt={`Banner event ${title}`}
                />
                <div className="dots">
                    <img
                        src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/sport_transparent.png`}
                        alt="decoration elements"
                    />
                </div>
            </section>
        </div>
    );
};

export default Banner;
