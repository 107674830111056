import {
	CLEAR_WEBTV_DATA,
	GET_WEBTVS,
	GET_WEBTV_DATA,
	REORDER_WEBTVS, GET_ALL_WEBTV_DATA,
} from "../actions/webTVS.action";

const initialState = [];

export function webTVSReducer(state = initialState, action) {
	switch (action.type) {
		case GET_WEBTVS:
			return action.payload;
		case REORDER_WEBTVS:
			return action.payload;
		default:
			return state;
	}
}

export function allWebTvs(state = initialState, action) {
	switch (action.type) {
		case GET_ALL_WEBTV_DATA:
			return action.payload;
		case REORDER_WEBTVS:
			return action.payload;
		default:
			return state;
	}
}

export function webTVData(state = initialState, action) {
	switch (action.type) {
		case GET_WEBTV_DATA:
			return action.payload;
		case CLEAR_WEBTV_DATA:
			return initialState;
		default:
			return state;
	}
}
