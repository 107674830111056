import React, { useContext, useEffect, useState } from "react";
import Iframe from "react-iframe";
import {useDispatch, useSelector} from "react-redux";
import { getOneEvent } from "../actions/events.action";
import { getWebTVData } from "../actions/webTVS.action";
import { isEmpty } from "../components/Utils";
import { MyContext } from "../contexts/MyContext";
import {Redirect} from "react-router";
import {Helmet} from "react-helmet-async";

const IframeLive = ({ match }) => {
	const {
		params: { evtID, clubURL },
	} = match;
	const webTV = useSelector((state) => state.webTVData);
	const [club, setClub] = useState("");
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getOneEvent(evtID));
		dispatch(getWebTVData(clubURL));
	}, [dispatch, clubURL, evtID]);

	useEffect(() => {
		if (!isEmpty(webTV)) {
			setClub(webTV[0]);
		}
	}, [webTV]);

	const { rootState, isLoginAuth } = useContext(MyContext);
	const { isAuth, theUser } = rootState;
	const [state, setState] = useState(null);
	useEffect(() => {
		async function auth() {
			setState(await isLoginAuth());
		}
		auth();
		// eslint-disable-next-line
	}, [isAuth]);

	return (
		<>
			<Helmet>
				<title>Fuchs Sports International</title>
				<meta property="og:image" content={`${process.env.REACT_APP_CACHE_SERVER}/img/icons/logo_fuchs_sports.png`} />
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
			</Helmet>

			<div>
				{state === false && <Redirect to="/account" />}
				{!isEmpty(club.id) && !isEmpty(evtID) &&
					<Iframe
						id="player"
						title="video"
						width="100%"
						height="100%"
						url={`https://mytvchain.com/liveevt.php?e=${club.id}&ide=${evtID}&dr=0&domain=fuchs-sports&ai=${!isEmpty(theUser) && theUser.id}`}
						loading="eager"
						allow="fullscreen;autoplay;encrypted-media;geolocation"
						className="iframe-only"
					>
						Your browser doesn't support player functionnality...
					</Iframe>
				}
			</div>
		</>
	);
};

export default IframeLive;
