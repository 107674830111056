import axios from "axios";

export const GET_SPORTS_EVENTS = "GET_SPORTS_EVENTS";
export const DELETE_SPORTS_EVENTS = "DELETE_SPORTS_EVENTS";

export const getSportsEvents = (input, sportId) => {
	return async (dispatch) => {
		dispatch({ type: DELETE_SPORTS_EVENTS });
		let inputUrl = encodeURI(input);
		let sportIdUrl = encodeURI(sportId);
		await axios.get(`${process.env.REACT_APP_API_URL}getEvents&n=10&s=${inputUrl}&ids=${sportIdUrl}`)
			.then((res) => dispatch({ type: GET_SPORTS_EVENTS, payload: res.data.events }))
			.catch((error) => {
				console.log(error)
			});
	};
};

export const GET_SPORTS = "GET_SPORTS";

export const getSports = () => {
	return async (dispatch) => {
		await axios
			.get(`${process.env.REACT_APP_API_URL}getSports`)
			.then((res) => dispatch({ type: GET_SPORTS, payload: res.data.sports }))
			.catch((err) => console.log(err));
	};
};

export const GET_ALL_SPORTS = "GET_ALL_SPORTS";

export const getAllSports = () => {
	return async (dispatch) => {
		await axios
			.get(`${process.env.REACT_APP_API_URL}getSports&v=all`)
			.then((res) => dispatch({ type: GET_ALL_SPORTS, payload: res.data.sports }))
			.catch((err) => console.log(err));
	};
};