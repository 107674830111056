import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import SportsSubMenu from "./SportsSubMenu";
import Footer from "../Footer";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { isEmpty } from "../Utils";
import { MyContext } from "../../contexts/MyContext";

const NavBar = () => {
    const location = useLocation();
    const [active, setActive] = useState(null);
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translations
    );
    const { rootState, logoutUser } = useContext(MyContext);
    const { isAuth } = rootState;

    //GET LOCATION
    useEffect(() => {
        if (location.pathname.startsWith("/sport")) {
            setActive("sport");
        } else if (location.pathname.startsWith("/events")) {
            setActive("direct");
        } else if (location.pathname.startsWith("/emissions")) {
            setActive("emissions");
        } else if (location.pathname === "/") {
            setActive("explorer");
        } else if (location.pathname.startsWith("/profile")) {
            setActive("profile");
        } else {
            setActive("null");
        }
    }, [location]);

    const dropdownHover = (menu, event) => {
        let sports = document.querySelector(".sports");
        let bckg = document.querySelector(".hide-background");
        if (
            event.type === "mouseenter" &&
            menu !== "explorer" &&
            menu !== "direct" &&
            menu !== "emissions" &&
            menu !== "profile"
        ) {
            bckg.classList.add("hover");
        } else {
            bckg.classList.remove("hover");
        }
        switch (menu) {
            case "close":
                sports.classList.remove("hover");

                break;
            case "explorer":
                if (event.type === "mouseenter") {
                    setActive("explorer");
                } else {
                    if (location.pathname.startsWith("/sport")) {
                        setActive("sport");
                    }
                    if (location.pathname.startsWith("/events")) {
                        setActive("direct");
                    }
                    if (location.pathname.startsWith("/emissions")) {
                        setActive("emissions");
                    }
                    if (location.pathname.startsWith("/profile")) {
                        setActive("profile");
                    }
                }
                break;
            case "sports":
                sports.classList.add("hover");
                if (event.type === "mouseenter") {
                    setActive("sport");
                } else {
                    sports.classList.remove("hover");
                    if (location.pathname === "/") {
                        setActive("explorer");
                    }
                    if (location.pathname.startsWith("/events")) {
                        setActive("direct");
                    }
                    if (location.pathname.startsWith("/emissions")) {
                        setActive("emissions");
                    }
                    if (location.pathname.startsWith("/profile")) {
                        setActive("profile");
                    }
                }
                break;
            case "direct":
                if (event.type === "mouseenter") {
                    setActive("direct");
                } else {
                    if (location.pathname === "/") {
                        setActive("explorer");
                    }
                    if (location.pathname.startsWith("/emissions")) {
                        setActive("emissions");
                    }
                    if (location.pathname.startsWith("/sport")) {
                        setActive("sport");
                    }
                    if (location.pathname.startsWith("/profile")) {
                        setActive("profile");
                    }
                }
                break;

            case "emissions":
                if (event.type === "mouseenter") {
                    setActive("emissions");
                } else {
                    if (location.pathname === "/") {
                        setActive("explorer");
                    }
                    if (location.pathname.startsWith("/events")) {
                        setActive("direct");
                    }
                    if (location.pathname.startsWith("/sport")) {
                        setActive("sport");
                    }
                    if (location.pathname.startsWith("/profile")) {
                        setActive("profile");
                    }
                }
                break;

            case "profile":
                if (event.type === "mouseenter") {
                    setActive("profile");
                } else {
                    if (location.pathname === "/") {
                        setActive("explorer");
                    }
                    if (location.pathname.startsWith("/events")) {
                        setActive("direct");
                    }
                    if (location.pathname.startsWith("/sport")) {
                        setActive("sport");
                    }
                    if (location.pathname.startsWith("/emissions")) {
                        setActive("emissions");
                    }
                }
                break;
            default:
                return;
        }
    };

    const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

    return (
        <nav>
            <ul>
                <li>
                    <NavLink
                        exact
                        to="/"
                        activeClassName=""
                        className={active === "explorer" ? "active" : ""}
                        onMouseEnter={(e) => dropdownHover("explorer", e)}
                        onMouseLeave={(e) => dropdownHover("explorer", e)}
                        onClick={(e) =>
                            isMobile &&
                            document
                                .querySelector(".main-header")
                                .classList.remove("menu-open")
                        }
                    >
                        {translations && !isEmpty(translations["explorerPage"])
                            ? parse(translations["explorerPage"])
                            : "explorerPage"}
                    </NavLink>
                </li>
                <li className="direct">
                    <NavLink
                        exact
                        to="/events"
                        activeClassName=""
                        className={active === "direct" ? "active" : ""}
                        onMouseEnter={(e) => dropdownHover("direct", e)}
                        onMouseLeave={(e) => dropdownHover("direct", e)}
                        onClick={(e) =>
                            isMobile &&
                            document
                                .querySelector(".main-header")
                                .classList.remove("menu-open")
                        }
                    >
                        {translations && !isEmpty(translations["directPage"])
                            ? parse(translations["directPage"])
                            : "directPage"}
                    </NavLink>
                </li>
                <li className="emissions">
                    <NavLink
                        exact
                        to="/emissions"
                        activeClassName=""
                        className={active === "emissions" ? "active" : ""}
                        onMouseEnter={(e) => dropdownHover("emissions", e)}
                        onMouseLeave={(e) => dropdownHover("emissions", e)}
                        onClick={(e) =>
                            isMobile &&
                            document
                                .querySelector(".main-header")
                                .classList.remove("menu-open")
                        }
                    >
                        {translations && !isEmpty(translations["emissionsPage"])
                            ? parse(translations["emissionsPage"])
                            : "emissionsPage"}
                    </NavLink>
                </li>
                <li
                    className={`sports-container ${
                        active === "sport" ? "active" : ""
                    }`}
                    onMouseEnter={(e) =>
                        !isMobile && dropdownHover("sports", e)
                    }
                    onMouseLeave={(e) =>
                        !isMobile && dropdownHover("sports", e)
                    }
                    onClick={(e) =>
                        (!isMobile && dropdownHover("close", e)) ||
                        (isMobile &&
                        document
                            .querySelector("nav .sports")
                            .classList.contains("hover")
                            ? document
                                  .querySelector("nav .sports")
                                  .classList.remove("hover")
                            : document
                                  .querySelector("nav .sports")
                                  .classList.add("hover"))
                    }
                >
                    {translations && !isEmpty(translations["sportPage"])
                        ? parse(translations["sportPage"])
                        : "sportPage"}
                    <SportsSubMenu />
                </li>

                {isAuth !== null ? (
                    isAuth === true ? (
                        <li className="btn-profile">
                            <NavLink
                                exact
                                to="/profile/info"
                                activeClassName=""
                                className="btn btn-red"
                                onClick={(e) =>
                                    isMobile &&
                                    document
                                        .querySelector(".main-header")
                                        .classList.remove("menu-open")
                                }
                            >
                                {translations &&
                                !isEmpty(translations["myProfile"])
                                    ? parse(translations["myProfile"])
                                    : "myProfile"}
                            </NavLink>
                        </li>
                    ) : null
                ) : null}

                {isAuth !== null ? (
                    isAuth === true ? (
                        isMobile === true ? (
                            <li className="logout">
                                <NavLink
                                    exact
                                    to="/"
                                    activeClassName=""
                                    className={
                                        active === "logout" ? "active" : ""
                                    }
                                    onClick={(e) => {
                                        logoutUser();
                                        if (isMobile) {
                                            document
                                                .querySelector(".main-header")
                                                .classList.remove("menu-open");
                                        }
                                    }}
                                >
                                    {translations &&
                                    !isEmpty(translations["logout"])
                                        ? parse(translations["logout"])
                                        : "logout"}
                                </NavLink>
                            </li>
                        ) : null
                    ) : null
                ) : null}
            </ul>

            <Footer />
        </nav>
    );
};

export default NavBar;
