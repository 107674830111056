import React, {useState} from "react";
import AddAdvantage from "./AddAdvantage";
import AllAdvantage from "./AllAdvantage";

const Advantage = () => {
	const [menu, setMenu] = useState("all");
	const [actualComponent, setActualComponent] = useState(<AllAdvantage />);

	return (
		<div className="bo-subscription">
			<nav>
				<ul>
					<li
						className={menu === "all" ? "selected" : ""}
						onClick={(e) => {
							setActualComponent(<AllAdvantage/>);
							setMenu("all");
						}}
					>
						Affichage tous les advantage
					</li>
					<li className={menu === "add" ? "selected" : ""}
						onClick={(e) => {
							setActualComponent(<AddAdvantage successCreate={() => setActualComponent(<AllAdvantage/>)}/>);
							setMenu("add");
						}}
					>
						Ajouter un advantage
					</li>
				</ul>
			</nav>
			<div className="bo-subscription-component">{actualComponent}</div>
		</div>
	);
};

export default Advantage;

