import React, {memo, useEffect, useState} from "react";
import {isEmpty, isFunction} from "../Utils";
import Select from "react-select";

const SelectInput = ({handleClick, handleChange, name, defaultValue, optionValue, optionLabel, options, label, errorText, placeholder, border, backgroundColor, error: propError = false}) => {
    const [selected, setSelected] = useState(false);
    const [error, setError] = useState(false);
    const [localValue, setLocalValue] = useState();
    const onClick = (event) => {

    };
    useEffect(() => {
        setError(propError)
        if (localValue !== undefined) {
            if(isFunction(handleChange)) {
                handleChange(name, localValue[optionValue])
            }
        }
    }, [localValue, propError]);

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            width: "100%",
            cursor: "pointer",
            borderBottom: !isEmpty(border) && border === true ? "2px solid #ffffff" : "unset",
            height: "2.8rem",
        }),
        option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
            ...provided,
            color: isDisabled ? undefined : isSelected ? "#ffffff" : isFocused ? "black" : undefined,
            cursor: "pointer",
            backgroundColor: isDisabled ? undefined : isSelected ? "#49546c" : isFocused ? "white" : undefined,
            ":hover": {
                ...provided[":hover"],
                color: isSelected ? "#ffffff" : "black"
            },
        }),
        input: (provided, state) => ({
            ...provided,
            height: "1.8rem",
            margin: "0",
            color: "#ffffff",
            ":after": {
                ...provided[":after"],
                color: "#ffffff"
            },
        }),
        control: (provided, state) => ({
            ...provided,
            borderRadius: "unset",
            borderStyle: "none",
            borderWidth: "unset",
            minHeight: "unset",
            height: "inherit",
            backgroundColor: isEmpty(backgroundColor) || backgroundColor !== false ? "rgba(202, 211, 255, 0.0676517)" : "unset",
            color: "#ffffff",
            boxShadow: "none",
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: "0px 8px",
            overflow: "inherit"
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#ffffff"
        }),
        menu: (provided, state) => ({
            ...provided,
            color: "#ffffff",
            backgroundColor: "#1b283c",
            marginTop: 0,
            borderRadius: "0 0 4px 4px",
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            height: "inherit"
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none"
        })
    };

    return (
        <div className={`input ${selected ? "select" : ""} ${error ? "error" : ""}`}>
            {label && (
                <label>
                    {label}
                </label>
            )}
            <Select
                onClick={(e) => onClick(e)}
                key={defaultValue} //for force re-render to show default value
                value={defaultValue}
                styles={customStyles}
                getOptionValue={ x => x[optionValue]}
                getOptionLabel={ x => x[optionLabel]}
                onFocus={() => setSelected(true)}
                onBlur={() => setSelected(false)}
                onChange={(option) => setLocalValue(option)}
                options={options}
                placeholder={placeholder}
            />
            {error && (
                <div className="errorDisplay">
                    {errorText}
                </div>
            )}
        </div>
    );
};

export default SelectInput;