import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTranslations } from "../../actions/translations.action";
import { isEmpty } from "../Utils";

const LanguagesSelection = () => {
	//GET STORE
	const supportedLanguages = useSelector(
		(state) => state.langReducer.languagesReducer
	);
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);

	//GET TRANSLATION AND ACTUAL LANG
	const selectedLanguage = translationsReducer.language;
	const [selectedLanguageData, setSelectedLanguageData] = useState("null");
	const dispatch = useDispatch();

	useEffect(() => {
		if (!isEmpty(supportedLanguages.languages)) {
			supportedLanguages.languages.forEach((elm) => {
				if(!isEmpty(localStorage.getItem('fuchsLang'))) {
					if (elm.shortLang === localStorage.getItem('fuchsLang')) {
						setSelectedLanguageData(elm);
						dispatch(getTranslations(elm.shortLang));
					}
				} else if (elm.shortLang === selectedLanguage) {
					setSelectedLanguageData(elm);
				}
			});
		}
	}, [supportedLanguages, selectedLanguage]);

	const dropdownHover = (event) => {
		let menu = document.querySelector(".language-sub");

		let bckg = document.querySelector(".hide-background");

		if (event.type === "mouseenter") {
			bckg.classList.add("hover");
			menu.classList.add("hover");
		} else if (event.type === "mouseleave") {
			bckg.classList.remove("hover");
			menu.classList.remove("hover");
		} else {
			menu.classList.remove("hover");
			bckg.classList.remove("hover");
		}
	};
	return (
		<div
			className="languages"
			onMouseEnter={(e) => dropdownHover(e)}
			onMouseLeave={(e) => dropdownHover(e)}
			onClick={(e) => dropdownHover(e)}
		>
			<img
				src={selectedLanguageData.flag}
				alt={`flag ${selectedLanguageData.longLang}`}
				width="20"
				height="20"
			/>

			<ul className="language-sub">
				{!isEmpty(supportedLanguages) &&
					supportedLanguages.languages.map((lang) => {
						return (
							<li
								key={lang.shortLang}
								value={lang.shortLang}
								onClick={(e) => dispatch(getTranslations(lang.shortLang))}
							>
								<img
									src={lang.flag}
									alt={`flag ${lang.longLang}`}
									width="20"
									height="20"
								/>
								<span>{lang.longLang}</span>
							</li>
						);
					})}
			</ul>
		</div>
	);
};

export default LanguagesSelection;
