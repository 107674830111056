import axios from "axios";
import { isEmpty } from "../components/Utils";

export const GET_PLAYLIST = "GET_PLAYLIST";
export const CLEAR_PLAYLISTS = "CLEAR_PLAYLISTS";
export const GET_COMMAND_PLAYLIST = "GET_COMMAND_PLAYLIST";
export const CLEAR_COMMAND_PLAYLISTS = "CLEAR_COMMAND_PLAYLISTS";
export const GET_FAVOURITE_CLUBS_PLAYLIST = "GET_FAVOURITE_CLUBS_PLAYLIST";

export const getPlaylist = (id) => {
	return async (dispatch) => {
		await axios
			.get(`${process.env.REACT_APP_API_URL}getVideosFromPlaylist&id=${id}&n=5`)
			.then((res) => dispatch({type: GET_PLAYLIST, payload: res.data}))
			.catch((error) => {
				console.log(error)
			});
	};
};
export const getFavouriteClubsPlaylist = (leagueId = "", groupId = "", clubId = "") => {
	const Axios = axios.create();

	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginToken");
		//Adding JWT token to axios default header
		if (loginToken) {
			Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;
			if (process.env.REACT_APP_ENV === "production") {
				Axios.defaults.headers.common["Authorization"] = "Bearer " + loginToken;
			}
		}
		await Axios
			.get(`${process.env.REACT_APP_NOT_CACHED_API_URL}getFavouriteClubsPlaylist&n=20&lig=${leagueId}&gr=${groupId}&cl=${clubId}`)
			.then((res) => dispatch({type: GET_FAVOURITE_CLUBS_PLAYLIST, payload: res.data}))
			.catch((error) => {
				console.log(error)
			});
	};
};

export const CLEAR_FAVOURITE_CLUBS_PLAYLISTS = "CLEAR_FAVOURITE_CLUBS_PLAYLISTS";
export const clearFavouriteClubslaylists = () => {
	return (dispatch) => {
		return dispatch({ type: CLEAR_FAVOURITE_CLUBS_PLAYLISTS, payload: null });
	};
};

export const GET_PARENT_COMMAND_PLAYLIST = "GET_PARENT_COMMAND_PLAYLIST";
export const getParentCommandPlaylist = (id, lang = 'EN') => {
	return (dispatch) => {
		axios
			.get(`${process.env.REACT_APP_API_URL}getParentCommandPlaylist&id=${id}&l=${lang}&n=8`)
			.then((res) => dispatch({type: GET_PARENT_COMMAND_PLAYLIST, payload: res.data}))
			.catch((error) => {
				console.log(error)
			});
	};
};
export const CLEAR_PARENT_COMMAND_PLAYLISTS = "CLEAR_PARENT_COMMAND_PLAYLISTS";
export const clearParentCommandPlaylists = () => {
	return (dispatch) => {
		return dispatch({ type: CLEAR_PARENT_COMMAND_PLAYLISTS, payload: null });
	};
};

export const getCommandPlaylist = (id) => {
	return async (dispatch) => {
		await axios
			.get(`${process.env.REACT_APP_API_URL}getCommandPlaylistVideos&id=${id}&n=100`)
			.then((res) => dispatch({type: GET_COMMAND_PLAYLIST, payload: res.data}))
			.catch((error) => {
				console.log(error)
			});
	};
};

export const clearCommandPlaylists = () => {
	return (dispatch) => {
		return dispatch({ type: CLEAR_COMMAND_PLAYLISTS, payload: null });
	};
};

export const clearPlaylists = () => {
	return (dispatch) => {
		return dispatch({ type: CLEAR_PLAYLISTS, payload: null });
	};
};

export const GET_FUCHS_PLAYLISTS = "GET_FUCHS_PLAYLISTS";
export const getFuchsPlaylists = (type, includeVideos, lang = 'EN', country = '', limit = 8, page = 1) => {
	if (isEmpty(includeVideos)) {
		includeVideos = "no";
	}
	const Axios = axios.create();
	const loginToken = localStorage.getItem("loginToken");
	if (loginToken) {
		Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;
		if (process.env.REACT_APP_ENV === "production") {
			Axios.defaults.headers.common["Authorization"] = "Bearer " + loginToken;
		}
	}
	return async (dispatch) => {
		await Axios
			.get(`${process.env.REACT_APP_API_URL}getPlaylistsLimit&t=${type}&iv=${includeVideos}&l=${lang}&co=${country}&lim=${limit}&p=${page}`)
			.then((res) => dispatch({ type: GET_FUCHS_PLAYLISTS, payload: res.data }))
			.catch((error) => {
				console.log(error)
			});
	};
};

export const CHANGE_FUCHS_PLAYLISTS = "CHANGE_FUCHS_PLAYLISTS";
export const changeFuchsPlaylists = (playlists) => {
	return (dispatch) => {
		return dispatch({ type: CHANGE_FUCHS_PLAYLISTS, payload: playlists });
	};
};


export const GET_CLUB_PLAYLISTS = "GET_CLUB_PLAYLISTS";
export const getClubPlaylist = (id) => {
	return (dispatch) => {
		axios
			.get(`${process.env.REACT_APP_API_URL}getClubPlaylistVideos&id=${id}&n=150`)
			.then((res) => dispatch({type: GET_CLUB_PLAYLISTS, payload: res.data}))
			.catch((error) => {
				console.log(error)
			});
	};
};

export const CLEAR_CLUB_PLAYLISTS = "CLEAR_CLUB_PLAYLISTS";
export const clearClubPlaylists = () => {
	return (dispatch) => {
		return dispatch({ type: CLEAR_CLUB_PLAYLISTS, payload: null });
	};
};