import { combineReducers } from "redux";
import {
	GET_COUNTRY_LIST_USER,
	TOGGLE_PARAMETER,
	TOGGLE_NOTIFICATION,
	TOGGLE_CONTACT_FORM
} from "../actions/account.action";
const initialState = false;

function paramReducer(state = initialState, action) {
	switch (action.type) {
		case TOGGLE_PARAMETER:
			return action.payload;
		default:
			return state;
	}
}

function notificationReducer(state = initialState, action) {
	switch (action.type) {
		case TOGGLE_NOTIFICATION:
			return action.payload;
		default:
			return state;
	}
}

function contactFormReducer(state = initialState, action) {
	switch (action.type) {
		case TOGGLE_CONTACT_FORM:
			return action.payload;
		default:
			return state;
	}
}

function countryUserReducer(state = null, action) {
	switch (action.type) {
		case GET_COUNTRY_LIST_USER:
			return action.payload;
		default:
			return state;
	}
}

export default combineReducers({
	paramReducer, countryUserReducer, notificationReducer, contactFormReducer
});
