import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "../../actions/country.action";
import { isEmpty } from "../../components/Utils";
import { AdminContext } from "../../contexts/AdminContext";

const Banner = ({ selectedCountry }) => {
	const { countryName } = selectedCountry;
	const countries = useSelector((state) => state.countryReducer.countries);

	const dispatch = useDispatch();
	const [file, setFile] = useState(null);
	const [img, setImg] = useState(null);

	useEffect(() => {
		dispatch(getCountries("all"));
	}, [dispatch]);

	useEffect(() => {
		setImg(countries.filter((country) => country.countryName === countryName)[0].img);
	}, [countries, countryName]);

	const { updateCountryBanner } = useContext(AdminContext);

	const updateBanner = (file) => {
		let tpm = URL.createObjectURL(file);

		setFile(file);
		setImg(tpm);
	};
	const submitForm = async () => {
		document.querySelector(".bo-banner .loading svg").classList.add("display");

		const formData = new FormData();
		formData.append("name", countryName);
		formData.append("banner", file);

		const data = await updateCountryBanner(formData);
		dispatch(getCountries("all"));

		if (data.success) {
			if (document.querySelector(".bo-banner .loading svg")) {
				setTimeout(() => {
					document.querySelector(".bo-banner .loading span").innerHTML = "Mettre à jour la bannière";
					document.querySelector(".bo-banner .loading svg").classList.remove("display");
				}, 1000);
			}
			console.log(data);
		} else {
			if (document.querySelector(".bo-banner .loading svg")) {
				setTimeout(() => {
					document.querySelector(".bo-banner .loading span").innerHTML = "Error";
					document.querySelector(".bo-banner .loading svg").classList.remove("display");
				}, 1000);
			}
			console.log(data);
		}

	};
	return (
		<div className="bo-banner">
			<div className="sticky">
				<div className="submit">
					<button onClick={submitForm} className="loading">
						<span>Mettre à jour la bannière</span>
						<div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
							>
								<path d="M13.75 22c0 .966-.783 1.75-1.75 1.75s-1.75-.784-1.75-1.75.783-1.75 1.75-1.75 1.75.784 1.75 1.75zm-1.75-22c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm10 10.75c.689 0 1.249.561 1.249 1.25 0 .69-.56 1.25-1.249 1.25-.69 0-1.249-.559-1.249-1.25 0-.689.559-1.25 1.249-1.25zm-22 1.25c0 1.105.896 2 2 2s2-.895 2-2c0-1.104-.896-2-2-2s-2 .896-2 2zm19-8c.551 0 1 .449 1 1 0 .553-.449 1.002-1 1-.551 0-1-.447-1-.998 0-.553.449-1.002 1-1.002zm0 13.5c.828 0 1.5.672 1.5 1.5s-.672 1.501-1.502 1.5c-.826 0-1.498-.671-1.498-1.499 0-.829.672-1.501 1.5-1.501zm-14-14.5c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2zm0 14c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2z" />
							</svg>
						</div>
					</button>
				</div>
			</div>

			<div className="img">
				<div className="input">
					<input
						type="file"
						name="img"
						id="img"
						onChange={(e) => updateBanner(e.target.files[0])}
					/>
					<label htmlFor="img">Cliquez ici pour modifier la bannière</label>
					<div className="warnings">
						<p>Image (png ou jpg), pas de transparence.</p>
						<p>Taille minimum : 1440 x 900 px</p>
						<p>Poids Max : 5 Mo</p>
					</div>
					{!isEmpty(img) && (
						<img
							onError={(e) => {
								e.currentTarget.onerror = null;
								e.currentTarget.src = `${process.env.REACT_APP_CACHE_SERVER}/img/footages/banner-event.png`;
							}}
							src={img}
							width="220"
							height="110"
							alt=""
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default Banner;
