import { GET_DIVISIONS } from "../actions/division.action";

const initialState = [];

export default function divisionReducer(state = initialState, action) {
	switch (action.type) {
		case GET_DIVISIONS:
			return action.payload;
		default:
			return state;
	}
}
