import axios from "axios";
import React, {
	Fragment,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { getFuchsPlaylists } from "../../actions/playlist.action";
import { isEmpty } from "../../components/Utils";
import { AdminContext } from "../../contexts/AdminContext";

const Playlists = () => {
	const dispatch = useDispatch();
	//INIT
	const playlistsReducer = useSelector(
		(state) => state.playlistAllReducer.fuchsPlaylistsReducer
	);
	const [listPlaylist, setListPlaylist] = useState([]);
	const [list, setList] = useState([]);
	const result = useRef(false);
	const search = useRef(false);

	useEffect(() => {
		if (!isEmpty(playlistsReducer)) {
			setListPlaylist(playlistsReducer.playlists);
		}
	}, [playlistsReducer]);

	useEffect(() => {
		dispatch(getFuchsPlaylists("home_page", "no", "", "", 20));
	}, [dispatch]);

	const handleSearch = (input) => {
		let search = encodeURIComponent(input.toLowerCase());

		axios.get(`${process.env.REACT_APP_API_URL}getAllPlaylistsFuchs&i=${search}`)
			.then((res) => {
				let copyList = res.data.playlists;
				Object.keys(listPlaylist).map((elem) => {
					delete copyList[elem];
					return true;
				});

				Object.keys(copyList).length > 0
					? result.current.classList.add("use")
					: result.current.classList.remove("use");
				setList(copyList)
			});
	};

	//UPDATE LIST
	const addToPlaylists = (newPlaylist) => {

		let items = Object.values(listPlaylist);

		let exists = items.findIndex(playlist => playlist.playlistID === newPlaylist.playlistID);

		if (exists === -1) {
			newPlaylist.type_page = "home_page";
			items.push(newPlaylist);
			let playlistClone = items;

			playlistClone.sort((a, b) => {
				return b.sort_order - a.sort_order;
			});

			playlistClone = playlistClone.map((playlist, index, array) => {
				playlist.sort_order = array.length - index;
				return playlist;
			});

			let playlistObject = {};

			playlistClone.map((playlist) => {
				return (playlistObject[playlist.playlistID] = playlist);
			});

			setListPlaylist(playlistObject);
			result.current.classList.remove("use");
		}

	};

	const removeToPlaylists = (removeItem) => {
		let items = Object.values(listPlaylist);

		items = items.sort((a, b) => {
			return b.sort_order - a.sort_order;
		});

		let index = items.findIndex(
			(playlist) => playlist.playlistID === removeItem.playlistID
		);
		delete items[index];
		let playlistClone = items;

		playlistClone = playlistClone.map((playlist, index, array) => {
			playlist.sort_order = array.length - index;

			return playlist;
		});
		let playlistObject = {};
		playlistClone.map((playlist) => {
			return (playlistObject[playlist.playlistID] = playlist);
		});

		setListPlaylist(playlistObject);
	};
	//DRAG AND DROP
	const handleDrag = (result) => {
		const { destination } = result;

		if (!destination) {
			return;
		}

		let items = Object.values(listPlaylist);

		items = items.sort((a, b) => {
			return b.sort_order - a.sort_order;
		});
		const [reorderedItem] = items.splice(result.source.index, 1);

		items.splice(result.destination.index, 0, reorderedItem);

		let playlistClone = items;
		playlistClone = playlistClone.map((playlist, index, array) => {
			playlist.sort_order = array.length - index;

			return playlist;
		});
		let playlistObject = {};
		playlistClone.map((playlist) => {
			return (playlistObject[playlist.playlistID] = playlist);
		});

		setListPlaylist(playlistObject);
	};

	//SUBMIT
	const { setPlaylists } = useContext(AdminContext);
	const updatePlaylist = async () => {
		document.querySelector(".loading-playlist svg").classList.add("display");
		let newPlaylists = Object.values(listPlaylist);

		newPlaylists = newPlaylists.sort((a, b) => {
			return b.sort_order - a.sort_order;
		});

		let data = await setPlaylists(newPlaylists, "home_page");

		if (data.success) {
			if (document.querySelector(".loading-playlist svg")) {
				setTimeout(() => {
					document.querySelector(".submit span").innerHTML = "Mettre à jour les playlists";
					document.querySelector(".loading-playlist svg").classList.remove("display");
				}, 300)
			}

		} else {
			if (document.querySelector(".loading-playlist svg")) {
				setTimeout(() => {
					document.querySelector(".submit span").innerHTML = "Error";
					document.querySelector(".loading-playlist svg").classList.remove("display");
				}, 1000);
			}
		}
	};
	return (
		<div className="bo-playlists">
			<div className="sticky">
				<div className="submit">
					<button onClick={updatePlaylist}>
						<span>Mettre à jour les playlists</span>
						<div className="loading-playlist">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
							>
								<path d="M13.75 22c0 .966-.783 1.75-1.75 1.75s-1.75-.784-1.75-1.75.783-1.75 1.75-1.75 1.75.784 1.75 1.75zm-1.75-22c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm10 10.75c.689 0 1.249.561 1.249 1.25 0 .69-.56 1.25-1.249 1.25-.69 0-1.249-.559-1.249-1.25 0-.689.559-1.25 1.249-1.25zm-22 1.25c0 1.105.896 2 2 2s2-.895 2-2c0-1.104-.896-2-2-2s-2 .896-2 2zm19-8c.551 0 1 .449 1 1 0 .553-.449 1.002-1 1-.551 0-1-.447-1-.998 0-.553.449-1.002 1-1.002zm0 13.5c.828 0 1.5.672 1.5 1.5s-.672 1.501-1.502 1.5c-.826 0-1.498-.671-1.498-1.499 0-.829.672-1.501 1.5-1.501zm-14-14.5c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2zm0 14c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2z" />
							</svg>
						</div>
					</button>
				</div>
			</div>
			<div className="list">
				<div className="search">
					<label htmlFor="search">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
						>
							<path d="M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z" />
						</svg>
						<svg
							onClick={() => {
								search.current.value = "";
								result.current.classList.remove("use");
							}}
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							className="cross"
						>
							<path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" />
						</svg>
					</label>
					<input
						ref={search}
						type="search"
						autoComplete="off"
						name="search"
						id="search"
						placeholder="Chercher une playlist"
						onInput={(e) => handleSearch(e.target.value)}
					/>
					<div className="result" ref={result}>
						<ul>
							{Object.keys(list).length > 0 &&
							Object.keys(list).map(function(key) {
								const { playlistName, playlistID, clubName } = list[key];
								return (
									<Fragment key={playlistID}>
										<li onClick={() => addToPlaylists(list[key])}>
											<h2>{playlistName}</h2>
											<h3>{clubName}</h3>
										</li>
									</Fragment>
								);
							})}
						</ul>
					</div>
				</div>
				{Object.keys(listPlaylist).length > 0 && (
					<DragDropContext onDragEnd={handleDrag}>
						<Droppable droppableId="playlists" direction="horizontal">
							{(provided) => (
								<div
									className="playlists"
									{...provided.droppableProps}
									ref={provided.innerRef}
								>
									{!isEmpty(listPlaylist) &&
									Object.keys(listPlaylist)
										.sort((a, b) => {
											return (
												parseInt(listPlaylist[b].sort_order) -
												parseInt(listPlaylist[a].sort_order)
											);
										})
										.map((key, index) => {
											const { playlistName, playlistID, clubName, img } =
												listPlaylist[key];
											const item = listPlaylist[key];
											return (
												<Draggable key={playlistID} draggableId={playlistID} index={index}>
													{(provided) => (
														<div
															className="playlist"
															ref={provided.innerRef}
															data-number={index + 1}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
														>
															<svg
																onClick={() => removeToPlaylists(item)}
																className="cross"
																xmlns="http://www.w3.org/2000/svg"
																width="24"
																height="24"
																viewBox="0 0 24 24"
															>
																<path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" />
															</svg>
															<div className="title">
																<h2>{playlistName}</h2>
																<h3>{clubName}</h3>
															</div>
															<img src={img} alt="playlist" width="200" height="100" />
														</div>
													)}
												</Draggable>
											);
										})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				)}
			</div>
		</div>
	);
};

export default Playlists;
