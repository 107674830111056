import React, {useContext, useState, useEffect, Fragment} from "react";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import {isEmpty} from "../../Utils";
import {useDispatch, useSelector} from "react-redux";
import {MyContext} from "../../../contexts/MyContext";
import {getCustomer, getListPaymentsMethods, getCoupon} from "../../../actions/stripe.action";
import Select from "react-select";
import {Elements} from "@stripe/react-stripe-js";
import {toggleModal} from "../../../actions/modal.action";
import EditPayment from "./EditPayment";


const CheckoutForm = ({cancelOrder,resetValidationCoupon, onClose, prices, checkedProduct,validCoupon, couponId}) => {

    const dispatch = useDispatch();
    const {rootState, createSubscribe} = useContext(MyContext);
    const {isAuth, theUser} = rootState;
    const stripe = useStripe();
    const elements = useElements();
    const stripeReducer = useSelector((state) => state.stripeReducer);
    const payments = stripeReducer.getListPaymentsReducer;
    const customer = stripeReducer.getCustomerReducer;
    //const coupon = stripeReducer.getCouponReducer;
    const [typeForPay, setTypeForPay] = useState("newCard");
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [checkedPriceId, setCheckedPriceId] = useState(null);
    const [coupon, setCoupon] = useState(null);
    const [wait, setWait] = useState(false);

    useEffect(() => {
        dispatch(getCustomer());
    }, [dispatch]);

    useEffect(() => {
        if (customer) {
            dispatch(getListPaymentsMethods(customer.id));
        }
    }, [customer]);
    useEffect(() => {
        if (!isEmpty(prices[0].id)) {
            setCheckedPriceId(prices[0].id);
            /*if(!isEmpty(couponId)){
                dispatch(getCoupon(couponId));
            }*/
        }
    }, [prices, couponId]);

    useEffect(() => {
        if (payments && payments.length >= 0) {
            setTypeForPay("myCards");
        }
    }, [payments]);

    useEffect(() => {
        setPaymentMethod(null)
    }, [typeForPay]);

    const options = {
        style: {
            base: {
                iconColor: "#c4f0ff",
                color: "#fff",
                fontWeight: "500",
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "16px",
                fontSmoothing: "antialiased",
                ':-webkit-autofill': {
                    color: "#fce883",
                },
                '::placeholder': {
                    color: "rgba(255, 255, 255, 0.6)",
                },
            }
        }
    }

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            width: "100%",
            cursor: "pointer",
            borderRadius: "5px",
            border: "1px solid #8d8d8d",
        }),
        option: (provided, {data, isDisabled, isFocused, isSelected}) => ({
            ...provided,
            color: isDisabled ? undefined : isSelected ? "#ffffff" : isFocused ? "black" : undefined,
            cursor: "pointer",
            backgroundColor: isDisabled ? undefined : isSelected ? "#49546c" : isFocused ? "white" : undefined,
            ":hover": {
                ...provided[":hover"],
                color: isSelected ? "#ffffff" : "black"
            },
        }),
        control: (provided, state) => ({
            ...provided,
            borderStyle: "none",
            borderWidth: "unset",
            minHeight: "2.5rem",
            color: "#ffffff",
            boxShadow: "none",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#000000",
            fontSize: "1.6rem"
        }),
        menu: (provided, state) => ({
            ...provided,
            color: "#ffffff",
            backgroundColor: "#1b283c",
            marginTop: 0,
            borderRadius: "0 0 4px 4px",
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none"
        })
    };
    const payHandle = async (paymentMethod) => {

        if (!isEmpty(customer.id) && !isEmpty(checkedProduct) && !isEmpty(checkedPriceId)) {

            let paymentResult = await createSubscribe(customer.id, checkedPriceId, checkedProduct, paymentMethod, coupon);

            if (paymentResult.result) {
                if (paymentResult.result === "empty_payments_methods") {



                } else if (paymentResult.result !== "succeeded" && paymentResult.result !== "ok") {
                    dispatch(toggleModal(
                        {
                            opened: true,
                            success: false,
                            text: !isEmpty(paymentResult.error) ? paymentResult.error : "Subscribe error"
                        })
                    );

                } else {
                    dispatch(getCustomer());
                    dispatch(toggleModal(
                        {
                            opened: true,
                            success: true,
                            text: "Subscribe success"
                        })
                    );
                }
            } else {
                dispatch(toggleModal(
                    {
                        opened: true,
                        success: false,
                        text: "Subscribe error"
                    })
                );
            }
            setWait(false);
        } else {
            setWait(false)
            console.log("error")
        }

        onClose(true);
    }

    const handlePayClick = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }
        setWait(true);
        if (isAuth) {
            if (isEmpty(paymentMethod) && typeForPay === "newCard") {
                const result = await stripe.createPaymentMethod({
                    type: "card",
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        email: theUser.email
                    }
                });

                if (result.error) {
                    dispatch(toggleModal({opened: true, success: false, text: result.error.message}));
                    setWait(false);
                } else {
                    setPaymentMethod(result.paymentMethod.id);
                    await payHandle(result.paymentMethod.id)
                }
            } else {
                await payHandle(paymentMethod)
            }
        }

    };

    const handleCancel = () => {
        cancelOrder(true);
        resetValidationCoupon(true);
    };


    return (
        <Fragment>

            <div className="background position-fixed top-left bottom-right"
                 style={{backgroundColor: "rgb(0 0 0 / 51%)"}}/>
            <div className="absolute-center ppv">
                {wait && (
                    <div className="success-animation">
                        <div className="loader">
                            <div className={wait === "succeeded" ? "circle-loader load-complete" : "circle-loader"}>
                                <div className="checkmark draw"/>
                            </div>
                        </div>
                    </div>
                )}
                <h3 className="d-flex justify-content-center p-4">Select price and method</h3>
                <div className="mx-4">
                    <Select
                        styles={customStyles}
                        defaultValue={prices[0]}
                        options={prices}
                        onChange={(option) => setCheckedPriceId(option.value)}
                    />
                </div>
                {validCoupon === true && (
                    <div className="voucher">
                        <label>Voucher Code</label>
                        <div className="input my-2">
                            <input
                                style={{background: "#FFFFFF", color: "#000000", border: "1px solid", borderColor: "#98A2B3", padding: "0.4rem 1rem 0.4rem"}}
                                type="text"
                                id="voucher"
                                required
                                name="voucher"
                                onChange={(e) => setCoupon(e.target.value)}
                            />
                        </div>
                    </div>
                )}
                <div className="content-block ml-4 mb-1">
                    <div className="buttons mt-2 mb-1">
                        <button className={`btn btn-stripe ${typeForPay === "newCard" ? "selected" : ""}`}
                                onClick={() => setTypeForPay("newCard")}>New Card
                        </button>

                        {payments && payments.length > 0 && (
                            <button className={`btn btn-stripe ${typeForPay === "myCards" ? "selected" : ""}`}
                                    onClick={() => setTypeForPay("myCards")}>My Cards</button>
                        )}
                    </div>
                    {typeForPay === "myCards" && (
                        <div>
                            {payments.map((elem) => {
                                return (
                                    <div
                                        className={`cursor-pointer method ${paymentMethod === elem.id ? "selected" : ""}`}
                                        key={elem.id} onClick={() => setPaymentMethod((elem.id))}>
                                        <div className="card d-flex my-1">
                                            <div className="image ml-1">
                                                <img src={`/img/icons/cards/${elem.card.brand}.svg`} width="70"
                                                     height="50" alt=""/>
                                            </div>
                                            <div className="my-auto mx-2 d-grid">
                                                <span className="number">{elem.card.brand} *** {elem.card.last4}</span>
                                                <span
                                                    className="expire mt-2">Expire le {elem.card.exp_month < 10 ? `0${elem.card.exp_month}` : elem.card.exp_month}/{elem.card.exp_year}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}

                    {typeForPay === "newCard" && payments && (
                        <div className="mr-4">
                            <CardNumberElement options={{...options, iconStyle: "solid", showIcon: true}}/>
                            <CardExpiryElement options={options}/>
                            <CardCvcElement options={options}/>
                        </div>
                    )}
                </div>
                <button
                    className="btn-stripe mt-1 ml-4 ml-4 mb-1"
                    disabled={isEmpty(paymentMethod) && typeForPay !== "newCard"}
                    onClick={handlePayClick}
                >Pay</button>
                <button
                    className="btn-stripe mt-1 ml-4 mb-1"
                    style={{background: "#FFFFFF", color: "#000000", border: "1px solid", borderColor: "#98A2B3"}}
                    onClick={() => handleCancel()}
                >Cancel</button>
            </div>
        </Fragment>
    );

};

export default CheckoutForm;