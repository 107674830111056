import React, {useContext, useState} from "react";
import {useDispatch} from "react-redux";
import Summary from "../EditAccountSubmenu/Summary";
import Plans from "../EditAccountSubmenu/Plans";
import Invoice from "../EditAccountSubmenu/Invoice";
import Payment from "../EditAccountSubmenu/Payment";
import {isEmpty} from "../../../components/Utils";
import {toggleModal} from "../../../actions/modal.action";
import {AdminContext} from "../../../contexts/AdminContext";
import {getCustomer} from "../../../actions/stripeAdmin.action";


const EditAccount = ({user}) => {
    const {id, email, stripe_id} = user;
    const { createStripeCustomerDefault } = useContext(AdminContext);
    const dispatch = useDispatch();
    const [actualSubMenuComponent, setActualSubMenuComponent] = useState(<Summary stripe_id={stripe_id} user={user}/>);
    const [activeComponent, setActiveComponent] = useState("summary");

    const createDefaultCustomer = async () => {
        await createStripeCustomerDefault(id).then((result) => {
            if (!isEmpty(result.id)) {
                dispatch(toggleModal({opened: true, success: true, text: "Customer data updated"}));
                setActualSubMenuComponent(<Summary stripe_id={result.id}/>);
                dispatch(getCustomer(result.id));
                setActiveComponent("summary");
            } else {
                dispatch(toggleModal({opened: true, success: false, text: "The customer has not been created"}));
            }
        }).catch((error) => {
            console.log(error)
        })
    };

    return (
        <div className="bo-edit_account">
            {stripe_id !== null && !isEmpty(stripe_id) ? (
                <section className="edit_block">
                    <nav>
                        <ul>
                            <li
                                className={`${activeComponent === "summary" ? "selected" : ""}`}
                                onClick={(e) => {
                                    setActualSubMenuComponent(<Summary stripe_id={stripe_id} user={user}/>);
                                    setActiveComponent("summary");
                                }}
                            >
                                Summary
                            </li>
                            <li
                                className={`${activeComponent === "payment" ? "selected" : ""}`}
                                onClick={(e) => {
                                    setActualSubMenuComponent(<Payment email={email} stripe_id={stripe_id}/>);
                                    setActiveComponent("payment");
                                }}
                            >
                                Payment
                            </li>
                            <li
                                className={`${activeComponent === "invoice" ? "selected" : ""}`}
                                onClick={(e) => {
                                    setActualSubMenuComponent(<Invoice stripe_id={stripe_id}/>);
                                    setActiveComponent("invoice");
                                }}
                            >
                                Invoice
                            </li>
                            <li
                                className={`${activeComponent === "plans" ? "selected" : ""}`}
                                onClick={(e) => {
                                    setActualSubMenuComponent(<Plans stripe_id={stripe_id}/>);
                                    setActiveComponent("plans");
                                }}
                            >
                                Plans
                            </li>
                        </ul>
                    </nav>
                    <div className="bo-edit_account-component">
                        {actualSubMenuComponent}
                    </div>
                </section>
            ) : (
                <div className="add_stripe">
                    <strong>The customer not exist in stripe service</strong>
                    <button onClick={() => createDefaultCustomer()}>Create A Stripe client for this user</button>
                </div>
            )}
        </div>
    );
};

export default EditAccount;
