import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { MyContext } from "../../../contexts/MyContext";

import parse from "html-react-parser";
import {isEmpty, removeTags} from "../../Utils";

const UploadId = ({typeAccount}) => {
    const { rootState, updateIdCard, isLoggedIn } = useContext(MyContext);
    const { theUser } = rootState;
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translations
    );
    const [upload, setUpload] = useState(false);

    const uploadIdHandler = async (file) => {
        const formData = new FormData();
        formData.append("identity_photo", file);
        formData.append("type_account", typeAccount);
        setUpload(true);
        const data = await updateIdCard(formData);

        if (data.success) {
            isLoggedIn();
            if (document.querySelector("#id_card #validUpdate")) {
                setTimeout(() => {
                    document.querySelector("#id_card #validUpdate").innerHTML = translations
                    && !isEmpty(translations["avatarGood"])
                        ? removeTags(translations["avatarGood"])
                        : "avatarGood";
                    document
                        .querySelector("#id_card #validUpdate")
                        .classList.add("great", "display");
                    setUpload(false);
                }, 10);

                setTimeout(() => {
                    document
                        .querySelector("#id_card #validUpdate")
                        .classList.remove("great", "display");
                }, 3000);
            }
        } else {
            setTimeout(() => {
                document.querySelector("#id_card #validUpdate").innerHTML = translations && !isEmpty(translations["errorOccur"])
                    ? removeTags(translations["errorOccur"])
                    : "errorOccur";
                document
                    .querySelector("#id_card #validUpdate")
                    .classList.add("error", "display");
                setUpload(false);
            }, 10);

            setTimeout(() => {
                document
                    .querySelector("#id_card #validUpdate")
                    .classList.remove("error", "display");
            }, 3000);
            console.log(data.message);
        }
    };

    return (
        <div id="id_card">
            <img src={upload ? `${process.env.REACT_APP_CACHE_SERVER}/img/footages/preloader.gif` : theUser.identity_photo} alt="account" width="100" />
            <div className="data">
                <h4>
                    {translations && !isEmpty(translations["idCard"])
                        ? removeTags(translations["idCard"])
                        : "ID photo"
                    }
                </h4>
                {translations && !isEmpty(translations["avatarParams"])
                    ? parse(translations["avatarParams"])
                    : "avatarParams"
                }
                <form>
                    <input
                        type="file"
                        name="idUpload"
                        id="idUpload"
                        onChange={(e) => uploadIdHandler(e.target.files[0])}
                    />
                    <label htmlFor="idUpload">
                        {translations && !isEmpty(translations["idCardUpdate"])
                            ? parse(translations["idCardUpdate"])
                            : "Update ID photo"
                        }
                    </label>
                    <div id="validUpdate" className="great"/>
                </form>
            </div>
        </div>
    );
};

export default UploadId;
