import React, { useContext, useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { MyContext } from "../../contexts/MyContext";
import {addResolution, isEmpty, removeTags} from "../Utils";

import parse from "html-react-parser";
import {getAllSports} from "../../actions/sports.action";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import {getFavorite} from "../../actions/favorite.action";

const Preferences = () => {
	const dispatch = useDispatch();
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);
	const sports = useSelector((state) => state.sportsReducer.sportsAllTypesReducer);
	const favorites = useSelector((state) => state.favoritesReducer.favoriteReducer);
	const [state, setState] = useState(true);
	const [sportCheck, setSportCheck] = useState([]);
	const [selectedFavoriteOptions, setSelectedFavoriteOptions] = useState([]);
	const [optionFavoriteList, setOptionFavoriteList] = useState([]);
	const [successUpdate, setSuccessUpdate] = useState(null);

	const handleSport = (sport) => {
		if (sportCheck.includes(sport)) {
			setSportCheck(sportCheck.filter((item) => item !== sport));
		} else {
			setSportCheck((arr) => [...arr, sport]);
		}
	};

	const submitForm = async (event) => {
		event.preventDefault();
		const data = await updateUserPreference(sportCheck, selectedFavoriteOptions);
		if (data.success) {
			setSuccessUpdate(true);
			isLoggedIn();
			setTimeout(() => {
				setSuccessUpdate(null)
			}, 1000);
		} else {
			setSuccessUpdate(false);
			isLoggedIn();
			setTimeout(() => {
				setSuccessUpdate(null)
			}, 1000);
		}
	};

	//USER DATA
	const { rootState, updateUserPreference, isLoggedIn } = useContext(MyContext);
	const { theUser } = rootState;

	useEffect(() => {
		dispatch(getAllSports());
		dispatch(getFavorite());
	}, [dispatch]);

	useEffect(() => {
		if (state) {
			if (!isEmpty(theUser)) {
				if (theUser.id_sport !== null) {
					if (Array.isArray(theUser.id_sport)) {
						theUser.id_sport.forEach((sport) => {
							handleSport(sport);
						});
					} else {
						handleSport(theUser.id_sport);
					}
				}
			}
			setState(false);
		}
		// eslint-disable-next-line
	}, [theUser, state]);

	useEffect(() => {
		if (favorites) {
			let newFavorite = [];
			favorites.filter((item) => {
				return newFavorite.hasOwnProperty(item.club_id) ? false : (newFavorite[item.club_id] = {
					id: item.club_id,
					name: item.club_name
				});
			});
			setSelectedFavoriteOptions(newFavorite.filter(function(){return true;}));
		}
	}, [favorites]);

	const handleFavoriteSelect = async (data) => {
		if (data.length >= 2) {
			axios
				.get(`${process.env.REACT_APP_API_URL}getWebTVS&lkn=${encodeURI(data)}`)
				.then((res) => {
					if (res.data.webTVS.length > 0) {
						let webTvs = res.data.webTVS.filter(
							(club) => !selectedFavoriteOptions.map( el => el.id ).includes(club.id)
						);
						setOptionFavoriteList(webTvs);
					}
				});
		}
	};

	const onSelectFavorite = (club) => {
		setSelectedFavoriteOptions(club.map((elem) => {
			return {id : elem.id, name: elem.name}
		}));
	};

	const onRemoveFavorite = (club) => {
		setSelectedFavoriteOptions(club.map((elem) => {
			return {id : elem.id, name: elem.name}
		}));
	};

	return (
		<section className="preferences">
			<h3>
				{translations && !isEmpty(translations["registerPreferences"])
					? parse(translations["registerPreferences"])
					: "registerPreferences"}
			</h3>

			<div className="input">
				{translations && !isEmpty(translations["registerPreferencesTitle"])
					? parse(translations["registerPreferencesTitle"])
					: "registerPreferencesTitle"}
				<div className="checkbox">
					{!isEmpty(sports) &&
					sports.map((sport) => {
						if(sport.visible === "yes") {
							let imgs = addResolution(["500", "250"], sport.iconPatch.replace(".svg", ".png"));
							return (
								<div key={sport.id}>
									<input
										type="checkbox"
										value={sport.name}
										id={sport.name}
										name={sport.name}
										data-id-sport={sport.id}
										onClick={(e) => handleSport(e.target.dataset.idSport)}
										checked={sportCheck.includes(sport.id)}
										onChange={()=>{}}
									/>
									<label className="radio" htmlFor={sport.name}>
									<img
                                    	src={sport.iconPatch ? sport.iconPatch : `${process.env.REACT_APP_CACHE_SERVER}/img/icons/default.svg`}
                                       	alt={sport.name}
                                       	width="40"
                                        height="40"
                                   		/>
										{!isEmpty(translations) && !isEmpty(translations[sport.name])
											? parse(translations[sport.name])
											: sport.name}
									</label>
								</div>
							);
						}
						return true;
					})}
				</div>
				<div className="dropdown-container">
					<label>
						{translations && !isEmpty(translations["selectFavoriteAndFollowClubs"])
							? parse(translations["selectFavoriteAndFollowClubs"])
							: "selectFavoriteAndFollowClubs"}
					</label>
					<Multiselect
						options={optionFavoriteList}
						selectedValues={selectedFavoriteOptions}
						onSelect={onSelectFavorite}
						onRemove={onRemoveFavorite}
						onSearch={handleFavoriteSelect}
						displayValue="name"
						hidePlaceholder={true}
						closeOnSelect={true}
					/>
				</div>
			</div>
			<div className="submit">
				<input
					type="submit"
					className={successUpdate ? "success" : (successUpdate !== null ? "error" : "") }
					value={
						translations && !isEmpty(translations["update"])
							? removeTags(translations["update"])
							: "update"
					}
					onClick={submitForm}
				/>
			</div>
		</section>
	);
};

export default Preferences;
