import { combineReducers } from "redux";
import {
	GET_CUSTOMER,
	GET_LIST_PAYMENTS,
	GET_PRODUCT,
	GET_INVOICES,
	GET_PRODUCTS,
	GET_ACTIVE_CUSTOMER_PRODUCT,
	GET_ORDERED_PRODUCTS,
	GET_COUPONS,
	GET_COUPON
} from "../actions/stripe.action";
const initialState = [];

function getCustomerReducer(state = initialState, action) {
	switch (action.type) {
		case GET_CUSTOMER:
			return action.payload;
		default:
			return state;
	}
}
function getListPaymentsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_LIST_PAYMENTS:
			return action.payload;
		default:
			return state;
	}
}

function getCouponReducer(state = initialState, action) {
	switch (action.type) {
		case GET_COUPON:
			return action.payload;
		default:
			return state;
	}
}

function getCouponsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_COUPONS:
			return action.payload;
		default:
			return state;
	}
}

function getActiveCustomerProductReducer(state = initialState, action) {
	switch (action.type) {
		case GET_ACTIVE_CUSTOMER_PRODUCT:
			return action.payload;
		default:
			return state;
	}
}

function getProductReducer(state = initialState, action) {
	switch (action.type) {
		case GET_PRODUCT:
			return action.payload;
		default:
			return state;
	}
}

function getOrderedProductsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_ORDERED_PRODUCTS:
			return action.payload;
		default:
			return state;
	}
}
function getProductsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_PRODUCTS:
			return action.payload;
		default:
			return state;
	}
}

function getInvoicesReducer(state = initialState, action) {
	switch (action.type) {
		case GET_INVOICES:
			return action.payload;
		default:
			return state;
	}
}


export default combineReducers({
	getCustomerReducer,
	getListPaymentsReducer,
	getProductReducer,
	getInvoicesReducer,
	getProductsReducer,
	getActiveCustomerProductReducer,
	getOrderedProductsReducer,
	getCouponsReducer,
	getCouponReducer
});
