import { GET_STATUS } from "../actions/status.action";

const initialState = null;

export default function statusReducer(state = initialState, action) {
	switch (action.type) {
		case GET_STATUS:
			return action.payload;
		default:
			return state;
	}
}
