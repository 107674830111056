import { combineReducers } from "redux";
import {
	GET_SUPPORTED_LANGUAGES,
	GET_TRANSLATIONS,
	GET_ALL_TRANSLATIONS
} from "../actions/translations.action";

const initialState = [];

function translationsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_TRANSLATIONS:
			return action.payload;
		default:
			return state;
	}
}

function translationsAllReducer(state = initialState, action) {
	switch (action.type) {
		case GET_ALL_TRANSLATIONS:
			return action.payload;
		default:
			return state;
	}
}

function languagesReducer(state = initialState, action) {
	switch (action.type) {
		case GET_SUPPORTED_LANGUAGES:
			return action.payload;
		default:
			return state;
	}
}

export default combineReducers({ translationsReducer, translationsAllReducer, languagesReducer });
