import axios from "axios";
import {isEmpty} from "../components/Utils";

export const GET_TRANSLATIONS = "GET_TRANSLATIONS";

export const getTranslations = (input) => {

	if(!isEmpty(input)){
		localStorage.setItem('fuchsLang', input);
	}
	return async (dispatch) => {
		try {
			if (typeof input === "undefined" || input === null) {
				input = navigator.language.split("-")[0];
			}
			let res = await axios.get(
				`${process.env.REACT_APP_API_URL}getTranslations&l=${input}`
			);

			dispatch({ type: GET_TRANSLATIONS, payload: res.data });
		} catch (err) {
			return console.log(err);
		}
	};
};

export const GET_ALL_TRANSLATIONS = "GET_ALL_TRANSLATIONS";
export const getAllTranslations = () => {
	return async (dispatch) => {
		try {
			let res = await axios.get(
				`${process.env.REACT_APP_API_URL}getTranslations&v=all`
			);
			dispatch({ type: GET_ALL_TRANSLATIONS, payload: res.data.translations });
		} catch (err) {
			return console.log(err);
		}
	};
};

export const GET_SUPPORTED_LANGUAGES = "GET_SUPPORTED_LANGUAGES";

export const getSupportedLanguages = () => {
	return async (dispatch) => {
		await axios.get(`${process.env.REACT_APP_API_URL}getSupportedLanguages`)
			.then((res) => dispatch({ type: GET_SUPPORTED_LANGUAGES, payload: res.data }))
			.catch((error) => {
				console.log(error)
			});
	};
};
