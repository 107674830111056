import axios from "axios";

export const GET_SLIDESHOW = "GET_SLIDESHOW";

export const getSlideshow = (v = '', cached = "no") => {
	return async (dispatch) => {
		let url = process.env.REACT_APP_API_URL;
		if (cached === "no") {
			url = process.env.REACT_APP_NOT_CACHED_API_URL
		}
		await axios.get(`${url}getSlideShow&v=${v}`)
			.then((res) => dispatch({ type: GET_SLIDESHOW, payload: res.data }))
			.catch((error) => {
				console.log(error)
			});
	};
};

export const REORDER_SLIDESHOW = "REORDER_SLIDESHOW";
export const reorderSlideshow = (slideshow) => {
	return (dispatch) => {
		dispatch({ type: REORDER_SLIDESHOW, payload: slideshow });
	};
};
