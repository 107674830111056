import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../../../components/Utils";
import HighlightEditor from "./HighlightEditor";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { getHighlights } from "../../../actions/highlights.action";

const EditHighlight = () => {
	const dispatch = useDispatch();
	let highlights = useSelector(
		(state) => state.highlightReducer.highlight
	);
	const [selectedHighlight, setSelectedHighlight] = useState(null);

	useEffect(() => {
		dispatch(getHighlights());
	}, [dispatch]);

	return (
		<div className="bo-edit-slide">
			<SimpleBar autoHide={false} className="slides-container hide-scroll">
				{!isEmpty(highlights) &&
					highlights.map((slide, index) => {
						return (
							<div
								className={index === 0 ? "slide selected" : "slide"}
								key={slide.id}
								onClick={(e) => {
									document
										.querySelectorAll(".bo-edit-slide .slides-container .slide")
										.forEach((elm) =>
											elm !== e.currentTarget ? elm.classList.remove("selected") : false
										);
									e.currentTarget.classList.add("selected");
									setSelectedHighlight(slide);
								}}
								onLoad={() => index === 0 && setSelectedHighlight(slide)}
							>
								<img
									src={slide.img}
									width="220"
									height="auto"
									alt=""
								/>
							</div>
						);
					})}
			</SimpleBar>

			{!isEmpty(selectedHighlight) && (
				<HighlightEditor highlight={selectedHighlight} isNew={false} />
			)}
		</div>
	);
};

export default EditHighlight;
