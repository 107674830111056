import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { AdminContext } from "../../contexts/AdminContext";

const Login = () => {
	const activeInput = (id) => {
		let inputs = document.querySelectorAll(".account-page .input");
		inputs.forEach((input) => {
			input.classList.remove("select");
		});

		let input = document.getElementById(id);
		input.parentElement.classList.add("select");
	};

	const { loginAdmin, isLoggedInAdmin } = useContext(AdminContext);

	const initialState = {
		userInfo: {
			email: "",
			password: "",
		},
		errorMsg: "",
		successMsg: "",
	};
	const [state, setState] = useState(initialState);
	const onChangeValue = (e) => {
		setErrorEmailLogin(false);
		setErrorPasswordLogin(false);
		document.querySelector("#email").parentElement.classList.remove("error");
		document.querySelector("#password").parentElement.classList.remove("error");
		setState({
			...state,
			userInfo: {
				...state.userInfo,
				[e.target.name]: e.target.value,
			},
		});
	};
	const [errorEmailLogin, setErrorEmailLogin] = useState(false);
	const [errorPasswordLogin, setErrorPasswordLogin] = useState(false);

	const submitForm = async (event) => {
		event.preventDefault();

		const data = await loginAdmin(state.userInfo);
		if (data.success && data.token) {
			setState({
				...initialState,
			});
			localStorage.setItem("loginAdminToken", data.token);
			await isLoggedInAdmin();
		} else {
			setErrorPasswordLogin(false);
			setErrorEmailLogin(false);
			setState({
				...state,
				successMsg: "",
				errorMsg: data.message,
			});
			if (data.message === "emailErrorLogin") {
				setErrorEmailLogin(true);

				document.querySelector("#email").parentElement.classList.add("error");
			}
			if (data.message === "passwordErrorLogin") {
				setErrorPasswordLogin(true);
				document.querySelector("#password").parentElement.classList.add("error");
			}
		}
	};
	return (
		<section className="login">
			<NavLink to="/" className="return">
				<img
					src={`${process.env.REACT_APP_CACHE_SERVER}/img/icons/logo-fuchs-header.png`}
					width="50"
					height="50"
					alt="Logo du site"
				/>
			</NavLink>
			<h2>Se connecter au panel d'administration</h2>
			<form onSubmit={submitForm} noValidate>
				<div className="input select">
					<label>Email/Username *</label>
					<input
						type="email"
						name="email"
						id="email"
						placeholder="Votre mail"
						value={state.userInfo.email}
						required
						autoFocus
						autoComplete="email"
						onClick={(e) => activeInput(e.target.id)}
						onChange={onChangeValue}
					/>
					{errorEmailLogin && (
						<div className="errorDisplay">
							<p>Adresse mail/Username Inconnu !</p>
						</div>
					)}
				</div>
				<div className="input">
					<label>Password *</label>
					<input
						type="password"
						name="password"
						id="password"
						placeholder="Votre mot de passe"
						onClick={(e) => activeInput(e.target.id)}
						required
						autoComplete="current-password"
						value={state.userInfo.password}
						onChange={onChangeValue}
					/>
					{errorPasswordLogin && (
						<div className="errorDisplay">
							<p>Password Error !</p>
						</div>
					)}
				</div>
				<input type="submit" name="submit" id="submit" value="Se connecter" />
			</form>
		</section>
	);
};

export default Login;
