import React, {Fragment, useContext, useEffect, useState} from "react";
import { isEmpty } from "../../../components/Utils";
import {useDispatch, useSelector} from "react-redux";
import Checkbox from "react-custom-checkbox";
import {AdminContext} from "../../../contexts/AdminContext";
import Select from "react-select";
import {toggleModal} from "../../../actions/modal.action";
import {getCustomer, getProducts} from "../../../actions/stripeAdmin.action";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import AddPayment from "./AddPayment";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC}`);

const Plans = ({stripe_id}) => {
    const { createSubscribe } = useContext(AdminContext);
    const dispatch = useDispatch();
    const stripeReducer = useSelector((state) => state.stripeAdminReducer);
    const customer = stripeReducer.getCustomerReducer;
    const products = stripeReducer.getProductsReducer;
    const [activePlan, setActivePlan] = useState(null);
    const [checkedPlanId, setCheckedPlanId] = useState(null);
    const [checkedPriceId, setCheckedPriceId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [prices, setPrices] = useState([]);
    const [wait, setWait] = useState(false);
    const [openAddPayment, setOpenAddPayment] = useState(false);

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            width: "100%",
            cursor: "pointer",
            borderRadius: "5px",
            border: "1px solid #8d8d8d",
        }),
        option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
            ...provided,
            color: isDisabled ? undefined : isSelected ? "#ffffff" : isFocused ? "black" : undefined,
            cursor: "pointer",
            backgroundColor: isDisabled ? undefined : isSelected ? "#49546c" : isFocused ? "white" : undefined,
            ":hover": {
                ...provided[":hover"],
                color: isSelected ? "#ffffff" : "black"
            },
        }),
        control: (provided, state) => ({
            ...provided,
            borderStyle: "none",
            borderWidth: "unset",
            minHeight: "2.5rem",
            color: "#ffffff",
            boxShadow: "none",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#000000",
            fontSize: "1.6rem"
        }),
        menu: (provided, state) => ({
            ...provided,
            color: "#ffffff",
            backgroundColor: "#1b283c",
            marginTop: 0,
            borderRadius: "0 0 4px 4px",
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none"
        })
    };

    useEffect(() => {
        dispatch(getCustomer(stripe_id));
    }, [stripe_id]);

    useEffect(() => {
        if (customer.id) {
            dispatch(getProducts(1));
        }
    }, [customer]);

    useEffect(()  => {
        setActivePlan(customer?.subscriptions?.data[0])
    }, [customer]);

    useEffect(() => {
        if (openModal && products) {
            products.forEach((product) => {
                if (product.id === checkedPlanId) {
                    const newArrayOfObj = product.prices.map((item) => {
                        return {value: item.id, label: (item.unit_amount / 100).toLocaleString('eu-EU', {style: 'currency',currency: 'EUR'}) + "/" + (!isEmpty(item?.recurring?.interval) ? item.recurring.interval : "one time"), ...item };
                    });
                    setPrices(newArrayOfObj);
                }
            })
        }
    }, [checkedPlanId, openModal, products]);

    const onChangePlan = async () => {
        if (!isEmpty(customer.id) && !isEmpty(checkedPriceId)) {
            setWait(true);
            let paymentResult = await createSubscribe(customer.id, checkedPriceId);
            setOpenModal(false);

            if (paymentResult.result) {
                if (paymentResult.result === "empty_payments_methods") {

                    setOpenAddPayment(true);

                } else if (paymentResult.result !== "succeeded") {
                    console.log(paymentResult.result);
                } else {
                    dispatch(getCustomer());
                    dispatch(getProducts());
                    dispatch(toggleModal(
                        {
                            opened: true,
                            success: true,
                            text: "Subscribe success"
                        })
                    )
                }
            } else {
                dispatch(toggleModal(
                    {
                        opened: true,
                        success: false,
                        text: "Subscribe error"
                    })
                )
            }
            setWait("succeeded")
        } else {
            console.log("error")
        }

    }

    return (
        <Fragment>

        {openAddPayment && (
            <Elements stripe={stripePromise}>
                <AddPayment
                    onClose={() => setOpenAddPayment(!openAddPayment)}
                    onSuccess={onChangePlan}
                    setAsDefault={true}
                    stripe_id={customer.id}
                />
            </Elements>
        )}

        {openModal && (
            <Fragment>
                <div className="background position-fixed top-left bottom-right" style={{backgroundColor: "rgb(0 0 0 / 51%)"}}/>

                <div className="absolute-center modal">
                    {wait && (
                        <div className="success-animation">
                            <div className="loader">
                                <div className={wait === "succeeded" ? "circle-loader load-complete" : "circle-loader"}>
                                    <div className="checkmark draw"/>
                                </div>
                            </div>
                        </div>
                    )}
                    <h3 className="add-payment p-4">Choose a subscription type</h3>
                    <div className="mx-4">
                        <Select
                            styles={customStyles}
                            options={prices}
                            onChange={(option) => setCheckedPriceId(option.value)}
                        />
                    </div>

                    <div className="d-flex float-right mt-4 ml-4 mb-2">
                        <button className="btn btn-stripe mr-3"
                                onClick={() => setOpenModal(!openModal)}
                        >Cancel</button>

                        <button className="btn btn-stripe"
                                onClick={() => onChangePlan()}
                        >Update</button>
                    </div>
                </div>


            </Fragment>
        )}

        <div className="d-grid">
            {activePlan ? (
                <div>
                    <h3>Votre abonnement</h3>
                    <div className="element active-plan p-3">
                        <div className="d-flex">
                            <span className="badge" style={activePlan.status !== "active" ? {background: "red"} : {}}>
                                {activePlan.status !== "active" ? (
                                    activePlan.status
                                ) : "Plan Actuel"}
                            </span>
                            <h2 className="my-auto mx-2">{!isEmpty(activePlan?.plan?.metadata?.title) ? activePlan.plan.metadata.title : ""}</h2>
                        </div>
                        <div>
                            <span className="price my-auto">{(activePlan.plan.amount / 100).toLocaleString('eu-EU', {style: 'currency',currency: 'EUR'})} / {activePlan.plan.interval}</span>
                        </div>
                        <div>
                            <p className="my-auto">{!isEmpty(activePlan?.plan?.metadata?.description) ? activePlan.plan.metadata.description : ""}</p>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <h3>Votre abonnement</h3>
                    <div className="element active-plan p-3">
                        You don't have an active subscribe.
                    </div>
                </div>
            )}

            <div>
                <h3>Take it to the next level</h3>
                {products && products.map((product) => {
                    if (isEmpty(activePlan) || (!isEmpty(activePlan) && product.id !== activePlan.plan.product)) {
                        return (
                            <div className="element plans" key={product.id} id={product.id}>

                                <div className="d-flex">
                                    <div className="checkbox">
                                        <Checkbox
                                            checked={checkedPlanId === product.id}
                                            onChange={() => setCheckedPlanId((prev) => prev === product.id ? null : product.id)}
                                            borderColor="#4f49a1"
                                            borderWidth="1px"
                                            containerClassName="d-block"
                                            icon={
                                                <svg fill="#4caf50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>
                                            }
                                        />
                                    </div>
                                    <div className="content">
                                        <h2 className="my-2">{product.name}</h2>
                                        <p className="mb-2">{product.description}</p>
                                    </div>
                                </div>

                                <div className="price">
                                    {!isEmpty(product.prices) && product.prices.sort((a, b) => !isEmpty(b?.recurring?.interval) && !isEmpty(a?.recurring?.interval) ? a.recurring.interval < b.recurring.interval ? -1 : 0 : 0).map(price => {
                                        return (
                                            <span key={price.id} className={`${price?.recurring?.interval} my-2`}>{(price.unit_amount / 100).toLocaleString('eu-EU', {style: 'currency',currency: 'EUR'})}<br/>{!isEmpty(price?.recurring?.interval) ? price.recurring.interval : "one-time payment"}</span>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    } else {
                        return false;
                    }

                })}

                <button
                    disabled={isEmpty(checkedPlanId)}
                    className="btn btn-stripe mt-2"
                    onClick={() => setOpenModal(!openModal)}
                    style={{background: '#000000' }}
                >Subscribe</button>
            </div>
        </div>
    </Fragment>
    );
}

export default Plans;