import Routes from "./components/Routes/Routes";
//HELMET
import { Helmet } from "react-helmet-async";
//CACHE
import withClearCache from "./ClearCache";
import React, {useEffect} from "react";
import ToastModal from "./components/partials/ToastModal";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
	return <ClearCacheComponent />;
}

function MainApp() {

	useEffect(() => {
		const handleContextmenu = e => {
			e.preventDefault()
		}
		document.addEventListener('contextmenu', handleContextmenu)
		return function cleanup() {
			document.removeEventListener('contextmenu', handleContextmenu)
		}
	}, [])

	return (
		<main className="App">
			<Helmet>
				<title>Fuchs Sports International</title>
				<meta property="og:image" content={`${process.env.REACT_APP_CACHE_SERVER}/img/icons/logo_fuchs_sports.png`} />
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
			</Helmet>
			<ToastModal/>
			<Routes />
		</main>
	);
}

export default App;
