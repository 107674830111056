import {
	GET_HIGHLIGHTS,
	REORDER_HIGHLIGHTS,
} from "../actions/highlights.action";

const initialState = [];

export default function highlightReducer(state = initialState, action) {
	switch (action.type) {
		case GET_HIGHLIGHTS:
			return action.payload;
		case REORDER_HIGHLIGHTS:
			return action.payload;

		default:
			return state;
	}
}
