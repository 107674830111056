import React, {useState} from "react";
import AllSubscriptions from "./AllSubscriptions";
import AddSubscription from "./AddSubscription";

const Subscription = () => {

	const [actualComponent, setActualComponent] = useState(<AllSubscriptions />);

	return (
		<div className="bo-subscription">
			<nav>
				<ul>
					<li
						className="selected"
						onClick={(e) => {
							setActualComponent(<AllSubscriptions/>);
							document.querySelectorAll(".bo-subscription > nav>ul>li").forEach((elm) => {
								e.target === elm
									? elm.classList.add("selected")
									: elm.classList.remove("selected");
							});
						}}
					>
						Affichage tous les souscriptions
					</li>
					<li
						onClick={(e) => {
							setActualComponent(<AddSubscription successCreate={() => setActualComponent(<AllSubscriptions/>)}/>);
							document.querySelectorAll(".bo-subscription > nav>ul>li").forEach((elm) => {
								e.target === elm
									? elm.classList.add("selected")
									: elm.classList.remove("selected");
							});
						}}
					>
						Ajouter un souscription
					</li>
				</ul>
			</nav>
			<div className="bo-subscription-component">{actualComponent}</div>
		</div>
	);
};

export default Subscription;
