import axios from "axios";

export const GET_WEBTVS = "GET_WEBTVS";
export const GET_WEBTV_DATA = "GET_WEBTV_DATA";
export const CLEAR_WEBTV_DATA = "CLEAR_WEBTV_DATA";
export const getWebTVS = (page = 1, quantity = 20, pageType = "", listNumber = "", idl = "", idiv = "", idg = "") => {
	let leagueUrl = encodeURI(idl);
	let divisionUrl = encodeURI(idiv);
	let groupUrl = encodeURI(idg);
	let link = `${process.env.REACT_APP_API_URL}getWebTVSHomepage&n=${quantity}&p=${page}&pt=${pageType}&list=${listNumber}&l=${leagueUrl}&d=${divisionUrl}&g=${groupUrl}`;
	return async (dispatch) => {
		await axios.get(link)
			.then((res) => dispatch({ type: GET_WEBTVS, payload: res.data.webTVS }))
			.catch((error) => {
				console.log(error)
			});
	};
};

export const GET_ALL_WEBTV_DATA = "GET_ALL_WEBTV_DATA";
export const getAllWebTvs = (page = 1, quantity = 20, pageType = "", listNumber = "", idl = "", idiv = "", idg = "") => {
    let leagueUrl = encodeURI(idl);
    let divisionUrl = encodeURI(idiv);
    let groupUrl = encodeURI(idg);
    let link = `${process.env.REACT_APP_API_URL}getWebTVSHomepage&n=${quantity}&p=${page}&pt=${pageType}&list=${listNumber}&l=${leagueUrl}&d=${divisionUrl}&g=${groupUrl}`;
    return async (dispatch) => {
        await axios.get(link)
            .then((res) => dispatch({ type: GET_ALL_WEBTV_DATA, payload: res.data.webTVS }))
            .catch((error) => {
                console.log(error)
            });
    };
};

export const CLEAR_ALL_WEBTV_DATA = "CLEAR_ALL_WEBTV_DATA";
export const clearAllWebTVData = () => {
    return (dispatch) => {
        return dispatch({ type: CLEAR_ALL_WEBTV_DATA, payload: null });
    };
};

//SOLO
export const getWebTVData = (url) => {
	return (dispatch) => {
		try {
			axios.get(
				`${process.env.REACT_APP_API_URL}getWebTVS&n=10&u=${url}`
			).then((res) => {
				if (res.data.result === "bad") {
					dispatch({ type: GET_WEBTV_DATA, payload: "bad" });
				} else {
					dispatch({ type: GET_WEBTV_DATA, payload: res.data.webTVS });
				}
			}).catch((error) => {
				dispatch({ type: GET_WEBTV_DATA, payload: "bad" });
			});

		} catch (err) {
			return console.log(err);
		}
	};
};

export const clearWebTVData = () => {
	return (dispatch) => {
		return dispatch({ type: CLEAR_WEBTV_DATA, payload: null });
	};
};

export const REORDER_WEBTVS = "REORDER_WEBTVS";
export const reorderWebTVS = (webTVS) => {
    return (dispatch) => {
        dispatch({ type: REORDER_WEBTVS, payload: webTVS });
    };
};