import {GET_NOTIFICATIONS, GET_PUSH_NOTIFICATIONS} from "../actions/notifications.action";
import {combineReducers} from "redux";

const initialState = [];

function standardNotificationsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_NOTIFICATIONS:
			return action.payload;
		default:
			return state;
	}
}

function pushNotificationsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_PUSH_NOTIFICATIONS:
			return action.payload;
		default:
			return state;
	}
}

export default combineReducers({ standardNotificationsReducer, pushNotificationsReducer });