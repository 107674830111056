import axios from "axios";

export const GET_VIDEO = "GET_VIDEO";

export const getVideo = (id, includeVideos = true) => {
	return (dispatch) => {
		let url = encodeURI(id);
		let includeVid = encodeURI(includeVideos ? '1' : '0');
		return axios
			.get(`${process.env.REACT_APP_API_URL}getVideo&i=${url}&inpl=${includeVid}`)
			.then((res) => dispatch({ type: GET_VIDEO, payload: res.data }));
	};
};

export const GET_VIDEOS_CLUB = "GET_VIDEOS_CLUB";

export const getVideosClub = (id) => {
	return async (dispatch) => {
		let url = encodeURI(id);
		await axios.get(`${process.env.REACT_APP_API_URL}getVideosClub&i=${url}`)
			.then((res) => dispatch({type: GET_VIDEOS_CLUB, payload: res.data}))
			.catch((error) => {
				console.log(error)
			});
	};
};

export const GET_VIDEOS_COMMAND = "GET_VIDEOS_COMMAND";

export const getVideosCommand = (id, page = "1", count = "20", ids = "", idl = "", idiv = "", idg = "", day = "") => {
	return async (dispatch) => {
		let idClub = encodeURI(id);
		let countUrl = encodeURI(count);
		let pageUrl = encodeURI(page);
		let sportUrl = encodeURI(ids);
		let leagueUrl = encodeURI(idl);
		let divisionUrl = encodeURI(idiv);
		let groupUrl = encodeURI(idg);
		let dayUrl = encodeURI(day);

		await axios.get(`${process.env.REACT_APP_API_URL}getVideoCommand&i=${idClub}&p=${pageUrl}&n=${countUrl}&ids=${sportUrl}&idl=${leagueUrl}&idiv=${divisionUrl}&idg=${groupUrl}&day=${dayUrl}`)
			.then((res) => dispatch({type: GET_VIDEOS_COMMAND, payload: res.data}))
			.catch((error) => {
				console.log(error)
			});
	};
};

export const CLEAR_REDUCER_VIDEO = "CLEAR_REDUCER_VIDEO";

export const clearReducerVideo = () => {
	return (dispatch) => {
		return dispatch({ type: CLEAR_REDUCER_VIDEO, payload: [] });
	};
};
