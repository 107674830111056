import axios from "axios";
import { isEmpty } from "../components/Utils";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_LIST_PAYMENTS = "GET_LIST_PAYMENTS";
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_INVOICES = "GET_INVOICES";
export const GET_ORDERED_PRODUCTS = "GET_ORDERED_PRODUCTS";
export const GET_ACTIVE_CUSTOMER_PRODUCT = "GET_ACTIVE_CUSTOMER_PRODUCT";
export const GET_COUPONS = "GET_COUPONS";
export const GET_COUPON = "GET_COUPON";
const Axios = axios.create();

export const getCustomer = () => {
    return async (dispatch) => {
        const loginToken = localStorage.getItem("loginToken");
        if (loginToken) {
            try {
                Axios.defaults.headers.common["Authorization-Bearer"] =
                    "Bearer " + loginToken;
                let formData = new FormData();
                formData.append("key", loginToken);
                await Axios.post(
                    `${process.env.REACT_APP_API_STRIPE}getStripeCustomer`,
                    formData
                )
                    .then((res) => {
                        if (res.data.result === "ok") {
                            dispatch({
                                type: GET_CUSTOMER,
                                payload: res.data.response,
                            });
                        } else {
                            dispatch({ type: GET_CUSTOMER, payload: [] });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (e) {
                console.log(e);
            }
        }
    };
};

export const getCoupon = (id) => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
                Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;
				let formData = new FormData();
				formData.append("id", id);
				await Axios.post(`${process.env.REACT_APP_API_STRIPE}getCoupon`, formData)
					.then((res) => {
						console.log(res);
						if (res.data.result === "ok" && !isEmpty(res.data.response)) {
							dispatch({type: GET_COUPON, payload: res.data.response})
						} else {
							dispatch({type: GET_COUPON, payload: []})
						}
					})
					.catch((error) => {
						console.log(error)
					});

			} catch (e) {
				console.log(e);
			}
		}
	}
};

export const getProduct = (productToken) => {
    return async (dispatch) => {
        const loginToken = localStorage.getItem("loginToken");
        if (loginToken) {
            try {
                let formData = new FormData();
                formData.append("key", loginToken);
                formData.append("source", productToken);
                await Axios.post(
                    `${process.env.REACT_APP_API_STRIPE}getProduct`,
                    formData
                )
                    .then((res) => {
                        if (
                            res.data.result === "ok" &&
                            !isEmpty(res.data.response)
                        ) {
                            dispatch({
                                type: GET_PRODUCT,
                                payload: res.data.response,
                            });
                        } else {
                            dispatch({ type: GET_PRODUCT, payload: [] });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (e) {
                console.log(e);
            }
        }
    };
};

export const getProducts = (
    includePrices,
    includeAdvantages,
    typeOfProduct,
    typeOfUser
) => {
    return async (dispatch) => {
        const loginToken = localStorage.getItem("loginToken");
        if (loginToken) {
            try {
                Axios.defaults.headers.common["Authorization-Bearer"] =
                    "Bearer " + loginToken;
                let formData = new FormData();
                formData.append("key", loginToken);
                if (includePrices) {
                    formData.append("include_prices", includePrices);
                }
                if (includeAdvantages) {
                    formData.append("include_advantages", includeAdvantages);
                }
                if (typeOfProduct) {
                    formData.append("type_product", typeOfProduct);
                }
                if (typeOfUser) {
                    formData.append("type_user", typeOfUser);
                }
                await Axios.post(
                    `${process.env.REACT_APP_API_STRIPE}getProducts`,
                    formData
                )
                    .then((res) => {
                        if (
                            res.data.result === "ok" &&
                            !isEmpty(res.data.response)
                        ) {
                            dispatch({
                                type: GET_PRODUCTS,
                                payload: res.data.response,
                            });
                        } else {
                            dispatch({ type: GET_PRODUCTS, payload: [] });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (e) {
                console.log(e);
            }
        }
    };
};

export const getCoupons = () => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
                Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;
				await Axios.post(`${process.env.REACT_APP_API_STRIPE}getCoupons`)
					.then((res) => {
						console.log(res);
						if (res.data.result === "ok" && !isEmpty(res.data.response)) {
							dispatch({type: GET_COUPONS, payload: res.data.response})
						} else {
							dispatch({type: GET_COUPONS, payload: []})
						}
					})
					.catch((error) => {
						console.log(error)
					});

			} catch (e) {
				console.log(e);
			}
		}
	}
};

export const getOrderedProducts = (filter) => {
    return async (dispatch) => {
        const loginToken = localStorage.getItem("loginToken");
        if (loginToken) {
            try {
                let formData = new FormData();
                formData.append("key", loginToken);
                if (filter) {
                    formData.append("filter", filter);
                }
                await Axios.post(
                    `${process.env.REACT_APP_API_STRIPE}getOrderedProducts`,
                    formData
                )
                    .then((res) => {
                        if (res.data.result === "ok") {
                            dispatch({
                                type: GET_ORDERED_PRODUCTS,
                                payload: res.data.videos,
                            });
                        } else {
                            dispatch({
                                type: GET_ORDERED_PRODUCTS,
                                payload: [],
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (e) {
                console.log(e);
            }
        }
    };
};

export const getActiveCustomerProduct = () => {
    return async (dispatch) => {
        const loginToken = localStorage.getItem("loginToken");
        if (loginToken) {
            try {
                Axios.defaults.headers.common["Authorization-Bearer"] =
                    "Bearer " + loginToken;
                let formData = new FormData();
                formData.append("key", loginToken);
                await Axios.post(
                    `${process.env.REACT_APP_API_STRIPE}getActiveCustomerProduct`,
                    formData
                )
                    .then((res) => {
                        if (
                            res.data.result === "ok" &&
                            !isEmpty(res.data.response)
                        ) {
                            dispatch({
                                type: GET_ACTIVE_CUSTOMER_PRODUCT,
                                payload: res.data.response,
                            });
                        } else {
                            dispatch({
                                type: GET_ACTIVE_CUSTOMER_PRODUCT,
                                payload: [],
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (e) {
                console.log(e);
            }
        }
    };
};

export const getInvoices = (limit, startFrom, endingBefore) => {
    return async (dispatch) => {
        const loginToken = localStorage.getItem("loginToken");
        if (loginToken) {
            try {
                Axios.defaults.headers.common["Authorization-Bearer"] =
                    "Bearer " + loginToken;
                let formData = new FormData();
                formData.append("key", loginToken);
                if (limit) {
                    formData.append("limit", limit);
                }
                if (startFrom) {
                    formData.append("startFrom", startFrom);
                }
                if (endingBefore) {
                    formData.append("endingBefore", endingBefore);
                }
                await Axios.post(
                    `${process.env.REACT_APP_API_STRIPE}getInvoices`,
                    formData
                )
                    .then((res) => {
                        if (
                            res.data.result === "ok" &&
                            !isEmpty(res.data.response.data)
                        ) {
                            dispatch({
                                type: GET_INVOICES,
                                payload: res.data.response.data,
                            });
                        } else {
                            dispatch({ type: GET_INVOICES, payload: [] });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (e) {
                console.log(e);
            }
        }
    };
};

export const getListPaymentsMethods = (clientSecret) => {
    return async (dispatch) => {
        const loginToken = localStorage.getItem("loginToken");
        if (loginToken) {
            try {
                Axios.defaults.headers.common["Authorization-Bearer"] =
                    "Bearer " + loginToken;
                let formData = new FormData();
                formData.append("key", loginToken);
                formData.append("client", clientSecret);
                await Axios.post(
                    `${process.env.REACT_APP_API_STRIPE}getListPaymentsMethods`,
                    formData
                )
                    .then((res) => {
                        if (
                            res.data.result === "ok" &&
                            !isEmpty(res.data.response.data)
                        ) {
                            dispatch({
                                type: GET_LIST_PAYMENTS,
                                payload: res.data.response.data,
                            });
                        } else {
                            dispatch({ type: GET_LIST_PAYMENTS, payload: [] });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (e) {
                console.log(e);
            }
        }
    };
};
