import React from "react";
import { isEmpty } from "./Utils";
import { useSelector } from "react-redux";

import Slider from "react-slick";
import RightArrow from "./slider/RightArrow";
import LeftArrow from "./slider/LeftArrow";

import parse from "html-react-parser";

const Highlight = (props) => {
	const { highlights } = props;
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);

	//SLIDE
	let settings = {
		infinite: false,
		speed: 500,
		autoplay: true,
		autoplaySpeed: 5000,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: <LeftArrow />,
		nextArrow: <RightArrow />
	};

	if (!isEmpty(highlights) && highlights.length > 0) {
		return (
			<div className="highlight-container">
				<div className="titles">
					<h3>
						{translations && !isEmpty(translations["highlights"])
							? parse(translations["highlights"])
							: "highlights"
						}
					</h3>
				</div>
				{highlights.length !== 1 ? (
					<Slider {...settings} className="highlights hide-scroll customScroll">
						{!isEmpty(highlights) && //RETURN EACH HIGHLIGHT
							highlights.map((highlight) => {
								return (
									<a
										className="highlight"
										key={highlight.id}
										href={highlight.link}
										target="_blank"
										rel="noreferrer noopener"
									>
										<img
											src={highlight.img}
											onError={(e) => {
												e.currentTarget.onerror = null;
												e.currentTarget.src = `${process.env.REACT_APP_CACHE_SERVER}/img/footages/banner-event.png`;
											}}
											alt={`Highlited event ${highlight.name}`}
											title={highlight.description}
										/>
									</a>
								);
							})}
					</Slider>
				) : (
					<div className="highlights hide-scroll customScroll">
						{!isEmpty(highlights) && //RETURN EACH HIGHLIGHT
							highlights.map((highlight) => {
								return (
									<a
										className="highlight"
										key={highlight.id}
										href={highlight.link}
										target="_blank"
										rel="noreferrer noopener"
									>
										<img
											src={highlight.img}
											onError={(e) => {
												e.currentTarget.onerror = null;
												e.currentTarget.src = `${process.env.REACT_APP_CACHE_SERVER}/img/footages/banner-event.png`;
											}}
											alt={`Highlited event ${highlight.name}`}
											title={highlight.description}
										/>
									</a>
								);
							})}
					</div>
				)}
			</div>
		);
	} else {
		return null;
	}
};

export default Highlight;
