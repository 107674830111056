import axios from "axios";

export const GET_STATUS = "GET_STATUS";

export const getStatus = () => {
	return async (dispatch) => {
		await axios
			.get(`${process.env.REACT_APP_API_URL}getFuchsStatus`)
			.then((res) => dispatch({ type: GET_STATUS, payload: res.data }))
			.catch((error) => console.log(error));
	};
};
