import React, {useEffect, useRef, useState} from 'react';
import { NavLink } from "react-router-dom";
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { dateRender } from "./Utils";

const PlaylistLoader = (props) => {

    const {defaultVideos, playlistId} = props;

    const scrollBlock = useRef(null);
    const [scrollPage, setScrollPage] = useState(1);
    const [videos, setVideos] = useState(defaultVideos);

    useEffect(() => {
        scrollBlock.current.scrollTo(0, 0);
        setVideos(defaultVideos)
    }, [defaultVideos]);

    const fetchVideos = async () => {
        let incrementPage = scrollPage + 1;
        setScrollPage(incrementPage);
        await axios
            .get(`${process.env.REACT_APP_API_URL}getVideosFromPlaylist&id=${playlistId}&n=5&p=${incrementPage}`)
            .then((res) => {
                let responseVideos = res.data.playlistVideo;
                setVideos([...videos, ...responseVideos])
            })
            .catch((error) => {
                console.log(error)
            });
    };

    return (
        <div className="videos-container hide-scroll" id="scrollableDiv" ref={scrollBlock}>
            <div className="videos">
                <InfiniteScroll
                    dataLength={videos.length}
                    next={fetchVideos}
                    hasMore={true}
                    loader={<h4>Loading...</h4>}
                    scrollableTarget="scrollableDiv"
                >
                    {videos.map((video) => (
                        <NavLink
                            key={video.id}
                            exact
                            to={`/player/${video.id}`}
                            onClick={() => window.scrollTo(0, 0)}
                        >
                            <div className="image-container">
                                <img src={video.img} alt={`Apercu de ${video.name}`} />
                                {video.time > 0 && <span>{dateRender(video.time)}</span>}
                            </div>

                            <div className={`title-container`}>
                                <h5>{video.name}</h5>
                            </div>
                        </NavLink>
                    ))}
                </InfiniteScroll>
            </div>
        </div>
    )
};

export default PlaylistLoader;
