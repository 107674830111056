import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { getHighlights, reorderHighlights } from "../../../actions/highlights.action";

import { isEmpty } from "../../../components/Utils";
import { AdminContext } from "../../../contexts/AdminContext";

const HandleHighlights = ({ sportID, sportName }) => {
	const dispatch = useDispatch();
	const { setHighlights } = useContext(AdminContext);

	let highlights = useSelector(
		(state) => state.highlightReducer.highlight
	);

	const [allHighlights, setAllHighlights] = useState(null);
	const [selectedHighlights, setSelectedHighlights] = useState(null);
	const [notSelectedHighlights, setNotSelectedHighlights] = useState(null);

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}getAllHighlightEvent`)
			.then((res) => {
				let slides = res.data.highlight;
				setAllHighlights(slides);
			});
	}, [sportName]);

	useEffect(() => {
		if (!isEmpty(allHighlights)) {
			let all = allHighlights.filter((elem) => {
				if (!isEmpty(selectedHighlights) && selectedHighlights.length > 0)  {
					return !selectedHighlights.map( el => el.id ).includes(elem.id);
				} else {
					return true;
				}
			});
			setNotSelectedHighlights(all);
		}
	}, [allHighlights, selectedHighlights]);

	useEffect(() => {
		dispatch(getHighlights(sportName + "_sport_page"));
		// eslint-disable-next-line
	}, [sportName]);

	useEffect(() => {
		setSelectedHighlights(highlights);
	}, [highlights]);

	const handleDrag = async (result) => {
		const {source, destination} = result;

		if (!destination) {
			return;
		}
		if (source.droppableId === destination.droppableId) {
			if (source.droppableId === "slideshow") {
				const items = Array.from(highlights);
				const [reorderedItem] = items.splice(result.source.index, 1);
				items.splice(result.destination.index, 0, reorderedItem);

				highlights = items;
				highlights = highlights.map((event, index) => {
					event.sort_order = index + 1;
					return event;
				});
				dispatch(reorderHighlights({result: "ok", highlight: highlights}));
			}
		} else {
			const move = (
				source,
				destination,
				droppableSource,
				droppableDestination
			) => {
				if (isEmpty(destination)) {
					const sourceClone = Array.from(source);

					const destClone = Array.from([]);
					const [removed] = sourceClone.splice(droppableSource.index, 1);

					destClone.splice(droppableDestination.index, 0, removed);

					const result = {};
					result[droppableSource.droppableId] = sourceClone;
					result[droppableDestination.droppableId] = destClone;
					return result;
				} else {
					const sourceClone = Array.from(source);
					const destClone = Array.from(destination);
					const [removed] = sourceClone.splice(droppableSource.index, 1);

					destClone.splice(droppableDestination.index, 0, removed);

					const result = {};
					result[droppableSource.droppableId] = sourceClone;
					result[droppableDestination.droppableId] = destClone;
					return result;
				}
			};

			const result = await move(
				source.droppableId === "slides" ? notSelectedHighlights : selectedHighlights,
				destination.droppableId === "slides" ? notSelectedHighlights : selectedHighlights,
				source,
				destination
			);

			let {slides, slideshow} = result;

			slideshow = slideshow.map((slide, index) => {
				slide.sort_order = index + 1;
				slide.type_page = sportName + "_sport_page";
				return slide;
			});
			slides = slides.map((slide, index) => {
				slide.sort_order = index + 1;
				return slide;
			});

			dispatch(reorderHighlights({result: "ok", highlight: slideshow}));
			setNotSelectedHighlights(slides);
			setSelectedHighlights(slideshow);
		}
	};

	const submitForm = async () => {

		document.querySelector(".highlight-sport-page svg").classList.add("display");
		const data = await setHighlights(highlights, sportName + "_sport_page");

		if (data.success) {
			if (document.querySelector(".highlight-sport-page svg")) {
				setTimeout(() => {
					document.querySelector(".highlight-sport-page span").innerHTML = "Enregistrer";
					document.querySelector(".highlight-sport-page svg").classList.remove("display");
				}, 1000);
			}
			console.log(data);
		} else {
			if (document.querySelector(".highlight-sport-page svg")) {
				setTimeout(() => {
					document.querySelector(".highlight-sport-page span").innerHTML = "Error";
					document.querySelector(".highlight-sport-page svg").classList.remove("display");
				}, 1000);
			}
			console.log(data);
		}
	};

	return (
		<div className="bo-handle-highlights">
			<div className="submit">
				<button onClick={submitForm} className="highlight-sport-page">
					<span>Enregistrer</span>
					<div className="loading-handle-highlights">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
						>
							<path d="M13.75 22c0 .966-.783 1.75-1.75 1.75s-1.75-.784-1.75-1.75.783-1.75 1.75-1.75 1.75.784 1.75 1.75zm-1.75-22c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm10 10.75c.689 0 1.249.561 1.249 1.25 0 .69-.56 1.25-1.249 1.25-.69 0-1.249-.559-1.249-1.25 0-.689.559-1.25 1.249-1.25zm-22 1.25c0 1.105.896 2 2 2s2-.895 2-2c0-1.104-.896-2-2-2s-2 .896-2 2zm19-8c.551 0 1 .449 1 1 0 .553-.449 1.002-1 1-.551 0-1-.447-1-.998 0-.553.449-1.002 1-1.002zm0 13.5c.828 0 1.5.672 1.5 1.5s-.672 1.501-1.502 1.5c-.826 0-1.498-.671-1.498-1.499 0-.829.672-1.501 1.5-1.501zm-14-14.5c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2zm0 14c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2z" />
						</svg>
					</div>
				</button>
			</div>
			<DragDropContext onDragEnd={handleDrag}>
				<Droppable droppableId="slides">
					{(provided) => (
						<section
							className="slides"
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							<h3>Immanquables en stock</h3>
							{!isEmpty(notSelectedHighlights) &&
								notSelectedHighlights
									.sort((a, b) => a.sort_order > b.sort_order)
									.map((highlight, index) => {
										return (
											<Draggable
												key={highlight.id}
												draggableId={highlight.id}
												index={index}
												title={highlight.title}
											>
												{(provided) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														className="bo-highlight-element"
													>
														<img
															onError={(e) => {
																e.currentTarget.onerror = null;
																e.currentTarget.src = `${process.env.REACT_APP_CACHE_SERVER}/img/footages/banner-event.png`;
															}}
															src={highlight.img}
															width="220"
															height="auto"
															alt=""
														/>
													</div>
												)}
											</Draggable>
										);
									})}
									{provided.placeholder}
						</section>
					)}
				</Droppable>
				<Droppable droppableId="slideshow">
					{(provided) => (
						<section
							className="slideshow"
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							<h3>Immanquables visibles</h3>
							{!isEmpty(selectedHighlights) &&
							selectedHighlights
								.sort((a, b) => a.sort_order > b.sort_order)
								.map((highlight, index) => {
									return (
										<Draggable
											key={highlight.id}
											draggableId={highlight.id}
											index={index}
										>
											{(provided) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													data-number={(index + 1).toString()}
													className="bo-highlight-element number"
												>
													<img
														onError={(e) => {
															e.currentTarget.onerror = null;
															e.currentTarget.src = `${process.env.REACT_APP_CACHE_SERVER}/img/footages/banner-event.png`;
														}}
														src={highlight.img}
														width="220"
														height="auto"
														alt=""
													/>
												</div>
											)}
										</Draggable>
									);
								})}
							{provided.placeholder}
						</section>
					)}
				</Droppable>
			</DragDropContext>
		</div>
	);
};

export default HandleHighlights;
