import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleContactForm } from "../actions/account.action";
import parse from "html-react-parser";
import { MyContext } from "../contexts/MyContext";
import { isEmpty, removeTags } from "./Utils";
import { useMediaQuery } from "react-responsive";

const Footer = () => {
    const dispatch = useDispatch();
    const contactForm = useSelector(
        (state) => state.accountReducer.contactFormReducer
    );
    const translationsReducer = useSelector(
        (state) => state.langReducer.translationsReducer
    );
    const translations = translationsReducer.translations;
    const selectedLanguage = translationsReducer.language;
    const { rootState } = useContext(MyContext);
    const { isAuth } = rootState;
    const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

    return (
        <footer>
            <div className="login-section">
                <NavLink exact to="/" onClick={() => window.scrollTo(0, 0)}>
                    <img
                        src={`${process.env.REACT_APP_CACHE_SERVER}/img/icons/2021_11_Logo_Fuchs_Sports_Vecto.png`}
                        alt="logo fuchs"
                        width="200"
                    />
                </NavLink>
                {!isAuth && (
                    <div className="login">
                        <ul>
                            <li>
                                <NavLink
                                    to="/account/register"
                                    onClick={(e) =>
                                        isMobile &&
                                        document
                                            .querySelector(".main-header")
                                            .classList.remove("menu-open")
                                    }
                                >
                                    {translations &&
                                    !isEmpty(translations["registerSubmit"])
                                        ? parse(translations["registerSubmit"])
                                        : "registerSubmit"}
                                </NavLink>
                            </li>

                            <li>
                                <NavLink
                                    to="/account/login"
                                    onClick={(e) =>
                                        isMobile &&
                                        document
                                            .querySelector(".main-header")
                                            .classList.remove("menu-open")
                                    }
                                >
                                    {translations &&
                                    !isEmpty(translations["loginSubmit"])
                                        ? parse(translations["loginSubmit"])
                                        : "loginSubmit"}
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                )}
                <div className="copyrights">
                    <span>
                        {translations && !isEmpty(translations["copyright"])
                            ? parse(translations["copyright"])
                            : "copyright"}
                    </span>
                    <span>
                        {translations &&
                        !isEmpty(translations["websiteDevelopper"])
                            ? parse(translations["websiteDevelopper"])
                            : "websiteDevelopper"}{" "}
                        <a
                            href="https://company.mytvchain.com/"
                            target="_blank"
                            rel="noopenner noreferrer"
                        >
                            MyTVchain
                        </a>
                    </span>
                    <span>
                        <a
                            href={
                                process.env.PUBLIC_URL +
                                "/files/Fuchs_Sports_" +
                                selectedLanguage +
                                "_CGU.pdf"
                            }
                            target="_blank"
                            rel="noreferrer noopener"
                            className="no-wrap"
                        >
                            {translations &&
                            !isEmpty(translations["termsOfServices"])
                                ? parse(translations["termsOfServices"])
                                : "termsOfServices"}{" "}
                        </a>
                    </span>
                </div>
            </div>
            <section className="discover">
                <h4>
                    {translations && !isEmpty(translations["discoverUs"])
                        ? parse(translations["discoverUs"])
                        : "discoverUs"}
                </h4>
                <ul>
                    <li>
                        <NavLink
                            exact
                            to="/"
                            onClick={() => window.scrollTo(0, 0)}
                        >
                            {translations && !isEmpty(translations["home"])
                                ? parse(translations["home"])
                                : "home"}
                        </NavLink>
                    </li>
                    <li>
                        <a
                            href="https://fuchssports.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {translations &&
                            !isEmpty(translations["discoverUs"])
                                ? parse(translations["discoverUs"])
                                : "discoverUs"}
                        </a>
                    </li>
                    {/*<li>
						<a
							href="https://www.fuchs-sports.lu/equipe/#partenaires"
							target="_blank"
							rel="noopener noreferrer"
						>
							{translations && !isEmpty(translations["partners"])
								? parse(translations["partners"])
								: "partners"
							}
						</a>
					</li>*/}
                    <li
                        id="contact-button"
                        onClick={() => {
                            dispatch(toggleContactForm(!contactForm));
                        }}
                    >
                        {translations && !isEmpty(translations["contact"])
                            ? parse(translations["contact"])
                            : "contact"}
                    </li>
                </ul>
            </section>
            <section className="follow">
                <h4>
                    {translations && !isEmpty(translations["followUs"])
                        ? parse(translations["followUs"])
                        : "followUs"}
                </h4>
                <ul>
                    <li>
                        <a
                            href="https://www.facebook.com/fuchssportsfr/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Facebook
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://twitter.com/FuchsSportsfr"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Twitter
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.instagram.com/fuchs.sports/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Instagram
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.linkedin.com/company/fuchs-sports-fr"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            LinkedIn
                        </a>
                    </li>
                </ul>
            </section>
            <section className="contact">
                <h4>
                    {translations && !isEmpty(translations["contactUs"])
                        ? parse(translations["contactUs"])
                        : "contactUs"}
                </h4>
                <address>
                    <span>47 Boulevard Prince Henri</span>
                    <span>L-1724 Luxembourg</span>
                    <span>Luxembourg</span>
                </address>
                <address>
                    <span>40 rue Blanche</span>
                    <span>75009 Paris</span>
                    <span>France</span>
                </address>
                <a
                    href={`mailto:${
                        translations && !isEmpty(translations["mailContact"])
                            ? removeTags(translations["mailContact"])
                            : "mailContact"
                    }`}
                >
                    {translations && !isEmpty(translations["mailContact"])
                        ? parse(translations["mailContact"])
                        : "mailContact"}
                </a>
            </section>
        </footer>
    );
};

export default Footer;
