import React, {useEffect, useRef, useState} from "react";
import { NavLink } from "react-router-dom";
import { hexToHSL, isEmpty } from "./Utils";
import {FastAverageColor} from "fast-average-color";
import Slider from "react-slick";
import RightArrow from "./slider/RightArrow";
import LeftArrow from "./slider/LeftArrow";
import { toggleSearch } from "../actions/search.action";
import {useDispatch, useSelector} from "react-redux";
import parse from "html-react-parser";
import axios from "axios";
import WebTVSFilter from "./WebTVSFilter";
import useMeasure from "react-use-measure";

const WebTVS = (props) => {
	const {webTVS, leagueId, sportId, filter, titleValue, onSelectFilter, listNumber, pageType, hideSeeAll} = props;
	const slider = useRef();
	const dispatch = useDispatch();
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);
	const [filterLeagueId, setFilterLeagueId] = useState("");
	const [filterDivisionId, setFilterDivisionId] = useState("");
	const [filterGroupId, setFilterGroupId] = useState("");
	const [isFiltered, setIsFiltered] = useState(false);
	const [listWebTVS, setListWebTVS] = useState([]);
	const [countPage, setCountPage] = useState(1);
	const [blockSlideRef, {width}] = useMeasure();

	const handlerPage = (page) => {
		let link = `${process.env.REACT_APP_API_URL}getWebTVSHomepage&n=20&p=${page}&pt=${pageType}&list=${listNumber}&l=${filterLeagueId}&d=${filterDivisionId}&g=${filterGroupId}`;
		axios.get(link)
			.then((res) => {
			if (res.data.result !== "bad") {
				setListWebTVS([...listWebTVS, ...res.data.webTVS])
			}
		});

	};

	let settings = {
		infinite: false,
		slidesToShow: (!isEmpty(width) && width !== 0) ? Math.floor(width / 200) : 6,
		slidesToScroll: (!isEmpty(width) && width !== 0) ? Math.floor(width / 200) : 6,
		prevArrow: <LeftArrow />,
		nextArrow: <RightArrow />,
		swipeToSlide: false,
		initialSlide: 0,
		afterChange: () => {
			if(document.querySelector(".d-none.arrow-slideshow.right")){
				setCountPage(countPage + 1);
				handlerPage(countPage + 1);
			}
		},
		responsive: [
			{
				breakpoint: 400,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				}
			}
		]
	};

	const colorize = (img) => {
		const fac = new FastAverageColor();

		let image = new Image();
		image.crossOrigin = "anonymous";
		image.src = img.src;
		fac
			.getColorAsync(image)
			.then((color) => {
				let hsl = hexToHSL(color.hex);
				hsl.s = hsl.s * 4;
				img.parentElement.style.backgroundColor = `hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)`;
			})
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		setListWebTVS(webTVS);
	}, [webTVS]);

	const handlerWebTvFilter = (leagueId, divisionId, groupId) => {
		onSelectFilter(leagueId, divisionId, groupId);
		setFilterLeagueId(leagueId);
		setFilterDivisionId(divisionId);
		setFilterGroupId(groupId);
		setCountPage(1);
		setTimeout(() => {
			if (slider && isFiltered) {
				slider.current.slickGoTo(0);
			}
		}, 1000);
	};

	return (
		<div className="webTVS filter white">
			{(listWebTVS.length > 0 || isFiltered) && !hideSeeAll && (
				<div className="titles">
					<section
						className={"flex-between m-auto" + (!isEmpty(filter) && " filter")}
					>
						<h3>
							{translations && !isEmpty(translations[titleValue])
								? parse(translations[titleValue])
								: titleValue
							}
						</h3>
						<WebTVSFilter
							onSelectFilter={handlerWebTvFilter}
							leagueId={!isEmpty(leagueId) ? leagueId : ""}
							sportId={!isEmpty(sportId) ? sportId : ""}
							setIsFiltered={setIsFiltered}
						/>
					</section>
				</div>
			)}

			{(listWebTVS.length > 0 || isFiltered) && (
				<div ref={blockSlideRef}>
					<Slider ref={slider} {...settings} className="webTVS-container hide-scroll customScroll">
						{!hideSeeAll && (
							<div className="last-card" onClick={() => dispatch(toggleSearch(true))}>
								<div className="dots">
									<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/dots1.png`} alt="decoration elements" />
									<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/dots2.png`} alt="decoration elements" />
								</div>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path d="M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z" />
								</svg>
								<h3>
									{translations && !isEmpty(translations["webTVSSearch"])
										? parse(translations["webTVSSearch"])
										: "webTVSSearch"
									}
								</h3>
								<img
									src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/events/background.png`}
									width="50"
									height="50"
									alt="background"
								/>
							</div>
						)}
						{!isEmpty(listWebTVS) &&
							listWebTVS.map((tv) => {
								return (
									<NavLink
										exact
										key={tv.id}
										to={`/${tv.url}`}
										onClick={() => window.scrollTo(0, 0)}
									>
										<div className="tv">
											<img
												src={tv.logo}
												alt={`${tv.name} logo`}
												title={`${tv.name} logo`}
												width="50"
												height="50"
												onLoad={(e) => {
													isEmpty(tv.color)
														? colorize(e.target)
														: (e.target.parentElement.style.backgroundColor = tv.color);
												}}
											/>
											<h4>{tv.name}</h4>
										</div>
									</NavLink>
								);
							})}
					</Slider>
				</div>
			)}
		</div>
	);
};

export default WebTVS;
