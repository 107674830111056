import React, { Fragment, useEffect, useState } from "react";
import { isEmpty, removeTags } from "../Utils";
import { useDispatch, useSelector } from "react-redux";
import { getDivisions } from "../../actions/division.action";
import { getGroups } from "../../actions/group.action";
import { getDistinctDays } from "../../actions/events.action";

import parse from "html-react-parser";

const Filter = (props) => {
	const onSelectFilter = props.onSelectFilter;
	const onSelectType = props.onSelectType;
	const setIsFiltered = props.setIsFiltered;
	const leagueId = props.leagueId;
	const isLeague = props.isLeague;
	const clubId = props.clubId;
	const dispatch = useDispatch();
	const [type, setType] = useState("all");
	const [daysMenu, setDaysMenu] = useState(false);
	const [divisionMenu, setDivisionMenu] = useState(false);
	const [groupMenu, setGroupMenu] = useState(false);
	const divisions = useSelector((state) => state.divisionReducer.divisions);
	const groups = useSelector((state) => state.groupReducer.groups);
	const days = useSelector((state) => state.eventsReducer.distinctDaysReducer);
	const translationsReducer = useSelector((state) => state.langReducer.translationsReducer);
	const selectedLanguage = translationsReducer.language;
	const translations = translationsReducer.translations;

	const [divisionSelect, setDivisionSelect] = useState("");
	const [groupSelect, setGroupSelect] = useState("");
	const [daySelect, setDaySelect] = useState("");

	useEffect(() => {
		setDivisionMenu(false);
		setGroupMenu(false);
		setDaysMenu(false);
		onSelectFilter(divisionSelect, groupSelect, daySelect);
		// eslint-disable-next-line
	}, [groupSelect, divisionSelect, daySelect]);

	useEffect(() => {
		setGroupSelect("");
		setDaySelect("");
	}, [divisionSelect]);

	useEffect(() => {
		setDaySelect("");
	}, [groupSelect]);

	useEffect(() => {
		setDivisionSelect("");
		setGroupSelect("");
		setDaySelect("");
		onSelectType(type);
		// eslint-disable-next-line
	}, [type]);

	useEffect(() => {
		dispatch(
			getDistinctDays(
				leagueId,
				divisionSelect,
				groupSelect,
				!isLeague && !isEmpty(clubId) ? clubId : ""
			)
		);
		// eslint-disable-next-line
	}, [leagueId, divisionSelect, groupSelect]);

	useEffect(() => {
		dispatch(getDivisions("", leagueId, "", "", selectedLanguage));
		dispatch(getGroups("", leagueId, divisionSelect, "", "", selectedLanguage));
		dispatch(getDistinctDays(leagueId, divisionSelect, groupSelect));
	}, [dispatch, selectedLanguage, groupSelect, divisionSelect, leagueId]);

	const getNameDivision = (id) => {
		let name = "";
		divisions.forEach((division) => {
			if (division.id === id) {
				name = !isEmpty(division.name) ? removeTags(division.name) : division.id
			}
		});

		return name;
	};

	const getNameGroup = (id) => {
		let name = "";
		groups.forEach((group) => {
			if (group.id === id) {
				name = !isEmpty(group.name) ? removeTags(group.name) : group.id
			}
		});

		return name;
	};

	const dropdownClicked = (menu) => {
		setIsFiltered(true);
		switch (menu) {
			case "division":
				daysMenu && setDaysMenu(!daysMenu);
				groupMenu && setGroupMenu(!groupMenu);
				setDivisionMenu(!divisionMenu);
				break;
			case "groups":
				daysMenu && setDaysMenu(!daysMenu);
				divisionMenu && setDivisionMenu(!divisionMenu);
				setGroupMenu(!groupMenu);
				break;
			case "days":
				divisionMenu && setDivisionMenu(!divisionMenu);
				groupMenu && setGroupMenu(!groupMenu);
				setDaysMenu(!daysMenu);
				break;
			default:
				setGroupMenu(false);
				setDivisionMenu(false);
				setDaysMenu(false);
				return;
		}
	};

	const handleClickOutside = (event) => {
		if (event.target.closest('.filter-block') === null) {
			setGroupMenu(false);
			setDivisionMenu(false);
			setDaysMenu(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	});

	return (
		<div className="container filter filter-block">
			<section className="flex-between">
				<div className="sport-menu">
					<span
						className={type === "all" ? "active link" : "link"}
						onClick={() => setType("all")}
					>
						{translations && !isEmpty(translations["allContent"])
							? parse(translations["allContent"])
							: "All content"
						}
					</span>
					<span
						className={type === "videos" ? "active link" : "link"}
						onClick={() => setType("videos")}
					>
						{translations && !isEmpty(translations["videos"])
							? parse(translations["videos"])
							: "videos"
						}
					</span>
					<span
						className={type === "events" ? "active link" : "link"}
						onClick={() => setType("events")}
					>
						{translations && !isEmpty(translations["events"])
							? parse(translations["events"])
							: "events"
						}
					</span>
				</div>

				{(type === "all" || type === "events") && (
					<div className="sport-filter">
						<span>
							{translations && !isEmpty(translations["filterBy"])
								? parse(translations["filterBy"])
								: "Filter by"
							}
						</span>
						{isLeague && (
							<Fragment>
								<div className="dropdown">
									<button
										className="dropbtn"
										onClick={() => dropdownClicked("division")}
									>
										{!isEmpty(divisionSelect)
											? getNameDivision(divisionSelect)
											: translations
											? parse(translations["divisions"])
											: "Divisions"}
									</button>
									{divisionMenu && (
										<ul className="dropdown-content">
											<li onClick={() => setDivisionSelect("")}>
												{translations && !isEmpty(translations["allFilters"])
												? parse(translations["allFilters"])
												: "allFilters"}
											</li>
											{divisions &&
												divisions.map((division) => {
													if (!isEmpty(leagueId)) {
														if (division.league === leagueId) {
															return (
																<li
																	onClick={() => setDivisionSelect(division.id)}
																	key={division.id}
																>
																	{!isEmpty(division.name) ? removeTags(division.name) : division.id}
																</li>
															);
														}
													} else {
														return (
															<li
																onClick={() => setDivisionSelect(division.id)}
																key={division.id}
															>
																{!isEmpty(division.name) ? removeTags(division.name) : division.id}
															</li>
														);
													}
													return null;
												})}
										</ul>
									)}
								</div>

								<div className="dropdown">
									<button className="dropbtn" onClick={() => dropdownClicked("groups")}>
										{!isEmpty(groupSelect)
											? getNameGroup(groupSelect)
											: translations
											? parse(translations["groups"])
											: "Groups"}
									</button>
									{groupMenu && divisionSelect && (
										<ul className="dropdown-content">
											<li onClick={() => setGroupSelect("")}>
												{translations && !isEmpty(translations["allFilters"])
												? parse(translations["allFilters"])
												: "allFilters"}
											</li>
											{groups &&
												groups.map((group) => {
													if (!isEmpty(leagueId) && !isEmpty(divisionSelect)) {
														if (
															group.league === leagueId &&
															group.division === divisionSelect
														) {
															return (
																<li onClick={() => setGroupSelect(group.id)} key={group.id}>
																	{!isEmpty(group.name) ? removeTags(group.name) : group.id}
																</li>
															);
														}
													} else if (!isEmpty(leagueId) || !isEmpty(divisionSelect)) {
														if (
															group.league === leagueId ||
															group.division === divisionSelect
														) {
															return (
																<li onClick={() => setGroupSelect(group.id)} key={group.id}>
																	{!isEmpty(group.name) ? removeTags(group.name) : group.id}
																</li>
															);
														}
													} else {
														return (
															<li onClick={() => setGroupSelect(group.id)} key={group.id}>
																{!isEmpty(group.name) ? removeTags(group.name) : group.id}
															</li>
														);
													}
													return null;
												})}
										</ul>
									)}
								</div>
							</Fragment>
						)}
						<div className="dropdown">
							<button className="dropbtn" onClick={() => dropdownClicked("days")}>
								{translations && !isEmpty(translations["days"])
									? parse(translations["days"])
									: "Days"
								}
							</button>
							{((isLeague && daysMenu && !isEmpty(divisionSelect)) ||
								(daysMenu && !isLeague)) && (
									<ul className="dropdown-content">
										<li onClick={() => setDaySelect("")}>
											{translations && !isEmpty(translations["allFilters"])
											? parse(translations["allFilters"])
											: "allFilters"}
										</li>
										{days &&
											days.map((day) => {
												return (
													<li
														onClick={() => setDaySelect(day.match_number)}
														key={day.match_number}
													>
														{day.match_number}
													</li>
												);
											})}
									</ul>
								)}
						</div>
					</div>
				)}
			</section>
		</div>
	);
};

export default Filter;
