import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Banner from "../components/explorer/Banner";
import Events from "../components/Events";
import Playlist from "../components/Playlist";
import FavouriteClubsPlaylist from "../components/FavouriteClubsPlaylist";
import WebTVS from "../components/WebTVS";
import Search from "../components/Search";
import { isEmpty } from "../components/Utils";
import { getHighlights } from "../actions/highlights.action";
import { getEvents, getRemindMeEvents } from "../actions/events.action";
import Highlight from "../components/Highlight";
import {getAllWebTvs, getWebTVS} from "../actions/webTVS.action";
import { toggleSearch } from "../actions/search.action";
import { useLocation } from "react-router";
import {getFavouriteClubsPlaylist, getFuchsPlaylists} from "../actions/playlist.action";
import {getLeagues} from "../actions/leagues.action";
import {getGroups} from "../actions/group.action";
import MenuSportCountry from "../components/explorer/MenuSportCountry";
import { MyContext } from "../contexts/MyContext";
import {Helmet} from "react-helmet-async";
import parse from "html-react-parser";
import RedirectApp from "../components/RedirectApp";

const Explorer = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const events = useSelector((state) => state.eventsReducer.eventsAllReducer);
	const eventsRemind = useSelector((state) => state.eventsReducer.eventsRemindMeReducer);
    const webTVS = useSelector((state) => state.webTVSReducer);
    const allWebTVS = useSelector((state) => state.allWebTvs);
    const translationsReducer = useSelector((state) => state.langReducer.translationsReducer);
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);
	const selectedLanguage = translationsReducer.language;
	const { rootState } = useContext(MyContext);
	const { isAuth } = rootState;
	const playlistsVideo = useSelector(
		(state) => state.playlistAllReducer.fuchsPlaylistsReducer
	);
	const favouriteClubsPlaylistsVideo = useSelector(
		(state) => state.playlistAllReducer.favouriteClubsPlaylistReducer
	);
	const searchState = useSelector(
		(state) => state.searchReducer.searchToggleReducer
	);
	const highlights = useSelector(
		(state) => state.highlightReducer
	);

	useEffect(() => {
		dispatch(getHighlights('home_page'));
		dispatch(getEvents(100));
		dispatch(getRemindMeEvents(100));
		dispatch(getFuchsPlaylists("home_page", "yes"));
		dispatch(getLeagues("", "", "",  selectedLanguage));
		dispatch(getGroups("", "", "", "", "", selectedLanguage));
        dispatch(getWebTVS(1, 20, "home_page", 1));
        dispatch(getAllWebTvs(1, 20, "home_page", 2));
    }, [selectedLanguage]);

	useEffect(() => {
		if (isAuth) {
			dispatch(getFavouriteClubsPlaylist());
		}
	}, [dispatch, isAuth, selectedLanguage]);

	useEffect(() => {
		if (searchState) {
			dispatch(toggleSearch(false));
		}
		// eslint-disable-next-line
	}, [location]);

	const onWebTvSelectFilter = (leagueId, divisionId, groupId) => {
		dispatch(getWebTVS(1, 20, "home_page", 1, leagueId, divisionId, groupId));
	};

	const onAllWebTvSelectFilter = (leagueId, divisionId, groupId) => {
		dispatch(getAllWebTvs(1, 20, "home_page", 2, leagueId, divisionId, groupId));
	};

	return (
		<>
			<Helmet>
				<title>Fuchs Sports International</title>
				<meta property="og:image" content={`${process.env.REACT_APP_CACHE_SERVER}/img/icons/logo_fuchs_sports.png`} />
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
			</Helmet>

			<RedirectApp page="home"/>

			<main className="explorer">
				<Banner />

				<MenuSportCountry/>

				{searchState && <Search />}

				{isAuth && (
					<Events events={eventsRemind} title={translations && !isEmpty(translations["remindMeEvents"]) ? parse(translations["remindMeEvents"]) : "remindMeEvents"} reminder={true} />
				)}

				<Events events={events} title={translations && !isEmpty(translations["events"]) ? parse(translations["events"]) : "events"} reminder={false}/>


				<WebTVS
					webTVS={webTVS}
					listNumber="1"
					pageType="home_page"
					titleValue={"champTitle"}
					onSelectFilter={onWebTvSelectFilter}
				/>


				<WebTVS
					webTVS={allWebTVS}
					listNumber="2"
					pageType="home_page"
					titleValue={"webTVSTitle"}
					onSelectFilter={onAllWebTvSelectFilter}
				/>

				{ isAuth && favouriteClubsPlaylistsVideo?.playlistVideo && (
					<FavouriteClubsPlaylist playlistVideo={favouriteClubsPlaylistsVideo.playlistVideo}/>
				)}

				{!isEmpty(playlistsVideo.playlists) &&
					Object.keys(playlistsVideo.playlists)
						.sort((a, b) => {
							return (
								parseInt(playlistsVideo["playlists"][b].sort_order) -
								parseInt(playlistsVideo["playlists"][a].sort_order)
							);
						})
						.map((key, index) => {
							return (
								<Playlist
									key={index}
									data={playlistsVideo["playlists"][key]}
									id={key}
									color={index}
									playlistId={key}
									clubPlayerId={""}
								/>
							);
						})}
				<Highlight highlights={highlights.highlight} />
			</main>
		</>
	);
};

export default Explorer;
