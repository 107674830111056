import React, { createContext, Component } from "react";
import axios from "axios";

export const AdminContext = createContext();
//DEFINE URL

const Axios = axios.create();

class AdminContextProvider extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAuth: false,
			theAdmin: null,
		};
		//this.isLoggedInAdmin();
	}

	loginAdmin = async (user) => {
		// Sending the user Login request
		const login = await Axios.post(`${process.env.REACT_APP_API_URL}loginAdmin`, {
			email: user.email,
			password: user.password,
		});
		return login.data;
	};
	logoutAdmin = () => {
		localStorage.removeItem("loginAdminToken");
		this.setState({
			...this.state,
			isAuth: false,
		});
	};

	isLoggedInAdmin = async () => {
		const loginToken = localStorage.getItem("loginAdminToken");

		if (loginToken) {
			//Adding JWT token to axios default header
			Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;
			if (process.env.REACT_APP_ENV === "production") {
				Axios.defaults.headers.common["Authorization"] = "Bearer " + loginToken;
			}

			// Fetching the user information
			const { data } = await Axios.get(
				`${process.env.REACT_APP_API_URL}adminInfo`
			);

			// If user information is successfully received
			if (data.success && data.user) {
				this.setState({
					...this.state,
					isAuth: true,
					theAdmin: data.user,
				});
			}
		} else {
			return false;
		}
	};

	updateNaviguationBar = async (sports, countries) => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}setNaviguationBar`,
			{
				sports: JSON.stringify(sports),
				countries: JSON.stringify(countries),
			}
		);

		return update.data;
	};

	pushNotificationCreate = async (notification) => {

		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}pushNotificationCreate`,
			{
				notification: JSON.stringify(notification)
			}
		);

		return update.data;
	};

	setSlideShow = async (slides) => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}setSlideShow`,
			{
				slides: JSON.stringify(slides),
			}
		);

		return update.data;
	};

	updateSlide = async (slide) => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}updateSlide`,
			slide,
			{
				headers: {
					"content-type": "multipart/form-data",
				},
			}
		);

		return update.data;
	};
	createSlide = async (slide) => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}createSlide`,
			slide,
			{
				headers: {
					"content-type": "multipart/form-data",
				},
			}
		);

		return update.data;
	};

	delSlide = async (id) => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}deleteSlide&i=${id}`
		);
		return update.data;
	};

	updateSlidePerPage = async (perPage) => {
		let form = new FormData();
		form.append("per_page", perPage);
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}updateSlidePerPage`,
			form,
			{
				headers: {
					"content-type": "multipart/form-data",
				}
			}
		);
		return update.data;
	};

	setWebTVS = async (webTVS, mode, listNumber) => {
		const update = await Axios.post(`${process.env.REACT_APP_API_URL}setWebTVS`, {
			webTVS: JSON.stringify(webTVS),
			mode: mode,
			listNumber: listNumber,
		});
		return update.data;
	};

	setPlaylists = async (playlists, mode) => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}setPlaylists`,
			{
				playlists: JSON.stringify(playlists),
				mode: mode
			}
		);
		return update.data;
	};
	setHighlights = async (highlights, mode) => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}setHighlights`,
			{
				highlights: JSON.stringify(highlights),
				mode,
			}
		);
		return update.data;
	};
	updateHighlight = async (slide) => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}updateHighlight`,
			slide,
			{
				headers: {
					"content-type": "multipart/form-data",
				},
			}
		);

		return update.data;
	};
	createHighlight = async (slide) => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}createHighlight`,
			slide,
			{
				headers: {
					"content-type": "multipart/form-data",
				},
			}
		);

		return update.data;
	};
	delHighlight = async (id) => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}deleteHighlight&i=${id}`
		);
		return update.data;
	};

	updateSportBanner = async (data) => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}updateSportBanner`,
			data,
			{
				headers: {
					"content-type": "multipart/form-data",
				},
			}
		);

		return update.data;
	};
	updateCountryBanner = async (data) => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}updateCountryBanner`,
			data,
			{
				headers: {
					"content-type": "multipart/form-data",
				},
			}
		);

		return update.data;
	};
	updatePassword = async (password) => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}setAdminSecurity`,
			{ password }
		);

		return update.data;
	};

	updateVariable = async (variable) => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}updateVariable`,
			variable
		);

		return update.data;
	};
	updateStatus = async (message) => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}setFuchsStatus`,
			{ message: message }
		);

		return update.data;
	};

	createProduct = async (data, file) => {
		let formData = new FormData();
		formData.append("data", JSON.stringify(data));
		if (file) {
			formData.append("file", file);
		}
		const result = await Axios.post(
			`${process.env.REACT_APP_API_ADMIN_STRIPE}createProduct`,
			formData,
			{
				headers: {
					"content-type": "multipart/form-data",
				}
			}
		);
		return result.data;
	};

	createCoupon = async (data) => {
		let formData = new FormData();
		formData.append("data", JSON.stringify(data));
		const result = await Axios.post(
			`${process.env.REACT_APP_API_ADMIN_STRIPE}createCoupon`,
			formData
		);
		console.log(result);
		return result.data;
	};

	createAdvantage = async (data) => {
		let formData = new FormData();
		formData.append("data", JSON.stringify(data));
		const result = await Axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}createAdvantage`, formData);
		return result.data;
	};

	updateAdvantage = async (data, id) => {
		let formData = new FormData();
		formData.append("data", JSON.stringify(data));
		const result = await Axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}updateAdvantage`, formData);
		return result.data;
	};

	deleteAdvantage = async (id) => {
		let formData = new FormData();
		formData.append("id", id);
		const result = await Axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}deleteAdvantage`, formData);
		return result.data;
	};

	archiveProduct = async (data) => {
		let formData = new FormData();
		if (data) {
			formData.append("source", data);
		}
		const result = await Axios.post(
			`${process.env.REACT_APP_API_ADMIN_STRIPE}archiveProduct`,
			formData
		);
		return result.data;
	};

	deleteCoupon = async (id) => {
		let formData = new FormData();
		if (id) {
			formData.append("id", id);
		}
		const result = await Axios.post(
			`${process.env.REACT_APP_API_ADMIN_STRIPE}deleteCoupon`,
			formData
		);
		return result.data;
	};

	updateProduct = async (id, data, file) => {
		let formData = new FormData();
		if (id) {
			formData.append("id", id);
		}
		if (data) {
			formData.append("data", JSON.stringify(data));
		}
		if (file) {
			formData.append("file", file);
		}
		const result = await Axios.post(
			`${process.env.REACT_APP_API_ADMIN_STRIPE}updateProduct`,
			formData,
			{
				headers: {
					"content-type": "multipart/form-data",
				}
			}
		);
		return result.data;
	};

	updateCoupon = async (id, data) => {
		let formData = new FormData();
		if (id) {
			formData.append("id", id);
		}
		if (data) {
			formData.append("data", JSON.stringify(data));
		}
		const result = await Axios.post(
			`${process.env.REACT_APP_API_ADMIN_STRIPE}updateCoupon`,
			formData,
			{
				headers: {
					"content-type": "multipart/form-data",
				}
			}
		);
		return result.data;
	};

	updateStripeCustomer = async (clientSecret, user) => {
		
			try {
				let formData = new FormData();
				formData.append("user", JSON.stringify(user));
				formData.append("client", clientSecret);
				const update = await Axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}updateStripeCustomer`, formData);
				return update.data;

			} catch (e) {
				console.log(e);
			}
		

		return false;
	};

	createStripeCustomerDefault = async (id) => {
		let formData = new FormData();
		formData.append("user_id", id);
		const result = await Axios.post(
			`${process.env.REACT_APP_API_ADMIN_STRIPE}createStripeCustomerDefault`, formData
		);
		return result.data;
	};
	
	attachPaymentMethod = async (id, source) => {
		let formData = new FormData();
		formData.append("id", id);
		formData.append("source", source);
		const result = await Axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}attachPaymentMethod`, formData);

		return result.data;
	};

	updateDefaultPaymentMethod = async (id, source) => {
			
		let formData = new FormData();
		formData.append("id", id);
		formData.append("source", source);
		const result = await Axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}updateDefaultPaymentMethod`, formData);
		
		return result.data;
};

	detachPaymentMethod = async (id, source) => {
			
		let formData = new FormData();
		formData.append("id", id);
		formData.append("source", source);
		const result = await Axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}detachPaymentMethod`, formData);
		
		return result.data;
};
	createSubscribe = async (client, priceSecret) => {
			
		let formData = new FormData();
		formData.append("client", client);
		formData.append("priceSecret", priceSecret);
		const result = await Axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}createSubscribe`, formData);
	
	return result.data;
};

	render() {
		const contextValue = {
			rootState: this.state,
			loginAdmin: this.loginAdmin,
			logoutAdmin: this.logoutAdmin,
			isLoggedInAdmin: this.isLoggedInAdmin,
			updateNaviguationBar: this.updateNaviguationBar,
			setSlideShow: this.setSlideShow,
			updateSlide: this.updateSlide,
			createSlide: this.createSlide,
			delSlide: this.delSlide,
			setWebTVS: this.setWebTVS,
			setPlaylists: this.setPlaylists,
			setHighlights: this.setHighlights,
			updateHighlight: this.updateHighlight,
			createHighlight: this.createHighlight,
			delHighlight: this.delHighlight,
			updateSportBanner: this.updateSportBanner,
			updateCountryBanner: this.updateCountryBanner,
			updatePassword: this.updatePassword,
			updateVariable: this.updateVariable,
			updateStatus: this.updateStatus,
			updateSlidePerPage: this.updateSlidePerPage,
			pushNotificationCreate: this.pushNotificationCreate,
			createProduct: this.createProduct,
			createCoupon: this.createCoupon,
			archiveProduct: this.archiveProduct,
			updateProduct: this.updateProduct,
			updateStripeCustomer: this.updateStripeCustomer,
			createStripeCustomerDefault: this.createStripeCustomerDefault,
			attachPaymentMethod: this.attachPaymentMethod,
			updateDefaultPaymentMethod: this.updateDefaultPaymentMethod,
			detachPaymentMethod: this.detachPaymentMethod,
			createSubscribe: this.createSubscribe,
			deleteCoupon: this.deleteCoupon,
			updateCoupon: this.updateCoupon,
			createAdvantage: this.createAdvantage,
			deleteAdvantage: this.deleteAdvantage,
			updateAdvantage: this.updateAdvantage
		};
		return (
			<AdminContext.Provider value={contextValue}>
				{this.props.children}
			</AdminContext.Provider>
		);
	}
}

export default AdminContextProvider;
