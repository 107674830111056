import React, { createContext, Component } from "react";
import axios from "axios";

export const MyContext = createContext();

const Axios = axios.create();

class MyContextProvider extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAuth: null,
			theUser: null
		};
		//this.isLoggedIn(this);
	}

	//Root State
	registerUser = async (user) => {
		// Sending the user registration request
		const register = await Axios.post(
			`${process.env.REACT_APP_API_URL}register`,
			{
				first_name: user.firstName,
				last_name: user.lastName,
				username: user.username,
				datebirth: user.datebirth,
				gender: user.gender,
				email: user.email,
				password: user.password,
				sport_check: { ...user.sportCheck },
				rules: user.rules,
				fuchs_offers: user.fuchsOffers,
				partner_offers: user.partnerOffers,
				lang: user.lang,
				favorites_clubs: user.favoriteClubs,
				lang_id: user.language,
				account_type: user.accountType,
			}
		);

		return register.data;
	};

	logoutUser = () => {
		localStorage.removeItem("loginToken");
		this.setState({
			...this.state,
			isAuth: false,
			theUser: null
		});
	};

	loginUser = async (user) => {
		// Sending the user Login request
		const login = await Axios.post(`${process.env.REACT_APP_API_URL}login`, {
			email: user.email,
			password: user.password,
		});
		return login.data;
	};

	isLoginAuth = async () => {
		const isLogged = localStorage.getItem("loginToken");
		if (isLogged) {
			try {
				Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + isLogged;
				if (process.env.REACT_APP_ENV === "production") {
					Axios.defaults.headers.common["Authorization"] = "Bearer " + isLogged;
				}
				const {data} = await Axios.get(`${process.env.REACT_APP_API_URL}userInfo`);
				return !!(data.success && data.user);
			} catch (e) {
				return false;
			}
		} else {
			return false;
		}
	};

	isLoggedIn = async () => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				//Adding JWT token to axios default header
				Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;
				if (process.env.REACT_APP_ENV === "production") {
					Axios.defaults.headers.common["Authorization"] = "Bearer " + loginToken;
				}
				// Fetching the user information
				const { data } = await Axios.get(`${process.env.REACT_APP_API_URL}userInfo`);
				// If user information is successfully received
				if (data.success && data.user) {
					this.setState({
						...this.state,
						isAuth: true,
						theUser: data.user
					});
					return false;
				} else {
					this.setState({
						...this.state,
						isAuth: false,
						theUser: null
					});
					return false;
				}
			} catch (e) {
				this.setState({
					...this.state,
					isAuth: false,
					theUser: null
				});
				return false;
			}

		} else {
			this.setState({
				...this.state,
				isAuth: false,
				theUser: null
			});
			return false;
		}
	};

	updateUserData = async (user) => {
		// Sending the user registration request
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}setUserData`,
			{
				first_name: user.firstName,
				last_name: user.lastName,
				username: user.username,
				email: user.email,
				country: user.country,
				zip_code: user.zipCode,
				gender: user.gender,
				data: user.datebirth,
				lang_id: user.language
			}
		);

		return update.data;
	};

	updateInfoUser = async (dataObject) => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}updateInfoUser`, dataObject
		);
		return update.data;
	};

	updateAvatar = async (formData) => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}updateAvatar`,
			formData,
			{
				headers: {
					"content-type": "multipart/form-data",
				},
			}
		);

		return update.data;
	};

	removeAvatar = async () => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}removeAvatar`
		);
		return update.data;
	};

	updateIdCard = async (formData) => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}updateIdCard`,
			formData,
			{
				headers: {
					"content-type": "multipart/form-data",
				},
			}
		);

		return update.data;
	};

	removeIdCard = async () => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}removeIdCard`
		);
		return update.data;
	};

	updatePassword = async (password) => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}setUserSecurity`,
			{ password }
		);

		return update.data;
	};

	updateUserNotif = async (user) => {
		// Sending the user registration request
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}setUserNotification`,
			{
				fuchs_offers: user.newsletterNotif,
				partner_offers: user.partnerNotif,
				mail_news: user.emailNotif,
			}
		);

		return update.data;
	};

	updateUserPreference = async (sports, favorites) => {
		// Sending the user registration request
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}changeSportPreference`, {
				sport: { ...sports },
				clubs: { ...favorites }
			}
		);
		return update.data;
	};

	toggleFollow = async (club, mode) => {
		// Sending the user registration request
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}handleFollow`,
			{
				club,
				mode,
			}
		);
		return update.data;
	};

	removeAccount = async (password, lang) => {
		const update = await Axios.post(
			`${process.env.REACT_APP_API_URL}removeAccount`,
			{
				password: password,
				lang: lang
			}
		);
		return update.data;
	};

	orderFuchsProducts = async (clientSecret, productId) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken && clientSecret && productId) {
			try {
				let formData = new FormData();
				formData.append("key", loginToken);
				formData.append("client", clientSecret);
				formData.append("product_id", productId);
				const result = await Axios.post(`${process.env.REACT_APP_API_STRIPE}orderFuchsProducts`, formData);
				return result.data;

			} catch (e) {
				console.log(e);
			}
		}

		return false;
	};

	createPaymentIntent = async (clientSecret, paymentId, checkedProductId) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken && clientSecret && checkedProductId) {
			try {
				let formData = new FormData();
				formData.append("key", loginToken);
				formData.append("client", clientSecret);
				formData.append("payment_method", paymentId);
				formData.append("product_id", checkedProductId);
				const result = await Axios.post(`${process.env.REACT_APP_API_STRIPE}createPaymentIntent`, formData);
				return result.data;

			} catch (e) {
				console.log(e);
			}
		}

		return false;
	};

	createSubscribe = async (client, priceSecret, productSecret, paymentMethod = "", coupon = "") => {
			
		let formData = new FormData();
		formData.append("client", client);
		formData.append("priceSecret", priceSecret);
		formData.append("productSecret", productSecret);
		if (paymentMethod) {
			formData.append("payment_method", paymentMethod);
		}
		if (coupon) {
			formData.append("coupon", coupon);
		}
		const result = await Axios.post(`${process.env.REACT_APP_API_STRIPE}createSubscribe`, formData);
	
		return result.data;
	};

	sendInviteEmail = async (email, videoId) => {
			
		let formData = new FormData();
		formData.append("email", email);
		formData.append("videoId", videoId);
		const result = await Axios.post(`${process.env.REACT_APP_API_STRIPE}sendInviteEmail`, formData);

		return result.data;
	};

	deleteInviteEmail = async (email, videoId) => {
					
		let formData = new FormData();
		formData.append("email", email);
		formData.append("videoId", videoId);
		const result = await Axios.post(`${process.env.REACT_APP_API_STRIPE}deleteInviteEmail`, formData);

		return result.data;
	};

	updateStripeCustomer = async (clientSecret, user) => {
	
		let formData = new FormData();
		formData.append("user", JSON.stringify(user));
		formData.append("client", clientSecret);
		const update = await Axios.post(`${process.env.REACT_APP_API_STRIPE}updateStripeCustomer`, formData);

		return update.data;

	};

	attachPaymentMethod = async (id, source) => {
			
		let formData = new FormData();
		formData.append("id", id);
		formData.append("source", source);
		const result = await Axios.post(`${process.env.REACT_APP_API_STRIPE}attachPaymentMethod`, formData);

		return result.data;
	};

	updateDefaultPaymentMethod = async (id, source) => {
			
		let formData = new FormData();
		formData.append("id", id);
		formData.append("source", source);
		const result = await Axios.post(`${process.env.REACT_APP_API_STRIPE}updateDefaultPaymentMethod`, formData);
		
		return result.data;
	};

	detachPaymentMethod = async (id, source) => {
			
		let formData = new FormData();
		formData.append("id", id);
		formData.append("source", source);
		const result = await Axios.post(`${process.env.REACT_APP_API_STRIPE}detachPaymentMethod`, formData);
		
		return result.data;
};
	updateSelectedGroupClub = async (group, club) => {
		let formData = new FormData();
		if (group !== null) {
			formData.append("group", group);
		}
		if (club !== null) {
			formData.append("club", club);
		}
		const result = await Axios.post(`${process.env.REACT_APP_API_STRIPE}updateSelectedGroupClub`, formData);
		return result.data;
	};

	createClubRecord = async (clubId) => {
		let formData = new FormData();
		formData.append("clubId", clubId);
		const result = await Axios.post(`${process.env.REACT_APP_API_STRIPE}createClubRecord`, formData);
		return result.data;
	};

	render() {
		const contextValue = {
			rootState: this.state,
			isLoggedIn: this.isLoggedIn,
			isLoginAuth: this.isLoginAuth,
			registerUser: this.registerUser,
			loginUser: this.loginUser,
			logoutUser: this.logoutUser,
			updateUserData: this.updateUserData,
			updateAvatar: this.updateAvatar,
			removeAvatar: this.removeAvatar,
			removeAccount: this.removeAccount,
			updateIdCard: this.updateIdCard,
			removeIdCard: this.removeIdCard,
			updatePassword: this.updatePassword,
			updateUserNotif: this.updateUserNotif,
			updateUserPreference: this.updateUserPreference,
			toggleFollow: this.toggleFollow,
			orderFuchsProducts: this.orderFuchsProducts,
			createPaymentIntent: this.createPaymentIntent,
			createSubscribe: this.createSubscribe,
			updateStripeCustomer: this.updateStripeCustomer,
			attachPaymentMethod: this.attachPaymentMethod,
			detachPaymentMethod: this.detachPaymentMethod,
			updateDefaultPaymentMethod: this.updateDefaultPaymentMethod,
			updateSelectedGroupClub :this.updateSelectedGroupClub,
			sendInviteEmail: this.sendInviteEmail,
			deleteInviteEmail: this.deleteInviteEmail,
			updateInfoUser: this.updateInfoUser,
			createClubRecord: this.createClubRecord
		};
		return (
			<MyContext.Provider value={contextValue}>
				{this.props.children}
			</MyContext.Provider>
		);
	}
}

export default MyContextProvider;
