import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { isEmpty } from "../Utils";
import EventStyle1 from "./EventStyle1";
import EventStyle2 from "./EventStyle2";
import EventStyle3 from "./EventStyle3";
import Slider from "react-slick";
import RightArrow from "../slider/RightArrow";
import LeftArrow from "../slider/LeftArrow";
import {getSettings} from "../../actions/setting.action";
import EmptyEventStyle from "./EmptyEventStyle";

const Banner = () => {
	const dispatch = useDispatch();
	const events = useSelector(
		(state) => state.slideShowReducer.slide
	);
	const settingReducer = useSelector(
		(state) => state.settingReducer
	);
	const [countPerPage, setCountPerPage] = useState(2);

	useEffect(() => {
		dispatch(getSettings())
	}, [dispatch]);

	useEffect(() => {
		if (settingReducer.slider_count_per_page) {
			setCountPerPage(parseInt(settingReducer.slider_count_per_page))
		}
	}, [settingReducer]);

	let settings = {
		dots: true,
		//autoplay: true,
		infinite: false,
		autoplaySpeed: 5000,
		pauseOnHover: false,
		speed: 500,
		slidesToShow: countPerPage,
		slidesToScroll: countPerPage,
		prevArrow: <LeftArrow />,
		nextArrow: <RightArrow />,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<div className="banner" id="scrollButton">
			<div className={`events-container ${countPerPage ? "multi" : ""}`}>
				{!isEmpty(events) && (
					<Slider {...settings}>
						{!isEmpty(events) &&
							events.map((event, index) => {
								return (
									<React.Fragment key={index}>
										{event.type === "1" && <EventStyle1 event={event} />}
										{event.type === "2" && <EventStyle2 event={event} />}
										{event.type === "3" && <EventStyle3 event={event} />}
										{event.type === "4" && <EmptyEventStyle event={event} />}
									</React.Fragment>
								);
							})}
					</Slider>
				)}
			</div>
		</div>
	);
};

export default Banner;
