import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Switch from "react-switch";
import { MyContext } from "../../contexts/MyContext";
import { isEmpty } from "../Utils";

import parse from "html-react-parser";

const NotificationParam = () => {
	const [emailNotif, setEmailNotif] = useState(false);
	const [newsletterNotif, setNewsletterNotif] = useState(false);
	const [partnerNotif, setPartnerNotif] = useState(false);

	const { rootState, updateUserNotif, isLoggedIn } = useContext(MyContext);
	const { theUser } = rootState;
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);

	//DEFAULT VALUE
	useEffect(() => {
		if (!isEmpty(theUser)) {
			theUser.mailNews === "yes" ? setEmailNotif(true) : setEmailNotif(false);
			theUser.fuchsOffers === "yes"
				? setNewsletterNotif(true)
				: setNewsletterNotif(false);
			theUser.partnersOffer === "yes"
				? setPartnerNotif(true)
				: setPartnerNotif(false);
		}
	}, [theUser]);

	const updateNotification = async (newsletter, partner, email) => {
		let user = {
			newsletterNotif: newsletter ? "yes" : "no",
			partnerNotif: partner ? "yes" : "no",
			emailNotif: email ? "yes" : "no",
		};

		const data = await updateUserNotif(user);

		if (data.success) {
			isLoggedIn();
			console.log("success");
		} else {
			console.log(data.message);
		}
	};

	return (
		<section className="notifications">
			<h3>
				{translations && !isEmpty(translations["notifications"])
					? parse(translations["notifications"])
					: "notifications"
				}
			</h3>

			<article className="email">
				<h4>
					{translations && !isEmpty(translations["registerEmail"])
						? parse(translations["registerEmail"])
						: "registerEmail"
					}
				</h4>
				<div className="input">
					<label htmlFor="emailNotif">
                    {translations && !isEmpty(translations["notifyByEmail"])
                        ? parse(translations["notifyByEmail"])
                        : "notifyByEmail"
                    }
					</label>
					<Switch
						onChange={() => updateNotification(newsletterNotif, partnerNotif, !emailNotif)}
						checked={emailNotif}
						uncheckedIcon={false}
						checkedIcon={false}
						offColor="#d6d6d6"
						onColor="#679bff"
						id="emailNotif"
					/>
				</div>
			</article>
			<article className="newsletter">
				<h4>Newsletter</h4>
				<div className="input">
					<label htmlFor="newsletterNotif">
                        {translations && !isEmpty(translations["subscribeNews"])
                            ? parse(translations["subscribeNews"])
                            : "subscribeNews"
                        }
					</label>
					<Switch
						onChange={() => updateNotification(!newsletterNotif, partnerNotif, emailNotif)}
						checked={newsletterNotif}
						uncheckedIcon={false}
						checkedIcon={false}
						offColor="#d6d6d6"
						onColor="#679bff"
						id="newsletterNotif"
					/>
				</div>
			</article>
			<article className="partner">
				<h4>Offre Partenaires</h4>
				<div className="input">
					<label htmlFor="partnerNotif">
						{translations && !isEmpty(translations["followPartner"])
							? parse(translations["followPartner"])
							: "followPartner"
						}
					</label>
					<Switch
						onChange={() => updateNotification(newsletterNotif, !partnerNotif, emailNotif)}
						checked={partnerNotif}
						uncheckedIcon={false}
						checkedIcon={false}
						offColor="#d6d6d6"
						onColor="#679bff"
						id="partnerNotif"
					/>
				</div>
			</article>
		</section>
	);
};

export default NotificationParam;
