import React from "react";
import {isEmpty} from "../Utils";
import parse from "html-react-parser";
import {useSelector} from "react-redux";

const CheckBoxesRadio = ({values, checked, onChange, name}) => {

    const translationsReducer = useSelector((state) => state.langReducer.translationsReducer);
    const translations = translationsReducer.translations;

    return (
        <div className="input radio">
            {!isEmpty(values) && values.map((value, key) => {
                return (
                    <fieldset key={key}>
                        <input
                            type="radio"
                            name={name}
                            value={value.value}
                            id={value.name}
                            checked={checked === value.name}
                            onClick={(event) => onChange(event.target.value)}
                            readOnly
                        />
                        <label
                            className="radio"
                            htmlFor={value.name}
                        >
                            <span>{translations && !isEmpty(translations[value.translate]) ? parse(translations[value.translate]) : value.translate}</span>

                            <div className="box">
                                <svg
                                    width="15"
                                    height="12"
                                    viewBox="0 0 15 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M14.8695 1.52424L5.51421 11.8558C5.43136 11.9481 5.31857 12 5.2009 12C5.08324 12 4.97045 11.9481 4.88759 11.8558L0.130532 6.60232C0.0469906 6.51082 0 6.38625 0 6.25631C0 6.12637 0.0469906 6.00181 0.130532 5.9103L0.748331 5.22802C0.83119 5.13576 0.943981 5.08387 1.06164 5.08387C1.17931 5.08387 1.2921 5.13576 1.37496 5.22802L5.19649 9.44839L13.625 0.140195C13.7996 -0.0467318 14.0771 -0.0467318 14.2517 0.140195L14.8695 0.832218C14.953 0.923724 15 1.04829 15 1.17823C15 1.30817 14.953 1.43273 14.8695 1.52424Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        </label>
                    </fieldset>
                )
            })}
        </div>
    );
};

export default CheckBoxesRadio;