import React, {useEffect, useRef, useState} from "react";
import {isEmpty, isFunction} from "../Utils";
import DatePicker from "react-datepicker";
import moment, {isDate} from "moment";
import "react-datepicker/dist/react-datepicker.css";

const InputData = ({handleClick, handleChange, name, value, label, type, errorText, regex, minDate, maxDate, placeholder, error: propError = false}) => {
    const [selected, setSelected] = useState(false);
    const [error, setError] = useState(false);
    const [formatDate, setFormatDate] = useState();
    const [localValue, setLocalValue] = useState();
    const [isDateOpen, setIsDateOpen] = useState(false);
    const inputRef = useRef();
    const onClick = (event) => {

    };

    useEffect(() => {
        setError(propError)
    }, [propError]);

    useEffect(() => {
        if (!isEmpty(value) && isDate(new Date(value))) {
            setLocalValue(moment(new Date(value)).format('DD/MM/YYYY'));
        }
    }, [value]);

    useEffect(() => {
        if(isFunction(handleChange) && !isEmpty(formatDate)) {
            handleChange(name, formatDate)
        }
    }, [formatDate]);

    const handleFormatInputDate = (date) => {
        let onlyNumber = date.replace(/\D/g, '');
        let cardValue = onlyNumber.match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
        let	year = cardValue[3];
        let	month = cardValue[2];
        let	day = cardValue[1];
        let format;

        if (year.length >= 1) {
            if (year.length >= 4) {
                if (year < 1930) {
                    year = 1930;
                } else if (year > 2021) {
                    year = 2021
                }
                //set state
                setFormatDate(year + "-" + month + "-" + day);
            }
            format = day + "/" + month + "/" + year;
        } else if (month.length >= 1) {
            if (month <= 12) {
                format = day + "/" + month;
            } else {
                format = day + "/" + 12;
            }
        } else if (day.length >= 1) {
            if (day <= 31) {
                format = day;
            } else {
                format = 31;
            }
        } else {
            format = "";
        }

        setLocalValue(format);
        if (inputRef.current) {
            inputRef.current.value = format;
        }
    };

    const handleClickOutside = (event) => {
        if (event.target.closest('.react-datepicker') === null && event.target.closest('.datepicker-toggle-button') === null) {
            if (isDateOpen) {
                setIsDateOpen(false);
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return (
        <div className={`input ${selected ? "select" : ""} ${error ? "error" : ""}`}>
            {label && (
                <label>
                    {label}
                </label>
            )}
            <input
                ref={inputRef}
                type={type}
                onClick={(e) => onClick(e)}
                defaultValue={localValue}
                onFocus={() => setSelected(true)}
                onBlur={() => setSelected(false)}
                placeholder={placeholder}
                onChange={(e) => handleFormatInputDate(e.target.value)}
            />
            {error && (
                <div className="errorDisplay">
                    {errorText}
                </div>
            )}
            {isDateOpen && (
                <DatePicker
                    selected={!isEmpty(formatDate) ? new Date(formatDate) : ""}
                    onChange={(date) => {
                        handleFormatInputDate(moment(date).format("DD/MM/YYYY"))
                    }}
                    inline
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    minDate={minDate ? minDate : undefined}
                    maxDate={maxDate ? maxDate : undefined}
                />
            )}
            <span className="datepicker-toggle-button"
                  onClick={(e) => setIsDateOpen(!isDateOpen)}
            />
        </div>
    );
};

export default InputData;