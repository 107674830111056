import {
	CLEAR_PLAYLISTS,
	GET_PLAYLIST,
	GET_FUCHS_PLAYLISTS,
	CHANGE_FUCHS_PLAYLISTS,
	GET_COMMAND_PLAYLIST,
	CLEAR_COMMAND_PLAYLISTS,
	CLEAR_CLUB_PLAYLISTS,
	GET_FAVOURITE_CLUBS_PLAYLIST,
	GET_CLUB_PLAYLISTS, GET_PARENT_COMMAND_PLAYLIST, CLEAR_PARENT_COMMAND_PLAYLISTS,CLEAR_FAVOURITE_CLUBS_PLAYLISTS
} from "../actions/playlist.action";
import { combineReducers } from "redux";

const initialState = [];

function playlistReducer(state = initialState, action) {
	switch (action.type) {
		case GET_PLAYLIST:
			return action.payload;
		case CLEAR_PLAYLISTS:
			return initialState;
		default:
			return state;
	}
}
function favouriteClubsPlaylistReducer(state = initialState, action) {
	switch (action.type) {
		case GET_FAVOURITE_CLUBS_PLAYLIST:
			return action.payload;
		case CLEAR_FAVOURITE_CLUBS_PLAYLISTS:
			return initialState;
		default:
			return state;
	}
}

function playlistCommandReducer(state = initialState, action) {
	switch (action.type) {
		case GET_COMMAND_PLAYLIST:
			return action.payload;
		case CLEAR_COMMAND_PLAYLISTS:
			return initialState;
		default:
			return state;
	}
}

function playlistParentCommandReducer(state = initialState, action) {
	switch (action.type) {
		case GET_PARENT_COMMAND_PLAYLIST:
			return action.payload;
		case CLEAR_PARENT_COMMAND_PLAYLISTS:
			return initialState;
		default:
			return state;
	}
}

function fuchsPlaylistsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_FUCHS_PLAYLISTS:
			return action.payload;
		case CHANGE_FUCHS_PLAYLISTS:
			return action.payload;
		default:
			return state;
	}
}

function clubPlaylistsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_CLUB_PLAYLISTS:
			return action.payload;
		case CLEAR_CLUB_PLAYLISTS:
			return initialState;
		default:
			return state;
	}
}

export default combineReducers({
	playlistReducer,
	fuchsPlaylistsReducer,
	playlistCommandReducer,
	clubPlaylistsReducer,
	playlistParentCommandReducer,
	favouriteClubsPlaylistReducer
});