import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
	getSlideshow,
	reorderSlideshow,
} from "../../../actions/slideshow.action";
import EmptyEventStyle from "../../../components/explorer/EmptyEventStyle";
import EventStyle1 from "../../../components/explorer/EventStyle1";
import EventStyle2 from "../../../components/explorer/EventStyle2";
import EventStyle3 from "../../../components/explorer/EventStyle3";
import {isEmpty} from "../../../components/Utils";
import {AdminContext} from "../../../contexts/AdminContext";

const HandleSlideShow = () => {
	let events = useSelector((state) => state.slideShowReducer.slide);
	const [allSlides, setAllSlides] = useState(null);
	useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_URL}getSlideShow&v=no`).then((res) => {
			const slides = res.data.slide;
			setAllSlides(slides);
		});
	}, []);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getSlideshow("yes"));
	}, [dispatch]);
	const handleDrag = async (result) => {
		const {source, destination} = result;

		if (!destination) {
			return;
		}
		if (source.droppableId === destination.droppableId) {
			if (source.droppableId === "slides") {
				const items = Array.from(allSlides);
				const [reorderedItem] = items.splice(result.source.index, 1);
				items.splice(result.destination.index, 0, reorderedItem);
				let slides = items;
				slides = slides.map((slide, index) => {
					slide.order = index + 9999;
					return slide;
				});
				setAllSlides(slides);
			} else if (source.droppableId === "slideshow") {
				const items = Array.from(events);
				const [reorderedItem] = items.splice(result.source.index, 1);
				items.splice(result.destination.index, 0, reorderedItem);

				events = items;
				events = events.map((event, index, array) => {
					event.order = array.length - index;
					return event;
				});

				dispatch(reorderSlideshow({ result: "ok", slide: events }));
			}
		} else {
			const move = (
				source,
				destination,
				droppableSource,
				droppableDestination
			) => {
				if (isEmpty(destination)) {
					const sourceClone = Array.from(source);

					const destClone = Array.from([]);
					const [removed] = sourceClone.splice(droppableSource.index, 1);

					destClone.splice(droppableDestination.index, 0, removed);

					const result = {};
					result[droppableSource.droppableId] = sourceClone;
					result[droppableDestination.droppableId] = destClone;
					return result;
				} else {
					const sourceClone = Array.from(source);
					const destClone = Array.from(destination);
					const [removed] = sourceClone.splice(droppableSource.index, 1);

					destClone.splice(droppableDestination.index, 0, removed);

					const result = {};
					result[droppableSource.droppableId] = sourceClone;
					result[droppableDestination.droppableId] = destClone;
					return result;
				}
			};

			const result = move(
				source.droppableId === "slides" ? allSlides : events,
				destination.droppableId === "slides" ? allSlides : events,
				source,
				destination
			);

			let { slides, slideshow } = result;

			slideshow = slideshow.map((slide, index, array) => {
				slide.order = array.length - index;
				slide.visible = "yes";
				return slide;
			});
			slides = slides.map((slide, index) => {
				slide.order = index + 9999;
				slide.visible = "no";
				return slide;
			});
			dispatch(reorderSlideshow({ result: "ok", slide: slideshow }));
			setAllSlides(slides);
		}
	};

	const { setSlideShow } = useContext(AdminContext);
	const submitForm = async () => {

		const all = events.concat(allSlides);
		document.querySelector(".loading-handle-slide svg").classList.add("display");
		const data = await setSlideShow(all);

		if (data.success) {
			if (document.querySelector(".loading-handle-slide svg")) {
				setTimeout(() => {
					document.querySelector(".submit span").innerHTML = "Enregistrer";
					document.querySelector(".loading-handle-slide svg").classList.remove("display");
				}, 1000);
			}
			console.log("ok");
		} else {
			if (document.querySelector(".loading-handle-slide svg")) {
				setTimeout(() => {
					document.querySelector(".submit span").innerHTML = "Error";
					document.querySelector(".loading-handle-slide svg").classList.remove("display");
				}, 1000);
			}
			console.log(data);
		}

	};
	return (
		<div className="bo-handle-slideshow">
			<div className="submit">
				<button onClick={submitForm}>
					<span>Enregistrer</span>
					<div className="loading-handle-slide">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
						>
							<path d="M13.75 22c0 .966-.783 1.75-1.75 1.75s-1.75-.784-1.75-1.75.783-1.75 1.75-1.75 1.75.784 1.75 1.75zm-1.75-22c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm10 10.75c.689 0 1.249.561 1.249 1.25 0 .69-.56 1.25-1.249 1.25-.69 0-1.249-.559-1.249-1.25 0-.689.559-1.25 1.249-1.25zm-22 1.25c0 1.105.896 2 2 2s2-.895 2-2c0-1.104-.896-2-2-2s-2 .896-2 2zm19-8c.551 0 1 .449 1 1 0 .553-.449 1.002-1 1-.551 0-1-.447-1-.998 0-.553.449-1.002 1-1.002zm0 13.5c.828 0 1.5.672 1.5 1.5s-.672 1.501-1.502 1.5c-.826 0-1.498-.671-1.498-1.499 0-.829.672-1.501 1.5-1.501zm-14-14.5c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2zm0 14c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2z" />
						</svg>
					</div>
				</button>
			</div>
			<DragDropContext onDragEnd={handleDrag}>
				<Droppable droppableId="slides">
					{(provided) => (
						<section
							className="slides"
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							<h3>Vos slides</h3>
							{!isEmpty(allSlides) &&
								allSlides.map((event, index) => {
									return (
										<Draggable key={event.id} draggableId={event.id} index={index}>
											{(provided) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
												>
													{event.type === "1" && <EventStyle1 event={event} />}
													{event.type === "2" && <EventStyle2 event={event} />}
													{event.type === "3" && <EventStyle3 event={event} />}
													{event.type === "4" && <EmptyEventStyle event={event} />}
												</div>
											)}
										</Draggable>
									);
								})}
							{provided.placeholder}
						</section>
					)}
				</Droppable>
				<Droppable droppableId="slideshow">
					{(provided) => (
						<section
							className="slideshow"
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							<h3>Votre slideshow</h3>
							{!isEmpty(events) &&
								events
									.map((event, index) => {
										return (
											<Draggable key={event.id} draggableId={event.id} index={index}>
												{(provided) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														data-number={(index + 1).toString()}
														className="slide-container"
													>
														{event.type === "1" && <EventStyle1 event={event} />}
														{event.type === "2" && <EventStyle2 event={event} />}
														{event.type === "3" && <EventStyle3 event={event} />}
														{event.type === "4" && <EmptyEventStyle event={event} />}
													</div>
												)}
											</Draggable>
										);
									})}
							{provided.placeholder}
						</section>
					)}
				</Droppable>
			</DragDropContext>
		</div>
	);
};

export default HandleSlideShow;
