import React, {Fragment, useContext, useEffect, useState} from "react";
import {isEmpty} from "../../components/Utils";
import moment from "moment";
import {AdminContext} from "../../contexts/AdminContext";
import axios from "axios";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

const AddCoupon = ({successCreate}) => {
    const {createCoupon} = useContext(AdminContext);
    const [dataForm, setDataForm] = useState({});
    const [accessProduct, setAccessProduct] = useState([]);
	const [accessOptions, setAccessOptions] = useState([]);
	const [updated, setUpdated] = useState(false)
	const [select, setSelect] = useState(null)
    const [errors, setErrors] = useState({});
    const [selectedDate, setSelectedDate] = useState(null);

    console.log(dataForm);
    console.log(accessProduct);
	useEffect(() => {
		const fetch = async () => {
			const loginAdminToken = localStorage.getItem("loginAdminToken");
			if (loginAdminToken) {
				let formData = new FormData();
				axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginAdminToken;
				formData.append("include_prices", "0");
				try {
					return await axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}getProducts`, formData)
						.then((res) => {
							if (res.data.result === "ok" && !isEmpty(res.data.response)) {
								setAccessOptions(res.data.response);
							}
						})
						.catch((error) => {
							console.log(error)
						});

				} catch (e) {
					console.log(e);
				}
			}
		};

		fetch();

	}, []);

    useEffect(() => {
        setDataForm({...dataForm, ['redeem_by']: moment(selectedDate).unix()});
	}, [selectedDate]);

    const incrementAccess = () => {
        setAccessProduct([
            ...accessProduct,
            {["id"]: moment().unix()}
        ]);
    };

    const decrementAccess = (key) => {
        let copyAccess = [...accessProduct];
        copyAccess.splice(key, 1)
        setAccessProduct(copyAccess);
    };

    const changeHandler = (event) => {
        setDataForm({...dataForm, [event.target.name]: event.target.value});
    };

    const changeAccessHandler = (event, key) => {
        let copyAccess = [...accessProduct];
        copyAccess[key][event.target.name] = event.target.value;
        setAccessProduct(copyAccess);
    };

    const validateForm = async () => {
        let valid = {};
        if (isEmpty(dataForm.name) || dataForm.name < 1) {
            setErrors(prevState => ({...prevState, name: true}));
            valid["name"] = true;
        } else {
            setErrors(prevState => ({...prevState, name: false}));
            valid["name"] = false;
        }
        if (isEmpty(dataForm.type) || dataForm.type.length < 1) {
            setErrors(prevState => ({...prevState, type: true}));
            valid["type"] = true;
        } else {
            setErrors(prevState => ({...prevState, type: false}))
            valid["type"] = false;
        }
        if (dataForm.type ==="percent_off" && (isEmpty(dataForm.percent_off) || dataForm.percent_off < 1)) {
            setErrors(prevState => ({...prevState, percent_off: true}));
            valid["percent_off"] = true;
        } else {
            setErrors(prevState => ({...prevState, percent_off: false}));
            valid["percent_off"] = false;
        }
        if (dataForm.type ==="amount_off" && (isEmpty(dataForm.amount_off) || dataForm.amount_off < 1)) {
            setErrors(prevState => ({...prevState, amount_off: true}));
            valid["amount_off"] = true;
        } else {
            setErrors(prevState => ({...prevState, amount_off: false}));
            valid["amount_off"] = false;
        }
        if (dataForm.type ==="amount_off" && (isEmpty(dataForm.currency) || dataForm.currency < 1)) {
            setErrors(prevState => ({...prevState, currency: true}));
            valid["currency"] = true;
        } else {
            setErrors(prevState => ({...prevState, currency: false}));
            valid["currency"] = false;
        }
        return valid;
    }

    const submitForm = async (event) => {
        event.preventDefault();
        let valid = await validateForm();

        if (Object.values(valid).includes(true)) {
            return false
        }

        let data = {
            ...dataForm,
            ["applies_to"]: accessProduct
        }

        const res = await createCoupon(data);

        if (!isEmpty(res)) {
			setTimeout(() => {
				setUpdated(true);
			}, 10);

			setTimeout(() => {
				setUpdated(false);
                successCreate();
			}, 3000);
        }
    };
    return (
        <div className="add_coupon">
            <article>
                <form onSubmit={submitForm} noValidate>
                   
                        <div className={`input ${select === "name" ? "select" : ""} ${errors.name ? "error" : ""}`}>
                            <label>Name*</label>
                            <input
                                type="text"
                                id="name"
                                required
                                name="name"
                                onBlur={(e) => setSelect(null)}
                                onClick={(e) => setSelect(e.target.id)}
                                onChange={changeHandler}
                            />
                        </div>

                        <div className={`input ${select === "type" ? "select" : ""} ${errors.type ? "error" : ""}`}>
                            <label>Type*</label>
                            <select
                                id="type"
                                required
                                name="type"
                                onChange={changeHandler}
                            >
                                <option/>
                                <option value="amount_off">Fixed Amount Discount</option>
                                <option value="percent_off">Percentage Discount</option>
                            </select>
                        </div>
                    
                    {dataForm.type === "percent_off"  && (
                            <div className="double-grid">
                            <div className={`input ${select === "percent_off" ? "select" : ""} ${errors.percent_off ? "error" : ""}`}>
                                <label>Percentage Off*</label>
                                <input
                                type="number"
                                id="percent_off"
                                placeholder="0.0"
                                min="0"
                                step=".5"
                                name="percent_off"
                                required
                                onBlur={(e) => {
                                    setSelect(null);
                                    e.target.value = parseFloat(e.target.value).toFixed(1);
                                }}
                                onClick={(e) => setSelect(e.target.id)}
                                onChange={changeHandler}
                            />
                            </div>
                        </div>
                    )}

                    {dataForm.type === "amount_off"  && (
                        <div className="double-grid">
                        <div className={`input ${select === "amount_off" ? "select" : ""} ${errors.amount_off ? "error" : ""}`}>
                            <label>Discount Amount*</label>
                            <input
                                type="number"
                                id="amount_off"
                                placeholder="0"
                                min="0"
                                step="1"
                                name="amount_off"
                                required
                                onClick={(e) => setSelect(e.target.id)}
                                onChange={changeHandler}
                            />
                        </div>
                        <div className={`input ${select === "currency" ? "select" : ""} ${errors.currency ? "error" : ""}`}>
                            <label>Currency*</label>
                            <select
                                id="currency"
                                required
                                name="currency"
                                defaultValue="fan"
                                onBlur={(e) => setSelect(null)}
                                onClick={(e) => setSelect(e.target.id)}
                                onChange={changeHandler}
                            >
                            <option/>
                            <option value="eur">EUR</option>
                            </select>
                        </div>
                    </div>
                    )}
                        <div className="double-grid">
                            <div className={`input ${select === "duration" ? "select" : ""} ${errors.duration ? "error" : ""}`}>
                                <label>Duration*</label>
                                <select
                                    id="duration"
                                    required
                                    name="duration"
                                    onChange={changeHandler}
                                >
                                    <option/>
                                    <option value="forever">Forever</option>
                                    <option value="once">Once</option>
                                    <option value="repeating">Repeating</option>
                                </select>
                            </div>

                            {dataForm.duration === "repeating"  && (
                            <div className={`input ${select === "duration_in_months" ? "select" : ""} ${errors.duration_in_months ? "error" : ""}`}>
                                <label>Duration in months*</label>
                                <input
                                type="number"
                                id="duration_in_months"
                                placeholder="0"
                                min="0"
                                step="1"
                                name="duration_in_months"
                                required
                                onChange={changeHandler}
                            />
                            </div>
                        
                    )}
                        </div>
                        <div className={`input ${select === "max_redemptions" ? "select" : ""} ${errors.max_redemptions ? "error" : ""}`}>
                                <label>Limit times redeemed</label>
                                <input
                                type="number"
                                id="max_redemptions"
                                placeholder="0"
                                min="0"
                                step="1"
                                name="max_redemptions"
                                onClick={(e) => setSelect(e.target.id)}
                                onChange={changeHandler}
                            />
                            </div>

                        <div className={`input ${select === "redeem_by" ? "select" : ""} ${errors.redeem_by ? "error" : ""}`}>
                                <label>Limit redeem date</label>
                                <DatePicker
                                    selected={selectedDate}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={date => setSelectedDate(date)} />
                            </div>

                    <hr/>
                    <div style={{clear: "both"}}/>

                    <div id="access_form">
                        {accessProduct && dataForm.type_of_product !== "product" && accessProduct.map((access, key) => {
                            return (
                                <Fragment key={access.id}>
                                    <div className={`input ${select === `access_${access.id}` ? "select" : ""} ${errors[`access_${access.id}`] ? "error" : ""}`}>
                                        <label>Product</label>
                                        <div className="access">
                                            <select
                                                id="product"
                                                required
                                                name="product"
                                                onBlur={(e) => setSelect(null)}
                                                onClick={(e) => setSelect(e.target.id)}
                                                onChange={(event) => changeAccessHandler(event, key)}
                                            >
                                                <option value="">Select an option</option>
	                                            {accessOptions.map(access => (
		                                            <option key={access.id} value={access.id}>
			                                            {access.name}
		                                            </option>
	                                            ))}
                                            </select>
                                        </div>
                                    </div>
                                    {key >= 0 && (
                                        <div className="submit mb-2">
                                            <input
                                                type="button"
                                                value="Remove Access"
                                                onClick={() => decrementAccess(key)}
                                            />
                                        </div>
                                    )}
                                </Fragment>
                            );
                        }
                    )}
                    </div>
                        <div className="submit">
                            <input
                                type="button"
                                value="Add Access"
                                onClick={incrementAccess}
                            />
                        </div>
                    <hr/>
                    <div style={{clear: "both"}}/>
                    <div className="submit">
                        <input
                            type="submit"
                            value="Add Coupon"
                        />
						<div id="validUpdate" className={`${updated ? "display" : ""}`}>
                            Le coupon a été ajouté
                        </div>
                    </div>
                </form>
            </article>
        </div>
    );
};

export default AddCoupon;
