import React, {useContext, useEffect, useState, Fragment} from "react";
import {AdminContext} from "../../contexts/AdminContext";
import {isEmpty} from "../../components/Utils";
import {useDispatch, useSelector} from "react-redux";
import {getCoupon} from "../../actions/stripeAdmin.action";
import moment from "moment";
import axios from "axios";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

const EditCoupon = (props) => {
	const {id} = props;
	const dispatch = useDispatch();
	const [prod, setProd] = useState({});
	const [activeElement, setActiveElement] = useState(null)
	const coupon = useSelector((state) => state.stripeAdminReducer.getCouponReducer);
	const {updateCoupon} = useContext(AdminContext);
	const [accessOptions, setAccessOptions] = useState([]);
	const [accessProduct, setAccessProduct] = useState([]);
	const [updated, setUpdated] = useState(false)
	const [errors, setErrors] = useState({});
	const [selectedDate, setSelectedDate] = useState();

	useEffect(() => {
		dispatch(getCoupon(id));
	}, [dispatch]);

	useEffect(() => {
		if (!isEmpty(coupon)) {
			setProd(coupon);
			setAccessProduct(coupon.applies_to?.products);
			if(!isEmpty(coupon.redeem_by)){
				setSelectedDate(moment.unix(coupon.redeem_by).format("YYYY-MM-DD HH:mm:ss"))
			}
		}
	}, [coupon]);

	useEffect(() => {
		const fetch = async () => {
			const loginAdminToken = localStorage.getItem("loginAdminToken");
			if (loginAdminToken) {
				axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginAdminToken;
				try {
					return await axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}getProducts`)
						.then((res) => {
							if (res.data.result === "ok" && !isEmpty(res.data.response)) {
								setAccessOptions(res.data.response);
							}
						})
						.catch((error) => {
							console.log(error)
						});

				} catch (e) {
					console.log(e);
				}
			}
		};

		fetch();

	}, []);


	const incrementAccess = () => {
		setAccessProduct([
			...accessProduct,
			{["id"]: moment().unix()}
		]);
	};


	const decrementAccess = (key) => {
		let copyAccess = [...accessProduct];
		copyAccess.splice(key, 1)
		setAccessProduct(copyAccess);
	};

	const changeAccessHandler = (event, key) => {
		let copyAccess = [...accessProduct];
		copyAccess[key][event.target.name] = event.target.value;
		setAccessProduct(copyAccess);
	};

	const validateForm = async () => {
		let valid = {};
		if (isEmpty(prod.name) || prod.name.length < 1) {
			setErrors(prevState => ({...prevState, name: true}));
			valid["name"] = true;
		} else {
			setErrors(prevState => ({...prevState, name: false}));
			valid["name"] = false;
		}
		if (isEmpty(prod['metadata'].type) || prod['metadata'].type < 1) {
			setErrors(prevState => ({...prevState, type: true}));
			valid["type"] = true;
		} else {
			setErrors(prevState => ({...prevState, type: false}))
			valid["type"] = false;
		}
		return valid;
	}

	const submitForm = async (event) => {
		event.preventDefault();
		let valid = await validateForm();

		if (Object.values(valid).includes(true)) {
			return false;
		}

		let data = {
			...prod,
			["applies_to"]: accessProduct
		}

		updateCoupon(prod.id, data).then((res) => {
			console.log(res);
			if (!isEmpty(res)) {
				setTimeout(() => {
					setUpdated(true);
				}, 10);

				setTimeout(() => {
					setUpdated(false);
				}, 3000);
			}
		})

	};

	return (
		<div className="edit_coupon">
			<Fragment>
				<article>
					<form onSubmit={submitForm} noValidate>
						<div className={`input ${activeElement === "title" ? "select" : ""} ${errors.name ? "error" : ""}`}>
							<label>Name</label>
							<input
								type="text"
								id="name"
								required
								name="name"
								autoFocus
								autoComplete="given-name"
								defaultValue={prod?.name}
								onBlur={(e) => setActiveElement(null)}
								onClick={(e) => setActiveElement(e.target.id)}
								onChange={(e) => setProd({
									...prod, name: e.target.value
								})}
							/>
						</div>

						<div className={`input ${activeElement === "type" ? "select" : ""} ${errors.type ? "error" : ""}`}>
							<label>Type</label>
							<select
								id="type"
								required
								name="type"
								readOnly={true}
								disabled={true}
								// onChange={(e) => setProd({
								// 	...prod,
								// 	metadata: {...prod.metadata, type: e.target.value}
								// })}
								value={prod?.metadata?.type}
							>
								<option/>
								<option value="amount_off">Fixed Amount Discount</option>
                                <option value="percent_off">Percentage Discount</option>
							</select>
						</div>
						{prod?.metadata?.type === "percent_off"  && (
                            <div className="double-grid">
	                            <div className={`input ${activeElement === "percent_off" ? "select" : ""} ${errors.percent_off ? "error" : ""}`}>
	                                <label>Percentage Off</label>
	                                <input
		                                type="number"
		                                id="percent_off"
		                                placeholder="0.0"
		                                min="0"
		                                step=".5"
		                                name="percent_off"
		                                required
										readOnly={true}
										disabled={true}
										defaultValue={prod?.percent_off}
										onBlur={(e) => setActiveElement(null)}
										onClick={(e) => setActiveElement(e.target.id)}
										// onChange={(e) => setProd({
										// 	...prod, percent_off: e.target.value
										// })}
	                                />
	                            </div>
							</div>
						)}

	                    {prod?.metadata?.type === "amount_off"  && (
	                        <div className="double-grid">
		                        <div className={`input ${activeElement === "amount_off" ? "select" : ""} ${errors.amount_off ? "error" : ""}`}>
		                            <label>Discount Amount</label>
		                            <input
		                                type="number"
		                                id="amount_off"
		                                placeholder="0"
		                                min="0"
		                                step="1"
		                                name="amount_off"
		                                required
										defaultValue={prod?.amount_off}
										readOnly={true}
										disabled={true}
										onBlur={(e) => setActiveElement(null)}
										onClick={(e) => setActiveElement(e.target.id)}
										// onChange={(e) => setProd({
										// 	...prod, amount_off: e.target.value
										// })}
		                            />
		                        </div>
		                        <div className={`input ${activeElement === "currency" ? "select" : ""} ${errors.currency ? "error" : ""}`}>
		                            <label>Currency</label>
		                            <select
		                                id="currency"
		                                required
		                                name="currency"
		                                value={prod?.currency}
										readOnly={true}
										disabled={true}
		                                // onChange={(e) => setProd({
			                            //     ...prod, currency: e.target.value
		                                // })}
		                            >
			                            <option/>
			                            <option value="eur">EUR</option>
		                            </select>
		                        </div>
	                        </div>
	                    )}

						<div className="double-grid">
	                        <div className={`input ${activeElement === "duration" ? "select" : ""} ${errors.duration ? "error" : ""}`}>
	                            <label>Duration</label>
	                            <select
	                                id="duration"
	                                required
	                                name="duration"
									readOnly={true}
									disabled={true}
									onBlur={(e) => setActiveElement(null)}
									onClick={(e) => setActiveElement(e.target.id)}
									// onChange={(e) => setProd({
									// 	...prod, duration: e.target.value
									// })}
									value={prod?.duration}
	                            >
	                                <option/>
	                                <option value="forever">Forever</option>
	                                <option value="once">Once</option>
	                                <option value="repeating">Repeating</option>
	                            </select>
	                        </div>

	                        {prod?.duration === "repeating"  && (
		                        <div className={`input ${activeElement === "duration_in_months" ? "select" : ""} ${errors.duration_in_months ? "error" : ""}`}>
		                            <label>Duration in months</label>
		                            <input
			                            type="number"
			                            id="duration_in_months"
			                            placeholder="0"
			                            min="0"
			                            step="1"
			                            name="duration_in_months"
			                            required
										readOnly={true}
										disabled={true}
			                            defaultValue={prod?.duration_in_months}
										onBlur={(e) => setActiveElement(null)}
										onClick={(e) => setActiveElement(e.target.id)}
										// onChange={(e) => setProd({
										// 	...prod, duration_in_months: e.target.value})
										// }
		                            />
		                        </div>
	                        )}
						</div>

						<div className={`input ${activeElement === "max_redemptions" ? "select" : ""} ${errors.max_redemptions ? "error" : ""}`}>
							<label>Limit times redeemed</label>
							<input
	                            type="number"
	                            id="max_redemptions"
	                            placeholder="0"
	                            min="0"
	                            step="1"
	                            name="max_redemptions"
								defaultValue={prod?.max_redemptions}
								readOnly={true}
								disabled={true}
	                            onBlur={(e) => setActiveElement(null)}
								onClick={(e) => setActiveElement(e.target.id)}
								// onChange={(e) => setProd({
								// 	...prod, max_redemptions: e.target.value})
								// }
                            />
						</div>

						<div className={`input ${activeElement=== "redeem_by" ? "select" : ""} ${errors.redeem_by ? "error" : ""}`}>
	                        <label>Limit redeem date</label>
	                        <DatePicker
								readOnly={true}
								disabled={true}
	                            selected={!isEmpty(selectedDate) ? new Date(selectedDate) : ""}
	                            // onChange={date => setSelectedDate(date)}
	                            minDate={new Date()}
	                        />
	                    </div>

						<hr/>
						<div style={{clear: "both"}}/>

						<div id="access_form">
							{accessProduct && accessProduct.map((elem, key) => {
								return (
									<Fragment key={elem.id}>
										<div className="input" data-id={elem}>
											<label>Product</label>
											<div className="access">
												<select
													id={`product_${elem.product}`}
													required
													name="product"
													value={elem}
													onBlur={(e) => setActiveElement(null)}
													onClick={(e) => setActiveElement(e.target.id)}
													onChange={(event) => changeAccessHandler(event, key)}
												>
													<option/>
													{accessOptions.map(access => (
		                                                <option key={access.id} value={access.id}>
		                                                    {access.name}
		                                                </option>
	                                                ))}
												</select>
											</div>
										</div>
										{key >= 0 && (
											<div className="submit mb-2">
												<input
													type="button"
													value="Remove Access"
													onClick={() => decrementAccess(key)}
												/>
											</div>
										)}
									</Fragment>
								);
							})}
						</div>

						<div className="submit">
							<input
								type="button"
								value="Add Access"
								onClick={incrementAccess}
							/>
						</div>

						<hr/>
						<div style={{clear: "both"}}/>

						<div className="submit">
							<input
								type="submit"
								value="Edit Coupon"
							/>
							<div id="validUpdate" className={`${updated ? "display" : ""}`}>
								Le coupon a été modifié
							</div>
						</div>
					</form>
				</article>
			</Fragment>
		</div>
	);
};

export default EditCoupon;
