import React from "react";
import {
	Route,
	Switch,
	BrowserRouter,
} from "react-router-dom";
import Explorer from "../../pages/Explorer";
import Sports from "../../pages/Sports";
import Events from "../../pages/Events";

import Club from "../../pages/Club";
import Player from "../../pages/Player";
import Live from "../../pages/Live";
import Account from "../../pages/Account";

import Country from "../../pages/Country";

import Home from "./Home.routes";
import BackOffice from "./BackOffice.routes";
import IframeVideo from "../../pages/IframeVideo";
import IframeLive from "../../pages/IframeLive";
import Emission from "../../pages/Emission";
import Register from "../../pages/Register";
import Profile from "../../pages/Profile";
import PrivateRoute from "./PrivateUserRoute";

//CREATE ROUTES NAVIGATION FOR FUCHS PAGES
const Routes = () => {
	return (
		<BrowserRouter>
			<Switch>
				<Route path="/admin" exact component={BackOffice} />
				<Route exact path="/iframe-video/:videoID" component={IframeVideo} />
				<Route exact path="/iframe-live/:clubURL/:evtID/" component={IframeLive} />
				<Route>
					<Home>
						<Switch>
							<Route exact path="/" component={Explorer} />
							<PrivateRoute exact path="/profile/:type/:videoID?">
								<Profile />
							</PrivateRoute>
							<Route
								exact
								path="/:clubURL?"
								render={(props) => {
									switch (props.match.params.clubURL) {
										case "events":
											return <Events {...props} />;
										case "account":
											return <Account {...props} />;
										case "emissions":
											return <Emission {...props} />;
										case undefined:
											return <Explorer />;

										default:
											return <Club {...props} />;
									}
								}}
							/>
							<Route exact path="/country/:countryName/:type?" component={Country} />
							<Route exact path="/sport/:sportName/:type?" component={Sports} />
							<Route exact path="/events/:sportName?" component={Events} />
							<PrivateRoute exact path="/player/:videoID">
								<Player/>
							</PrivateRoute>
							<Route exact path="/emissions" component={Emission} />
							<Route exact path="/events" component={Events} />
							<Route exact path="/account/register" component={Register} />
							<Route exact path="/account/:method?" component={Account} />
							<PrivateRoute exact path="/:clubURL/:evtID/">
								<Live/>
							</PrivateRoute>
							{/*<Redirect to="/" />*/}
						</Switch>
					</Home>
				</Route>
			</Switch>
		</BrowserRouter>
	);
};

export default Routes;
