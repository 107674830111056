import React, { useEffect, useRef, useState, useContext } from "react";
import { isEmpty } from "./Utils";
import {useSelector, useDispatch} from "react-redux";
import { NavLink } from "react-router-dom";
import { useLocation, useHistory } from "react-router";
import FilterRow from "./events/FilterRow";
import Slider from "react-slick";
import RightArrow from "./slider/RightArrow";
import LeftArrow from "./slider/LeftArrow";
import Moment from "react-moment";
import parse from "html-react-parser";
import axios from "axios";
import { MyContext } from "../contexts/MyContext";
import {
	getEvents,
	getRemindMeEvents,
} from "../actions/events.action";
import useMeasure from "react-use-measure";

const Events = (props) => {
	const { events, title, filter, dataKey, leagueId, sportId, countryId, reminder } = props;
	const onSelectFilter = props.onSelectFilter;
	const dispatch = useDispatch();
	const hideSeeAll = props.hideSeeAll;
	const { rootState } = useContext(MyContext);
	const { isAuth } = rootState;
	const history = useHistory();
	const location = useLocation();
	const slider = useRef(null);
	const [isFiltered, setIsFiltered] = useState(false);
	const [eventRef, {width}] = useMeasure();

	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);

	const getUpdatedEvents = () => {
		dispatch(getEvents());
		dispatch(getRemindMeEvents());
	}

	const remindEvent = async (id) => {
		if (isAuth) {
			let idUrl = encodeURI(id);
			const loginToken = localStorage.getItem("loginToken");
			let config = {
				headers: { "Authorization-Bearer": `Bearer ${loginToken}` },
			};
			await axios
				.get(`${process.env.REACT_APP_NOT_CACHED_API_URL}remindEvent&i=${idUrl}`, config)
				.then((res) =>
					res.data.status === 201
						? getUpdatedEvents()
						: console.log("Update Error!")
				);
		} else {
			history.replace("/account/login");
		}
	};

	const seeAllPath = () => {
		let seeAllPath;
		let regex = new RegExp(/^\/sport\/[a-zA-Z]*$/gm);
		let sportName = location.pathname.split("/").pop();
		if (location.pathname !== "/events") {
			seeAllPath = location.pathname.match(regex)
				? "/events/" + sportName
				: "/events";
		}
		return seeAllPath;
	};

	const scrollToTop = () => {
		const c = document.documentElement.scrollTop || document.body.scrollTop;
		if (c > 0) {
			window.requestAnimationFrame(scrollToTop);
			window.scrollTo(0, c - c / 8);
		}
	};

	let settings = {
		infinite: false,
		lazyLoad: true,
		speed: 500,
		slidesToShow: (!isEmpty(width) && width !== 0) ? Math.floor(width / 342) : 4,
		slidesToScroll: (!isEmpty(width) && width !== 0) ? Math.floor(width / 342) : 4,
		prevArrow: <LeftArrow />,
		nextArrow: <RightArrow />
	};

	if (isFiltered === true || events.length > 0) {
		return (
			<div className="events filter">
				<div className="titles">
					<section
						className={"flex-between m-auto" + (!isEmpty(filter) && " filter")}
					>
						<h3>
							{title ? title : translations && !isEmpty(translations["events"])? parse(translations["events"]) : "events"}
						</h3>
						{filter === true ? (
							<FilterRow
								onSelectFilter={onSelectFilter}
								leagueId={leagueId}
								sportId={sportId}
								countryId={countryId}
								setIsFiltered={setIsFiltered}
								slider={slider.current && null}
							/>
						) : (
							location.pathname !== "/events" &&
							!hideSeeAll && (
								<NavLink exact to={seeAllPath()} className="item-menu">
									<h3 className="right-arrow">
										{translations && !isEmpty(translations["seeAllEvents"])
											? parse(translations["seeAllEvents"])
											: "seeAllEvents"
										}
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
										>
											<path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
										</svg>
									</h3>
								</NavLink>
							)
						)}
					</section>
				</div>
				<div ref={eventRef}>
					{events.length > 0 && (
						<Slider
							ref={slider}
							{...settings}
							className="events-container hide-scroll customScroll"
						>
							{events.map((event) => {
								//RETURN EACH EVENT
								return (
									<div
										className={`event ${event.status ? "issue" : ""}`}
										key={dataKey + event.evtId}
									>
										<div className="title-container">
											<h4>{`${event["titleEN"]}`}</h4>
										</div>
										<div className="division">
											<h5>
												{event["divisionNameEN"]
													? `${event["divisionNameEN"]}`
													: ""}
											</h5>
											{event["divisionNameEN"] ? (
												<img
													src={event.divisionImg}
													alt={`${event["divisionNameEN"]} Logo`}
													width="50"
												/>
											) : (
												<span/>
											)}
										</div>
										<div className="logos">
											<div className="logo-container">
												<img
													src={event.team1Img}
													alt={`${event.team1Name} Logo`}
													title={event.team1Name}
													width="50"
												/>
											</div>
											<div className="vs">
												<img
													className="VS-V"
													src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/events/V_VS.svg`}
													alt="V"
													width="40"
													height="40"
												/>
												<img
													className="VS-S"
													src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/events/S_VS.svg`}
													alt="S"
													width="40"
													height="40"
												/>
											</div>
											<div className="logo-container">
												<img
													src={event.team2Img}
													alt={`${event.team2Name} Logo`}
													title={event.team2Name}
													width="50"
												/>
											</div>
										</div>

										<div className="live">
											{event.started === "yes" && isEmpty(event.status) && (
												<span className="live">
													{translations && !isEmpty(translations["live"])
														? parse(translations["live"])
														: "live"
													}
												</span>
											)}
											{event.started === "no" && isEmpty(event.status) && (
												<span className="off">
													{translations && !isEmpty(translations["soon"])
														? parse(translations["soon"])
														: "soon"
													}
												</span>
											)}
											{!isEmpty(event.status) && (
												<span
													className="issue"
													title="Voir le status des matchs"
													onClick={() => {
														scrollToTop();
														document
															.querySelector(".status svg + h4 + ul")
															.classList.toggle("active");
													}}
												>
													{translations && !isEmpty(translations[event.status + "Event"])
														? parse(translations[event.status + "Event"])
														: event.status}
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
													>
														<path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z" />
													</svg>
												</span>
											)}
										</div>
										<div className="date">
											<Moment format="DD MMM, YYYY / HH:mm" element="span">
												{event.start}
											</Moment>
										</div>
										{isEmpty(event.status) && (
											<div className="button">
												{event.started === "yes" && (
													<button>
														<NavLink
															exact
															rel="noopener noreferrer"
															to={`/${event.clubURL}/${event.evtId}`}
															onClick={() => window.scrollTo(0, 0)}
														>
															{translations && !isEmpty(translations["followMatch"])
																? parse(translations["followMatch"])
																: "followMatch"}
														</NavLink>
													</button>
												)}
													{event.started === "no" && !reminder &&(
														<button  onClick={() => remindEvent(event.evtId)} style={{background: isAuth ? (event.notif === "1" ? "#FF0000" : "") : ""}}>
															{isAuth ? (
																event.notif === "1" ? (translations && !isEmpty(translations["removeRemind"])
																	? parse(translations["removeRemind"])
																	: "removeRemind")
																	: (translations && !isEmpty(translations["remindEvent"])
																		? parse(translations["remindEvent"])
																		: "remindEvent"))
																: (translations && !isEmpty(translations["remindEvent"])
																	? parse(translations["remindEvent"])
																	: "remindEvent")
															}

														</button>
													)}
											</div>
										)}
									</div>
								);
							})}
						</Slider>
					)}
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default Events;
