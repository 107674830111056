import axios from "axios";

export const GET_COUNTRIES = "GET_COUNTRIES";

export const getCountries = (d = null) => {
    return async (dispatch) => {
        await axios
            .get(`${process.env.REACT_APP_API_URL}getCountries&d=${d}`)
            .then((res) => dispatch({ type: GET_COUNTRIES, payload: res.data }))
            .catch((error) => {
                console.log(error)
            });
  };
};
