import React, { useEffect, useState } from "react";
import { isEmpty, removeTags } from "../Utils";
import {useDispatch, useSelector} from "react-redux";
import parse from "html-react-parser";
import axios from "axios";
import {getFavorite} from "../../actions/favorite.action";

const FavouriteClubsFilter = (props) => {
    const { sportId, countryId } = props;
	const selectFilter = props.onSelectFilter;
	const leagues = useSelector((state) => state.leaguesReducer.leagues);
	const favorites = useSelector((state) => state.favoritesReducer.favoriteReducer);
	const translationsReducer = useSelector((state) => state.langReducer.translationsReducer);
	const translations = translationsReducer.translations;
    const selectedLanguage = translationsReducer.language;
	const [champMenu, setChampMenu] = useState(false);
	const [clubsMenu, setClubsMenu] = useState(false);
	const [groupMenu, setGroupMenu] = useState(false);
	const [groups, setGroups] = useState([]);
	const [champSelect, setChampSelect] = useState("");
	const [groupSelect, setGroupSelect] = useState("");
	const [clubSelect, setClubSelect] = useState("");
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getFavorite()); //get only favorites clubs
		setChampSelect("");
		setGroupSelect("");
        setClubSelect("");
	}, [sportId, countryId]);

    useEffect(() => {
    	setChampMenu(false);
        setGroupMenu(false);
        setClubsMenu(false);
        // eslint-disable-next-line
    }, [champSelect, groupSelect, clubSelect]);

    useEffect(() => {
        fetchGroups();
        return () => {
			setGroups([]);
		}
    }, [selectedLanguage, champSelect]);

    const fetchGroups = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}getGroups&lig=${champSelect}`)
            .then((res) => {
                if (res.data.result === "ok" && res.data.groups) {
                    setGroups(res.data.groups);
                } else {
                    setGroups([]);
                }
            })
            .catch((error) => {
                console.log(error)
            })
    };

	const getNameLeague = (id) => {
		let name = "";
		leagues.forEach((league) => {
			if (league.id === id) {
				name = !isEmpty(league.name) ? removeTags(league.name) : league.flig_uname
			}
		});

		return name;
	};

	const getNameGroup = (id) => {
		let name = "";
		groups.forEach((group) => {
			if (group.id === id) {
				name = !isEmpty(group.name) ? removeTags(group.name) : group.id
			}
		});

		return name;
	};

    const getNameClub = (id) => {
		let name = "";
		favorites.forEach((club) => {
			if (club.club_id === id) {
				name = !isEmpty(club.club_name) ? removeTags(club.club_name) : club.club_id
			}
		});

		return name;
	};

	const onSelectFilter = (menu, id) => {
		setChampMenu(false);
		setGroupMenu(false);
		setClubsMenu(false);
		switch (menu) {
			case "league":
				setChampSelect(id);
				setGroupSelect("");
				setClubSelect("");
				selectFilter(id, "", "");
				break;
			case "groups":
				setGroupSelect(id);
				setClubSelect("");
				selectFilter(champSelect, id, "");
				break;
			case "clubs":
				setClubSelect(id);
				selectFilter(champSelect, groupSelect, id);
				break;
			default:
				return;
		}
	};

	const dropdownClicked = (menu) => {
		switch (menu) {
			case "champ":
				groupMenu && setGroupMenu(!groupMenu);
				clubsMenu && setClubsMenu(!clubsMenu);
				setChampMenu(!champMenu);
				break;
            case "groups":
				champMenu && setChampMenu(!champMenu);
                clubsMenu && setClubsMenu(!clubsMenu);
                setGroupMenu(!groupMenu);
                break;
            case "clubs":
                setClubsMenu(!clubsMenu);
                break;
			default:
                setGroupMenu(false);
                setClubsMenu(false);
				return;
		}
	};


	const handleClickOutside = (event) => {
		if (event.target.closest('.sport-filter') === null) {
			setClubsMenu(false);
			setGroupMenu(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	});

	return(
		<div className="sport-filter">
			<div className="dropdown">
				<button className={`${champMenu ? "open dropbtn" : "dropbtn"}`} onClick={() => dropdownClicked("champ")}>
					{!isEmpty(champSelect)
						? getNameLeague(champSelect)
						: translations && !isEmpty(translations["championship"])
							? parse(translations["championship"])
							: "Championship"}
				</button>
				{champMenu && (
					<ul className="dropdown-content">
						<li onClick={() => setChampSelect("")}>
							{translations && !isEmpty(translations["allFilters"])
								? parse(translations["allFilters"])
								: "allFilters"}
						</li>
						{leagues &&
						leagues.map((league) => {
							if (!isEmpty(sportId)) {
								if (sportId === league.sport_id) {
									return (
										<li
											key={league.id}
											onClick={() => onSelectFilter("league", league.id)}
										>
											{!isEmpty(league.name) ? removeTags(league.name) : league.flig_uname}
										</li>
									);
								}
							} else {
								return (
									<li
										key={league.id}
										onClick={() => onSelectFilter("league", league.id)}
									>
										{!isEmpty(league.name) ? removeTags(league.name) : league.flig_uname}
									</li>
								);
							}
							return null;
						})}
					</ul>
				)}
			</div>
			<div className="dropdown">
				<button className={`${groupMenu && !isEmpty(champSelect)? "open dropbtn" : "dropbtn"}`} onClick={() => dropdownClicked("groups")}>
					{!isEmpty(groupSelect)
						? getNameGroup(groupSelect)
						: translations && !isEmpty(translations["groups"])
							? parse(translations["groups"])
							: "Groups"}
				</button>
				{groupMenu && !isEmpty(champSelect) && (
					<ul className="dropdown-content">
						<li onClick={() => setGroupSelect("")}>
							{translations && !isEmpty(translations["allFilters"])
								? parse(translations["allFilters"])
								: "allFilters"}
						</li>
						{groups &&
						groups.map((group) => {
							return (
								<li
									key={group.id}
									onClick={() => onSelectFilter("groups", group.id)}
								>
									{!isEmpty(group.name) ? removeTags(group.name) : group.id}
								</li>
							);
						})}
					</ul>
				)}
			</div>
			<div className="dropdown">
				<button className={`${clubsMenu && !isEmpty(groupSelect) ? "open dropbtn" : "dropbtn"}`} onClick={() => dropdownClicked("clubs")}>
					{!isEmpty(clubSelect)
						? getNameClub(clubSelect)
						: translations && !isEmpty(translations["clubs"])
							? parse(translations["clubs"])
							: "Clubs"}
				</button>
				{clubsMenu && !isEmpty(groupSelect) && (
					<ul className="dropdown-content">
						<li onClick={() => setClubSelect("")}>
							{translations && !isEmpty(translations["allFilters"])
								? parse(translations["allFilters"])
								: "allFilters"}
						</li>
						{favorites &&
						favorites.map((club) => {
							if (!isEmpty(groupSelect)) {
								if (groupSelect === club.id_group) {
									return (
										<li
											key={club.club_id}
											onClick={() => onSelectFilter("clubs", club.club_id)}
										>
											{!isEmpty(club.club_name) ? removeTags(club.club_name) : club.club_id}
										</li>
									);
								} else {
									return true;
								}
							}

						})}
					</ul>
				)}
			</div>
		</div>
	);
};

export default FavouriteClubsFilter;