import React, { useContext, useEffect, useState } from "react";
import EventStyle1 from "../../../components/explorer/EventStyle1";
import EventStyle2 from "../../../components/explorer/EventStyle2";
import EventStyle3 from "../../../components/explorer/EventStyle3";
import EmptyEventStyle from "../../../components/explorer/EmptyEventStyle";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import Switch from "react-switch";
import { AdminContext } from "../../../contexts/AdminContext";
import { useDispatch } from "react-redux";
import { getSlideshow } from "../../../actions/slideshow.action";
import { Redirect } from "react-router";

const SingleEditor = ({ slide, isNew }) => {
	const [redirect, setRedirect] = useState(false);
	//VALUE
	const [id, setId] = useState(slide.id);
	const [type, setType] = useState(slide.type);
	const [team1, setTeam1] = useState(slide.team1);
	const [team2, setTeam2] = useState(slide.team2);
	const [img, setImg] = useState(slide.img);
	const [file, setFile] = useState(null);
	const [title, setTitle] = useState(slide.title);
	const [url, setUrl] = useState(slide.url);
	const [visible, setVisible] = useState(slide.visible);
	const [newSlide, setNewSlide] = useState(slide);

	//UPDATE SLIDE IN REAL TIME

	useEffect(() => {
		setSuppress(false);
		setNewSlide(slide);
		setId(slide.id);
		setType(slide.type);
		setTeam1(slide.team1);
		setTeam2(slide.team2);
		setImg(slide.img);
		setTitle(slide.title);
		setUrl(slide.url);
		setVisible(slide.visible);
		setRedirect(false);
	}, [slide, redirect]);

	useEffect(() => {
		setNewSlide({ id, type, team1, team2, img, title, url, visible });
	}, [id, type, team1, team2, img, title, url, visible]);

	const updateBanner = (file) => {
		let tpm = URL.createObjectURL(file);

		setFile(file);
		setImg(tpm);
	};

	const dispatch = useDispatch();

	//GRAPHIC INPUT
	const activeInput = (id) => {
		let inputs = document.querySelectorAll(".slide-editor .input");
		inputs.forEach((input) => {
			input.classList.remove("select");
		});

		let input = document.getElementById(id);
		input.parentElement.classList.add("select");
	};

	const { updateSlide, createSlide, delSlide } = useContext(AdminContext);
	const updateSlideForm = async () => {
		document.querySelector(".bo-slideshow .loading svg").classList.add("display");
		const formData = new FormData();

		formData.append("id", id);
		formData.append("type", type);
		formData.append("team1", team1);
		formData.append("team2", team2);
		formData.append("banner", file);
		file === null
			? formData.append("mode", "no")
			: formData.append("mode", "updateBanner");

		formData.append("title", title);
		formData.append("url", url);
		formData.append("visible", visible);
		const data = isNew
			? await createSlide(formData)
			: await updateSlide(formData);

		if (data.success) {
			dispatch(getSlideshow("all", "no"));
			if (isNew) setRedirect(true);
		} else {
			console.log(data);
		}
		document
			.querySelector(".bo-slideshow .loading svg")
			.classList.remove("display");
	};

	const deleteSlideForm = async (id) => {
		const data = await delSlide(id);
		if (data.success) {
			dispatch(getSlideshow("all", "no"));
			setSuppress(true);
		} else {
			console.log(data);
		}
	};
	const [deleteSlide, setDeleteSlide] = useState(false);
	const [deleteButton, setDeleteButton] = useState(
		"Supprimer la slide (définitif)"
	);
	const [suppress, setSuppress] = useState(false);
	const handleDelete = () => {
		if (!deleteSlide) {
			setDeleteButton("Cliquez encore une fois pour confirmer (définitif !)");
			setDeleteSlide(true);
		} else {
			setDeleteButton("Supprimer la slide (définitif)");
			deleteSlideForm(id);
			setDeleteSlide(false);
		}
	};
	return (
		<SimpleBar autoHide={false} className="slide-editor">
			{redirect && <Redirect push="/admin" to="/admin" />}
			{!suppress && (
				<>
					<div className="sticky">
						<div className="submit">
							<button onClick={updateSlideForm}>
								<span>{isNew ? "Créer un slide" : "Mettre à jour la slide"}</span>
								<div className="loading">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
									>
										<path d="M13.75 22c0 .966-.783 1.75-1.75 1.75s-1.75-.784-1.75-1.75.783-1.75 1.75-1.75 1.75.784 1.75 1.75zm-1.75-22c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm10 10.75c.689 0 1.249.561 1.249 1.25 0 .69-.56 1.25-1.249 1.25-.69 0-1.249-.559-1.249-1.25 0-.689.559-1.25 1.249-1.25zm-22 1.25c0 1.105.896 2 2 2s2-.895 2-2c0-1.104-.896-2-2-2s-2 .896-2 2zm19-8c.551 0 1 .449 1 1 0 .553-.449 1.002-1 1-.551 0-1-.447-1-.998 0-.553.449-1.002 1-1.002zm0 13.5c.828 0 1.5.672 1.5 1.5s-.672 1.501-1.502 1.5c-.826 0-1.498-.671-1.498-1.499 0-.829.672-1.501 1.5-1.501zm-14-14.5c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2zm0 14c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2z" />
									</svg>
								</div>
							</button>
						</div>
						{!isNew && (
							<div className="delete">
								<button onClick={handleDelete}>{deleteButton}</button>
							</div>
						)}
					</div>

					<section className="style">
						<h2>Style</h2>
						<div
							className={type === "1" ? "slide selected" : "slide"}
							onClick={() => setType("1")}
						>
							<EventStyle1 event={newSlide} />
						</div>
						<div
							className={type === "2" ? "slide selected" : "slide"}
							onClick={() => setType("2")}
						>
							<EventStyle2 event={newSlide} />
						</div>
						<div
							className={type === "3" ? "slide selected" : "slide"}
							onClick={() => setType("3")}
						>
							<EventStyle3 event={newSlide} />
						</div>
						<div
							className={type === "4" ? "slide selected" : "slide"}
							onClick={() => setType("4")}
						>
							<EmptyEventStyle event={newSlide} />
						</div>
					</section>
					{type !== "4" && (
						<section>
							<section className="title ">
								<h2>Titre</h2>
								<div className="input">
									<label htmlFor="title">Titre du slide *</label>
									<input
										type="text"
										name="title"
										id="title"
										value={title}
										onClick={(e) => activeInput(e.target.id)}
										required
										onFocus={(e) => activeInput(e.target.id)}
										onChange={(e) => setTitle(e.target.value)}
									/>
								</div>
							</section>
							{type !== "3" && (
								<section className="teams">
									<h2>&Eacute;quipes</h2>
									<div className="teams-inputs">
										<div className="input">
											<label htmlFor="team1">&Eacute;quipe 1 *</label>
											<input
												type="text"
												name="team1"
												id="team1"
												value={team1}
												onClick={(e) => activeInput(e.target.id)}
												required
												onFocus={(e) => activeInput(e.target.id)}
												onChange={(e) => setTeam1(e.target.value)}
											/>
										</div>
										<div className="input">
											<label htmlFor="team2">&Eacute;quipe 2 *</label>
											<input
												type="text"
												name="team2"
												id="team2"
												value={team2}
												onClick={(e) => activeInput(e.target.id)}
												required
												onFocus={(e) => activeInput(e.target.id)}
												onChange={(e) => setTeam2(e.target.value)}
											/>
										</div>
									</div>
								</section>
							)}
							<section className="link">
								<h2>Lien du slide</h2>
								<div className="input">
									<label htmlFor="url">URL *</label>
									<input
										type="text"
										name="url"
										id="url"
										value={url}
										onClick={(e) => activeInput(e.target.id)}
										required
										onFocus={(e) => activeInput(e.target.id)}
										onChange={(e) => setUrl(e.target.value)}
									/>
								</div>
							</section>
							<section className="img">
								<h2>Bannière du slide</h2>
								<div className="input">
									<input
										type="file"
										name="img"
										id="img"
										onChange={(e) => updateBanner(e.target.files[0])}
									/>
									<label htmlFor="img">Cliquez ici pour modifier la bannière</label>
									<div className="warnings">
										<p>Image (png ou jpg), pas de transparence.</p>
										<p>Taille minimum : 1440 x 900 px</p>
										<p>Poids Max : 5 Mo</p>
									</div>
								</div>
							</section>
						</section>
					)}
					<section className="visibility">
						<h2>Visibilité de la slide</h2>
						<div className="input not">
							<Switch
								onChange={() => setVisible(visible === "yes" ? "no" : "yes")}
								checked={visible === "yes" ? true : false}
								uncheckedIcon={
									<svg
										className="icon-switch unchecked"
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
									>
										<path d="M19.604 2.562l-3.346 3.137c-1.27-.428-2.686-.699-4.243-.699-7.569 0-12.015 6.551-12.015 6.551s1.928 2.951 5.146 5.138l-2.911 2.909 1.414 1.414 17.37-17.035-1.415-1.415zm-6.016 5.779c-3.288-1.453-6.681 1.908-5.265 5.206l-1.726 1.707c-1.814-1.16-3.225-2.65-4.06-3.66 1.493-1.648 4.817-4.594 9.478-4.594.927 0 1.796.119 2.61.315l-1.037 1.026zm-2.883 7.431l5.09-4.993c1.017 3.111-2.003 6.067-5.09 4.993zm13.295-4.221s-4.252 7.449-11.985 7.449c-1.379 0-2.662-.291-3.851-.737l1.614-1.583c.715.193 1.458.32 2.237.32 4.791 0 8.104-3.527 9.504-5.364-.729-.822-1.956-1.99-3.587-2.952l1.489-1.46c2.982 1.9 4.579 4.327 4.579 4.327z" />
									</svg>
								}
								checkedIcon={
									<svg
										className="icon-switch checked"
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
									>
										<path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.21 0-4 1.791-4 4s1.79 4 4 4c2.209 0 4-1.791 4-4s-1.791-4-4-4zm-.004 3.999c-.564.564-1.479.564-2.044 0s-.565-1.48 0-2.044c.564-.564 1.479-.564 2.044 0s.565 1.479 0 2.044z" />
									</svg>
								}
								offColor="#d6d6d6"
								onColor="#679bff"
								id="visibility"
							/>
						</div>
					</section>
				</>
			)}
			{suppress && <h2>Suppression réussie !</h2>}
		</SimpleBar>
	);
};

export default SingleEditor;
