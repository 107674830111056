import React, {Fragment, useContext, useEffect, useState} from "react";
import {isEmpty} from "../../components/Utils";
import moment from "moment";
import {AdminContext} from "../../contexts/AdminContext";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {getAdvantages} from "../../actions/stripeAdmin.action";

const AddSubscription = ({successCreate}) => {
    const advantagesReducer = useSelector((state) => state.stripeAdminReducer.getAdvantagesReducer);
    const {createProduct} = useContext(AdminContext);
    const dispatch = useDispatch();
    const [dataForm, setDataForm] = useState({});
    const [prices, setPrices] = useState([{"id": moment().unix()}]); //important innit `prices`
    const [advantages, setAdvantages] = useState([]);
    const [accessProduct, setAccessProduct] = useState([]);
	const [accessOptions, setAccessOptions] = useState([]);
    const [advantagesOptions, setAdvantagesOptions] = useState([]);
	const [updated, setUpdated] = useState(false)
	const [select, setSelect] = useState(null)
    const [errors, setErrors] = useState({});
    const [image, setImage] = useState(null);
	const [file, setFile] = useState(null);

    const updateProductImage = (file) => {
		let tpm = URL.createObjectURL(file);
		setFile(file);
		setImage(tpm);
	};

    useEffect(() => {
        dispatch(getAdvantages(1, 150, ""));
    }, []);

    useEffect(() => {
        if (advantagesReducer.advantages) {
            setAdvantagesOptions(advantagesReducer.advantages)
        }
    }, [advantagesReducer]);

	useEffect(() => {
		const fetch = async () => {
			const loginAdminToken = localStorage.getItem("loginAdminToken");
			if (loginAdminToken) {
				let formData = new FormData();
				axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginAdminToken;
				formData.append("include_prices", "0");
				formData.append("type_product", "product");
				try {
					return await axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}getProducts`, formData)
						.then((res) => {
							if (res.data.result === "ok" && !isEmpty(res.data.response)) {
								setAccessOptions(res.data.response);
							}
						})
						.catch((error) => {
							console.log(error)
						});

				} catch (e) {
					console.log(e);
				}
			}
		};

		fetch();

	}, []);

    const increment = () => {
        setPrices([
            ...prices,
            {["id"]: moment().unix()}
        ]);
    };
    const incrementAdvantages = () => {
        setAdvantages([
            ...advantages,
            {["id"]: moment().unix()}
        ]);
    };

    const incrementAccess = () => {
        setAccessProduct([
            ...accessProduct,
            {["id"]: moment().unix()}
        ]);
    };

    const decrement = (key) => {
        let copyPrices = [...prices];
        copyPrices.splice(key, 1)
        setPrices(copyPrices);
    };
    const decrementAdvantages = (key) => {
        let copyAdvantages = [...advantages];
        copyAdvantages.splice(key, 1)
        setAdvantages(copyAdvantages);
    };

    const decrementAccess = (key) => {
        let copyAccess = [...accessProduct];
        copyAccess.splice(key, 1)
        setAccessProduct(copyAccess);
    };

    const changeHandler = (event) => {
        setDataForm({...dataForm, [event.target.name]: event.target.value});
    };

    const changePricesHandler = (event, key) => {
        let copyPrices = [...prices];
        let value = event.target.value;
        if (event.target.name === "unitAmountDecimal") {
            value = parseFloat(event.target.value).toFixed(2);
        }
        copyPrices[key][event.target.name] = value;
        setPrices(copyPrices);
    };

    const changeAdvantagesHandler = (event, key) => {
        let copyAdvantages = [...advantages];
        copyAdvantages[key][event.target.name] = event.target.value;
        setAdvantages(copyAdvantages);
    };

    const changeAccessHandler = (event, key) => {
        let copyAccess = [...accessProduct];
        copyAccess[key][event.target.name] = event.target.value;
        setAccessProduct(copyAccess);
    };

    const validateForm = async () => {
        let valid = {};
        if (isEmpty(dataForm.title_en) || dataForm.title_en.length < 1) {
            setErrors(prevState => ({...prevState, title_en: true}));
            valid["title_en"] = true;
        } else {
            setErrors(prevState => ({...prevState, title_en: false}));
            valid["title_en"] = false;
        }
        if (isEmpty(dataForm.description_en) || dataForm.description_en.length < 1) {
            setErrors(prevState => ({...prevState, description_en: true}));
            valid["description_en"] = true;
        } else {
            setErrors(prevState => ({...prevState, description_en: false}));
            valid["description_en"] = false;
        }
        if (isEmpty(dataForm.title_fr) || dataForm.title_fr.length < 1) {
            setErrors(prevState => ({...prevState, title_fr: true}));
            valid["title_fr"] = true;
        } else {
            setErrors(prevState => ({...prevState, title_fr: false}));
            valid["title_fr"] = false;
        }
        if (isEmpty(dataForm.description_fr) || dataForm.description_fr.length < 1) {
            setErrors(prevState => ({...prevState, description_fr: true}));
            valid["description_fr"] = true;
        } else {
            setErrors(prevState => ({...prevState, description_fr: false}));
            valid["description_fr"] = false;
        }
        if (isEmpty(dataForm.title_es) || dataForm.title_es.length < 1) {
            setErrors(prevState => ({...prevState, title_es: true}));
            valid["title_es"] = true;
        } else {
            setErrors(prevState => ({...prevState, title_es: false}));
            valid["title_es"] = false;
        }
        if (isEmpty(dataForm.description_es) || dataForm.description_es.length < 1) {
            setErrors(prevState => ({...prevState, description_es: true}));
            valid["description_es"] = true;
        } else {
            setErrors(prevState => ({...prevState, description_es: false}));
            valid["description_es"] = false;
        }
        if (isEmpty(dataForm.type_of_user) || dataForm.type_of_user.length < 1) {
            setErrors(prevState => ({...prevState, type_of_user: true}));
            valid["type_of_user"] = true;
        } else {
            setErrors(prevState => ({...prevState, type_of_user: false}))
            valid["type_of_user"] = false;
        }
        if (isEmpty(dataForm.type_of_product) || dataForm.type_of_product < 1) {
            setErrors(prevState => ({...prevState, type_of_product: true}));
            valid["type_of_product"] = true;
        } else {
            setErrors(prevState => ({...prevState, type_of_product: false}))
            valid["type_of_product"] = false;
        }
        if (prices.length > 0) {
            prices.map(price => {
                if (isEmpty(price.unitAmountDecimal) || price.unitAmountDecimal.length < 1) {
                    setErrors(prevState => ({...prevState, ["price_" + price.id]: true}));
                    valid["price_" + price.id] = true;
                } else {
                    setErrors(prevState => ({...prevState, ["price_" + price.id]: false}));
                    valid["price_" + price.id] = false;
                }
                if (isEmpty(price.currency) || price.currency.length < 1) {
                    setErrors(prevState => ({...prevState, ["currency_" + price.id]: true}));
                    valid["currency_" + price.id] = true;
                } else {
                    setErrors(prevState => ({...prevState, ["currency_" + price.id]: false}));
                    valid["currency_" + price.id] = false;
                }
                if (isEmpty(price.oneTime) || price.oneTime.length < 1) {
                    setErrors(prevState => ({...prevState, ["type_" + price.id]: true}));
                    valid["type_" + price.id] = true;
                } else {
                    setErrors(prevState => ({...prevState, ["type_" + price.id]: false}));
                    valid["type_" + price.id] = false;
                }
                if (isEmpty(price.mode) || price.mode.length < 1) {
                    setErrors(prevState => ({...prevState, ["billing_period_" + price.id]: true}));
                    valid["billing_period_" + price.id] = true;
                } else {
                    setErrors(prevState => ({...prevState, ["billing_period_" + price.id]: false}));
                    valid["billing_period_" + price.id] = false;
                }
            })
        }
        return valid;
    }

    const submitForm = async (event) => {
        event.preventDefault();
        let valid = await validateForm();

        if (Object.values(valid).includes(true)) {
            return false
        }

        let data = {
            ...dataForm,
            ["prices"]: prices,
            ["advantages"]: advantages,
            ["access_prod"]: accessProduct
        }
 
        const res = await createProduct(data, file);

        if (!isEmpty(res)) {
			setTimeout(() => {
				setUpdated(true);
			}, 10);

			setTimeout(() => {
				setUpdated(false);
                successCreate();
			}, 3000);
        }
    };
    return (
        <div className="add_subs">
            <article>
                <form onSubmit={submitForm} noValidate>
                    <div className="double-grid">
                        <div className="img">
                            <label>Image</label>
                            <div className="content">
                            <input
                                type="file"
                                name="image"
                                id="image"
                                onChange={(e) => updateProductImage(e.target.files[0])}
                            />
                            <label htmlFor="image">Choose image</label>
                            {image && (<img src={image} alt=""/>)}
                            </div>
                        </div>
                    </div>
                    <div className="double-grid">
                        <div className={`input ${select === "title_en" ? "select" : ""} ${errors.title_en ? "error" : ""}`}>
                            <label>Title English*</label>
                            <input
                                type="text"
                                id="title_en"
                                required
                                name="title_en"
                                onBlur={(e) => setSelect(null)}
                                onClick={(e) => setSelect(e.target.id)}
                                onChange={changeHandler}
                            />
                        </div>
                        <div className={`input ${select === "description_en" ? "select" : ""} ${errors.description_en ? "error" : ""}`}>
                            <label>Description English*</label>
                            <input
                                type="text"
                                id="description_en"
                                name="description_en"
                                onBlur={(e) => setSelect(null)}
                                onClick={(e) => setSelect(e.target.id)}
                                onChange={changeHandler}
                            />
                        </div>
                    </div>
                    <div className="double-grid">
                        <div className={`input ${select === "title_fr" ? "select" : ""} ${errors.title_fr ? "error" : ""}`}>
                            <label>Title French*</label>
                            <input
                                type="text"
                                id="title_fr"
                                required
                                name="title_fr"
                                onBlur={(e) => setSelect(null)}
                                onClick={(e) => setSelect(e.target.id)}
                                onChange={changeHandler}
                            />
                        </div>
                        <div className={`input ${select === "description_fr" ? "select" : ""} ${errors.description_fr ? "error" : ""}`}>
                            <label>Description French*</label>
                            <input
                                type="text"
                                id="description_fr"
                                name="description_fr"
                                onBlur={(e) => setSelect(null)}
                                onClick={(e) => setSelect(e.target.id)}
                                onChange={changeHandler}
                            />
                        </div>
                    </div>
                    <div className="double-grid">
                        <div className={`input ${select === "title_es" ? "select" : ""} ${errors.title_es ? "error" : ""}`}>
                            <label>Title Spanish*</label>
                            <input
                                type="text"
                                id="title_es"
                                required
                                name="title_es"
                                onBlur={(e) => setSelect(null)}
                                onClick={(e) => setSelect(e.target.id)}
                                onChange={changeHandler}
                            />
                        </div>
                        <div className={`input ${select === "description_es" ? "select" : ""} ${errors.description_es ? "error" : ""}`}>
                            <label>Description Spanish*</label>
                            <input
                                type="text"
                                id="description_es"
                                name="description_es"
                                onBlur={(e) => setSelect(null)}
                                onClick={(e) => setSelect(e.target.id)}
                                onChange={changeHandler}
                            />
                        </div>
                    </div>

                    <div className="double-grid">
                        <div className={`input ${select === "type_of_user" ? "select" : ""} ${errors.type_of_user ? "error" : ""}`}>
                            <label>Type of user*</label>
                            <select
                                id="type_of_user"
                                required
                                name="type_of_user"
                                onChange={changeHandler}
                            >
                                <option/>
                                <option value="fan">Fan</option>
                                <option value="athlete">Athlete</option>
                                <option value="club">Club</option>
                                <option value="media">Media</option>
                            </select>
                        </div>
                        <div className={`input ${select === "type_of_product" ? "select" : ""} ${errors.type_of_product ? "error" : ""}`}>
                            <label>Type of product*</label>
                            <select
                                id="type_of_product"
                                required
                                name="type_of_product"
                                onChange={changeHandler}
                            >
                                <option/>
                                <option value="product">Product</option>
                                <option value="subscription">Subscription</option>
                            </select>
                        </div>
                    </div>

                    <hr/>
                    <div style={{clear: "both"}}/>

                    <div id="price_form">
                        {prices && prices.map((price, key) => {
                            return (
                                <Fragment key={price.id}>
                                    <div className={`input ${select === `price_${price.id}` ? "select" : ""} ${errors[`price_${price.id}`] ? "error" : ""}`}>
                                        <label>Price*</label>
                                        <input
                                            type="number"
                                            id={`price_${price.id}`}
                                            placeholder="0.00"
                                            min="0"
                                            step=".01"
                                            name="unitAmountDecimal"
                                            required
                                            onBlur={(e) => {
                                                setSelect(null);
                                                e.target.value = parseFloat(e.target.value).toFixed(2);
                                            }}
                                            onClick={(e) => setSelect(e.target.id)}
                                            onChange={(event) => changePricesHandler(event, key)}
                                        />
                                    </div>
                                    <div className={`input ${select === `currency_${price.id}` ? "select" : ""} ${errors[`currency_${price.id}`] ? "error" : ""}`}>
                                        <label>Currency*</label>
                                        <select
                                            id={`currency_${price.id}`}
                                            required
                                            name="currency"
                                            defaultValue="fan"
                                            onBlur={(e) => setSelect(null)}
                                            onClick={(e) => setSelect(e.target.id)}
                                            onChange={(event) => changePricesHandler(event, key)}
                                        >
                                            <option/>
                                            <option value="eur">EUR</option>
                                        </select>
                                    </div>
                                    <div className={`input ${select === `type_${price.id}` ? "select" : ""} ${errors[`type_${price.id}`] ? "error" : ""}`}>
                                        <label>Type*</label>
                                        <select
                                            id={`type_${price.id}`}
                                            required
                                            name="oneTime"
                                            onBlur={(e) => setSelect(null)}
                                            onClick={(e) => setSelect(e.target.id)}
                                            onChange={(event) => changePricesHandler(event, key)}
                                        >
                                            <option/>
                                            <option value="recurring">recurring</option>
                                            <option value="one_time">one_time</option>
                                        </select>
                                    </div>
                                    <div className={`input ${select === `billing_period_${price.id}` ? "select" : ""} ${errors[`billing_period_${price.id}`] ? "error" : ""}`}>
                                        <label>Billing Period</label>
                                        <select
                                            id={`billing_period_${price.id}`}
                                            name="mode"
                                            onBlur={(e) => setSelect(null)}
                                            onClick={(e) => setSelect(e.target.id)}
                                            onChange={(event) => changePricesHandler(event, key)}
                                        >
                                            <option/>
                                            <option value="day">day</option>
                                            <option value="week">week</option>
                                            <option value="month">month</option>
                                            <option value="year">year</option>
                                        </select>
                                    </div>
                                    {key > 0 && (
                                        <div className="submit mb-2">
                                            <input
                                                type="button"
                                                value="Remove Price"
                                                onClick={() => decrement(key)}
                                            />
                                        </div>
                                    )}
                                </Fragment>
                            );
                        })}
                    </div>
                    <div className="submit">
                        <input
                            type="button"
                            value="Add Price"
                            onClick={increment}
                        />
                    </div>

                    <hr/>
                    <div style={{clear: "both"}}/>

                    <div id="advantages_form">
                        {advantages && advantages.map((advantage, key) => {
                            return (
                                <Fragment key={advantage.id}>
                                    <div className="input" data-id={advantage.id}>
                                        <label>Advantage</label>
                                        <select
                                            id="translation"
                                            required
                                            name="advantage_id"
                                            onBlur={(e) => setSelect(null)}
                                            onClick={(e) => setSelect(e.target.id)}
                                            onChange={(event) => changeAdvantagesHandler(event, key)}
                                        >
                                            <option/>
                                            {advantagesOptions.map(option => (
                                                <option key={option.id} value={option.id}>
                                                    {option.title_fr} ({option.description_fr})
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {key >= 0 && (
                                        <div className="submit mb-2">
                                            <input
                                                type="button"
                                                value="Remove Advantage"
                                                onClick={() => decrementAdvantages(key)}
                                            />
                                        </div>
                                    )}
                                </Fragment>
                            )}
                        )}
                    </div>
                    <div className="submit">
                        <input
                            type="button"
                            value="Add Advantage"
                            onClick={incrementAdvantages}
                        />
                    </div>

                    <hr/>
                    <div style={{clear: "both"}}/>

                    <div id="access_form">
                        {accessProduct && dataForm.type_of_product !== "product" && accessProduct.map((access, key) => {
                            return (
                                <Fragment key={access.id}>
                                    <div className={`input ${select === `access_${access.id}` ? "select" : ""} ${errors[`access_${access.id}`] ? "error" : ""}`}>
                                        <label>Product</label>
                                        <div className="access">
                                            <select
                                                id="product"
                                                required
                                                name="product"
                                                onBlur={(e) => setSelect(null)}
                                                onClick={(e) => setSelect(e.target.id)}
                                                onChange={(event) => changeAccessHandler(event, key)}
                                            >
                                                <option value="">Select an option</option>
	                                            {accessOptions.map(access => (
		                                            <option key={access.id} value={access.id}>
			                                            {access.name}
		                                            </option>
	                                            ))}
                                            </select>
                                            <input
                                                type="number"
                                                id={`quantity_${access.id}`}
                                                placeholder="0"
                                                min="1"
                                                name="quantity"
                                                required
                                                onBlur={(e) => {setSelect(null);}}
                                                onClick={(e) => setSelect(e.target.id)}
                                                onChange={(event) => changeAccessHandler(event, key)}
                                            />
                                        </div>
                                    </div>
                                    {key >= 0 && (
                                        <div className="submit mb-2">
                                            <input
                                                type="button"
                                                value="Remove Access"
                                                onClick={() => decrementAccess(key)}
                                            />
                                        </div>
                                    )}
                                </Fragment>
                            );
                        }
                    )}
                    </div>
                    {dataForm && dataForm.type_of_product === "product" ? ("") : (
                        <div className="submit">
                            <input
                                type="button"
                                value="Add Access"
                                onClick={incrementAccess}
                            />
                        </div>
                    )}
                    <hr/>
                    <div style={{clear: "both"}}/>
                    <div className="submit">
                        <input
                            type="submit"
                            value="Add Subscription"
                        />
						<div id="validUpdate" className={`${updated ? "display" : ""}`}>
                            La souscription a été ajouté
                        </div>
                    </div>
                </form>
            </article>
        </div>
    );
};

export default AddSubscription;
