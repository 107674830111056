import React, {useEffect, useRef, useState} from "react";
import {isEmpty} from "./Utils";
import Slider from "react-slick";
import axios from 'axios';
import RightArrow from "./slider/RightArrow";
import LeftArrow from "./slider/LeftArrow";
import FirstDefault from "./playlist/FirstDefault";
import NewFilter from "./playlist/NewFilter";
import useMeasure from "react-use-measure";
import VideoPreview from "./partials/VideoPreview";

const Playlist = (props) => {
	const {
		playlistName,
		playlistVideo,
		filter,
		ligue_id,
		firstCardPlaylist
	} = props.data;
    const playlistId = props.playlistId;
    const sportId = props.sportId;
    const clubPlayerId = props.clubPlayerId;
	const slider = useRef();
	const [videos, setVideos] = useState(null);
	const [countPage, setCountPage] = useState(1);

	const [divisionId, setDivisionId] = useState("");
	const [groupId, setGroupId] = useState("");
	const [clubId, setClubId] = useState(clubPlayerId);
	const [matchDay, setMatchDay] = useState("");
	const [sliderRef, {height, width}] = useMeasure();

	useEffect(() => {
		setVideos(playlistVideo);
	}, [playlistVideo]);

	const fetchPlaylist = async (page, divisionId = "", groupId = "", clubId = "", matchDay = "") => {
        await axios
            .get(`${process.env.REACT_APP_API_URL}getVideosFromPlaylist&id=${playlistId}&n=8&p=${page}&div=${divisionId}&gr=${groupId}&cl=${clubId}&mtd=${matchDay}`)
            .then((res) => {
            	if (res.data.playlistVideo) {
					let responseVideos = res.data.playlistVideo;
            		if (page === 1) {
						setVideos(responseVideos)
					} else {
						setVideos([...videos, ...responseVideos])
					}
				}
            })
            .catch((error) => {
                console.log(error)
            });
    };

	let settings = {
		infinite: false,
		speed: 500,
		slidesToShow: (!isEmpty(width) && width !== 0)  ? Math.floor(width / 400) : 4,
		slidesToScroll: (!isEmpty(width) && width !== 0) ? Math.floor(width / 400) : 4,
		prevArrow: <LeftArrow />,
		nextArrow: <RightArrow />,
		swipeToSlide: false,
		initialSlide: 0,
		centerMode: false,
		afterChange: () => {
			if(document.querySelector(".d-none.arrow-slideshow.right")){
				setCountPage(countPage + 1);
				fetchPlaylist(countPage + 1, divisionId, groupId, clubId, matchDay);
			}
		},
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				}
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			}
		]

	};

	const onSelectFilter = (leagueSelectId, divisionSelectId, groupSelectId, clubSelectId, matchDay) => {
		fetchPlaylist(1, divisionSelectId, groupSelectId, clubSelectId, matchDay);
		setDivisionId(divisionSelectId);
		setGroupId(groupSelectId);
		setClubId(clubSelectId);
		setMatchDay(matchDay);
		setCountPage(1);
		setTimeout(() => {
			if (slider) {
				slider.current.slickGoTo(0);
			}
		}, 1000);
	};

	return (
		<div className="playlist-videos filter white">
			<div className="titles">
				<section
					className={"flex-between m-auto" + (!isEmpty(filter) && " filter")}
				>
					<h3>{!isEmpty(playlistName) && playlistName}</h3>
					{process.env.REACT_APP_PLAYLIST_EMISSION_LIVE !== playlistId && process.env.REACT_APP_PLAYLIST_EMISSION_REPLAY !== playlistId  && (
						<NewFilter
							playlistId={playlistId}
							onSelectFilter={onSelectFilter}
							leagueId={ligue_id}
							sportId={sportId}
						/>
					)}


				</section>
			</div>
			<div className="playlist-slider">

				<FirstDefault width={width} height={height} name={!process.env.REACT_APP_HAS_FIRST_CARD_PLAYLISTS.includes(playlistId) && playlistName} image={firstCardPlaylist}/>

				<div ref={sliderRef} >
					<Slider ref={slider} {...settings} className="videos-container hide-scroll customScroll">
						{!isEmpty(videos) &&
						videos.map((video) => {
							return (
								<VideoPreview video={video} key={video.id}/>
							);
						})}
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default Playlist;
