import React, {useContext, useEffect, useRef, useState} from "react";
import { NavLink, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getVideosClub } from "../actions/video.action";
import {dateRender, hexToHSL, isEmpty} from "../components/Utils";
import { useMediaQuery } from "react-responsive";
import Search from "../components/Search";
import { toggleSearch } from "../actions/search.action";
import {FastAverageColor} from "fast-average-color";

import { MyContext } from "../contexts/MyContext";
import Moment from "react-moment";
import { getWebTVData } from "../actions/webTVS.action";
import { getOneEvent } from "../actions/events.action";

import parse from "html-react-parser";
import Iframe from "react-iframe";

import { CopyToClipboard } from "react-copy-to-clipboard";
import {Helmet} from "react-helmet-async";
import RedirectApp from "../components/RedirectApp";
import fscreen from "fscreen";
import {useRouteMatch} from "react-router";

const Live = () => {
	let match = useRouteMatch();
	const {
		params: { evtID, clubURL },
	} = match;

	const similarVideoReducer = useSelector(
		(state) => state.videoAllReducer.videosClubReducer
	);
	const isMobile = useMediaQuery({ query: `(max-width: 1000px)` });
	const webTV = useSelector((state) => state.webTVData);
	const events = useSelector((state) => state.eventsReducer.eventsAllReducer);
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);
	const [club, setClub] = useState("");
	const [actualEvent, setActualEvent] = useState(null);
	const [height, setHeight] = useState("500");
	const [width, setWidth] = useState("100%");
	const [positionIframe, setPositionIframe] = useState("block");
	const dispatch = useDispatch();
	const refPositionIframe = useRef(positionIframe);

	useEffect(() => {
		dispatch(getOneEvent(evtID));
		dispatch(getWebTVData(clubURL));
	}, [dispatch, clubURL, evtID]);

	useEffect(() => {
		if (!isEmpty(events)) {
			setActualEvent(events[0]);
		}
		if (!isEmpty(webTV)) {
			setClub(webTV[0]);
		}
		if (!isEmpty(club)) {
			dispatch(getVideosClub(club.id));
		}
	}, [dispatch, events, webTV, club]);

	//COLOR LOGO
	const colorize = (img) => {
		const fac = new FastAverageColor();

		let image = new Image();
		image.crossOrigin = "anonymous";
		image.src = img.src;
		fac
			.getColorAsync(image)
			.then((color) => {
				let hsl = hexToHSL(color.hex);
				hsl.s = hsl.s * 4;
				img.parentElement.style.backgroundColor = `hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)`;
			})
			.catch((err) => console.log(err));
	};

	function handleResize() {
		let orientation = window.innerHeight < window.innerWidth ? "landscape-primary" : "portrait-secondary";

		if (refPositionIframe.current === "fixed" && orientation === "landscape-primary") {
			let h = window.innerHeight;
			let w = h * 1.777;
			setHeight(h);
			setWidth(w + "px");
		} else {
			let w = document.getElementById("video").offsetWidth;
			let h = (w / 1.777).toFixed(3); //1.78 is ratio 16:9
			setHeight(h);
			setWidth("100%");
		}
	}

	const searchState = useSelector(
		(state) => state.searchReducer.searchToggleReducer
	);

	useEffect(() => {
		if (searchState) {
			dispatch(toggleSearch(false));
		}
		// eslint-disable-next-line
	}, [location, dispatch]);

	//FOLLOW
	const [className, setClassName] = useState(null);
	const [followHTML, setFollowHTML] = useState(
		translations ? translations["subscribe"] : "subscribe"
	);
	const [redirectToHome, setRedirectToHome] = useState(false);
	const { rootState, toggleFollow, isLoggedIn } = useContext(MyContext);
	const { isAuth, theUser } = rootState;

	useEffect(() => {
		if (!isEmpty(translations)) {
			if (className == null) {
				setFollowHTML(translations ? translations["subscribe"] : "subscribe");
			} else {
				setFollowHTML(translations ? translations["follow"] : "follow");
			}
		}
	}, [translations, className]);

	useEffect(() => {
		if (!isEmpty(theUser)) {
			if (theUser.id_club === null) {
				setClassName(null);
				setFollowHTML(translations ? translations["subscribe"] : "subscribe");
			} else if (theUser.id_club.includes(club.id)) {
				setClassName("follow");
				setFollowHTML(translations ? translations["follow"] : "follow");
			} else {
				setClassName(null);
				setFollowHTML(translations ? translations["subscribe"] : "subscribe");
			}
		}
	}, [setClassName, club, theUser, translations]);

	const onHover = (e) => {
		if (!isEmpty(theUser)) {
			if (!isEmpty(theUser.id_club) && theUser.id_club.includes(club.id)) {
				if (e.type === "mouseenter") {
					setFollowHTML(translations ? translations["unfollow"] : "unfollow");
				} else {
					setFollowHTML(translations ? translations["follow"] : "follow");
				}
			}
		}
	};
	const handleFollow = async (id) => {
		let followNumber = document.querySelector(".title .count-followers");
		if (isAuth !== null && isAuth === true) {
			//UNFOLLOW
			if (theUser.id_club !== null && theUser.id_club.includes(id)) {
				const data = await toggleFollow(id, "unfollow");

				if (data.success) {
					setClassName(null);
					followNumber.innerHTML = parseInt(followNumber.innerHTML, 10) - 1;
				} else {
					console.log(data.message);
				}
			} else {
				//FOLLOW

				const data = await toggleFollow(id, "follow");

				if (data.success) {
					setClassName("follow");
					followNumber.innerHTML = parseInt(followNumber.innerHTML, 10) + 1;
				} else {
					console.log(data.message);
				}
			}
			isLoggedIn();
		} else {
			//setRedirectToHome(true);
		}
	};

	const [copied, setCopied] = useState(false);
	const handleCopy = () => {
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 2500);
	};

	const handlerMessageFullscreen = (event) => {
		if (event.origin === "https://mytvchain.com") {
			if (event.data === "fullscreen-on") {
				if (!isMobile) {
					let element = document.querySelector("#player");
					fscreen.requestFullscreen(element);
				} else {
					setPositionIframe("fixed");
				}
			} else if (event.data === "fullscreen-off") {
				if (!isMobile) {
					fscreen.exitFullscreen();
				} else {
					setPositionIframe("block");
				}
			}
		}
	};

	useEffect(() => {
		refPositionIframe.current = positionIframe;
		handleResize();
	}, [positionIframe]);

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		window.addEventListener("message", handlerMessageFullscreen, false);
		return () => {
			window.removeEventListener("resize", handleResize);
			window.removeEventListener("message", handlerMessageFullscreen, false);
		};
	}, []);

	return (
		<>
			<Helmet>
				{!isEmpty(actualEvent) && (
					<>
						{!isEmpty(actualEvent.titleFR) && (
							<title>{`Fuchs Sports International - ${actualEvent.titleFR}`}</title>
						)}

						{!isEmpty(actualEvent.titleFR) && (
							<meta name="title" content={`Fuchs Sports International - ${actualEvent.titleFR}`} />
						)}

						{(!isEmpty(actualEvent.titleFR) || !isEmpty(actualEvent.descriptionFR)) && (
							<meta name="description" content={`Fuchs Sports International ${!isEmpty(actualEvent.titleFR) ? ` - ${actualEvent.titleFR}` : ""} ${!isEmpty(actualEvent.descriptionFR) ? ` - ${actualEvent.descriptionFR}` : ""}`} />
						)}

						<meta property="og:type" content="video.movie" />
						{!isEmpty(actualEvent.titleFR) && (
							<meta property="og:title" content={`Fuchs Sports International - ${actualEvent.titleFR}`} />
						)}

						{!isEmpty(actualEvent.descriptionFR) && (
							<meta property="og:description" content={`Fuchs Sports International - ${actualEvent.descriptionFR}`} />
						)}
						{!isEmpty(actualEvent.img) && (
							<meta property="og:image" content={`${actualEvent.img}`} />
						)}
					</>
				)}
			</Helmet>

			<RedirectApp page="event"/>

			<div>
				{redirectToHome && <Redirect to="/" />}
				{searchState && <Search />}
				{positionIframe === "fixed" && (
					<div id="video_player_bg">
						<div id="fullscreen_background"/>
					</div>
				)}
				<div className="player-page container">
					<div className="d-flex">
						<div className={`video-iframe ${positionIframe}`}>
							<div id="video">
								{!isEmpty(club.id) && !isEmpty(evtID) &&
									<Iframe
										name="video-frame"
										id="player"
										title="video"
										width={width}
										height={height + "px"}
										url={`https://mytvchain.com/liveevt.php?e=${club.id}&ide=${evtID}&dr=0&domain=fuchs-sports&ai=${!isEmpty(theUser) && theUser.id}&ad=1`}
										scrolling="no"
										loading="eager"
										onLoad={handleResize}
										allow="fullscreen;autoplay;encrypted-media;geolocation"
									>
										Your browser doesn't support player functionnality...
									</Iframe>
								}
							</div>
							<div className="video-data">
								<div className="first-row">
									<h2>{!isEmpty(actualEvent) && actualEvent.titleFR}</h2>
									<div className="share">
										<div className="share-button">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
											>
												<path d="M5 7c2.761 0 5 2.239 5 5s-2.239 5-5 5-5-2.239-5-5 2.239-5 5-5zm11.122 12.065c-.073.301-.122.611-.122.935 0 2.209 1.791 4 4 4s4-1.791 4-4-1.791-4-4-4c-1.165 0-2.204.506-2.935 1.301l-5.488-2.927c-.23.636-.549 1.229-.943 1.764l5.488 2.927zm7.878-15.065c0-2.209-1.791-4-4-4s-4 1.791-4 4c0 .324.049.634.122.935l-5.488 2.927c.395.535.713 1.127.943 1.764l5.488-2.927c.731.795 1.77 1.301 2.935 1.301 2.209 0 4-1.791 4-4z" />
											</svg>
											{translations && !isEmpty(translations["share"])
												? parse(translations["share"])
												: "share"
											}
										</div>

										<ul>
											{copied && (
												<li className="copy-message">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
													>
														<path d="M15.143 13.244l.837-2.244 2.698 5.641-5.678 2.502.805-2.23s-8.055-3.538-7.708-10.913c2.715 5.938 9.046 7.244 9.046 7.244zm8.857-7.244v18h-18v-6h-6v-18h18v6h6zm-2 2h-12.112c-.562-.578-1.08-1.243-1.521-2h7.633v-4h-14v14h4v-3.124c.6.961 1.287 1.823 2 2.576v6.548h14v-14z" />
													</svg>
													Copy in clipboard !
												</li>
											)}

											<CopyToClipboard onCopy={handleCopy} text={window.location.href}>
												<li>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
													>
														<path d="M6.188 8.719c.439-.439.926-.801 1.444-1.087 2.887-1.591 6.589-.745 8.445 2.069l-2.246 2.245c-.644-1.469-2.243-2.305-3.834-1.949-.599.134-1.168.433-1.633.898l-4.304 4.306c-1.307 1.307-1.307 3.433 0 4.74 1.307 1.307 3.433 1.307 4.74 0l1.327-1.327c1.207.479 2.501.67 3.779.575l-2.929 2.929c-2.511 2.511-6.582 2.511-9.093 0s-2.511-6.582 0-9.093l4.304-4.306zm6.836-6.836l-2.929 2.929c1.277-.096 2.572.096 3.779.574l1.326-1.326c1.307-1.307 3.433-1.307 4.74 0 1.307 1.307 1.307 3.433 0 4.74l-4.305 4.305c-1.311 1.311-3.44 1.3-4.74 0-.303-.303-.564-.68-.727-1.051l-2.246 2.245c.236.358.481.667.796.982.812.812 1.846 1.417 3.036 1.704 1.542.371 3.194.166 4.613-.617.518-.286 1.005-.648 1.444-1.087l4.304-4.305c2.512-2.511 2.512-6.582.001-9.093-2.511-2.51-6.581-2.51-9.092 0z" />
													</svg>
													Video Page Link
												</li>
											</CopyToClipboard>
											<CopyToClipboard
												onCopy={handleCopy}
												text={`https://fuchs-sports.com/iframe-live/${club.id}/${evtID}`}
											>
												<li>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
													>
														<path d="M24 10.935v2.131l-8 3.947v-2.23l5.64-2.783-5.64-2.79v-2.223l8 3.948zm-16 3.848l-5.64-2.783 5.64-2.79v-2.223l-8 3.948v2.131l8 3.947v-2.23zm7.047-10.783h-2.078l-4.011 16h2.073l4.016-16z" />
													</svg>
													Embeded Video Link
												</li>
											</CopyToClipboard>
										</ul>
									</div>
								</div>
								<p>
									{!isEmpty(actualEvent) && (
										<Moment format="DD/MM/YYYY">{actualEvent.start}</Moment>
									)}
								</p>
							</div>
							<div className="webtv-data">
								<div className="logo-container">
									<NavLink
										className="logo"
										exact
										to={!isEmpty(club) ? `/${club.url}` : "/"}
									>
										<img
											src={!isEmpty(club) ? club.logo : undefined}
											alt={`Logo ${!isEmpty(club) && club.name}`}
											onLoad={(e) => {
												isEmpty(club.color)
													? colorize(e.target)
													: (e.target.parentElement.style.backgroundColor = club.color);
											}}
										/>
									</NavLink>
								</div>

								<div className="title">
									<NavLink exact to={`/${club.url}`}>
										<h3>{!isEmpty(club) && club.name}</h3>
									</NavLink>
									<div className="followers">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
										>
											<path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z" />
										</svg>
										<span className="count-followers">{club.followers}</span>{" "}
										{translations && !isEmpty(translations["followers"])
											? parse(translations["followers"])
											: "followers"
										}
									</div>
								</div>
								<button
									className={className}
									onMouseEnter={(e) => {
										onHover(e);
									}}
									onMouseLeave={(e) => onHover(e)}
									onClick={() => {
										handleFollow(club.id);
									}}
								>
									{parse(followHTML)}
								</button>
							</div>
							<div className="descr">
								<h3>
									{translations && !isEmpty(translations["description"])
										? parse(translations["description"])
										: "description"
									}
								</h3>
								<p>{!isEmpty(actualEvent) && actualEvent.descriptionFR}</p>
							</div>
						</div>
					</div>

					{!isMobile && (
						<div className="similar-video-container">
							<h3>
								{translations && !isEmpty(translations["similarVideos"])
									? parse(translations["similarVideos"])
									: "similarVideos"
								}
							</h3>

							{!isEmpty(similarVideoReducer) && (
								<div className="similar-grid">
									{!isEmpty(similarVideoReducer.playlistVideo) &&
										similarVideoReducer.playlistVideo.map((video) => {
											return (
												<div className="video" key={video.id}>
													<NavLink
														exact
														to={`/player/${video.id}`}
														onClick={() => window.scrollTo(0, 0)}
													>
														<img src={video.img} alt={`Apercu de ${video.name}`} />
														<div className="title-container">
															<h4>{video.name}</h4>
														</div>
														{video.time > 0 && <span>{dateRender(video.time)}</span>}
													</NavLink>
												</div>
											);
										})}
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Live;
