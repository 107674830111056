import axios from "axios";

export const TOGGLE_PARAMETER = "TOGGLE_PARAMETER";

export const toogleParameter = (value) => {
	return (dispatch) => {
		return dispatch({ type: TOGGLE_PARAMETER, payload: value });
	};
};

export const TOGGLE_NOTIFICATION = "TOGGLE_NOTIFICATION";

export const toggleNotification = (value) => {
	return (dispatch) => {
		return dispatch({ type: TOGGLE_NOTIFICATION, payload: value });
	};
};

export const TOGGLE_CONTACT_FORM = "TOGGLE_CONTACT_FORM";

export const toggleContactForm = (value) => {
	return (dispatch) => {
		return dispatch({ type: TOGGLE_CONTACT_FORM, payload: value });
	};
};

export const GET_COUNTRY_LIST_USER = "GET_COUNTRY_LIST_USER";

export const getCountryUser = () => {
	return async (dispatch) => {
		await axios.get(`${process.env.REACT_APP_API_URL}getCountryListUser`)
			.then((res) => dispatch({ type: GET_COUNTRY_LIST_USER, payload: res.data }))
			.catch((error) => {
				console.log(error)
			});
	};
};
