import React from "react";
import HandleHighlights from "./HandleHighlights";

const Highlights = ({ selectedCountry }) => {

	let { id, countryName } = selectedCountry;

	return (
		<div className="bo-highlights">
			<div className="bo-highlight-component">
				<HandleHighlights countryID={id} countryName={countryName}/>
			</div>
		</div>
	);
};

export default Highlights;
