import axios from "axios";

export const GET_SETTING = "GET_SEARCH";

export const getSettings = () => {
    return async (dispatch) => {
        await axios.get(`${process.env.REACT_APP_API_URL}getSettings`)
            .then((res) =>
                dispatch({ type: GET_SETTING, payload: res.data })
            )
            .catch((error) => {
                console.log(error)
            });
    };
};
