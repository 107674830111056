import axios from "axios";
import {GET_TRANSLATIONS} from "./translations.action";

const Axios = axios.create();

export const GET_PUSH_NOTIFICATIONS = "GET_PUSH_NOTIFICATIONS";
export const getPushNotifications = () => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginAdminToken");

		if (loginToken) {
			//Adding JWT token to axios default header
			Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;
			if (process.env.REACT_APP_ENV === "production") {
				Axios.defaults.headers.common["Authorization"] = "Bearer " + loginToken;
			}

			await Axios.get(`${process.env.REACT_APP_API_URL}getPushNotifications`)
				.then((res) =>
					dispatch({ type: GET_PUSH_NOTIFICATIONS, payload: res.data })
				)
				.catch((error) => {
					console.log(error)
				});
		} else {
			console.log("You are not auth")
		}

	};
}

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const getNotifications = (start = null) => {
	return async (dispatch) => {

		const loginToken = localStorage.getItem("loginToken");
		let config = {
			headers: {
				"Authorization-Bearer": `Bearer ${loginToken}`
			},
		};

		if (process.env.REACT_APP_ENV === "production") {
			config = {
				headers: {
					"Authorization-Bearer": `Bearer ${loginToken}`,
					"Authorization": `Bearer ${loginToken}`
				},
			};
		}
		let url = `${process.env.REACT_APP_API_URL}getNotifications`;
		if (start !== null) {
			url += `&s=${encodeURI(start)}`
		}
		await axios.get(url, config)
			.then((res) =>
				dispatch({ type: GET_NOTIFICATIONS, payload: res.data })
			).catch((error) => {
				console.log(error)
			});
	};
};
