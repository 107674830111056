import axios from "axios";
const Axios = axios.create();

export const GET_FAVORITE = "GET_FAVORITE";

export const getFavorite = () => {
	const loginToken = localStorage.getItem("loginToken");

	return async (dispatch) => {
		//Adding JWT token to axios default header
		if (loginToken) {
			Axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;
			if (process.env.REACT_APP_ENV === "production") {
				Axios.defaults.headers.common["Authorization"] = "Bearer " + loginToken;
			}
		}

		await Axios
			.post(`${process.env.REACT_APP_NOT_CACHED_API_URL}getFollows`)
			.then((res) => dispatch({ type: GET_FAVORITE, payload: res.data.follows }))
			.catch((err) => console.log(err));
	};
};