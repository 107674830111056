import React, { useState, useEffect, useCallback, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import {
    getOrderedProducts,
    getCustomer,
} from "../../../actions/stripe.action";
import { isEmpty } from "../../Utils";
import parse from "html-react-parser";
import Moment from "react-moment";
import { MyContext } from "../../../contexts/MyContext";

const AthleteVideos = () => {
    const dispatch = useDispatch();
    const stripeReducer = useSelector((state) => state.stripeReducer);
    const orderedProducts = stripeReducer.getOrderedProductsReducer;
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translations
    );
    const [actualFilter, setActualFilter] = useState(null);
    const { rootState } = useContext(MyContext);
    const { isAuth } = rootState;

    useEffect(() => {
        if (isAuth) {
            dispatch(getOrderedProducts(actualFilter));
        }
    }, [isAuth, actualFilter]);

    return (
        <div className="videos-page">
            <h3>
                {translations && !isEmpty(translations["ordered_videos"])
                    ? parse(translations["ordered_videos"])
                    : "My Ordered Videos"}
            </h3>
            <div className="nav-filter">
                <ul className="filter-tabs">
                    <li className="filter-item">
                        <span
                            className={
                                actualFilter === null
                                    ? "filter active"
                                    : "filter"
                            }
                            onClick={() => {
                                setActualFilter(null);
                            }}
                        >
                            {translations &&
                            !isEmpty(translations["all_my_videos"])
                                ? parse(translations["all_my_videos"])
                                : "All My Videos"}
                        </span>
                    </li>
                    <li className="filter-item">
                        <span
                            className={
                                actualFilter === 3 ? "filter active" : "filter"
                            }
                            onClick={() => {
                                setActualFilter(3);
                            }}
                        >
                            {translations && !isEmpty(translations["cv_video"])
                                ? parse(translations["cv_videos"])
                                : "CV Video"}
                        </span>
                    </li>
                    <li className="filter-item">
                        <span
                            className={
                                actualFilter === 12 ? "filter active" : "filter"
                            }
                            onClick={() => {
                                setActualFilter(12);
                            }}
                        >
                            {translations &&
                            !isEmpty(translations["match_report"])
                                ? parse(translations["match_report"])
                                : "Match Report"}
                        </span>
                    </li>
                </ul>
            </div>
            <div className="videos">
                {orderedProducts &&
                    orderedProducts.map((video) => {
                        return (
                            <div className="item" key={video.id} id={video.id}>
                                <div className="content">
                                    {video.status !== "ready" ? (
                                        <div className="being-created">
                                            <div className="loader-dot">
                                                <svg id="svg-spinner" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><circle cx="24" cy="4" r="4" fill="#fff"/><circle cx="12.19" cy="7.86" r="3.7" fill="#fff"/><circle cx="5.02" cy="17.68" r="3.4" fill="#fff"/><circle cx="5.02" cy="30.32" r="3.1" fill="#fff"/><circle cx="12.19" cy="40.14" r="2.8" fill="#fff"/><circle cx="24" cy="44" r="2.5" fill="#fff"/><circle cx="35.81" cy="40.14" r="2.2" fill="#fff"/><circle cx="42.98" cy="30.32" r="1.9" fill="#fff"/><circle cx="42.98" cy="17.68" r="1.6" fill="#fff"/><circle cx="35.81" cy="7.86" r="1.3" fill="#fff"/></svg>
                                            </div>
                                            <div className="loader"/>
                                            <h2>
                                                {video.product_name} -{" "}
                                                <Moment format="DD/MM/YYYY">
                                                    {video.date_created}
                                                </Moment>
                                            </h2>
                                            <h3>
                                                {translations && !isEmpty(translations["being_created"])
                                                    ? parse(translations["being_created"])
                                                    : "Being created..."}
                                            </h3>
                                        </div>
                                    ) : (
                                        <>
                                            <img
                                                src={video.img}
                                                alt="Video Image"
                                                width="267"
                                                height="151"
                                            />
                                            <h2>
                                                {video.product_name} -{" "}
                                                <Moment format="DD/MM/YYYY">
                                                    {video.date_created}
                                                </Moment>
                                            </h2>
                                            <div className="buttons">
                                                <div>
                                                    <NavLink
                                                        exact
                                                        rel="noopener noreferrer"
                                                        to={`/profile/video-player/${video.id}`}
                                                        onClick={() => window.scrollTo(0, 0)}
                                                    >
                                                        <button className="btn btn-stripe">
                                                            {translations && !isEmpty(translations["see_video"])
                                                                ? parse(translations["see_video"])
                                                                : "See the video"}
                                                        </button>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default AthleteVideos;
