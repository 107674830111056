import React, { useEffect, useState} from "react";
import {isEmpty, isFunction} from "../Utils";
import AsyncSelect from "react-select/async";

const AsyncSelectInput = ({handleClick, classes, handleChange, name, defaultValue, optionValue, optionLabel, loadOptions, label, errorText, isMulti, maxSelected, placeholder, border, backgroundColor, error: propError = false}) => {
    const [selected, setSelected] = useState(false);
    const [error, setError] = useState(false);
    const [localValue, setLocalValue] = useState();

    const onClick = (event) => {

    };

    useEffect(() => {
        setError(propError)
    }, [propError]);

    useEffect(() => {
        if(isFunction(handleChange) && localValue) {
            if (isMulti) {
                handleChange(name, localValue.map(i => i[optionValue]))
            } else {
                handleChange(name, localValue[optionValue])
            }
        }
    }, [localValue]);

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            width: "100%",
            cursor: "pointer",
            borderBottom: !isEmpty(border) && border === true ? "2px solid #ffffff" : "unset",
            height: "2.8rem",
        }),
        option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
            ...provided,
            color: isDisabled ? undefined : isSelected ? "#ffffff" : isFocused ? "black" : undefined,
            cursor: "pointer",
            backgroundColor: isDisabled ? undefined : isSelected ? "#49546c" : isFocused ? "white" : undefined,
            ":hover": {
                ...provided[":hover"],
                color: isSelected ? "#ffffff" : "black"
            },
        }),
        input: (provided, state) => ({
            ...provided,
            height: "1.8rem",
            margin: "0",
            color: "#ffffff",
            ":after": {
                ...provided[":after"],
                color: "#ffffff"
            },
        }),
        control: (provided, state) => ({
            ...provided,
            borderRadius: "unset",
            borderStyle: "none",
            borderWidth: "unset",
            minHeight: "unset",
            height: "inherit",
            backgroundColor: isEmpty(backgroundColor) || backgroundColor !== false ? "rgba(202, 211, 255, 0.0676517)" : "unset",
            color: "#ffffff",
            boxShadow: "none",
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: "0px 8px",
            overflow: "inherit"
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#ffffff"
        }),
        menu: (provided, state) => ({
            ...provided,
            color: "#ffffff",
            backgroundColor: "#1b283c",
            marginTop: 0,
            borderRadius: "0 0 4px 4px",
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            height: "inherit"
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none"
        })
    };

    return (
        <div className={`input ${selected ? " select" : ""}${error ? " error" : ""} ${!isEmpty(classes) ? classes : ""}`}>
            {label && (
                <label>
                    {label}
                </label>
            )}
            <AsyncSelect
                onClick={(e) => onClick(e)}
                key={defaultValue} //for force re-render to show default value
                defaultValue={defaultValue}
                getOptionValue={ x => x[optionValue]}
                getOptionLabel={ x => x[optionLabel]}
                styles={customStyles}
                loadOptions={loadOptions}
                onFocus={() => setSelected(true)}
                onBlur={() => setSelected(false)}
                isSearchable
                isOptionDisabled={() => !isEmpty(maxSelected) && !isEmpty(localValue) ? localValue.length >= maxSelected : false}
                isMulti={isMulti}
                isClearable={false}
                placeholder={placeholder}
                onChange={(option) => setLocalValue(option)}
            />
            {error && (
                <div className="errorDisplay">
                    {errorText}
                </div>
            )}
        </div>
    );
};

export default AsyncSelectInput;