import React, { useState } from "react";
import SecurityParam from "./SecurityParam";
import NotificationParam from "./NotificationParam";
import Preferences from "./Preferences";
import { useDispatch, useSelector } from "react-redux";
import { toogleParameter } from "../../actions/account.action";

import parse from "html-react-parser";
import {isEmpty} from "../Utils";

const Parameters = () => {
	const [preferences, setPreferences] = useState(true);
	const [security, setSecurity] = useState(false);
	const [notification, setNotification] = useState(false);
	const dispatch = useDispatch();
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);

	return (
		<div className="parameters-container hide-scroll">
			<div className="cross" onClick={() => dispatch(toogleParameter(false))}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
				>
					<path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
				</svg>
			</div>

			<nav className="menu">
				<h3>
					{translations && !isEmpty(translations["settings"])
						? parse(translations["settings"])
						: "settings"
					}
				</h3>
				<ul>
					<li
						className={preferences ? "selected" : null}
						onClick={() => {
							setPreferences(true);
							setSecurity(false);
							setNotification(false);
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
						>
							<path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.326 18.266l-4.326-2.314-4.326 2.313.863-4.829-3.537-3.399 4.86-.671 2.14-4.415 2.14 4.415 4.86.671-3.537 3.4.863 4.829z" />
						</svg>
						<span>
							{translations && !isEmpty(translations["registerPreferences"])
								? parse(translations["registerPreferences"])
								: "registerPreferences"}
						</span>
					</li>
					<li
						className={security ? "selected" : null}
						onClick={() => {
							setPreferences(false);
							setSecurity(true);
							setNotification(false);
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
						>
							<path d="M18 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-10 0v-4c0-2.206 1.794-4 4-4s4 1.794 4 4v4h-8z" />
						</svg>
						<span>
							{translations && !isEmpty(translations["security"])
								? parse(translations["security"])
								: "security"
							}
						</span>
					</li>
					<li
						className={notification ? "selected" : null}
						onClick={() => {
							setPreferences(false);
							setSecurity(false);
							setNotification(true);
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
						>
							<path d="M15.137 3.945c-.644-.374-1.042-1.07-1.041-1.82v-.003c.001-1.172-.938-2.122-2.096-2.122s-2.097.95-2.097 2.122v.003c.001.751-.396 1.446-1.041 1.82-4.667 2.712-1.985 11.715-6.862 13.306v1.749h20v-1.749c-4.877-1.591-2.195-10.594-6.863-13.306zm-3.137-2.945c.552 0 1 .449 1 1 0 .552-.448 1-1 1s-1-.448-1-1c0-.551.448-1 1-1zm3 20c0 1.598-1.392 3-2.971 3s-3.029-1.402-3.029-3h6z" />
						</svg>
						<span>
							{translations && !isEmpty(translations["notifications"])
								? parse(translations["notifications"])
								: "notifications"
							}
						</span>
					</li>
				</ul>
			</nav>
			{preferences && <Preferences />}
			{security && <SecurityParam />}
			{notification && <NotificationParam />}
		</div>
	);
};

export default Parameters;
