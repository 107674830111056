import React, {useContext, useEffect, useRef, useState} from "react";
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import axios from "axios";
import {isEmpty, removeTags} from "../../components/Utils";
import {useDispatch, useSelector} from "react-redux";
import {getPushNotifications} from "../../actions/notifications.action";
import {AdminContext} from "../../contexts/AdminContext";

const PushNotifications = () => {
	const selectInputRef = useRef();
	const selectInputUserRef = useRef();
	const dispatch = useDispatch();
	const { pushNotificationCreate } = useContext(AdminContext);
    const [activeEntityType, setActiveEntityType] = useState();
    const [activeEntityId, setActiveEntityId] = useState();
    const [activeUserId, setActiveUserId] = useState();
	const notifications = useSelector(
		(state) => state.notificationsReducer.pushNotificationsReducer.notifications
	);

	useEffect(() => {
		dispatch(getPushNotifications());
	}, []);

	const customStyles = {
		container: (provided, state) => ({
			...provided,
			width: "70%",
			cursor: "pointer"
		}),
		option: (provided) => ({
			...provided,
			color: "black",
			cursor: "pointer"
		})
	};

	const options = [
		{ value: 'new_video', label: 'Upload new video' },
		{ value: 'new_event', label: 'Added new event' },
		{ value: 'event_is_live', label: 'Event is live' }
	]

	useEffect(() => {
		if (selectInputRef.current) {
			selectInputRef.current.clearValue()
			selectInputUserRef.current.clearValue()
		}
	}, [activeEntityType]);

	const loadOptions = async (inputValue, callback) => {
		if (inputValue.length > 2 && !isEmpty(activeEntityType)) {
			if (activeEntityType === "new_event" || activeEntityType === "event_is_live") {
				await axios.get(`${process.env.REACT_APP_API_URL}getEvents&like=${encodeURIComponent(inputValue)}`)
					.then((res) => {
						let prepareArray = [];
						res.data.events.forEach((event) => {
							prepareArray.push({
								value: event.evtId,
								label: event.titleFR + " (" + event.team1Name + " & " + event.team2Name + ")"
							})
						});
						callback(prepareArray);
					});
			} else if (activeEntityType === "new_video") {
				await axios
					.get(`${process.env.REACT_APP_API_URL}getVideos&like=${encodeURIComponent(inputValue)}`)
					.then((res) => {
						let prepareArray = [];
						res.data.videos.forEach((video) => {
							prepareArray.push({
								value: video.id,
								label: video.name + " (id: " + video.id + ")"
							})
						});
						callback(prepareArray);
					});
			}
		}
	};

	const loadUserOptions = async (inputValue, callback) => {
		if (inputValue.length > 2 && !isEmpty(activeEntityType)) {
			const loginToken = localStorage.getItem("loginAdminToken");

			if (loginToken) {
				//Adding JWT token to axios default header
				axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginToken;
			}
			await axios.get(`${process.env.REACT_APP_API_URL}getUsers&l=${encodeURIComponent(inputValue)}`)
				.then((res) => {
					let prepareArray = [];
					if (res.data.result === "ok") {
						res.data.users.forEach((user) => {
							prepareArray.push({
								value: user.id,
								label: user.email
							})
						});
					}
					callback(prepareArray);
				});
		}
	};

	const submitForm = async () => {
		if (!isEmpty(activeEntityType) && !isEmpty(activeEntityId)) {
			let notification = {
				type: activeEntityType,
				typeId: activeEntityId,
				user: activeUserId
			};
			let data = await pushNotificationCreate(notification);

			if (data.success === 1 && data.status === 200) {
				alert(data.message)
				dispatch(getPushNotifications());
			}
		} else {
			alert("activeEntityType or activeEntityId is empty")
		}

	};

	return (
        <div className="bo-notification">
            <header>
                <h1>Push Notification</h1>
            </header>

			<div className="content">
				<div className="notifications">
					<h3>Create notification</h3>
					<div className="row">
						<label htmlFor="type">Notification Type</label>
						<Select
							styles={customStyles}
							options={options}
							onChange={(option) => setActiveEntityType(option.value)}
						/>
					</div>

					<div className="row">
						<label htmlFor="search">
							Type the name
						</label>
						<AsyncSelect
							isClearable
							ref={selectInputRef}
							styles={customStyles}
							isDisabled={isEmpty(activeEntityType)}
							loadOptions={loadOptions}
							onChange={(option) => setActiveEntityId(option ? option.value : null)}
						/>
					</div>

					<div className="row">
						<label htmlFor="search">
							For user
						</label>
						<AsyncSelect
							isClearable
							ref={selectInputUserRef}
							styles={customStyles}
							isDisabled={isEmpty(activeEntityType) || isEmpty(activeEntityId)}
							loadOptions={loadUserOptions}
							onChange={(option) => setActiveUserId(option ? option.value : null)}
						/>
					</div>

					<div className="row">
						<button className="button"
								onClick={submitForm}
								disabled={isEmpty(activeEntityType) || isEmpty(activeEntityId)}
						>Push</button>
					</div>
				</div>
				<div className="notifications">
					<h3>Notification list (last 10)</h3>

					<div className="listing row">
						<ul>
							{!isEmpty(notifications) &&
								notifications.map((notification) => {
									return (
										<li key={notification.fpn_id}>
											<span className="count">({notification.count})</span>
											<img src={notification.img} alt=""/>
											<span className="name">{notification.name}</span>
										</li>

									)
								})
							}
						</ul>
					</div>
				</div>
			</div>
        </div>
    );
};

export default PushNotifications;
