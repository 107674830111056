import axios from "axios";

export const GET_SEARCH = "GET_SEARCH";
export const DELETE_SEARCH = "DELETE_SEARCH";
export const TOGGLE_SEARCH = "TOGGLE_SEARCH";

export const getSearch = (input) => {
	return async (dispatch) => {
		// dispatch({ type: DELETE_SEARCH });
		let search = encodeURIComponent(input.toLowerCase());
		await axios.get(`${process.env.REACT_APP_API_URL}search&i=${search}`)
			.then((res) =>
				dispatch({ type: GET_SEARCH, payload: res.data })
			)
			.catch((error) => {
				console.log(error)
			});
	};
};

export const toggleSearch = (input) => {
	return (dispatch) => {
		dispatch({ type: TOGGLE_SEARCH, payload: input });
	};
};
