import axios from "axios";

export const GET_DIVISIONS = "GET_DIVISIONS";

export const getDivisions = (id = '', leagueId = '', sportId = '', country = '', lang = 'EN') => {
	return async (dispatch) => {
		await axios
			.get(`${process.env.REACT_APP_API_URL}getDivisions&i=${id}&le=${leagueId}&idsp=${sportId}&country=${country}&l=${lang}`)
			.then((res) => dispatch({type: GET_DIVISIONS, payload: res.data}))
			.catch((error) => {
				console.log(error)
			});
	};
};
