import { GET_LEAGUES } from "../actions/leagues.action";

const initialState = [];

export default function leaguesReducer(state = initialState, action) {
	switch (action.type) {
		case GET_LEAGUES:
			return action.payload;
		default:
			return state;
	}
}
