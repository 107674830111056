import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MyContext } from "../../contexts/MyContext";
import {isEmpty, removeTags} from "../Utils";

import parse from "html-react-parser";

const SecurityParam = () => {
	const [password, setPassword] = useState(null);
	const [passwordVerify, setPasswordVerify] = useState(null);
	const [capital, setCapital] = useState(false);
	const [letterNumber, setLetterNumber] = useState(false);
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);

	const [allow, setAllow] = useState(false);
	const activeInput = (id) => {
		let inputs = document.querySelectorAll(
			".parameters-container .security .input"
		);
		inputs.forEach((input) => {
			input.classList.remove("select");
		});

		let input = document.getElementById(id);
		input.parentElement.classList.add("select");
	};

	//HANDLE ERROR
	useEffect(() => {
		if (!isEmpty(password)) {
			password.length >= 8 ? setLetterNumber(true) : setLetterNumber(false);
			password.match(/^(?=.*[A-Z])[0-9a-zA-Z-+_!@#$%^&*.,?]{8,}$/)
				? setCapital(true)
				: setCapital(false);

			password.length < 8 ||
			!password.match(/^(?=.*[A-Z])[0-9a-zA-Z-+_!@#$%^&*.,?]{8,}$/)
				? document.querySelector("#password").parentElement.classList.add("error")
				: document
						.querySelector("#password")
						.parentElement.classList.remove("error");
		}
		if (!isEmpty(password) && !isEmpty(passwordVerify)) {
			passwordVerify !== password
				? document
						.querySelector("#passwordConfirm")
						.parentElement.classList.add("error")
				: document
						.querySelector("#passwordConfirm")
						.parentElement.classList.remove("error");
		}
	}, [password, passwordVerify]);

	useEffect(() => {
		setAllow(false);
		if (!isEmpty(password) && !isEmpty(passwordVerify) && capital && letterNumber && passwordVerify === password) {
			setAllow(true);
		}
	}, [capital, letterNumber, password, passwordVerify]);

	const { updatePassword } = useContext(MyContext);

	const submitForm = async (event) => {
		event.preventDefault();
		if (allow) {
			const data = await updatePassword(password);

			if (data.success) {
				console.log("updated");
			} else {
				console.log(data.message);
			}
		}
	};
	return (
		<section className="security">
			<h3>Sécurité</h3>
			<form onSubmit={submitForm}>
				<div className="input select">
					<label>
						{translations && !isEmpty(translations["registerPassword"])
							? parse(translations["registerPassword"])
							: "registerPassword"}{" "}
						*
					</label>
					<input
						type="password"
						name="password"
						id="password"
						placeholder={
							translations && !isEmpty(translations["registerPasswordPH"])
								? parse(
									translations["registerPasswordPH"]
											.replace("<p>", "")
											.replace("</p>", "")
								  )
								: "registerPasswordPH"
						}
						onFocus={(e) => activeInput(e.target.id)}
						required
						autoFocus
						autoComplete="new-password"
						onClick={(e) => activeInput(e.target.id)}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</div>
				<div className="input select">
					<label>
						{translations && !isEmpty(translations["registerPasswordConfirm"])
							? parse(translations["registerPasswordConfirm"])
							: "registerPasswordConfirm"}{" "}
						*
					</label>
					<input
						type="password"
						name="passwordConfirm"
						id="passwordConfirm"
						placeholder={
							translations && !isEmpty(translations["registerPasswordConfirmPH"])
								? parse(
										translations["registerPasswordConfirmPH"]
											.replace("<p>", "")
											.replace("</p>", "")
								  )
								: "registerPasswordConfirmPH"
						}
						onFocus={(e) => activeInput(e.target.id)}
						required
						autoFocus
						autoComplete="new-password"
						onClick={(e) => activeInput(e.target.id)}
						onChange={(e) => setPasswordVerify(e.target.value)}
					/>
				</div>
				<div className="error">
					{translations && !isEmpty(translations["registerPasswordCheck"])
						? parse(translations["registerPasswordCheck"])
						: "registerPasswordCheck"}
					<div className="validator">
						<div>
							{capital ? (
								<svg
									className="validate"
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
								</svg>
							) : (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
								</svg>
							)}
							{translations && !isEmpty(translations["registerPasswordCapital"])
								? parse(translations["registerPasswordCapital"])
								: "registerPasswordCapital"}
						</div>
						<div>
							{letterNumber ? (
								<svg
									className="validate"
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
								</svg>
							) : (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
								</svg>
							)}
							{translations && !isEmpty(translations["registerPasswordCharacters"])
								? parse(translations["registerPasswordCharacters"])
								: "registerPasswordCharacters"}
						</div>
					</div>
				</div>
				<input
					type="submit"
					value={
						translations && !isEmpty(translations["update"])
							? removeTags(translations["update"])
							: "update"
					}
				/>
			</form>
		</section>
	);
};

export default SecurityParam;
