import React from "react";
import { isEmpty } from "../Utils";

const Banner = (props) => {
	const { title, bannerPatch } = props;
	return (
		<div className="banner">
			<div className="banner-container">
				<section className="item">
					<div className="title">
						{!isEmpty(title) && (
							<h1 className="decor">
								<span>{title}</span>
							</h1>
						)}
					</div>
					<img
						src={
							!isEmpty(bannerPatch) ? bannerPatch : `${process.env.REACT_APP_CACHE_SERVER}/img/footages/banner-event.png`
						}
						width="100"
						alt={`Banner event ${title}`}
					/>
					<div className="dots">
						<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/dots1.png`} alt="decoration elements" />
						<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/dots2.png`} alt="decoration elements" />
						<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/sport_transparent.png`} alt="decoration elements"/>
					</div>
				</section>
			</div>
		</div>
	);
};

export default Banner;
