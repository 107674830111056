import {GET_SETTING} from "../actions/setting.action";
const initialState = false;

export default function settingReducer(state = initialState, action) {
	switch (action.type) {
		case GET_SETTING:
			return action.payload;
		default:
			return state;
	}
}