import React, {useEffect, useState} from "react";
import {isMobileDevise, removeTags} from "../components/Utils";
import {useLocation} from "react-router";
import {useSelector} from "react-redux";

const RedirectApp = ({page, club}) => {
	const location = useLocation();
	const [redirectUrl, setRedirectUrl] = useState("/");
	const [isMobile, setIsMobile] = useState(null);
	const [popup, setPopup] = useState(false);
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);

	useEffect(() => {
		setIsMobile(isMobileDevise());
	}, [location]);

	useEffect(() => {
		if (isMobile) {
			const itemStr = localStorage.getItem("hidePopupRedirectApp");
			if (!itemStr) {
				setPopup(true);
				return null;
			}
			const item = JSON.parse(itemStr);
			const now = new Date();
			if (now.getTime() > item.expiry) {
				localStorage.removeItem("hidePopupRedirectApp");
				setPopup(true);
			} else {
				setPopup(false);
			}
		}
	}, [isMobile, location]);

	useEffect(() => {
		if (popup) {
			let url = `fuchssports://path/into/app?page=${page}&id=${club.id}&name=${encodeURIComponent(club.name)}&isWebTv=${club.is_web_tv}&webTvLeague=${club.web_tv_ligue}&leagueId=${club.is_ligue}&groupId=${club.group_id}`;

			setRedirectUrl(url);
		}
	}, [popup, club, page]);

	const handleClose = (e) => {
		e.preventDefault();
		const minutes = 230;
		const now = new Date();
		const item = {
			value: "hidePopupRedirectApp",
			expiry: now.getTime() + minutes * 60000,
		};
		localStorage.setItem("hidePopupRedirectApp", JSON.stringify(item));
		setPopup(false);
	};

	return (
		popup ? (
			<div className="to_app">
				<div className="redirect-image">
					<img
						src={`${process.env.REACT_APP_CACHE_SERVER}/img/icons/logo_fuchs_mini.svg`}
						alt="logo fuchs"
					/>
					<p>{translations && translations["open_app"] ? removeTags(translations["open_app"]) : "Open in the Fuchs Sports app"}</p>
				</div>
				<div className="redirect-button">
					<span className="close" onClick={(e) => handleClose(e)}>{translations && translations["close"] ? removeTags(translations["close"]) : "Close"}</span>
					<a href={redirectUrl}>{translations && translations["open"] ? removeTags(translations["open"]) : "Open"}</a>
				</div>
			</div>
		) : (
			<></>
		)
	)
};

export default RedirectApp;
