import React, {useContext, useEffect, useState, Fragment} from "react";
import {AdminContext} from "../../contexts/AdminContext";
import {isEmpty} from "../../components/Utils";
import {useDispatch, useSelector} from "react-redux";
import {getAdvantages, getProduct} from "../../actions/stripeAdmin.action";
import moment from "moment";
import axios from "axios";

const EditSubscription = (props) => {
	const {id} = props;
	const dispatch = useDispatch();
	const [prod, setProd] = useState({});
	const [activeElement, setActiveElement] = useState(null)
	const product = useSelector((state) => state.stripeAdminReducer.getProductReducer);
	const advantagesReducer = useSelector((state) => state.stripeAdminReducer.getAdvantagesReducer);
	const {updateProduct} = useContext(AdminContext);
	const [prices, setPrices] = useState([]); //important innit `prices`
	const [advantages, setAdvantages] = useState([]);
	const [accessOptions, setAccessOptions] = useState([]);
	const [accessProduct, setAccessProduct] = useState([]);
	const [advantagesOptions, setAdvantagesOptions] = useState([]);
	const [updated, setUpdated] = useState(false)
	const [errors, setErrors] = useState({});
	const [image, setImage] = useState(null);
	const [file, setFile] = useState(null);

	useEffect(() => {
		dispatch(getProduct(id, 1, 1));
	}, [dispatch]);

	useEffect(() => {
		dispatch(getAdvantages(1, 150, ""));
	}, []);

	useEffect(() => {
		if (!isEmpty(product)) {
			setProd(product);
			setAdvantages(!isEmpty(product.metadata) && !isEmpty(product.metadata.advantages) ? product.metadata.advantages : []);
			setAccessProduct(product.access_prod);
			setImage(product.images[0])
		}
	}, [product]);

	useEffect(() => {
		if (advantagesReducer.advantages) {
			setAdvantagesOptions(advantagesReducer.advantages)
		}
	}, [advantagesReducer]);

	const updateProductImage = (file) => {
		let tpm = URL.createObjectURL(file);
		setFile(file);
		setImage(tpm);
	};

	useEffect(() => {
		const fetch = async () => {
			const loginAdminToken = localStorage.getItem("loginAdminToken");
			if (loginAdminToken) {
				let formData = new FormData();
				axios.defaults.headers.common["Authorization-Bearer"] = "Bearer " + loginAdminToken;
				formData.append("include_prices", "0");
				formData.append("type_product", "product");
				try {
					return await axios.post(`${process.env.REACT_APP_API_ADMIN_STRIPE}getProducts`, formData)
						.then((res) => {
							if (res.data.result === "ok" && !isEmpty(res.data.response)) {
								setAccessOptions(res.data.response);
							}
						})
						.catch((error) => {
							console.log(error)
						});

				} catch (e) {
					console.log(e);
				}
			}
		};

		fetch();

	}, []);

	const increment = () => {
		setPrices([
			...prices,
			{["id"]: moment().unix()}
		]);
	};

	const incrementAdvantages = () => {
		setAdvantages([
			...advantages,
			{["id"]: moment().unix()}
		]);
	};

	const incrementAccess = () => {
		setAccessProduct([
			...accessProduct,
			{["id"]: moment().unix()}
		]);
	};

	const decrement = (key) => {
		let copyPrices = [...prices];
		copyPrices.splice(key, 1)
		setPrices(copyPrices);
	};

	const decrementAdvantages = (key) => {
		let copyAdvantages = [...advantages];
		copyAdvantages.splice(key, 1)
		setAdvantages(copyAdvantages);
	};

	const decrementAccess = (key) => {
		let copyAccess = [...accessProduct];
		copyAccess.splice(key, 1)
		setAccessProduct(copyAccess);
	};

	const changePricesHandler = (event, key) => {
		let copyPrices = [...prices];
		let value = event.target.value;
		if (event.target.name === "unitAmountDecimal") {
			value = parseFloat(event.target.value).toFixed(2);
		}
		copyPrices[key][event.target.name] = value;
		setPrices(copyPrices);
	};

	const changeAdvantagesHandler = (event, key) => {
		let copyAdvantages = [...advantages];
		copyAdvantages[key][event.target.name] = event.target.value;
		setAdvantages(copyAdvantages);
	};

	const changeAccessHandler = (event, key) => {
		let copyAccess = [...accessProduct];
		copyAccess[key][event.target.name] = event.target.value;
		setAccessProduct(copyAccess);
	};

	const validateForm = async () => {
		let valid = {};
		if (isEmpty(prod['metadata'].title_en) || prod['metadata'].title_en.length < 1) {
			setErrors(prevState => ({...prevState, title_en: true}));
			valid["title_en"] = true;
		} else {
			setErrors(prevState => ({...prevState, title_en: false}));
			valid["title_en"] = false;
		}
		if (isEmpty(prod['metadata'].description_en) || prod['metadata'].description_en.length < 1) {
			setErrors(prevState => ({...prevState, description_en: true}));
			valid["description_en"] = true;
		} else {
			setErrors(prevState => ({...prevState, description_en: false}));
			valid["description_en"] = false;
		}
		if (isEmpty(prod['metadata'].title_fr) || prod['metadata'].title_fr.length < 1) {
			setErrors(prevState => ({...prevState, title_fr: true}));
			valid["title_fr"] = true;
		} else {
			setErrors(prevState => ({...prevState, title_fr: false}));
			valid["title_fr"] = false;
		}
		if (isEmpty(prod['metadata'].description_fr) || prod['metadata'].description_fr.length < 1) {
			setErrors(prevState => ({...prevState, description_fr: true}));
			valid["description_fr"] = true;
		} else {
			setErrors(prevState => ({...prevState, description_fr: false}));
			valid["description_fr"] = false;
		}
		if (isEmpty(prod['metadata'].title_es) || prod['metadata'].title_es.length < 1) {
			setErrors(prevState => ({...prevState, title_es: true}));
			valid["title_es"] = true;
		} else {
			setErrors(prevState => ({...prevState, title_es: false}));
			valid["title_es"] = false;
		}
		if (isEmpty(prod['metadata'].description_es) || prod['metadata'].description_es.length < 1) {
			setErrors(prevState => ({...prevState, description_es: true}));
			valid["description_es"] = true;
		} else {
			setErrors(prevState => ({...prevState, description_es: false}));
			valid["description_es"] = false;
		}
		if (isEmpty(prod['metadata'].type_of_user) || prod['metadata'].type_of_user.length < 1) {
			setErrors(prevState => ({...prevState, type_of_user: true}));
			valid["type_of_user"] = true;
		} else {
			setErrors(prevState => ({...prevState, type_of_user: false}))
			valid["type_of_user"] = false;
		}
		if (isEmpty(prod['metadata'].type_of_product) || prod['metadata'].type_of_product < 1) {
			setErrors(prevState => ({...prevState, type_of_product: true}));
			valid["type_of_product"] = true;
		} else {
			setErrors(prevState => ({...prevState, type_of_product: false}))
			valid["type_of_product"] = false;
		}
		if (prices.length > 0) {
			prices.map(price => {
				if (isEmpty(price.unitAmountDecimal) || price.unitAmountDecimal.length < 1) {
					setErrors(prevState => ({...prevState, ["price_" + price.id]: true}));
					valid["price_" + price.id] = true;
				} else {
					setErrors(prevState => ({...prevState, ["price_" + price.id]: false}));
					valid["price_" + price.id] = false;
				}
				if (isEmpty(price.currency) || price.currency.length < 1) {
					setErrors(prevState => ({...prevState, ["currency_" + price.id]: true}));
					valid["currency_" + price.id] = true;
				} else {
					setErrors(prevState => ({...prevState, ["currency_" + price.id]: false}));
					valid["currency_" + price.id] = false;
				}
				if (isEmpty(price.oneTime) || price.oneTime.length < 1) {
					setErrors(prevState => ({...prevState, ["type_" + price.id]: true}));
					valid["type_" + price.id] = true;
				} else {
					setErrors(prevState => ({...prevState, ["type_" + price.id]: false}));
					valid["type_" + price.id] = false;
				}
				if (isEmpty(price.mode) || price.mode.length < 1) {
					setErrors(prevState => ({...prevState, ["billing_period_" + price.id]: true}));
					valid["billing_period_" + price.id] = true;
				} else {
					setErrors(prevState => ({...prevState, ["billing_period_" + price.id]: false}));
					valid["billing_period_" + price.id] = false;
				}
			})
		}
		return valid;
	}

	const submitForm = async (event) => {
		event.preventDefault();
		let valid = await validateForm();

		if (Object.values(valid).includes(true)) {
			return false;
		}

		let data = {
			...prod,
			["prices"]: prices,
			["advantages"]: advantages,
			["access_prod"]: accessProduct
		}

		updateProduct(prod.id, data, file).then((res) => {
			if (!isEmpty(res)) {
				setTimeout(() => {
					setUpdated(true);
				}, 10);

				setTimeout(() => {
					setUpdated(false);
				}, 3000);
			}
		})

	};

	console.log(advantages)
	return (
		<div className="edit_sub">
			<Fragment>
				<article>
					<form onSubmit={submitForm} noValidate>
						<div className="double-grid">
							<div className="img">
								<label>Image</label>
								<div className="content">
								<input
									type="file"
									name="image"
									id="image"
									onChange={(e) => updateProductImage(e.target.files[0])}
								/>
								<label htmlFor="image">Choose image</label>
								{image && (<img src={image} alt=""/>)}
							</div>
							</div>
						</div>
						<div className="double-grid">
							<div className={`input ${activeElement === "title" ? "select" : ""} ${errors.title_en ? "error" : ""}`}>
								<label>Title English</label>
								<input
									type="text"
									id="title"
									required
									name="name"
									autoFocus
									autoComplete="given-name"
									defaultValue={prod?.metadata?.title_en}
									onBlur={(e) => setActiveElement(null)}
									onClick={(e) => setActiveElement(e.target.id)}
									onChange={(e) => setProd({
										...prod,
										metadata: {...prod.metadata, title_en: e.target.value}
									})}
								/>
							</div>
							<div className={`input ${activeElement === "description" ? "select" : ""} ${errors.description_en ? "error" : ""}`}>
								<label>Description English</label>
								<input
									type="text"
									id="description"
									name="description"
									defaultValue={prod?.metadata?.description_en}
									onBlur={(e) => setActiveElement(null)}
									onClick={(e) => setActiveElement(e.target.id)}
									onChange={(e) => setProd({
										...prod,
										metadata: {...prod.metadata, description_en: e.target.value}
									})}
								/>
							</div>
						</div>
						<div className="double-grid">
							<div className={`input ${activeElement === "title_fr" ? "select" : ""} ${errors.title_fr ? "error" : ""}`}>
								<label>Title French</label>
								<input
									type="text"
									id="title_fr"
									required
									name="title_fr"
									autoFocus
									autoComplete="given-name"
									defaultValue={prod?.metadata?.title_fr}
									onBlur={(e) => setActiveElement(null)}
									onClick={(e) => setActiveElement(e.target.id)}
									onChange={(e) => setProd({
										...prod,
										metadata: {...prod.metadata, title_fr: e.target.value}
									})}
								/>
							</div>
							<div className={`input ${activeElement === "description_fr" ? "select" : ""} ${errors.description_fr ? "error" : ""}`}>
								<label>Description French</label>
								<input
									type="text"
									id="description_fr"
									name="description_fr"
									defaultValue={prod?.metadata?.description_fr}
									onBlur={(e) => setActiveElement(null)}
									onClick={(e) => setActiveElement(e.target.id)}
									onChange={(e) => setProd({
										...prod,
										metadata: {...prod.metadata, description_fr: e.target.value}
									})}
								/>
							</div>
						</div>
						<div className="double-grid">
							<div className={`input ${activeElement === "title_es" ? "select" : ""} ${errors.title_es ? "error" : ""}`}>
								<label>Title Spanish</label>
								<input
									type="text"
									id="title_es"
									required
									name="title_es"
									autoFocus
									autoComplete="given-name"
									defaultValue={prod?.metadata?.title_es}
									onBlur={(e) => setActiveElement(null)}
									onClick={(e) => setActiveElement(e.target.id)}
									onChange={(e) => setProd({
										...prod,
										metadata: {...prod.metadata, title_es: e.target.value}
									})}
								/>
							</div>
							<div className={`input ${activeElement === "description_es" ? "select" : ""} ${errors.description_es ? "error" : ""}`}>
								<label>Description Spanish</label>
								<input
									type="text"
									id="description_es"
									name="description_es"
									defaultValue={prod?.metadata?.description_es}
									onBlur={(e) => setActiveElement(null)}
									onClick={(e) => setActiveElement(e.target.id)}
									onChange={(e) => setProd({
										...prod,
										metadata: {...prod.metadata, description_es: e.target.value}
									})}
								/>
							</div>
						</div>
						<div className="double-grid">
							<div className={`input ${activeElement === "type_of_user" ? "select" : ""} ${errors.type_of_user ? "error" : ""}`}>
								<label>Type of user</label>
								<select
									id="type"
									required
									name="type_of_user"
									onChange={(e) => setProd({
										...prod,
										metadata: {...prod.metadata, type_of_user: e.target.value}
									})}
									value={prod?.metadata?.type_of_user}
								>
									<option/>
									<option value="fan">Fan</option>
									<option value="athlete">Athlete</option>
									<option value="club">Club</option>
									<option value="media">Media</option>
								</select>
							</div>
							<div className={`input ${activeElement === "type_of_product" ? "select" : ""} ${errors.type_of_product ? "error" : ""}`}>
								<label>Type of product</label>
								<select
									id="type"
									required
									name="type_of_product"
									onChange={(e) => setProd({
										...prod,
										metadata: {...prod.metadata, type_of_product: e.target.value}
									})}
									value={prod?.metadata?.type_of_product}
								>
									<option/>
									<option value="product">Product</option>
									<option value="subscription">Subscription</option>
								</select>
							</div>
						</div>
						<hr/>
						<div style={{clear: "both"}}/>

						<div id="price_form">
							{prod.prices && prod.prices.map((price, key) => {
								return (
									<Fragment key={price.id}>
										<div className="input" data-id={price.id}>
											<label>Price</label>
											<input
												type="number"
												id="price"
												placeholder="0.00"
												min="0"
												step=".01"
												name="unitAmountDecimal"
												required
												value={price.unit_amount}
												readOnly={true}
												disabled={true}
											/>
										</div>
										<div className="input">
											<label>Currency</label>
											<select
												id="currency"
												required
												name="currency"
												disabled={true}
												value={price.currency}
											>
												<option/>
												<option value="eur">EUR</option>
											</select>
										</div>
										<div className="input">
											<label>Type</label>
											<select
												id="type"
												required
												name="oneTime"
												disabled={true}
												value={price.type}
											>
												<option/>
												<option value="recurring">recurring</option>
												<option value="one_time">one_time</option>
											</select>
										</div>
										{price.type === "recurring" && (
											<div className="input">
												<label>Billing Period</label>
												<select
													id="billingPeriod"
													required
													name="mode"
													disabled={true}
													value={price?.recurring?.interval}
												>
													<option/>
													<option value="day">day</option>
													<option value="week">week</option>
													<option value="month">month</option>
													<option value="year">year</option>
												</select>
											</div>
										)}
									</Fragment>
								)}
							)}

							{prices.length > 0 && prices.map((price, key) => {
								return (
									<Fragment key={price.id}>
										<div className={`input ${activeElement === `price_${price.id}` ? "select" : ""} ${errors[`price_${price.id}`] ? "error" : ""}`}>
											<label>Price*</label>
											<input
												type="number"
												id={`price_${price.id}`}
												placeholder="0.00"
												min="0"
												step=".01"
												name="unitAmountDecimal"
												required
												onBlur={(e) => {
													setActiveElement(null);
													e.target.value = parseFloat(e.target.value).toFixed(2);
												}}
												onClick={(e) => setActiveElement(e.target.id)}
												onChange={(event) => changePricesHandler(event, key)}
											/>
										</div>
										<div className={`input ${activeElement === `currency_${price.id}` ? "select" : ""} ${errors[`currency_${price.id}`] ? "error" : ""}`}>
											<label>Currency*</label>
											<select
												id={`currency_${price.id}`}
												required
												name="currency"
												onBlur={(e) => setActiveElement(null)}
												onClick={(e) => setActiveElement(e.target.id)}
												onChange={(event) => changePricesHandler(event, key)}
											>
												<option/>
												<option value="eur">EUR</option>
											</select>
										</div>
										<div className={`input ${activeElement === `type_${price.id}` ? "select" : ""} ${errors[`type_${price.id}`] ? "error" : ""}`}>
											<label>Type*</label>
											<select
												id={`type_${price.id}`}
												required
												name="oneTime"
												onBlur={(e) => setActiveElement(null)}
												onClick={(e) => setActiveElement(e.target.id)}
												onChange={(event) => changePricesHandler(event, key)}
											>
												<option/>
												<option value="recurring">recurring</option>
												<option value="one_time">one_time</option>
											</select>
										</div>

										{prices[key]['oneTime'] === "recurring" && (
											<div className={`input ${activeElement === `billing_period_${price.id}` ? "select" : ""}`}>
												<label>Billing Period</label>
												<select
													id={`billing_period_${price.id}`}
													required
													name="mode"
													onBlur={(e) => setActiveElement(null)}
													onClick={(e) => setActiveElement(e.target.id)}
													onChange={(event) => changePricesHandler(event, key)}
												>
													<option/>
													<option value="day">day</option>
													<option value="week">week</option>
													<option value="month">month</option>
													<option value="year">year</option>
												</select>
											</div>
										)}

										<div className="submit mb-2">
											<input
												type="button"
												value="Remove Price"
												onClick={() => decrement(key)}
											/>
										</div>
									</Fragment>
								)}
							)}
						</div>

						<div className="submit">
							<input
								type="button"
								value="Add Price"
								onClick={increment}
							/>
						</div>

						<hr/>
						<div style={{clear: "both"}}/>

						<div id="advantage_form">
							{advantages && advantages.length > 0 && advantages.map((advantage, key) => {
								return (
									<Fragment key={advantage.id}>
										<div className="input" data-id={advantage.id}>
											<label>Advantage</label>
											<select
												id={`advantage_${advantage.id}`}
												required
												name="advantage_id"
												value={advantage.advantage_id}
												onBlur={(e) => setActiveElement(null)}
												onClick={(e) => setActiveElement(e.target.id)}
												onChange={(event) => changeAdvantagesHandler(event, key)}
											>
												<option/>
												{advantagesOptions.map(option => (
													<option key={option.id} value={option.id}>
														{option.title_fr} ({option.description_fr})
													</option>
												))}
											</select>
										</div>

										<div className="submit mb-2">
											<input
												type="button"
												value="Remove Advantage"
												onClick={() => decrementAdvantages(key)}
											/>
										</div>
									</Fragment>
								);
							})}
						</div>

						<div className="submit">
							<input
								type="button"
								value="Add Advantage"
								onClick={incrementAdvantages}
							/>
						</div>

						<hr/>
						<div style={{clear: "both"}}/>

						<div id="access_form">
							{accessProduct && accessProduct.map((elem, key) => {
								return (
									<Fragment key={elem.id}>
										<div className="input" data-id={elem}>
											<label>Product</label>
											<div className="access">
												<select
													id={`product_${elem.product}`}
													required
													name="product"
													value={elem.product}
													onBlur={(e) => setActiveElement(null)}
													onClick={(e) => setActiveElement(e.target.id)}
													onChange={(event) => changeAccessHandler(event, key)}
												>
													<option/>
													{accessOptions.map(access => (
		                                                <option key={access.id} value={access.id}>
		                                                    {access.name}
		                                                </option>
                                                    ))}
												</select>
												<input
													type="number"
													id={`quantity_${elem.product}`}
													min="1"
													step="1"
													name="quantity"
													defaultValue={elem.quantity}
													onChange={(event) => changeAccessHandler(event, key)}
												/>
											</div>
										</div>
										{key >= 0 && (
											<div className="submit mb-2">
												<input
													type="button"
													value="Remove Access"
													onClick={() => decrementAccess(key)}
												/>
											</div>
										)}
									</Fragment>
								);
							})}
						</div>

						{prod && prod.metadata && prod.metadata.type_of_product === "product" ? ("") : (
							<div className="submit">
								<input
									type="button"
									value="Add Access"
									onClick={incrementAccess}
								/>
							</div>
						)}

						<hr/>
						<div style={{clear: "both"}}/>

						<div className="submit">
							<input
								type="submit"
								value="Edit Subscription"
							/>
							<div id="validUpdate" className={`${updated ? "display" : ""}`}>
								La souscription a été modifié
							</div>
						</div>
					</form>
				</article>
			</Fragment>
		</div>
	);
};

export default EditSubscription;
