import React from "react";
import {render, hydrate} from "react-dom";
import App from "./App";
import MyContextProvider from "./contexts/MyContext";
import "./style/style.scss";
//REDUX
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/";
import { getSports } from "./actions/sports.action";
import {
	getSupportedLanguages,
	getTranslations,
} from "./actions/translations.action";
import { getCountries } from "./actions/country.action";
import { getSlideshow } from "./actions/slideshow.action";
import { getCountryUser } from "./actions/account.action";
import { HelmetProvider } from "react-helmet-async";
import AdminContextProvider from "./contexts/AdminContext";
import { getStatus } from "./actions/status.action";

const store = createStore(rootReducer, applyMiddleware(thunk));

store.dispatch(getTranslations());
store.dispatch(getSports());
store.dispatch(getSupportedLanguages());
store.dispatch(getCountries());
store.dispatch(getSlideshow());
store.dispatch(getCountryUser());
store.dispatch(getStatus());
//REACT RENDER

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
	hydrate(
		<React.StrictMode>
			<HelmetProvider>
				<MyContextProvider>
					<AdminContextProvider>
						<Provider store={store}>
							<App />
						</Provider>
					</AdminContextProvider>
				</MyContextProvider>
			</HelmetProvider>
		</React.StrictMode>,
		rootElement
	);
} else {
	render(
		<React.StrictMode>
			<HelmetProvider>
				<MyContextProvider>
					<AdminContextProvider>
						<Provider store={store}>
							<App />
						</Provider>
					</AdminContextProvider>
				</MyContextProvider>
			</HelmetProvider>
		</React.StrictMode>,
		rootElement
	);
}