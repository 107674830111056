import React, { useContext, useEffect } from "react";
import Dashboard from "../../Backoffice/pages/Dashboard";
import Login from "../../Backoffice/pages/Login";
import { AdminContext } from "../../contexts/AdminContext";
const BackOffice = () => {
	const { rootState, isLoggedInAdmin } = useContext(AdminContext);
	const { isAuth } = rootState;

	useEffect(() => {
		async function fetchData() {
			await isLoggedInAdmin();
		}
		fetchData();
		// eslint-disable-next-line
	}, []);

	return (
		<main className="back-office">
			{isAuth && <Dashboard />}
			{!isAuth && <Login />}
		</main>
	);
};

export default BackOffice;
