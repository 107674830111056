import { combineReducers } from "redux";
import { GET_SEARCH, TOGGLE_SEARCH } from "../actions/search.action";

const initialState = [];

function searchToggleReducer(state = false, action) {
	switch (action.type) {
		case TOGGLE_SEARCH:
			return action.payload;
		default:
			return state;
	}
}

function searchDataReducer(state = initialState, action) {
	switch (action.type) {
		case GET_SEARCH:
			return action.payload;
		default:
			return state;
	}
}

export default combineReducers({ searchToggleReducer, searchDataReducer });
