import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { getProducts, getCoupons} from "../../actions/stripeAdmin.action";
import { isEmpty } from "../../components/Utils";
import { AdminContext } from "../../contexts/AdminContext";
import EditCoupon from "./EditCoupon";

const AllCoupons = () => {
	const {archiveProduct, deleteCoupon} = useContext(AdminContext);
	const dispatch = useDispatch();
	const products = useSelector((state) => state.stripeAdminReducer.getProductsReducer);
	const coupons = useSelector((state) => state.stripeAdminReducer.getCouponsReducer);
	const [page, setPage] = useState(0);
	const [offset, setOffset] = useState(10);
	const [couponsArray, setCouponsArray] = useState({});
	const [editComponent, setEditComponent] = useState( null );

	useEffect(() => {
		dispatch(getCoupons());
	}, [dispatch]);

	useEffect(() => {
		if (!isEmpty(coupons)) {
			setCouponsArray(coupons);
		}
	}, [coupons]);

	const handleEdit = async (couponId) => {
		if(editComponent === null) {
			setEditComponent(<EditCoupon id={couponId}/>)
		} else {
			setEditComponent(null);
		}
	};
	const handleDelete = async (id) => {
		const data = await deleteCoupon(id);
			dispatch(getCoupons());
	};

	//PAGE
	const handlePage = (mode) => {
		let pageNumber = Math.ceil(couponsArray.length / offset);
		if (mode === "next") {
			setPage(page + 1 > pageNumber - 1 ? 0 : page + 1);
		} else {
			setPage(page - 1 < 0 ? pageNumber - 1 : page - 1);
		}
	};

	//SEARCH
	const handleSearch = (input) => {
		let array = [...coupons];
		array = array.filter(
			elm => {
				return elm.id.toLowerCase().includes(input.toLowerCase()) ||
				elm.name?.toLowerCase().includes(input.toLowerCase()) ||
				elm.description?.toLowerCase().includes(input.toLowerCase())
			}
		);
		setCouponsArray(array);
		setPage(0);
	};

	return (
		<div className="bo-all_coupons">
             <div className="controls">
				<div className="search">
					<label htmlFor="search">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
						>
							<path d="M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z" />
						</svg>
					</label>
					<input
						type="search"
						autoComplete="off"
						name="search"
						id="search"
						placeholder="Chercher une variable"
						onInput={(e) => handleSearch(e.target.value)}
					/>
				</div>
				<div className="pages">
					<div className="buttons">
						<button onClick={() => handlePage("prev")}>Page précédente</button>
						<button onClick={() => handlePage("next")}>Page suivante</button>
					</div>

					<p>
						Page {page + 1}/{Math.ceil(couponsArray.length / offset)}
					</p>
				</div>
				<div className="offset">
					<label htmlFor="offset">Nombre d'éléments affichés</label>
					<input
						type="number"
						name="offset"
						step="10"
						value={offset}
						onChange={(e) => setOffset(parseInt(e.currentTarget.value))}
						min="10"
						max={
							!isEmpty(Math.ceil(Object.entries(couponsArray).length))
								? Math.ceil(Object.entries(couponsArray).length) + 10
								: 100
						}
					/>
				</div>
			</div> 
			<div className="coupons-list">
				<table>
					<thead>
						<tr>
							<th>ID</th>
							<th>Titre</th>
							<th>Contenu</th>
							<th>Actions</th>
							
						</tr>
					</thead>
					<tbody>
						{!isEmpty(couponsArray) &&
							couponsArray
								.slice(page * offset, offset + page * offset)
								.map((coupons, index) => {
									return (
										<tr key={index}>
											<td>{coupons.id}</td>
											<td>{coupons.name}</td>
											<td>
												{!isEmpty(coupons.percent_off) ? 
												(
													<span className="mr-1">{coupons.percent_off+ "%/"+ (coupons.duration ==="repeating" ? coupons.duration + " " + coupons.duration_in_months + " months": coupons.duration)}</span>
												) : (
													<span className="mr-1">{coupons.amount_off+ "EUR/" + (coupons.duration ==="repeating" ? coupons.duration + " " + coupons.duration_in_months + " months": coupons.duration)}</span>
													)
													// products.prices.map(price => {
													// 	return(
													// 		<span key={price.id}>
													// 			<span className="mr-1">{(price.unit_amount / 100).toLocaleString('eu-EU', {style: 'currency',currency: 'EUR'})}/</span>
													// 			<span>{!isEmpty(price?.recurring?.interval) ? price.recurring.interval : "one-time"}</span>
													// 		</span>
													// 	);
													// })
												}
											</td>
											<td className="d-flex">
												<button onClick={() => handleEdit(coupons.id)}>Edit</button>
												<button onClick={() => handleDelete(coupons.id)}>Archiver le coupon</button>
											</td>
										</tr>
									);
								})
						}
					</tbody>
				</table>
			</div>
			{editComponent}
		</div>
	);
};

export default AllCoupons;
