import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSearch, toggleSearch } from "../actions/search.action";
import Events from "./Events";
import {isEmpty, removeTags} from "./Utils";
import WebTVS from "./WebTVS";
import Banner from "./search/Banner";
import Highlight from "./Highlight";
import { getEventsPopular } from "../actions/events.action";

import Slider from "react-slick";
import RightArrow from "./slider/RightArrow";
import LeftArrow from "./slider/LeftArrow";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";

import parse from "html-react-parser";
import VideoPreview from "./partials/VideoPreview";

const Search = () => {
	//GET STORE
	const [state, setState] = useState(false);
	const dispatch = useDispatch();
	const [lang, setLang] = useState("EN");
	const selectLanguage = useSelector(
		(state) => state.langReducer.translationsReducer.language
	);
	const searchToggle = useSelector(
		(state) => state.searchReducer.searchToggleReducer
	);
	const events = useSelector(
		(state) => state.searchReducer.searchDataReducer.events
	);
	const sportPages = useSelector(
		(state) => state.searchReducer.searchDataReducer.sportPages
	);
	const highlights = useSelector(
		(state) => state.searchReducer.searchDataReducer.highlights
	);
	const videos = useSelector(
		(state) => state.searchReducer.searchDataReducer.videos
	);
	const webTVS = useSelector(
		(state) => state.searchReducer.searchDataReducer.webTVS
	);
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translations
	);
	const [type, setType] = useState("all");

	const eventPopular = useSelector(
		(state) => state.eventsReducer.eventsPopularReducer
	)[0];

	const [countResult, setCountResult] = useState(0);
	const [inputSearch, setInputSearch] = useState("");

	useEffect(() => {
		dispatch(getEventsPopular(1));
	}, [dispatch]);

	useEffect(() => {
		setLang(selectLanguage && selectLanguage.toUpperCase());
	}, [selectLanguage]);

	useEffect(() => {
		let eventsCount = !isEmpty(events) ? events.events.length : 0;
		let videosCount = !isEmpty(videos) ? videos.length : 0;
		let webTVSCount = !isEmpty(webTVS) ? webTVS.webTVS.length : 0;
		let sportPagesCount = !isEmpty(sportPages) ? sportPages.length : 0;
		let highlightsCount = !isEmpty(highlights) ? highlights.length : 0;

		setCountResult(
			eventsCount + videosCount + webTVSCount + sportPagesCount + highlightsCount
		);
	}, [events, videos, webTVS, highlights, sportPages]);

	const handleSearch = (e) => {
		e.preventDefault();
		if (inputSearch.length > 1) {
			dispatch(getSearch(inputSearch));
			setState(true);
		} else {
			setState(false);
		}
	};

	//SLIDE
	let settings = {
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 5,
		prevArrow: <LeftArrow />,
		nextArrow: <RightArrow />,
		swipeToSlide: false,
		initialSlide: 0,
		variableWidth: true,
		responsive: [
			{
				breakpoint: 1800,
				settings: {
					slidesToScroll: 4,
					initialSlide: 0,
				},
			},
			{
				breakpoint: 1670,
				settings: {
					slidesToScroll: 4,
					initialSlide: 0,
				},
			},
			{
				breakpoint: 1500,
				settings: {
					slidesToScroll: 3,
					initialSlide: 0,
				},
			},
			{
				breakpoint: 1400,
				settings: {
					slidesToScroll: 3,
					initialSlide: 0,
				},
			},
			{
				breakpoint: 1215,
				settings: {
					slidesToScroll: 3,
					initialSlide: 0,
				},
			},
			{
				breakpoint: 1150,
				settings: {
					slidesToScroll: 2,
					initialSlide: 0,
				},
			},
			{
				breakpoint: 1000,
				settings: {
					slidesToScroll: 2,
					initialSlide: 0,
				},
			},
			{
				breakpoint: 900,
				settings: {
					slidesToScroll: 2,
					initialSlide: 0,
				},
			},
			{
				breakpoint: 825,
				settings: {
					slidesToScroll: 2,
					initialSlide: 0,
				},
			},
			{
				breakpoint: 760,
				settings: {
					slidesToScroll: 1,
					initialSlide: 0,
				},
			},
			{
				breakpoint: 695,
				settings: {
					slidesToScroll: 1,
					initialSlide: 0,
				},
			},
			{
				breakpoint: 610,
				settings: {
					slidesToScroll: 1,
					initialSlide: 0,
				},
			},
			{
				breakpoint: 550,
				settings: {
					slidesToScroll: 1,
					initialSlide: 0,
				},
			},
			{
				breakpoint: 400,
				settings: {
					slidesToScroll: 1,
					initialSlide: 0,
				},
			},
		],
	};
	return (
		<div className="search-container">
			<div className="search">
				<section className="search-section">
					<h4>
						{translations && !isEmpty(translations["search"])
							? parse(translations["search"])
							: "Search"
						}
					</h4>
					<form onSubmit={handleSearch}>
						<span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
							>
								<path d="M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z" />
							</svg>
							<input
								type="text"
								name="search"
								onKeyPress={(e) => {
									e.key === 'Enter' && handleSearch(e)
								}}
								onInput={(e) => setInputSearch(e.target.value)}
							/>
							<input type="submit" className="button"
								   onClick={(e) => handleSearch(e)}
								   size={(translations &&
									   !isEmpty(translations["search"])
										   ? removeTags(translations["search"])
										   : "Search"
								   ).length}
								   value={translations && !isEmpty(translations["search"])
									   ? removeTags(translations["search"])
									   : "Search"
								   }
							/>
						</span>
					</form>
					<div
						className="close"
						onClick={() => dispatch(toggleSearch(!searchToggle))}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
						>
							<path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
						</svg>
					</div>
				</section>

				<div className="results">
					{state ? (
						<Fragment>
							<div className="container filter">
								<section className="flex-between">
									<div className="result">
										<span className="count">{countResult}</span>
										<span>
											{" "}
											{translations && !isEmpty(translations["searchResults"])
												? parse(translations["searchResults"])
												: "Search results"}
										</span>
									</div>
									<div className="sport-menu">
										<span
											className={type === "all" ? "active link" : "link"}
											onClick={() => setType("all")}
										>
											{translations && !isEmpty(translations["allContent"])
												? parse(translations["allContent"])
												: "All content"
											}
										</span>
										<span
											className={type === "videos" ? "active link" : "link"}
											onClick={() => setType("videos")}
										>
											{translations && !isEmpty(translations["videos"])
												? parse(translations["videos"])
												: "videos"
											}
										</span>
										<span
											className={type === "events" ? "active link" : "link"}
											onClick={() => setType("events")}
										>
											{translations && !isEmpty(translations["events"])
												? parse(translations["events"])
												: "events"
											}
										</span>
									</div>
								</section>
							</div>

							{!isEmpty(sportPages) &&
								sportPages.length > 0 &&
								type === "all" &&
								sportPages.map((sportPage) => {
									return (
										<Banner
											key={sportPage.id}
											title={
												translations && !isEmpty(translations[sportPage.name])
													? parse(translations[sportPage.name])
													: ""
											}
											bannerPatch={sportPage.bannerPatch}
											name={sportPage.name}
										/>
									);
								})}

							{!isEmpty(events) &&
								events.events.length > 0 &&
								(type === "all" || type === "events") && (
									<Events events={events.events} hideSeeAll={true} />
								)}

							{!isEmpty(videos) &&
								videos.length > 0 &&
								(type === "all" || type === "videos") && (
									<div className="playlist-videos">
										<div className="titles">
											<h3>
												{translations && !isEmpty(translations["videos"])
													? parse(translations["videos"])
													: "Videos"
												}
											</h3>
										</div>

										<Slider
											{...settings}
											className="videos-container hide-scroll customScroll"
										>
											{videos.map((video) => {
												return <VideoPreview video={video} key={video.id}/>
											})}
										</Slider>
									</div>
								)}

							{!isEmpty(webTVS) &&
							<WebTVS
								webTVS={webTVS.webTVS}
								hideSeeAll={true} />
							}
							<Highlight highlights={highlights} />
						</Fragment>
					) : (
						!isEmpty(eventPopular) && (
							<div className="container">
								<div className="popular-event">
									<div className="title">
										<span>
											{translations && !isEmpty(translations["popularLive"])
												? parse(translations["popularLive"])
												: "Popular live"
											}
										</span>
									</div>
									<div className="event">
										<div className="logos">
											<div className="logo-container">
												<img
													src={eventPopular.team1Img}
													alt={`${eventPopular.team1Name} Logo`}
													title={eventPopular.team1Name}
													width="50"
												/>
											</div>
											<div className="vs">
												<img
													className="VS-V"
													src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/events/V_VS.svg`}
													alt="V"
												/>
												<img
													className="VS-S"
													src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/events/S_VS.svg`}
													alt="S"
												/>
											</div>
											<div className="logo-container">
												<img
													src={eventPopular.team2Img}
													alt={`${eventPopular.team2Name} Logo`}
													title={eventPopular.team2Name}
													width="50"
												/>
											</div>
										</div>

										<div className="event-content">
											<div className="title">
												<h4>{`${eventPopular["title" + lang]}`}</h4>
											</div>
											<div className="date">
												{eventPopular.start && (
													<Moment element="span" format="DD MMM, YYYY / HH:mm">
														{eventPopular.start}
													</Moment>
												)}
											</div>
											<div className="division">
												{eventPopular["divisionName" + lang] ? (
													<img
														src={eventPopular.divisionImg}
														alt={`${eventPopular["divisionName" + lang]} Logo`}
														width="50"
													/>
												) : (
													<span/>
												)}
												<h5>
													{eventPopular["divisionName" + lang]
														? `${eventPopular["divisionName" + lang]}`
														: ""}
												</h5>

												<div className="live">
													{eventPopular.started === "yes" ? (
														<span className="live">
															<NavLink
																exact
																onClick={() => window.scrollTo(0, 0)}
																to={`/${eventPopular.clubURL}/${eventPopular.evtId}`}
															>
																{translations && !isEmpty(translations["live"])
																	? parse(translations["live"])
																	: "live"
																}
															</NavLink>
														</span>
													) : (
														<span className="off">
															{translations && !isEmpty(translations["soon"])
																? parse(translations["soon"])
																: "soon"
															}
														</span>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						)
					)}
				</div>
			</div>
		</div>
	);
};

export default Search;
