import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSlideshow } from "../../../actions/slideshow.action";
import EventStyle1 from "../../../components/explorer/EventStyle1";
import EventStyle2 from "../../../components/explorer/EventStyle2";
import EventStyle3 from "../../../components/explorer/EventStyle3";
import { isEmpty } from "../../../components/Utils";
import SlideEditor from "./SlideEditor";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import EmptyEventStyle from "../../../components/explorer/EmptyEventStyle";

const EditSlide = () => {
	const slides = useSelector((state) => state.slideShowReducer.slide);
	const [selectedSlide, setSelectedSlide] = useState(null);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getSlideshow("all"));
	}, [dispatch]);

	return (
		<div className="bo-edit-slide">
			<SimpleBar autoHide={false} className="slides-container hide-scroll">
				{!isEmpty(slides) &&
					slides.map((slide, index) => {
						return (
							<div
								className={index === 0 ? "slide selected" : "slide"}
								key={slide.id}
								onClick={(e) => {
									document
										.querySelectorAll(".bo-edit-slide .slides-container .slide")
										.forEach((elm) =>
											elm !== e.currentTarget ? elm.classList.remove("selected") : false
										);
									e.currentTarget.classList.add("selected");
									setSelectedSlide(slide);
								}}
								onLoad={() => index === 0 && setSelectedSlide(slide)}
							>
								{slide.type === "1" && <EventStyle1 event={slide} />}
								{slide.type === "2" && <EventStyle2 event={slide} />}
								{slide.type === "3" && <EventStyle3 event={slide} />}
								{slide.type === "4" && <EmptyEventStyle event={slide} />}
							</div>
						);
					})}
			</SimpleBar>

			{!isEmpty(selectedSlide) && (
				<SlideEditor slide={selectedSlide} isNew={false} />
			)}
		</div>
	);
};

export default EditSlide;
