import React, { useContext, useState } from "react";
import Homepage from "../homepage/Homepage";
import SportPage from "../sport_page/SportPage";
import EmissionPage from "../emission/EmissionPage";
import Translation from "../translation/Translation";
import Admin from "../admin/Admin";
import { NavLink } from "react-router-dom";
import { AdminContext } from "../../contexts/AdminContext";
import CountryPage from "../country_page/CountryPage";
import PushNotifications from "../push_notifications/PuchNotifications";
import Accounts from "../homepage/Account/Accounts";
import Subscription from "../subscription/Subscription";
import Coupon from "../coupons/Coupon";
import Advantage from "../advantage/Advantage";

const Dashboard = () => {
	const [actualComponent, setActualComponent] = useState(<Homepage />);
	const { logoutAdmin } = useContext(AdminContext);
	const [active, setActive] = useState("homepage")
	return (
		<section className="dashboard">
			<section className="nav">
				<h1>Dashboard</h1>
				<nav>
					<ul>
						<li
							className={`homepage ${active === "homepage" ? "selected" : ""}`}
							onClick={() => {
								setActualComponent(<Homepage />);
								setActive("homepage");
							}}
						>
							Homepage
						</li>
						<li
							className={`sportPage ${active === "sportPage" ? "selected" : ""}`}
							onClick={() => {
								setActualComponent(<SportPage />);
								setActive("sportPage");
							}}
						>
							Page Sport
						</li>
						<li
							className={`countryPage ${active === "countryPage" ? "selected" : ""}`}
							onClick={() => {
								setActualComponent(<CountryPage />);
								setActive("countryPage");
							}}
						>
							Page Pays
						</li>
						<li
							className={`emissionPage ${active === "emissionPage" ? "selected" : ""}`}
							onClick={() => {
								setActualComponent(<EmissionPage />);
								setActive("emissionPage");
							}}
						>
							Page Emission
						</li>
						<li
							className={`translation ${active === "translation" ? "selected" : ""}`}
							onClick={() => {
								setActualComponent(<Translation />);
								setActive("translation");
							}}
						>
							Traduction
						</li>
						<li
							className={`admin ${active === "admin" ? "selected" : ""}`}
							onClick={() => {
								setActualComponent(<Admin />);
								setActive("admin");
							}}
						>
							Admin User
						</li>
						<li
							className={`pushNotifications ${active === "pushNotifications" ? "selected" : ""}`}
							onClick={() => {
								setActualComponent(<PushNotifications />);
								setActive("pushNotifications");
							}}
						>
							Push Notifications
						</li>

						<li
							className={`subscription ${active === "avantage" ? "selected" : ""}`}
							onClick={() => {
								setActualComponent(<Advantage />);
								setActive("avantage");
							}}
						>
							Avantage
						</li>

						<li
							className={`subscription ${active === "subscription" ? "selected" : ""}`}
							onClick={() => {
								setActualComponent(<Subscription />);
								setActive("subscription");
							}}
						>
							Abonnement
						</li>

						<li
							className={`coupon ${active === "coupon" ? "selected" : ""}`}
							onClick={() => {
								setActualComponent(<Coupon />);
								setActive("coupon");
							}}
						>
							Coupons
						</li>

						<li
							className={`users ${active === "users" ? "selected" : ""}`}
							onClick={() => {
								setActualComponent(<Accounts />);
								setActive("users");
							}}
						>
							Comptes
						</li>

						<li>
							<NavLink exact to="/" onClick={() => logoutAdmin()}>
								Se deconnecter
							</NavLink>
						</li>
					</ul>
				</nav>
				<div className="logo">
					<a href="/" target="_blank" rel="noreferrer noopener">
						<img
							src={`${process.env.REACT_APP_CACHE_SERVER}/img/icons/logo-fuchs-header.png`}
							alt="logo fuchs"
							width="50"
							height="50"
						/>
						<h2>Retour au site</h2>
					</a>
				</div>
			</section>

			<div className="window">{actualComponent}</div>
		</section>
	);
};

export default Dashboard;
