import React, {useState, useEffect, Fragment, useContext, useMemo} from "react";
import {useSelector, useDispatch} from "react-redux";
import parse from "html-react-parser";
import {isEmpty} from "../../Utils";
import {getActiveCustomerProduct, getCustomer, getProducts} from "../../../actions/stripe.action";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import {MyContext} from "../../../contexts/MyContext";
import {toggleModal} from "../../../actions/modal.action";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC}`);

const Shop = () => {
    const dispatch = useDispatch();
    const stripeReducer = useSelector((state) => state.stripeReducer);
    const customer = stripeReducer.getCustomerReducer;
    const products = stripeReducer.getProductsReducer;
    const activePlan = stripeReducer.getActiveCustomerProductReducer;
    const [openPpvBlock, setOpenPpvBlock] = useState(false);
    const [prices, setPrices] = useState([]);
    const [checkedProductId, setCheckedProductId] = useState(null);
    const [wait, setWait] = useState(false);
    const [countFill, setCountFill] = useState(0);
    const {orderFuchsProducts, rootState} = useContext(MyContext)
    const {theUser} = rootState;

    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translations
    );

    const accessProducts = useMemo(() => {
        if (activePlan?.product?.metadata["access_prod"]) {
            let arrayProducts = [];
            JSON.parse(activePlan.product.metadata["access_prod"]).map(elem => {
                arrayProducts.push(elem.product)
            });
            return arrayProducts;
        }
    }, [activePlan])
    
    const clickToPay = (product) => {
          if (product.id) {
              const newArrayOfObj = product.prices.map((item) => {
                  return {value: item.id, label: (item.unit_amount / 100).toLocaleString('eu-EU', {style: 'currency',currency: 'EUR'}) + "/" + (!isEmpty(item?.recurring?.interval) ? item.recurring.interval : "one time"), ...item };
              });
              setPrices(newArrayOfObj);
              setCheckedProductId(product.id);
              setOpenPpvBlock(true);
          }
    };

    const handleFreePayment = async (productId) => {
        if (productId) {
            setWait(true);
            const response = await orderFuchsProducts(customer.id, productId);
            console.log(response);
            if (response.result === "ok") {
                dispatch(toggleModal(
                    {
                        opened: true,
                        success: true,
                        text: "Order in processing"
                    })
                )

            } else {
                dispatch(toggleModal({opened: true, success: false, text: response.error}));
            }
            setWait(false);
        }
    };

    useEffect(() => {
        dispatch(getCustomer());
        dispatch(getActiveCustomerProduct());
        dispatch(getProducts(1, 1, "product", theUser.account_type));
    }, [dispatch]);

    useEffect(() => {
        if (theUser.account_type === "athlete") {
            setCountFill(theUser?.required_count_fill)
        }
    }, [theUser]);

    return (
        <Fragment>

            {wait && (
                <Fragment>
                    <div className="background position-fixed top-left bottom-right"
                     style={{backgroundColor: "rgb(0 0 0 / 51%)"}}/>
                    <div className="absolute-center ppv">
                        <div className="success-animation">
                            <div className="loader">
                                <div className={wait === "succeeded" ? "circle-loader load-complete" : "circle-loader"}>
                                    <div className="checkmark draw"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}

            {openPpvBlock && (
                <Elements stripe={stripePromise}>
                    <CheckoutForm
                        onClose={() => setOpenPpvBlock(!openPpvBlock)}
                        prices={prices}
                        checkedProduct={checkedProductId}
                    />
                </Elements>
            )}

            <div className="shop-page">
                <h3>
                    {translations && !isEmpty(translations["shop"])
                        ? parse(translations["shop"])
                        : "Shop"}
                </h3>
                {countFill < 17 && (
                    <div className="info-block">
                        <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.9043 6.52067 11 6.28333 11 6C11 5.71667 10.9043 5.479 10.713 5.287C10.521 5.09567 10.2833 5 10 5C9.71667 5 9.47933 5.09567 9.288 5.287C9.096 5.479 9 5.71667 9 6C9 6.28333 9.096 6.52067 9.288 6.712C9.47933 6.904 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z" fill="black"/>
                        </svg>
                        <span>
                            Pour pouvoir acheter un produit dans la boutique, les champs requis de votre formulaire joueur doivent être complétés.
                        </span>

                    </div>
                )}
                {isEmpty(activePlan) && (
                    <div className="info-block mt-4">
                        <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.9043 6.52067 11 6.28333 11 6C11 5.71667 10.9043 5.479 10.713 5.287C10.521 5.09567 10.2833 5 10 5C9.71667 5 9.47933 5.09567 9.288 5.287C9.096 5.479 9 5.71667 9 6C9 6.28333 9.096 6.52067 9.288 6.712C9.47933 6.904 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z" fill="black"/>
                        </svg>
                        <span>
                            {translations && !isEmpty(translations["shop_page_message"])
                                ? parse(translations["shop_page_message"])
                                : "Veuillez commencer à souscrire à un plan pour donner accès aux produits disponibles."}
                        </span>

                    </div>
                )}
                <div className="shop-items">
                    {products && products.map((product) => {
                        if (!isEmpty(accessProducts) && accessProducts.includes(product.id) && theUser.account_type === product["metadata"]["type_of_user"]) {
                            return (
                                <div className="item" key={product.id} id={product.id}>
                                    <div className="content">
                                        <img src={product.images} alt="Product Image"/>
                                        <h2>{product.name}</h2>
                                        <p className="description">
                                            {translations && !isEmpty(translations[product.description])
                                                ? parse(translations[product.description])
                                                : product.description}
                                        </p>

                                        <div className="buy">
                                            {theUser.account_type === "athlete" && countFill < 17 ?
                                                (
                                                    <div/>
                                                ) : (
                                                    product.prices[0].unit_amount !== 0 ? (
                                                        <Fragment>
                                                            <button
                                                                className="btn btn-stripe"
                                                                onClick={() => clickToPay(product)}
                                                            >
                                                                {translations && !isEmpty(translations["order"])
                                                                    ? parse(translations["order"])
                                                                    : "Order"}
                                                            </button>
                                                            <div className="price">
                                                                {(product.prices[0].unit_amount / 100).toLocaleString('eu-EU', {
                                                                    style: 'currency',
                                                                    currency: 'EUR'
                                                                })}
                                                            </div>
                                                        </Fragment>

                                                    ) : (
                                                        <button
                                                            className="btn btn-stripe"
                                                            onClick={() => handleFreePayment(product.id)}
                                                        >
                                                            {translations && !isEmpty(translations["order"])
                                                                ? parse(translations["order"])
                                                                : "Order"}
                                                        </button>
                                                    )
                                                )}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        </Fragment>
    );
};

export default Shop;
