import React, {Fragment, useContext, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { isEmpty } from "../../../components/Utils";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import AddPayment from "./AddPayment";
import {getCustomer, getListPaymentsMethods} from "../../../actions/stripeAdmin.action";
import {toggleModal} from "../../../actions/modal.action";
import {AdminContext} from "../../../contexts/AdminContext";
import Switch from "react-switch";
import moment from "moment";


const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC}`);

const Payment = ({email, stripe_id}) => {
    const {detachPaymentMethod, updateDefaultPaymentMethod} = useContext(AdminContext);
    const stripeReducer = useSelector((state) => state.stripeAdminReducer);
    const customer = stripeReducer.getCustomerReducer;
    const payments = stripeReducer.getListPaymentsReducer;
    const dispatch = useDispatch();
    const [openAddPayment, setOpenAddPayment] = useState(false);
    const [firsAsDefault, setFirsAsDefault] = useState(false);

    useEffect(() => {
        if (!isEmpty(stripe_id)) {
            dispatch(getListPaymentsMethods(stripe_id));
            dispatch(getCustomer(stripe_id));
        }
    }, [stripe_id, dispatch]);

    console.log(payments);

    useEffect(() => {
        if (!isEmpty(payments) && payments.length > 0) {
            setFirsAsDefault(false);
        } else {
            setFirsAsDefault(true);
        }
    }, [payments])


    const removeCard = async (paymentId) => {
        if (window.confirm("Are you sure you want to delete it?")) {
            if (!isEmpty(stripe_id) && paymentId) {
                let result = await detachPaymentMethod(stripe_id, paymentId);
                if(result.result === "ok") {
                    dispatch(getListPaymentsMethods(stripe_id));
                    dispatch(toggleModal({opened: true, success: true, text: "Payments data updated"}));
                } else if (result.result === "bad" && result.error) {
                    dispatch(toggleModal({opened: true, success: false, text: result.error}));
                }
            }
        }
    };

    const setDefault = async (paymentId) => {
       
            if (!isEmpty(stripe_id) && paymentId) {
                let result = await updateDefaultPaymentMethod(stripe_id, paymentId);
                if (result.result === "ok") {
                    dispatch(getCustomer(stripe_id));
                    dispatch(toggleModal({opened: true, success: true, text: "Payments data updated"}));
                } else if (result.result === "bad" && result.error) {
                    dispatch(toggleModal({opened: true, success: false, text: result.error}));
                }
            }
    };

    const defaultCard = useMemo(() => {
        // eslint-disable-next-line array-callback-return
        let card = payments.filter((item) => {
            if(customer.invoice_settings.default_payment_method === item.id || customer.default_source === item.id) {
                return item.id
            }
        })
        return card[0]?.id
    }, []);

    return (
        <div>
        <div className="payment">
                {payments && (
                    payments.map((elem, index) => {
                        return (
                            <div className={`${defaultCard === elem.id ? "default method justify-content-space-between d-flex" : "method justify-content-space-between d-flex"}`} key={elem.id}>
                                <div className="card d-flex my-4">
                                    <div className="image ml-4">
                                        <img src={`/img/icons/cards/${elem.card.brand}.svg`} width="120" length="100" alt=""/>
                                    </div>
                                    <div className="my-auto mx-2 d-grid">
                                        <span className="number">{elem.card.brand} *** {elem.card.last4}</span>
                                        <span className="expire mt-2">Expire le {elem.card.exp_month < 10 ? `0${elem.card.exp_month}` : elem.card.exp_month}/{elem.card.exp_year}</span>
                                    </div>
                                    <div className="d-grid ml-4">
                                        <label htmlFor={`default_${elem.id}`}>Default</label>
                                        <Switch
                                            onChange={() => setDefault(elem.id)}
                                            checked={defaultCard === elem.id}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            offColor="#d6d6d6"
                                            onColor="#679bff"
                                            id={`default_${elem.id}`}
                                        />
                                    </div>
                                </div>

                                <div className="edit my-auto mr-4">
                                    <img src="/img/icons/delete.svg" alt="" onClick={() => removeCard(elem.id)}/>
                                </div>
                            </div>
                        )
                    })
                )}

                <button
                    className="btn btn-stripe mt-2 small"
                    onClick={() => setOpenAddPayment(!openAddPayment)}
                    style={{background: ""}}
                >Add method</button>
            </div>

            {openAddPayment && (
                <Elements stripe={stripePromise}>
                    <AddPayment
                        onClose={() => setOpenAddPayment(!openAddPayment)}
                        onSuccess={() => console.log("add success")}
                        setAsDefault={firsAsDefault}
                        email={email}
                        stripe_id ={stripe_id}
                    />
                </Elements>
            )}
            </div>
    );
}

export default Payment;