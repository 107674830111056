import React from "react"
import {Route} from "react-router-dom";
import {Redirect} from "react-router";
import {useContext, useEffect} from "react";
import {MyContext} from "../../contexts/MyContext";
import Loader from "./Loader";

const PrivateRoute = ({ children, ...rest }) => {
    const {rootState} = useContext(MyContext);
    const {isAuth} = rootState;

    useEffect(() => {
        if (isAuth !== null) {
            if (isAuth === false) {
                localStorage.setItem("loginRedirectUrl", rest.location.pathname);
            }
        }
    }, [isAuth]);

    return (
        <Route
            {...rest}
            render={(routeProps) =>
                isAuth !== null ? (
                    isAuth === true ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/account/login",
                                state: { from: routeProps.location }
                            }}
                        />
                    )
                ) : (<Loader/>)
            }
        />
    );
};

export default PrivateRoute;