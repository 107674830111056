import React, {useContext, useEffect, useState} from "react";
import {isEmpty} from "../../components/Utils";
import {useDispatch, useSelector} from "react-redux";
import {getAdvantages} from "../../actions/stripeAdmin.action";
import {AdminContext} from "../../contexts/AdminContext";
import EditAdvantage from "./EditAdvantage";

const AllAdvantage = () => {
	const advantages = useSelector((state) => state.stripeAdminReducer.getAdvantagesReducer);
	const [advantagesArray, setAdvantagesArray] = useState();
	const [page, setPage] = useState(1);
	const [offset, setOffset] = useState(10);
	const [totalRows, setTotalRows] = useState(0);
	const [editComponent, setEditComponent] = useState(null);
	const {deleteAdvantage} = useContext(AdminContext);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAdvantages(page, offset))
	}, [editComponent, offset, page]);

	useEffect(() => {
		if (advantages.totalRows) {
			setTotalRows(advantages.totalRows);
			setAdvantagesArray(advantages.advantages);
		}
	}, [advantages]);

	const handleSearch = (input) => {
		if (input && input.length > 2) {
			dispatch(getAdvantages(1, offset, input))
		}
	};

	const handleDelete = async (id) => {
		const data = await deleteAdvantage(id);
		if (data.result === "ok") {
			dispatch(getAdvantages());
		} else {
			console.log(data);
		}
	};

	const handleEdit = async (advantageId) => {
		if(editComponent === null) {
			setEditComponent(<EditAdvantage id={advantageId} successCreate={() => setEditComponent(null)}/>)
		} else {
			setEditComponent(null);
		}
	};

	const handlePage = (mode) => {
		let pageNumber = Math.ceil(totalRows / offset);
		if (mode === "next") {
			setPage(page + 1 > pageNumber ? 0 : page + 1);
		} else {
			setPage(page - 1 < 0 ? pageNumber : page - 1);
		}
	};

	return (
		<div className="bo-all_subs">
			<div className="controls">
				<div className="search">
					<label htmlFor="search">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
						>
							<path d="M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z" />
						</svg>
					</label>
					<input
						type="search"
						autoComplete="off"
						name="search"
						id="search"
						placeholder="Chercher une variable"
						onInput={(e) => handleSearch(e.target.value)}
					/>
				</div>
				<div className="pages">
					<div className="buttons">
						<button onClick={() => handlePage("prev")}>Page précédente</button>
						<button onClick={() => handlePage("next")}>Page suivante</button>
					</div>
					<p>
						Page {page} / {Math.ceil(totalRows / offset)}
					</p>
				</div>
				<div className="offset">
					<label htmlFor="offset">Nombre d'éléments affichés</label>
					<input
						type="number"
						name="offset"
						step="10"
						value={offset}
						onChange={(e) => setOffset(parseInt(e.currentTarget.value))}
						min="10"
						max={
							!isEmpty(Math.ceil(advantagesArray).length)
								? Math.ceil(advantagesArray).length + 10
								: 100
						}
					/>
				</div>
			</div>
			<div className="subs-list">
				<table>
					<thead>
					<tr>
						<th>ID</th>
						<th>Titre</th>
						<th>Description</th>
						<th>Type of advantage</th>
						<th>Action</th>
					</tr>
					</thead>
					<tbody>
					{!isEmpty(advantagesArray) &&
					advantagesArray.map((advantage, index) => {
							return (
								<tr key={index}>
									<td>{advantage.id}</td>
									<td>{advantage.title_en}</td>
									<td>{advantage.description_en}</td>
									<td>{advantage.type_of_advantage}</td>
									<td className="d-flex">
										<button onClick={() => handleEdit(advantage.id)}>Edit</button>
										<button onClick={() => handleDelete(advantage.id)}>Archiver la advantage</button>
									</td>
								</tr>
							);
						})
					}
					</tbody>
				</table>
			</div>
			{editComponent}
		</div>
	);

};

export default AllAdvantage;