import { combineReducers } from "redux";
import eventsReducer from "./eventsReducer";
import {webTVSReducer, webTVData, allWebTvs} from "./webTVSReducer";
import playlistAllReducer from "./playlistReducer";
import sportsReducer from "./sportsReducer";
import searchReducer from "./searchReducer";
import langReducer from "./langReducer";
import countryReducer from "./countryReducer";
import divisionReducer from "./divisionReducer";
import groupReducer from "./groupReducer";
import leaguesReducer from "./leaguesReducer";
import highlightReducer from "./highlightReducer";
import videoAllReducer from "./videoReducer";
import slideShowReducer from "./slideShowReducer";
import accountReducer from "./accountReducer";
import notificationsReducer from "./notificationsReducer";
import statusReducer from "./statusReducer";
import settingReducer from "./settingReducer";
import favoritesReducer from "./favoritesReducer";
import stripeReducer from "./stripeReducer";
import stripeAdminReducer from "./stripeAdminReducer";
import modalReducer from "./modalReducer";

//COMBINE ALL REDUCERS
export default combineReducers({
	accountReducer,
	eventsReducer,
	webTVSReducer,
	allWebTvs,
	webTVData,
	playlistAllReducer,
	sportsReducer,
	searchReducer,
	langReducer,
	countryReducer,
	divisionReducer,
	groupReducer,
	highlightReducer,
	leaguesReducer,
	videoAllReducer,
	slideShowReducer,
	notificationsReducer,
	statusReducer,
	settingReducer,
	favoritesReducer,
	stripeReducer,
	stripeAdminReducer,
	modalReducer
});
