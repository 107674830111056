import { useDispatch, useSelector } from "react-redux";
import React, { useContext, useEffect, useState } from "react";
import { getNotifications } from "../../actions/notifications.action";
import Moment from "react-moment";
import axios from "axios";
import { useHistory } from "react-router";
import { MyContext } from "../../contexts/MyContext";
import { toggleNotification } from "../../actions/account.action";

import parse from "html-react-parser";
import {isEmpty} from "../Utils";

const Notification = () => {
	const dispatch = useDispatch();
	let history = useHistory();
	const { rootState } = useContext(MyContext);
	const { isAuth } = rootState;
	const [notifications, setNotifications] = useState([]);
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const notificationsReducer = useSelector(
		(state) => state.notificationsReducer.standardNotificationsReducer
	);
	const notificationToggleReducer = useSelector(
		(state) => state.accountReducer.notificationReducer
	);
	const translations = translationsReducer.translations;
	const selectedLanguage = translationsReducer.language;

	useEffect(() => {
		dispatch(getNotifications())
	}, []);

	useEffect(() => {
		setNotifications(notificationsReducer.notifications);
	}, [notificationsReducer]);

	/*id can be 'all'*/
	const updateRead = async (id) => {
		let idUrl = encodeURI(id);
		const loginToken = localStorage.getItem("loginToken");

		let config = {
			headers: {
				"Authorization-Bearer": `Bearer ${loginToken}`
			},
		};

		if (process.env.REACT_APP_ENV === "production") {
			config = {
				headers: {
					"Authorization-Bearer": `Bearer ${loginToken}`,
					"Authorization": `Bearer ${loginToken}`
				},
			};
		}

		await axios
			.get(`${process.env.REACT_APP_API_URL}readNotifications&i=${idUrl}`, config)
			.then((res) =>
				res.data.status === 201
					? dispatch(getNotifications())
					: console.log("Update Error!")
			)
			.then(() => {
				idUrl !== "all" && linkTo(idUrl);
			});
	};

	const linkTo = (id) => {
		let notify = notifications[id];
		if (notify.vidId) {
			dispatch(toggleNotification(!notificationToggleReducer));
			let link = `/player/${notify.vidId}`;
			history.replace(link);
		}
	};

	useEffect(() => {
		let menu = document.querySelector(".notifications.sub-menu");
		let bckg = document.querySelector(".hide-background");
		let iconNoty = document.querySelector(".account-section .notifications");

		if (notificationToggleReducer) {
			menu.classList.add("hover");
			bckg.classList.add("hover");
			iconNoty.classList.add("hover");
		} else {
			menu.classList.remove("hover");
			bckg.classList.remove("hover");
			iconNoty.classList.remove("hover");
		}
	}, [notificationToggleReducer, isAuth]);

	const handleClickOutside = (event) => {
		if (event.target.closest('.notifications') === null) {
			dispatch(toggleNotification(false));
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	});

	return (
		<div className="notifications sub-menu">
			<div className="title">
				<span
					onClick={() => {
						dispatch(toggleNotification(!notificationToggleReducer));
					}}
				>
					{translations && !isEmpty(translations["notifications"])
						? parse(translations["notifications"])
						: "Notifications"
					}
				</span>
				<span onClick={() => updateRead("all")}>
					{translations && !isEmpty(translations["markAsRead"])
						? parse(translations["markAsRead"])
						: "Mark as read"
					}
				</span>
			</div>
			<div className="item-rows">
				{notifications &&
					Object.keys(notifications).map((key, index) => {
						let notification = notifications[key];
						return (
							<div
								className={"content" + (notification.read === "0" ? " new" : "")}
								key={notification.notifId}
							>
								<div className="item" onClick={() => updateRead(notification.notifId)}>
									<div className="image">
										<img
											src={notification.img}
											alt={notification["title" + selectedLanguage.toUpperCase()]}
											width="50"
										/>
										{/*if has `started` it is event*/}
										{notification.started === "yes" && (
											<span className="live">
												{translations && !isEmpty(translations["live"])
													? parse(translations["live"])
													: "live"
												}
											</span>
										)}
										{notification.started === "no" && (
											<span className="off">
												{translations && !isEmpty(translations["soon"])
													? parse(translations["soon"])
													: "soon"
												}
											</span>
										)}
									</div>
									<div className="message">
										<h3>{notification["title" + selectedLanguage.toUpperCase()]}</h3>
										<div className="message-text">
											{notification["description" + selectedLanguage.toUpperCase()]}
										</div>
										<span className="date">
											<Moment format="DD/MM/YYYY">{notification.date}</Moment>
										</span>
									</div>
								</div>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default Notification;
