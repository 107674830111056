import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EventsComponent from "../components/Events";
import Search from "../components/Search";
import Banner from "../components/sports/Banner";

import Submenu from "../components/events/Submenu";
import {isEmpty, removeTags} from "../components/Utils";
import { Redirect } from "react-router";
import { getLeagues } from "../actions/leagues.action";
import {
	getEvents,
	getEventsLeague,
	getEventsSport,
} from "../actions/events.action";
import { toggleSearch } from "../actions/search.action";
import parse from "html-react-parser";
import {getDivisions} from "../actions/division.action";
import {getGroups} from "../actions/group.action";
import {Helmet} from "react-helmet-async";
import RedirectApp from "../components/RedirectApp";

const Events = ({ match, location }) => {
	const {
		params: { sportName },
	} = match;

	let param = sportName ? sportName.toLowerCase() : undefined;
	const dispatch = useDispatch();
	const sports = useSelector((state) => state.sportsReducer.sportsTypesReducer);
	const leagues = useSelector((state) => state.leaguesReducer.leagues);
	const events = useSelector(
		(state) => state.eventsReducer.eventsGroupByReducer
	);
	const allEvents = useSelector((state) => state.eventsReducer.eventsAllReducer);
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const translations = translationsReducer.translations;
	const selectedLanguage = translationsReducer.language;
	const searchState = useSelector(
		(state) => state.searchReducer.searchToggleReducer
	);

	useEffect(() => {
		dispatch(getEvents(100));
		dispatch(getLeagues());
		dispatch(getLeagues("", "", "",  selectedLanguage));
		dispatch(getDivisions("", "", "", "", selectedLanguage));
		dispatch(getGroups("", "", "", "", "", selectedLanguage));
	}, [dispatch, selectedLanguage]);

	//GET SUPPORTED SPORT AND VERIFY IF MATCH
	const [matchSport, setMatchSport] = useState(true);
	// eslint-disable-next-line
	async function findSport(sports) {
		return await sports.filter((sport) => sport.name.toLowerCase() === param);
	}

	useEffect(() => {
		if (sports.length > 0) {
			findSport(sports).then((resolve) => {
				setMatchSport(resolve.length > 0);
			});
		}
	}, [sports, param, findSport]);

	//IF SELECT ALL EVENTS -> GET EVENTS BY SPORT_ID, ORDER BY SPORT_ID
	useEffect(() => {
		if (!param) {
			sports.map((sport) => {
				dispatch(getEventsSport(sport.id));
				return true;
			});
		}
		// eslint-disable-next-line
	}, [param, sports]);

	//IF SELECT ANY SPORT -> GET EVENTS BY LEAGUE AND SPORT_ID, ORDER BY LEAGUE_ID
	useEffect(() => {
		if (param && leagues) {
			findSport(sports).then((resolve) => {
				let sp = resolve[0];
				leagues.map((league) => {
					dispatch(getEventsLeague(league.id, sp.id));
					return true;
				});
			});
		}
		// eslint-disable-next-line
	}, [param, leagues]);

	useEffect(() => {
		if (searchState) {
			dispatch(toggleSearch(false));
		}
		// eslint-disable-next-line
	}, [location, dispatch]);

	const onSelectFilter = (leagueId, divisionId, groupId, isSport, isLeague) => {
		if (isEmpty(isSport) && isEmpty(isLeague)) {
			//IS ALL ROW EVENTS
			//console.log('IS ALL ROW EVENTS');
			dispatch(getEvents(100, "", leagueId, divisionId, groupId));
		} else if (!isEmpty(isSport) && isEmpty(isLeague)) {
			//ALL EVENTS PAGE -> SPORT ROW
			//console.log('ALL EVENTS PAGE -> SPORT ROW');
			dispatch(getEventsSport(isSport, leagueId, divisionId, groupId));
		} else if (isEmpty(isSport) && !isEmpty(isLeague)) {
			//SPORT EVENTS PAGE -> LEAGUE ROW
			//console.log('SPORT EVENTS PAGE -> LEAGUE ROW');
			dispatch(getEventsLeague(leagueId, isSport, divisionId, groupId));
		}
	};

	return (
		<>
			<Helmet>
				<title>Fuchs Sports International</title>
				<meta property="og:image" content={`${process.env.REACT_APP_CACHE_SERVER}/img/icons/logo_fuchs_sports.png`} />
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
			</Helmet>

			<RedirectApp page="events"/>

			<div>
				{!matchSport && <Redirect to="/events" />}
				<Banner title={translations && !isEmpty(translations["events"])
					? parse(translations["events"])
					: "events"
				} />
				{searchState && <Search />}
				<Submenu />

				{/* ALL EVENTS */}
				{!isEmpty(sports) && !param && (
					<EventsComponent
						key="all"
						dataKey="all"
						title={translations && !isEmpty(translations["events"])
							? parse(translations["events"])
							: "events"
						}
						events={allEvents}
						filter={true}
						onSelectFilter={onSelectFilter}
					/>
				)}

				{!param
					? !isEmpty(sports) &&
					  sports.map((sport) => {
							return (
								<EventsComponent
									key={"sport" + sport.id}
									dataKey={"sport" + sport.id}
									title={translations && !isEmpty(translations[sport.name])
										? parse(translations[sport.name])
										: sport.name
									}
									events={events[sport.id] ? events[sport.id] : []}
									filter={true}
									sportId={sport.id}
									onSelectFilter={onSelectFilter}
								/>
							);
					  })
					: !isEmpty(leagues) &&
						leagues.map((league) => {
							return (
								<EventsComponent
									key={"league" + league.id}
									dataKey={"league" + league.id}
									title={!isEmpty(league.name) ? removeTags(league.name) : ""}
									events={events[league.id] ? events[league.id] : []}
									filter={true}
									leagueId={league.id}
									onSelectFilter={onSelectFilter}
								/>
							);
						})
				}
			</div>
		</>
	);
};

export default Events;
