import React, { useContext, useEffect, useState } from "react";
import { isEmpty } from "../../components/Utils";
import { AdminContext } from "../../contexts/AdminContext";

const Admin = () => {
	const [password, setPassword] = useState(null);
	const [passwordVerify, setPasswordVerify] = useState(null);
	const [capital, setCapital] = useState(false);
	const [letterNumber, setLetterNumber] = useState(false);

	const [allow, setAllow] = useState(false);
	const activeInput = (id) => {
		let inputs = document.querySelectorAll(
			".parameters-container .security .input"
		);
		inputs.forEach((input) => {
			input.classList.remove("select");
		});

		let input = document.getElementById(id);
		input.parentElement.classList.add("select");
	};

	//HANDLE ERROR
	useEffect(() => {
		if (!isEmpty(password)) {
			password.length >= 8 ? setLetterNumber(true) : setLetterNumber(false);
			password.match(/^(?=.*[A-Z])[0-9a-zA-Z-+_!@#$%^&*.,?]{8,}$/)
				? setCapital(true)
				: setCapital(false);

			password.length < 8 ||
			!password.match(/^(?=.*[A-Z])[0-9a-zA-Z-+_!@#$%^&*.,?]{8,}$/)
				? document.querySelector("#password").parentElement.classList.add("error")
				: document
						.querySelector("#password")
						.parentElement.classList.remove("error");
		}
		if (!isEmpty(password) && !isEmpty(passwordVerify)) {
			passwordVerify !== password
				? document
						.querySelector("#passwordConfirm")
						.parentElement.classList.add("error")
				: document
						.querySelector("#passwordConfirm")
						.parentElement.classList.remove("error");
		}
	}, [password, passwordVerify]);

	useEffect(() => {
		setAllow(false);
		if (
			!isEmpty(password) &&
			!isEmpty(passwordVerify) &&
			capital &&
			letterNumber &&
			passwordVerify === password
		) {
			setAllow(true);
		}
	}, [capital, letterNumber, password, passwordVerify]);

	const { updatePassword } = useContext(AdminContext);

	const submitForm = async (event) => {
		event.preventDefault();
		if (allow) {
			const data = await updatePassword(password);

			if (data.success) {
				console.log("updated");
			} else {
				console.log(data.message);
			}
		}
	};

	return (
		<section className="bo-admin">
			<h2>Admin</h2>

			<form onSubmit={submitForm}>
				<div className="input select">
					<label>Mot de Passe *</label>
					<input
						type="password"
						name="password"
						id="password"
						placeholder="Votre mot de passe"
						onFocus={(e) => activeInput(e.target.id)}
						required
						autoFocus
						autoComplete="new-password"
						onClick={(e) => activeInput(e.target.id)}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</div>
				<div className="input select">
					<label>Confirmez le mot de passe *</label>
					<input
						type="password"
						name="passwordConfirm"
						id="passwordConfirm"
						placeholder="Confirmez le mot de passe"
						onFocus={(e) => activeInput(e.target.id)}
						required
						autoFocus
						autoComplete="new-password"
						onClick={(e) => activeInput(e.target.id)}
						onChange={(e) => setPasswordVerify(e.target.value)}
					/>
				</div>
				<div className="error">
					<p>Le mot de passe doit contenir 1 majuscule et au moins 8 caractères</p>
					<div className="validator">
						<div>
							{capital ? (
								<svg
									className="validate"
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
								</svg>
							) : (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
								</svg>
							)}
							Une majuscule
						</div>
						<div>
							{letterNumber ? (
								<svg
									className="validate"
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
								</svg>
							) : (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
								</svg>
							)}
							8 caractères minimum
						</div>
					</div>
				</div>
				<input type="submit" value="UPDATE" />
			</form>
		</section>
	);
};

export default Admin;
