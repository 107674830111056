import React, {useContext, useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Redirect} from "react-router";
import Search from "../components/Search";
import SuccessError from "../components/account/SuccessError";
import {isEmpty, removeTags} from "../components/Utils";
import parse from "html-react-parser";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";
import {useDispatch, useSelector} from "react-redux";
import {toggleSearch} from "../actions/search.action";
import {MyContext} from "../contexts/MyContext";
import axios from "axios";
import CheckBoxesRadio from "../components/modules/CheckBoxesRadio";

const Register = ({ match, location }) => {

    const searchState = useSelector(
        (state) => state.searchReducer.searchToggleReducer
    );
    const supportedLanguages = useSelector((state) => state.langReducer.languagesReducer.languages);
    const translationsReducer = useSelector((state) => state.langReducer.translationsReducer);
    const sports = useSelector((state) => state.sportsReducer.sportsTypesReducer);
    const dispatch = useDispatch();
    const inputDateRef = useRef();
    const { registerUser, rootState } = useContext(MyContext);
    const { isAuth } = rootState;
    const selectedLanguage = translationsReducer.language;
    const translations = translationsReducer.translations;
    const [page, setPage] = useState(1);

    const [languagesOptions, setLanguagesOptions] = useState([]);

    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [username, setUsername] = useState(null);

    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [userNameError, setUserNameError] = useState(false);
    const [datebirthError, setDatebirthError] = useState(false);

    const [datebirth, setDatebirth] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [gender, setGender] = useState("other");
    const [typeUser, setTypeUser] = useState("fan");
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [passwordVerify, setPasswordVerify] = useState(null);
    const [sportCheck, setSportCheck] = useState([]);
    const [fuchsOffers, setFuchsOffers] = useState("false");
    const [partnerOffers, setPartnerOffers] = useState("false");
    const [selectedFavoriteOptions, setSelectedFavoriteOptions] = useState([]);
    const [optionFavoriteList, setOptionFavoriteList] = useState([]);
    const [language, setLanguage] = useState(null);
    const [formatDatebirth, setFormatDatebirth] = useState(moment(new Date()).format('DD/MM/YYYY'));
    const [isDateOpen, setIsDateOpen] = useState(false);

    const [rules, setRules] = useState(false);
    const [popup, setPopup] = useState(false);

    const [rulesError, setRulesError] = useState(false);
    const [sportsError, setSportsError] = useState(false);

    const [errorMail, setErrorMail] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
    const [errorPasswordVerify, setErrorPasswordVerify] = useState(false);

    const [errorResponseMail, setErrorResponseMail] = useState(false);
    const [errorUserName, setErrorUserName] = useState(false);
    const [userNameExist, setUserNameExist] = useState(false);
    const [errorFirstName, setErrorFirstName] = useState(false);
    const [errorLastName, setErrorLastName] = useState(false);
    const [letterNumber, setLetterNumber] = useState(false);
    const [capital, setCapital] = useState(false);
    const [submitWork, setSubmitWork] = useState(false);

    const [popupContent, setPopupContent] = useState({
        title: "",
        text: "",
        textButton: "",
        type: "",
    });

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            width: "100%",
            cursor: "pointer"
        }),
        option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
            ...provided,
            color: isDisabled ? undefined : isSelected ? "#ffffff" : isFocused ? "black" : undefined,
            cursor: "pointer",
            backgroundColor: isDisabled ? undefined : isSelected ? "#49546c" : isFocused ? "white" : undefined,
            ":hover": {
                ...provided[":hover"],
                color: isSelected ? "#ffffff" : "black"
            },
        }),
        control: (provided, state) => ({
            ...provided,
            borderRadius: "unset",
            borderStyle: "none",
            borderWidth: "unset",
            minHeight: "2.5rem",
            backgroundColor: "rgba(202, 211, 255, 0.0676517)",
            color: "#ffffff",
            boxShadow: "none",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#ffffff"
        }),
        menu: (provided, state) => ({
            ...provided,
            color: "#ffffff",
            backgroundColor: "#1b283c",
            marginTop: 0,
            borderRadius: "0 0 4px 4px",
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none"
        })
    };

    useEffect(() => {
        if (searchState) {
            dispatch(toggleSearch(false));
        }
        // eslint-disable-next-line
    }, [location]);

    useEffect(() => {
        setErrorUserName(false);
        setUserNameExist(false);

        if (page === 1) {
            if (!isEmpty(firstName)) {
                if (firstName.length < 2 || !firstName.match(/^[a-zA-ZÀ-ÿ\s-]*$/)) {
                    setFirstNameError(true)
                    setErrorFirstName(true);
                } else {
                    setFirstNameError(false)
                    setErrorFirstName(false);
                }
            }

            if (!isEmpty(lastName)) {
                if (lastName.length < 2 || !lastName.match(/^[a-zA-ZÀ-ÿ\s-]*$/)) {
                    setLastNameError(true);
                    setErrorLastName(true);
                } else {
                    setLastNameError(false);
                    setErrorLastName(false);
                }
            }

            if (!isEmpty(username)) {
                if (username.length < 2 || !username.match(/^[a-zA-Z0-9_]*$/)) {
                    setUserNameError(true);
                    setErrorUserName(true);
                } else {
                    setUserNameError(false);
                    setErrorUserName(false);
                }
            }

            if (!isEmpty(formatDatebirth)) {//19/08/2022
                if(moment(formatDatebirth, 'DD/MM/YYYY',true).isValid()) {
                    setDatebirthError(false);
                } else {
                    setDatebirthError(true);
                }
                //if (!datebirth.match(/^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/)) {
                //    setDatebirthError(true);
                //} else {
                //    //setDatebirthError(false);
                //}
            }
        } else if (page === 2) {
            if (!isEmpty(email)) {
                email.length < 2 ||
                !email.match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
                    ? setErrorMail(true)
                    : setErrorMail(false)
            }

            if (!isEmpty(password)) {
                password.length >= 8 ? setLetterNumber(true) : setLetterNumber(false);
                if (password.match(/^(?=.*[A-Z])[0-9a-zA-Z-+_!@#$%^&*.,?]{8,}$/)) {
                    setCapital(true);
                    setErrorPassword(false);
                } else {
                    setCapital(false);
                    setErrorPassword(true);
                }
            }
            if (!isEmpty(password) && !isEmpty(passwordVerify)) {
                passwordVerify !== password
                    ? setErrorPasswordVerify(true)
                    : setErrorPasswordVerify(false)
            }
        }


    }, [firstName, lastName, username, datebirth, password, passwordVerify, email, formatDatebirth]);



    useEffect(() => {
        if (!isEmpty(datebirth)) {
            setFormatDatebirth(moment(new Date(datebirth)).format('DD/MM/YYYY'))
        }
    }, [datebirth]);

    useEffect(() => {
        //TITLES
        let titles = document.querySelectorAll(".account-container h2.border");
        titles.forEach((title) => {
            title.id.split("-")[1] === page.toString()
                ? title.classList.add("display")
                : title.classList.remove("display");
        });

        //PAGE
        let pages = document.querySelectorAll(".account-container .page");
        pages.forEach((pageRegister) => {
            pageRegister.id.split("-")[1] === page.toString()
                ? pageRegister.classList.add("display")
                : pageRegister.classList.remove("display");
        });

        //DISPLAY NUMBER
        let displaysNumber = document.querySelectorAll(
            ".account-container .page-display>span"
        );
        displaysNumber.forEach((elm, index) => {
            index + 1 === page
                ? elm.classList.add("display")
                : elm.classList.remove("display");
            if (index + 2 === page) elm.classList.add("validate");
        });
    }, [page]);

    const activeInput = (id) => {
        let inputs = document.querySelectorAll(".account-page .input");
        inputs.forEach((input) => {
            input.classList.remove("select");
        });

        let input = document.getElementById(id);
        input.parentElement.classList.add("select");
    };

    useEffect(() => {
        if (!isEmpty(supportedLanguages) && !isEmpty(supportedLanguages[0].idLanguage)) {
            setLanguage(supportedLanguages[0].idLanguage);
            setLanguagesOptions(supportedLanguages.map((lang) => {
                return { value: lang.idLanguage, label: lang.longLang.charAt(0).toUpperCase() + lang.longLang.slice(1) }
            }))
        }
    }, [supportedLanguages]);

    useEffect(() => {
        if (popup) {
            document.querySelector(".hide-background").classList.add("hover");
            document.querySelector(".hide-background-not").classList.add("hover");

            switch (popup) {
                case 1:
                    //register success
                    setPopupContent({
                        title: translations && !isEmpty(translations["registerSuccess"])
                            ? removeTags(translations["registerSuccess"])
                            : "",
                        text: translations && !isEmpty(translations["emailSendLabel"])
                            ? removeTags(translations["emailSendLabel"])
                            : "",
                        textButton: translations && !isEmpty(translations["connectionButton"])
                            ? removeTags(translations["connectionButton"])
                            : "",
                        type: "success",
                    });
                    break;

                default:
                    return false;
            }
        } else {
            setPopupContent({ title: "", text: "", textButton: "", type: "" });
            document.querySelector(".hide-background").classList.remove("hover");
            document.querySelector(".hide-background-not").classList.remove("hover");
        }
    }, [popup, translations]);

    const handlePage = (value) => {
        //handle errors
        let allow = false;
        switch (value) {
            case 2:
                if (isEmpty(firstName) || isEmpty(lastName) || isEmpty(username) || firstNameError || lastNameError || userNameError || datebirthError) {
                    allow = false;
                    isEmpty(firstName) && setFirstNameError(true);
                    isEmpty(lastName) && setLastNameError(true);
                    isEmpty(username) && setUserNameError(true);
                } else {
                    allow = true;
                }

                break;
            case 3:
                if (isEmpty(email) || isEmpty(password) || isEmpty(passwordVerify) || errorPassword || errorPasswordVerify || errorMail) {
                    allow = false;
                    isEmpty(email) && setErrorMail(true);
                    isEmpty(password) && setErrorPassword(true);
                    isEmpty(passwordVerify) && setErrorPasswordVerify(true);
                } else {
                    allow = true;
                }
                break;
            default:
                break;
        }

        if (allow || (value < page && value >= 1)) {
            setPage(value);
        }
    };

    const handleFavoriteSelect = async (data) => {
        if (data.length >= 2) {
            axios
                .get(`${process.env.REACT_APP_API_URL}getWebTVS&lkn=${encodeURI(data)}`)
                .then((res) => {
                    if (res.data.webTVS.length > 0) {
                        let webTvs = res.data.webTVS.filter(
                            (club) => !selectedFavoriteOptions.map( el => el.id ).includes(club.id)
                        );
                        setOptionFavoriteList(webTvs);
                    }
                });
        }
    };

    const onSelectFavorite = (club) => {
        setSelectedFavoriteOptions(club.map((elem) => {
            return {id : elem.id, name: elem.name}
        }));
    };

    const onRemoveFavorite = (club) => {
        setSelectedFavoriteOptions(club.map((elem) => {
            return {id : elem.id, name: elem.name}
        }));
    };

    const handleSport = (sport) => {
        if (sportCheck.includes(sport)) {
            setSportCheck(sportCheck.filter((item) => item !== sport));
        } else {
            setSportCheck((arr) => [...arr, sport]);
        }
    };

    const handleFormatInputDate = (date) => {
        let onlyNumber = date.replace(/\D/g, '');
        let cardValue = onlyNumber.match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
        let	year = cardValue[3];
        let	month = cardValue[2];
        let	day = cardValue[1];
        let format;

        if (year.length >= 1) {
            if (year.length >= 4) {
                if (year < 1930) {
                    year = 1930;
                } else if (year > 2021) {
                    year = 2021
                }
                //set state
                setDatebirth(year + "-" + month + "-" + day);
            }
            format = day + "/" + month + "/" + year;
        } else if (month.length >= 1) {
            if (month <= 12) {
                format = day + "/" + month;
            } else {
                format = day + "/" + 12;
            }
        } else if (day.length >= 1) {
            if (day <= 31) {
                format = day;
            } else {
                format = 31;
            }
        } else {
            format = "";
        }

        setFormatDatebirth(format);
    };

    const submitRegister = async (event) => {
        event.preventDefault();
        if (!rules || sportCheck.length < 1) {
            !rules && setRulesError(true);
            sportCheck.length < 1 && setSportsError(true);
            return false;
        }
        setSubmitWork(true);
        let userInfo = {
            firstName,
            lastName,
            username,
            datebirth,
            gender,
            email,
            password,
            sportCheck: sportCheck,
            rules,
            fuchsOffers,
            partnerOffers,
            lang: selectedLanguage,
            favoriteClubs: selectedFavoriteOptions,
            language: language,
            accountType: typeUser
        };


        const data = await registerUser(userInfo);

        if (data.success) {
            setPopup(1);
            setPage(1);
        } else {
            if (data.message === "emailErrorRegister") {
                setErrorResponseMail(true);
                setErrorMail(true);
                setPage(2);
            }
            if (data.message === "usernameErrorRegister") {
                setErrorUserName(true);
                setUserNameExist(true);
                setUserNameError(true);
                setPage(1);
            }
        }

        setSubmitWork(false);
    };

    const handleClickOutside = (event) => {
        if (event.target.closest('.react-datepicker') === null && event.target.closest('.datepicker-toggle-button') === null) {
            if (isDateOpen) {
                setIsDateOpen(false);
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return (
        <>
            <Helmet>
                <title>Fuchs Sports International</title>
                <meta property="og:image" content={`${process.env.REACT_APP_CACHE_SERVER}/img/icons/logo_fuchs_sports.png`} />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
            </Helmet>

            <div className="account-page">
                {(isAuth !== null && isAuth === true) && <Redirect to="/" />}
                {searchState && <Search />}

                {popup && (
                    <SuccessError
                        content={popupContent}
                        close={setPopup}
                    />
                )}

                <div className="banner">
                    <img src={`${process.env.REACT_APP_CACHE_SERVER}/img/footages/banner-account.jpg`} alt="banner account" />
                    <div className="dots">
                        <img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/dots1.png`} alt="decoration elements" />
                        <img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/dots2.png`} alt="decoration elements" />
                    </div>
                    <div className="decorations">
                        <img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/lb1.svg`} alt="decoration elements" />
                        <img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/lb2.svg`} alt="decoration elements" />
                        <img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/lb3.svg`} alt="decoration elements" />
                        <img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/rb.svg`} alt="decoration elements" />
                    </div>
                </div>

                    <section className="account">
                        <div className="account-container">
                            <div className="page-display">
								<span className="display">
									<span>1</span>
									<svg
                                        width="15"
                                        height="12"
                                        viewBox="0 0 15 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
										<path
                                            d="M14.8695 1.52424L5.51421 11.8558C5.43136 11.9481 5.31857 12 5.2009 12C5.08324 12 4.97045 11.9481 4.88759 11.8558L0.130532 6.60232C0.0469906 6.51082 0 6.38625 0 6.25631C0 6.12637 0.0469906 6.00181 0.130532 5.9103L0.748331 5.22802C0.83119 5.13576 0.943981 5.08387 1.06164 5.08387C1.17931 5.08387 1.2921 5.13576 1.37496 5.22802L5.19649 9.44839L13.625 0.140195C13.7996 -0.0467318 14.0771 -0.0467318 14.2517 0.140195L14.8695 0.832218C14.953 0.923724 15 1.04829 15 1.17823C15 1.30817 14.953 1.43273 14.8695 1.52424Z"
                                            fill="white"
                                        />
									</svg>
								</span>
                                <span>
									<span>2</span>
									<svg
                                        width="15"
                                        height="12"
                                        viewBox="0 0 15 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
										<path
                                            d="M14.8695 1.52424L5.51421 11.8558C5.43136 11.9481 5.31857 12 5.2009 12C5.08324 12 4.97045 11.9481 4.88759 11.8558L0.130532 6.60232C0.0469906 6.51082 0 6.38625 0 6.25631C0 6.12637 0.0469906 6.00181 0.130532 5.9103L0.748331 5.22802C0.83119 5.13576 0.943981 5.08387 1.06164 5.08387C1.17931 5.08387 1.2921 5.13576 1.37496 5.22802L5.19649 9.44839L13.625 0.140195C13.7996 -0.0467318 14.0771 -0.0467318 14.2517 0.140195L14.8695 0.832218C14.953 0.923724 15 1.04829 15 1.17823C15 1.30817 14.953 1.43273 14.8695 1.52424Z"
                                            fill="white"
                                        />
									</svg>
								</span>
                                <span>
									<span>3</span>
									<svg
                                        width="15"
                                        height="12"
                                        viewBox="0 0 15 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
										<path
                                            d="M14.8695 1.52424L5.51421 11.8558C5.43136 11.9481 5.31857 12 5.2009 12C5.08324 12 4.97045 11.9481 4.88759 11.8558L0.130532 6.60232C0.0469906 6.51082 0 6.38625 0 6.25631C0 6.12637 0.0469906 6.00181 0.130532 5.9103L0.748331 5.22802C0.83119 5.13576 0.943981 5.08387 1.06164 5.08387C1.17931 5.08387 1.2921 5.13576 1.37496 5.22802L5.19649 9.44839L13.625 0.140195C13.7996 -0.0467318 14.0771 -0.0467318 14.2517 0.140195L14.8695 0.832218C14.953 0.923724 15 1.04829 15 1.17823C15 1.30817 14.953 1.43273 14.8695 1.52424Z"
                                            fill="white"
                                        />
									</svg>
								</span>
                            </div>
                            <h2 id="title-1" className="border display">
                                {translations && !isEmpty(translations["registerGeneralInfo"])
                                    ? parse(translations["registerGeneralInfo"])
                                    : "registerGeneralInfo"}
                            </h2>
                            <h2 id="title-2" className="border">
                                {translations && !isEmpty(translations["registerCredentials"])
                                    ? parse(translations["registerCredentials"])
                                    : "registerCredentials"}
                            </h2>
                            <h2 id="title-3" className="border">
                                {translations && !isEmpty(translations["registerPreferences"])
                                    ? parse(translations["registerPreferences"])
                                    : "registerPreferences"}
                            </h2>
                            <form id="register-form" onSubmit={submitRegister} noValidate>
                                <div id="page-1" className="page display">

                                    <CheckBoxesRadio
                                        values={[
                                            {value: "fan", name: "fan", translate: "fan"},
                                            {value: "athlete", name: "athlete", translate: "athlete"},
                                            {value: "club", name: "club", translate: "club"},
                                            {value: "media", name: "media", translate: "media"},
                                        ]}
                                        name="type_user"
                                        checked={typeUser}
                                        onChange={(value) => setTypeUser(value)}
                                    />

                                    <div className={firstNameError ? "input select error" : "input select"}>
                                        <label>
                                            {translations && !isEmpty(translations["registerFirstName"])
                                                ? parse(translations["registerFirstName"])
                                                : "registerFirstName"}{" "}
                                            *
                                        </label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            id="firstName"
                                            placeholder={
                                                translations && !isEmpty(translations["registerFirstNamePH"])
                                                    ? removeTags(
                                                        translations["registerFirstNamePH"]
                                                    )
                                                    : "registerFirstNamePH"
                                            }
                                            required
                                            autoFocus
                                            autoComplete="given-name"
                                            onFocus={(e) => activeInput(e.target.id)}
                                            onClick={(e) => activeInput(e.target.id)}
                                            onChange={(e) => setFirstName(e.target.value)}
                                        />
                                        {errorFirstName && (
                                            <div className="errorDisplay">
                                                {translations && !isEmpty(translations["registerFirstNameError"])
                                                    ? parse(translations["registerFirstNameError"])
                                                    : "registerFirstNameError"}
                                            </div>
                                        )}
                                    </div>
                                    <div className={lastNameError ? "input select error" : "input select"}>
                                        <label>
                                            {translations && !isEmpty(translations["registerLastName"])
                                                ? parse(translations["registerLastName"])
                                                : "registerLastName"}{" "}
                                            *
                                        </label>
                                        <input
                                            type="text"
                                            name="lastName"
                                            id="lastName"
                                            placeholder={
                                                translations && !isEmpty(translations["registerLastNamePH"])
                                                    ? removeTags(
                                                        translations["registerLastNamePH"]
                                                    )
                                                    : "registerLastNamePH"
                                            }
                                            onClick={(e) => activeInput(e.target.id)}
                                            required
                                            autoComplete="family-name"
                                            onFocus={(e) => activeInput(e.target.id)}
                                            onChange={(e) => setLastName(e.target.value)}
                                        />
                                        {errorLastName && (
                                            <div className="errorDisplay">
                                                {translations && !isEmpty(translations["registerLastNameError"])
                                                    ? parse(translations["registerLastNameError"])
                                                    : "registerLastNameError"}
                                            </div>
                                        )}
                                    </div>
                                    <div className={userNameError ? "input select error" : "input select"}>
                                        <label>
                                            {translations && !isEmpty(translations["registerUsername"])
                                                ? parse(translations["registerUsername"])
                                                : "registerUsername"}{" "}
                                            *
                                        </label>
                                        <input
                                            type="text"
                                            name="username"
                                            id="username"
                                            placeholder={
                                                translations && !isEmpty(translations["registerUsernamePH"])
                                                    ? removeTags(
                                                        translations["registerUsernamePH"]
                                                    )
                                                    : "registerUsernamePH"
                                            }
                                            autoComplete="username"
                                            onClick={(e) => activeInput(e.target.id)}
                                            required
                                            onFocus={(e) => activeInput(e.target.id)}
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                        {errorUserName && (
                                            <div className="errorDisplay">
                                                {
                                                    userNameExist ?
                                                        (translations && !isEmpty(translations["registerUsernameError"])
                                                                ? parse(translations["registerUsernameError"])
                                                                : "registerUsernameError") :
                                                        (translations && !isEmpty(translations["registerUserNameError"])
                                                                ? parse(translations["registerUserNameError"])
                                                                : "registerUserNameError"
                                                        )
                                                }
                                            </div>
                                        )}
                                    </div>
                                    <div className={datebirthError ? "input error" : "input"}>
                                        <label>
                                            {translations && !isEmpty(translations["registerBirthdate"])
                                                ? parse(translations["registerBirthdate"])
                                                : "registerBirthdate"}{" "}
                                        </label>
                                        <input
                                            ref={inputDateRef}
                                            type="text"
                                            name="datebirth"
                                            id="datebirth"
                                            value={formatDatebirth ? formatDatebirth : ""}
                                            onFocus={(e) => {
                                                activeInput(e.target.id);
                                                setIsDateOpen(false);
                                            }}
                                            onChange={(e) => handleFormatInputDate(e.target.value)}
                                            placeholder="DD/MM/YYYY"
                                        />
                                        {isDateOpen && (
                                            <DatePicker
                                                selected={new Date(datebirth)}
                                                onChange={(date) => {
                                                    handleFormatInputDate(moment(date).format("DD/MM/YYYY"))
                                                }}
                                                inline
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                minDate={new Date(1930, 1, 1)}
                                                maxDate={new Date()}
                                            />
                                        )}
                                        <span className="datepicker-toggle-button"
                                              onClick={(e) => setIsDateOpen(!isDateOpen)}
                                        />
                                    </div>

                                    <div className="input">
                                        <label>
                                            {translations && !isEmpty(translations["language"])
                                                ? parse(translations["language"])
                                                : "language"
                                            }
                                        </label>
                                        <Select
                                            id="language"
                                            defaultValue={{value: 1, label: "English"}}
                                            styles={customStyles}
                                            options={languagesOptions}
                                            onChange={(option) => setLanguage(option.value)}
                                        />
                                    </div>


                                    <CheckBoxesRadio
                                        values={[
                                            {value: "man", name: "man", translate: "registerMan"},
                                            {value: "woman", name: "woman", translate: "registerWoman"},
                                            {value: "other", name: "other", translate: "registerOther"},
                                        ]}
                                        name="gender"
                                        checked={gender}
                                        onChange={(value) => setGender(value)}
                                    />

                                </div>
                                <div id="page-2" className="page">
                                    <div className={errorMail ? "error input select" : "input select"}>
                                        <label>
                                            {translations && !isEmpty(translations["registerEmail"])
                                                ? parse(translations["registerEmail"])
                                                : "registerEmail"}{" "}
                                            *
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder={
                                                translations && !isEmpty(translations["registerEmailPH"])
                                                    ? removeTags(
                                                        translations["registerEmailPH"]
                                                    )
                                                    : "registerEmailPH"
                                            }
                                            onFocus={(e) => activeInput(e.target.id)}
                                            required
                                            autoFocus
                                            autoComplete="email"
                                            onClick={(e) => activeInput(e.target.id)}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                setErrorResponseMail(false)
                                            }}
                                        />
                                        {errorResponseMail && (
                                            <div className="errorDisplay">
                                                {translations && !isEmpty(translations["registerEmailError"])
                                                    ? parse(translations["registerEmailError"])
                                                    : "registerEmailError"}
                                            </div>
                                        )}
                                    </div>

                                    <div className={errorPassword ? "error input select" : "input select"}>
                                        <label>
                                            {translations && !isEmpty(translations["registerPassword"])
                                                ? parse(translations["registerPassword"])
                                                : "registerPassword"}{" "}
                                            *
                                        </label>
                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            placeholder={
                                                translations && !isEmpty(translations["registerPasswordPH"])
                                                    ? removeTags(
                                                        translations["registerPasswordPH"]
                                                    )
                                                    : "registerPasswordPH"
                                            }
                                            onFocus={(e) => activeInput(e.target.id)}
                                            required
                                            autoFocus
                                            autoComplete="new-password"
                                            onClick={(e) => activeInput(e.target.id)}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                    <div className={errorPasswordVerify ? "error input select" : "input select"}>
                                        <label>
                                            {translations && !isEmpty(translations["registerPasswordConfirm"])
                                                ? parse(translations["registerPasswordConfirm"])
                                                : "registerPasswordConfirm"}{" "}
                                            *
                                        </label>
                                        <input
                                            type="password"
                                            name="passwordConfirm"
                                            id="passwordConfirm"
                                            placeholder={
                                                translations && !isEmpty(translations["registerPasswordConfirmPH"])
                                                    ? removeTags(
                                                        translations["registerPasswordConfirmPH"]
                                                    )
                                                    : "registerPasswordConfirmPH"
                                            }
                                            onFocus={(e) => activeInput(e.target.id)}
                                            required
                                            autoFocus
                                            autoComplete="new-password"
                                            onClick={(e) => activeInput(e.target.id)}
                                            onChange={(e) => setPasswordVerify(e.target.value)}
                                        />
                                    </div>
                                    <div className="error">
                                        {translations && !isEmpty(translations["registerPasswordCheck"])
                                            ? parse(translations["registerPasswordCheck"])
                                            : "registerPasswordCheck"}
                                        <div className="validator">
                                            <div>
                                                {capital ? (
                                                    <svg
                                                        className="validate"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
                                                    </svg>
                                                )}
                                                {translations && !isEmpty(translations["registerPasswordCapital"])
                                                    ? parse(translations["registerPasswordCapital"])
                                                    : "registerPasswordCapital"}
                                            </div>
                                            <div>
                                                {letterNumber ? (
                                                    <svg
                                                        className="validate"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
                                                    </svg>
                                                )}
                                                {translations && !isEmpty(translations["registerPasswordCharacters"])
                                                    ? parse(translations["registerPasswordCharacters"])
                                                    : "registerPasswordCharacters"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="page-3" className="page">
                                    <div className="input">
                                        {translations && !isEmpty(translations["registerPreferencesTitle"])
                                            ? parse(translations["registerPreferencesTitle"])
                                            : "registerPreferencesTitle"}
                                        <div className="checkbox">
                                            {!isEmpty(sports) &&
                                            sports.map((sport) => {
                                                return (
                                                    <div key={sport.id}>
                                                        <input
                                                            data-id-sport={sport.id}
                                                            type="checkbox"
                                                            value={sport.name}
                                                            id={sport.name}
                                                            name={sport.name}
                                                            onChange={(e) => {
                                                                setSportsError(false);
                                                                handleSport(e.target.dataset.idSport);
                                                            }}

                                                        />
                                                        <label className={sportsError ? "radio sport error" : "radio sport"} htmlFor={sport.name}>
                                                            <img
                                                                src={sport.iconPatch ? sport.iconPatch : `${process.env.REACT_APP_CACHE_SERVER}/img/icons/default.svg`}
                                                                alt={sport.name}
                                                                width="40"
                                                                height="40"
                                                            />
                                                            {translations && !isEmpty(translations[sport.name])
                                                                ? parse(translations[sport.name])
                                                                : sport.name}
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>

                                    <div className="dropdown-container">
                                        <label>
                                            {translations && !isEmpty(translations["selectFavoriteAndFollowClubs"])
                                                ? parse(translations["selectFavoriteAndFollowClubs"])
                                                : "selectFavoriteAndFollowClubs"}
                                        </label>
                                        <Multiselect
                                            options={optionFavoriteList}
                                            selectedValues={selectedFavoriteOptions}
                                            onSelect={onSelectFavorite}
                                            onRemove={onRemoveFavorite}
                                            onSearch={handleFavoriteSelect}
                                            displayValue="name"
                                            hidePlaceholder={true}
                                            closeOnSelect={true}
                                        />
                                    </div>

                                    <div className={rulesError ? "input error" : "input"}>
                                        <input
                                            type="checkbox"
                                            required
                                            value="rules"
                                            name="rules"
                                            id="rules"
                                            onChange={() => {
                                                setRulesError(false);
                                                setRules(!rules);
                                            }}
                                        />
                                        <label className="radio" htmlFor="rules">
                                            <div className="box">
                                                <div className="div">
                                                    <svg
                                                        width="15"
                                                        height="12"
                                                        viewBox="0 0 15 12"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M14.8695 1.52424L5.51421 11.8558C5.43136 11.9481 5.31857 12 5.2009 12C5.08324 12 4.97045 11.9481 4.88759 11.8558L0.130532 6.60232C0.0469906 6.51082 0 6.38625 0 6.25631C0 6.12637 0.0469906 6.00181 0.130532 5.9103L0.748331 5.22802C0.83119 5.13576 0.943981 5.08387 1.06164 5.08387C1.17931 5.08387 1.2921 5.13576 1.37496 5.22802L5.19649 9.44839L13.625 0.140195C13.7996 -0.0467318 14.0771 -0.0467318 14.2517 0.140195L14.8695 0.832218C14.953 0.923724 15 1.04829 15 1.17823C15 1.30817 14.953 1.43273 14.8695 1.52424Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                            {translations && !isEmpty(translations["registerAgreeStart"])
                                                ? parse(translations["registerAgreeStart"])
                                                : "registerAgreeStart"}
                                            <a href={process.env.PUBLIC_URL + "/files/Fuchs_Sports_" + selectedLanguage + "_CGU.pdf"} target="_blank" rel="noreferrer noopener" className="no-wrap">
                                                {translations && !isEmpty(translations["registerUseConditions"])
                                                    ? parse(translations["registerUseConditions"])
                                                    : "registerUseConditions"}{" "}
                                                *
                                            </a>
                                        </label>
                                    </div>
                                    <div className="input">
                                        <input
                                            type="checkbox"
                                            value="fuchsOffers"
                                            name="fuchsOffers"
                                            id="fuchsOffers"
                                            onChange={() => {
                                                setFuchsOffers(fuchsOffers === "true" ? "false" : "true");
                                            }}
                                        />
                                        <label className="radio" htmlFor="fuchsOffers">
                                            <div className="box">
                                                <div className="div">
                                                    <svg
                                                        width="15"
                                                        height="12"
                                                        viewBox="0 0 15 12"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M14.8695 1.52424L5.51421 11.8558C5.43136 11.9481 5.31857 12 5.2009 12C5.08324 12 4.97045 11.9481 4.88759 11.8558L0.130532 6.60232C0.0469906 6.51082 0 6.38625 0 6.25631C0 6.12637 0.0469906 6.00181 0.130532 5.9103L0.748331 5.22802C0.83119 5.13576 0.943981 5.08387 1.06164 5.08387C1.17931 5.08387 1.2921 5.13576 1.37496 5.22802L5.19649 9.44839L13.625 0.140195C13.7996 -0.0467318 14.0771 -0.0467318 14.2517 0.140195L14.8695 0.832218C14.953 0.923724 15 1.04829 15 1.17823C15 1.30817 14.953 1.43273 14.8695 1.52424Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                            {translations && !isEmpty(translations["registerFuchsOffers"])
                                                ? parse(translations["registerFuchsOffers"])
                                                : "registerFuchsOffers"}
                                        </label>
                                    </div>
                                    <div className="input">
                                        <input
                                            type="checkbox"
                                            value="partnerOffers"
                                            name="partnerOffers"
                                            id="partnerOffers"
                                            onChange={() => {
                                                setPartnerOffers(partnerOffers === "true" ? "false" : "true");
                                            }}
                                        />
                                        <label className="radio" htmlFor="partnerOffers">
                                            <div className="box">
                                                <div className="div">
                                                    <svg
                                                        width="15"
                                                        height="12"
                                                        viewBox="0 0 15 12"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M14.8695 1.52424L5.51421 11.8558C5.43136 11.9481 5.31857 12 5.2009 12C5.08324 12 4.97045 11.9481 4.88759 11.8558L0.130532 6.60232C0.0469906 6.51082 0 6.38625 0 6.25631C0 6.12637 0.0469906 6.00181 0.130532 5.9103L0.748331 5.22802C0.83119 5.13576 0.943981 5.08387 1.06164 5.08387C1.17931 5.08387 1.2921 5.13576 1.37496 5.22802L5.19649 9.44839L13.625 0.140195C13.7996 -0.0467318 14.0771 -0.0467318 14.2517 0.140195L14.8695 0.832218C14.953 0.923724 15 1.04829 15 1.17823C15 1.30817 14.953 1.43273 14.8695 1.52424Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                            {translations && !isEmpty(translations["registePartnerOffers"])
                                                ? parse(translations["registePartnerOffers"])
                                                : "registePartnerOffers"}
                                        </label>
                                    </div>
                                </div>
                            </form>
                            <div className={page !== 1 ? 'page-select' : 'page-select-right'}>
                                {page !== 1 &&
                                <button
                                    onClick={() => handlePage(page - 1)}
                                    disabled={page === 1}
                                >
                                    <svg
                                        className="left"
                                        width="23"
                                        height="20"
                                        viewBox="0 0 23 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.4245 16.4229C10.7846 16.7484 11.3684 16.7484 11.7285 16.4229C12.0886 16.0975 12.0886 15.5698 11.7285 15.2444L6.84805 10.8337L17.531 10.8337C18.0403 10.8337 18.4531 10.4606 18.4531 10.0003C18.4531 9.54009 18.0403 9.16699 17.531 9.16699L6.84805 9.16699L11.7285 4.75625C12.0886 4.43081 12.0886 3.90317 11.7285 3.57774C11.3684 3.2523 10.7846 3.2523 10.4245 3.57774L3.96995 9.41107C3.60986 9.73651 3.60986 10.2641 3.96995 10.5896L10.4245 16.4229Z" fill="white"/>
                                    </svg>
                                    {translations && !isEmpty(translations["back"])
                                        ? parse(translations["back"])
                                        : "back"
                                    }
                                </button>
                                }
                                {page !== 3 && (
                                    <button onClick={() => handlePage(page + 1)}>
                                        {translations && !isEmpty(translations["next"])
                                            ? parse(translations["next"])
                                            : "next"
                                        }
                                        <svg
                                            className="right"
                                            width="23"
                                            height="20"
                                            viewBox="0 0 23 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path fillRule="evenodd" clipRule="evenodd" d="M12.5364 3.57709C12.1763 3.25165 11.5925 3.25165 11.2324 3.57709C10.8723 3.90252 10.8723 4.43016 11.2324 4.7556L16.1129 9.16634H5.42989C4.92064 9.16634 4.50781 9.53944 4.50781 9.99967C4.50781 10.4599 4.92064 10.833 5.42989 10.833H16.1129L11.2324 15.2438C10.8723 15.5692 10.8723 16.0968 11.2324 16.4223C11.5925 16.7477 12.1763 16.7477 12.5364 16.4223L18.991 10.5889C19.3511 10.2635 19.3511 9.73586 18.991 9.41042L12.5364 3.57709Z" fill="white"/>
                                        </svg>
                                    </button>
                                )}

                                {page === 3 && (
                                    <label>
                                        <input
                                            form="register-form"
                                            type="submit"
                                            name="submit"
                                            id="submit"
                                            disabled={submitWork}
                                            value={
                                                translations && !isEmpty(translations["registerSubmit"])
                                                    ? removeTags(
                                                        translations["registerSubmit"]
                                                    )
                                                    : "registerSubmit"
                                            }
                                        />
                                    </label>
                                )}
                            </div>
                        </div>
                    </section>
            </div>
        </>
    );
};

export default Register;