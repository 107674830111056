import React, {useState, useEffect, useContext} from "react";
import AthleteVideos from "./athleteSubmenu/AthleteVideos";
import Orders from "./common/Orders";
import BillingInfo from "./common/BillingInfo";
import Info from "./common/Info";
import Plans from "./common/Plans";
import GroupSelect from "./common/GroupSelect";
import ClubSelect from "./common/ClubSelect";
import {useHistory, useRouteMatch} from "react-router";
import {NavLink} from "react-router-dom";
import {isEmpty} from "../Utils";
import parse from "html-react-parser";
import {useSelector} from "react-redux";
import {MyContext} from "../../contexts/MyContext";

const Club = () => {
    let match = useRouteMatch();
    const {
        params: {type},
    } = match;

    const history = useHistory();
    const [actualSubMenu, setActualSubMenu] = useState("info");
    const [actualSubMenuComponent, setActualSubMenuComponent] = useState(<Info/>);
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translations
    );
    const {rootState} = useContext(MyContext);
    const {theUser} = rootState;

    useEffect(() => {
        let accessType = ["info", "videos", "orders", "billing-info", "plans", "group-select"].includes(type);
        if (accessType) {
            if (theUser.associate_club !== null) {
                setActualSubMenu(type);
            } else {
                setActualSubMenu("club-select");
            }
        } else {
            history.push("/profile/info")
        }
    }, [type]);

    useEffect(() => {
        switch (actualSubMenu) {
            case "info":
                setActualSubMenuComponent(<Info/>);
                break;
            case "videos":
                setActualSubMenuComponent(<AthleteVideos/>);
                break;
            case "orders":
                setActualSubMenuComponent(<Orders/>);
                break;
            case "billing-info":
                setActualSubMenuComponent(<BillingInfo/>);
                break;
            case "plans":
                setActualSubMenuComponent(<Plans />);
                break;
            case "group-select":
                setActualSubMenuComponent(<GroupSelect />);
                break;
            case "club-select":
                setActualSubMenuComponent(<ClubSelect onClose={() => setActualSubMenu("info")} />);
                break;
            default:
                setActualSubMenuComponent(<Info/>);
                break;
        }
    }, [actualSubMenu]);

    return (
        <div className="club-page">
            <section>
                <div className="submenu">
                    <ul className="nav-tabs">
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/profile/info"
                                activeClassName=""
                                className={actualSubMenu === "info" ? "nav-link active" : "nav-link"}
                                onClick={() => {
                                    setActualSubMenu("info");
                                }}
                            >
                                {translations && !isEmpty(translations["my_info"])
                                    ? parse(translations["my_info"])
                                    : "My Information"
                                }
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/profile/videos"
                                activeClassName=""
                                className={actualSubMenu === "videos" ? "nav-link active" : "nav-link"}
                                onClick={() => {
                                    setActualSubMenu("videos");
                                }}
                            >
                                {translations && !isEmpty(translations["myVideos"])
                                    ? parse(translations["myVideos"])
                                    : "My Videos"
                                }
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/profile/orders"
                                activeClassName=""
                                className={actualSubMenu === "orders" ? "nav-link active" : "nav-link"}
                                onClick={() => {
                                    setActualSubMenu("orders");
                                }}
                            >
                                {translations && !isEmpty(translations["myOrders"])
                                    ? parse(translations["myOrders"])
                                    : "My Orders"
                                }
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/profile/billing-info"
                                activeClassName=""
                                className={actualSubMenu === "billing-info" ? "nav-link active" : "nav-link"}
                                onClick={() => {
                                    setActualSubMenu("billing-info");
                                }}
                            >
                                {translations && !isEmpty(translations["billing"])
                                    ? parse(translations["billing"])
                                    : "Billing"
                                }
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/profile/plans"
                                activeClassName=""
                                className={actualSubMenu === "plans" ? "nav-link active" : "nav-link"}
                                onClick={() => {
                                    setActualSubMenu("plans");
                                }}
                            >
                                {translations && !isEmpty(translations["plans"])
                                    ? parse(translations["plans"])
                                    : "Plans"
                                }
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <article>
                    {actualSubMenuComponent}
                </article>
            </section>
        </div>
    );
};

export default Club;
