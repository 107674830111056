import React, {useState} from "react";
import AllCoupons from "./AllCoupons";
import AddCoupon from "./AddCoupon";
import {useDispatch} from "react-redux";

const Coupon = () => {
	const dispatch = useDispatch();

	const [actualComponent, setActualComponent] = useState(<AllCoupons />);

	return (
		<div className="bo-coupon">
			<nav>
				<ul>
					<li
						className="selected"
						onClick={(e) => {
							setActualComponent(<AllCoupons/>);
							document.querySelectorAll(".bo-coupon > nav>ul>li").forEach((elm) => {
								e.target === elm
									? elm.classList.add("selected")
									: elm.classList.remove("selected");
							});
						}}
					>
						Affichage tous les coupons
					</li>
					<li
						onClick={(e) => {
							setActualComponent(<AddCoupon successCreate={() => setActualComponent(<AllCoupons/>)}/>);
							document.querySelectorAll(".bo-coupon > nav>ul>li").forEach((elm) => {
								e.target === elm
									? elm.classList.add("selected")
									: elm.classList.remove("selected");
							});
						}}
					>
						Ajouter un coupon
					</li>
				</ul>
			</nav>
			<div className="bo-coupon-component">{actualComponent}</div>
		</div>
	);
};

export default Coupon;
