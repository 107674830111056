import React from "react";
import HandleHighlights from "./HandleHighlights";

const Highlights = ({ selectedSport }) => {
	let { id, name } = selectedSport;

	return (
		<div className="bo-highlights">
			<div className="bo-highlight-component">
				<HandleHighlights sportID={id} sportName={name}/>
			</div>
		</div>
	);
};

export default Highlights;
