import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import Input from "../../partials/Input";
import {isEmpty, removeTags} from "../../Utils";
import InputData from "../../partials/InputData";
import AsyncSelectInput from "../../partials/AsyncSelectInput";
import axios from "axios";
import SelectInput from "../../partials/SelectInput";
import {MyContext} from "../../../contexts/MyContext";
import parse from "html-react-parser";
import Avatar from "../../account/Avatar";
import Delete from "../../account/Delete";

const Info = () => {
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translations
    );
    const supportedLanguages = useSelector(
        (state) => state.langReducer.languagesReducer.languages
    );
    const [defaultCountry, setDefaultCountry] = useState("");
    const [userInfo, setUserInfo] = useState({});
    const [errors, setErrors] = useState({});
    const [allow, setAllow] = useState(true);
    const ref = useRef(); //hack for clear await when unmount component
    const {rootState, updateUserData, isLoggedIn} = useContext(MyContext);
    const {isAuth, theUser} = rootState;

    const requiredFields = useMemo(() => {
        return [
            "firstName",
            "lastName",
            "username",
            "email"
        ];
    }, []);

    const optionsGender = useMemo(() => {
        return [
            {
                value: "man", label: translations && !isEmpty(translations["registerMan"])
                    ? removeTags(translations["registerMan"])
                    : "registerMan"
            },
            {
                value: "woman", label: translations && !isEmpty(translations["registerWoman"])
                    ? removeTags(translations["registerWoman"])
                    : "registerWoman"
            },
            {
                value: "other", label: translations && !isEmpty(translations["registerOther"])
                    ? removeTags(translations["registerOther"])
                    : "registerOther"
            }
        ]
    }, []);

    const optionsLanguages = useMemo(() => {
        return supportedLanguages.map((lang) => {
                return {"value": lang.idLanguage, label: lang.longLang.charAt(0).toUpperCase() + lang.longLang.slice(1)}
            })
    }, [supportedLanguages]);

    const getCountries = useCallback(async (like = "", code = "") => {
        return await axios.get(`${process.env.REACT_APP_API_URL}getAllCountries&l=${like}&c=${code}`).then((result) => {
            if (result.data.result === "ok" && result.data.countries) {
                return result.data.countries;
            }
        });
    }, []);

    const handleInputs = (name, value) => {
        setUserInfo(prevState => ({...prevState, [name]: value}));
    };

    const loadCountryOptions = useCallback(async (inputValue, callback) => {
        if (inputValue.length >= 2) {
            callback(await getCountries(inputValue))
        }
    }, []);

    const validate = async () => {
        let valid = {};
        await requiredFields.forEach((field) => {
            if (isEmpty(userInfo[field]) || userInfo[field].length < 1) {
                setErrors((prevState) => ({...prevState, [field]: true}));
                valid[field] = true;
            } else {
                setErrors(prevState => {
                    const copy = {...prevState};
                    delete copy[field];
                    return copy;
                });
                delete valid[field];
            }
        });
        return valid;
    };

    useEffect(() => {
        if (!isEmpty(theUser)) {
            if (theUser.country) {
                getCountries("", theUser.country).then((res) => {
                    if (!isEmpty(res) && !isEmpty(res[0]) && ref.current) {
                        setDefaultCountry(res[0]);
                    }
                });
            }

            const copyData = {...theUser}
            copyData.language = copyData.langId;
            delete copyData.langId;
            setUserInfo(copyData)
        }
    }, [theUser, ref]);

    const submitHandle = async () => {
        await validate().then(async (result) => {
            if (isAuth !== null && isAuth && Object.keys(result).length < 1) {
                if (allow) {
                    setAllow(false);
                    const data = await updateUserData(userInfo);
                    setErrors(prevState => {
                        const copy = {...prevState};
                        delete copy["email"];
                        delete copy["username"];
                        return copy;
                    });
                    if (data.success) {
                        setTimeout(() => {
                            document
                                .querySelector("#validUpdate")
                                .classList.add("display");
                        }, 10);

                        setTimeout(() => {
                            if (document.querySelector("#validUpdate")) {
                                document
                                    .querySelector("#validUpdate")
                                    .classList.remove("display");
                            }
                        }, 3000);
                    } else {
                        if (data.message === "emailErrorRegister") {
                            setErrors((prevState) => ({...prevState, "email": true}));
                        }
                        if (data.message === "usernameErrorRegister") {
                            setErrors((prevState) => ({...prevState, "username": true}));
                        }
                        console.log("erreur : " + data.message);
                    }
                    isLoggedIn();
                    setAllow(true);
                }

            }
        });

    };

    return (
        <div ref={ref}>
            <div className="d-flex mt-4 justify-content-space-between">
                <h2 className="title">Account Information</h2>
                <div className="submit">
                    <div id="validUpdate">
                        {translations && !isEmpty(translations["updated"])
                            ? parse(translations["updated"])
                            : "updated"
                        }
                    </div>
                    <div className="btn btn-transparent" onClick={() => submitHandle()}>Modifier le profil</div>
                </div>
            </div>


            <div>
                <div className="d-flex mt-4 mb-5">
                    <div className="image d-flex">
                        <Avatar />
                    </div>
                    <div className="image d-flex ml-4">
                        <Delete />
                    </div>
                </div>
                <div className="row-inputs mt-4">
                    <Input
                        type="text"
                        name="firstName"
                        regex="^[a-zA-ZÀ-ÿ\\s-]*$"
                        value={userInfo?.firstName}
                        handleChange={handleInputs}
                        error={errors.firstName}
                        label={translations && !isEmpty(translations["registerFirstName"])
                            ? parse(translations["registerFirstName"])
                            : "registerFirstName"}
                    />
                    <Input
                        type="text"
                        name="lastName"
                        regex="^[a-zA-ZÀ-ÿ\\s-]*$"
                        value={userInfo?.lastName}
                        error={errors.lastName}
                        handleChange={handleInputs}
                        label={translations && !isEmpty(translations["registerLastName"])
                            ? parse(translations["registerLastName"])
                            : "registerLastName"}
                    />
                    <Input
                        type="text"
                        name="username"
                        regex="^[a-zA-Z0-9_]*$"
                        value={userInfo?.username}
                        error={errors.username}
                        handleChange={handleInputs}
                        label={translations && !isEmpty(translations["registerUsername"])
                            ? parse(translations["registerUsername"])
                            : "registerUsername"
                        }
                    />

                    <Input
                        type="text"
                        name="email"
                        regex='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
                        value={userInfo?.email}
                        error={errors.email}
                        handleChange={handleInputs}
                        label={translations && !isEmpty(translations["registerEmail"])
                            ? parse(translations["registerEmail"])
                            : "registerEmail"
                        }
                    />
                    <AsyncSelectInput
                        name="country"
                        defaultValue={defaultCountry}
                        handleChange={handleInputs}
                        optionValue="id"
                        optionLabel="nicename"
                        isMulti={false}
                        error={errors?.country}
                        loadOptions={loadCountryOptions}
                        label={translations && !isEmpty(translations["country"])
                            ? parse(translations["country"])
                            : "country"
                        }
                    />

                    <Input
                        type="text"
                        name="zipCode"
                        value={userInfo?.zipCode}
                        error={errors.zipCode}
                        handleChange={handleInputs}
                        label={translations && !isEmpty(translations["zipcodePH"])
                                ? removeTags(translations["zipcodePH"])
                                : "zipcodePH"
                        }
                    />

                    <InputData
                        type="text"
                        name="datebirth"
                        value={userInfo?.datebirth}
                        handleChange={handleInputs}
                        error={errors?.datebirth}
                        minDate={new Date(1930, 1, 1)}
                        maxDate={new Date(2010, 12, 31)}
                        label={translations && !isEmpty(translations["registerBirthdate"])
                            ? removeTags(translations["registerBirthdate"])
                            : "registerBirthdate"}
                    />

                    <SelectInput
                        type="text"
                        name="gender"
                        defaultValue={optionsGender.find(({ value }) => value === userInfo?.gender)}
                        handleChange={handleInputs}
                        optionValue="value"
                        optionLabel="label"
                        error={errors?.position}
                        options={optionsGender}
                        label={translations && !isEmpty(translations["gender"])
                            ? parse(translations["gender"])
                            : "gender"
                        }
                    />

                    <SelectInput
                        type="text"
                        name="language"
                        defaultValue={optionsLanguages.find(({ value }) => value === userInfo?.language)}
                        handleChange={handleInputs}
                        optionValue="value"
                        optionLabel="label"
                        error={errors?.position}
                        options={optionsLanguages}
                        label={translations && !isEmpty(translations["language"])
                            ? parse(translations["language"])
                            : "language"
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default Info;
