import React, {useContext, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Redirect, useHistory} from "react-router";
import { NavLink } from "react-router-dom";
import { isEmpty, removeTags, useQuery} from "../components/Utils";
import { MyContext } from "../contexts/MyContext";
import axios from "axios";
import moment from "moment";
import SuccessError from "../components/account/SuccessError";
import Search from "../components/Search";
import { toggleSearch } from "../actions/search.action";
import parse from "html-react-parser";
import {Helmet} from "react-helmet-async";

const Account = ({ match, location }) => {
	//GET DATA
	const {params: { method }} = match;

	const searchState = useSelector(
		(state) => state.searchReducer.searchToggleReducer
	);
	const translationsReducer = useSelector((state) => state.langReducer.translationsReducer);
	const { rootState, loginUser, isLoggedIn } = useContext(MyContext);
	const { isAuth } = rootState;
	const selectedLanguage = translationsReducer.language;
	const translations = translationsReducer.translations;
	const query = useQuery();
	const dispatch = useDispatch();
	const history = useHistory();

	const [resetPasswordEarly, setResetPasswordEarly] = useState("");
	const [recoveryKey, setRecoveryKey] = useState("");
	const [recoveryUser, setRecoveryUser] = useState("");
	const [popup, setPopup] = useState(false);
	const [errorEmailLogin, setErrorEmailLogin] = useState(false);
	const [errorPasswordLogin, setErrorPasswordLogin] = useState(false);

	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [passwordVerify, setPasswordVerify] = useState(null);

	const [errorPasswordVerify, setErrorPasswordVerify] = useState(false);
	const [capital, setCapital] = useState(false);
	const [letterNumber, setLetterNumber] = useState(false);

	const [popupContent, setPopupContent] = useState({
		title: "",
		text: "",
		textButton: "",
		type: "",
	});

	const initialState = {
		userInfo: {
			email: "",
			password: "",
		},
		errorMsg: "",
		successMsg: "",
	};

	const [state, setState] = useState(initialState);

	const availableMethods = [
		"login",
		"forgot",
		"recovery",
		"verification",
		undefined,
	];


	useEffect(() => {
		if (popup) {
			document.querySelector(".hide-background").classList.add("hover");
			document.querySelector(".hide-background-not").classList.add("hover");

			switch (popup) {
				case 1:
					//register success
					setPopupContent({
						title: translations && !isEmpty(translations["registerSuccess"])
                        ? removeTags(translations["registerSuccess"])
                        : "",
						text: translations && !isEmpty(translations["emailSendLabel"])
                        ? removeTags(translations["emailSendLabel"])
                        : "",
						textButton: translations && !isEmpty(translations["connectionButton"])
                        ? removeTags(translations["connectionButton"])
                        : "",
						type: "success",
					});
					break;
				case 2:
					//send mail success
					setPopupContent({
						title: "Confirmée",
						text: translations && !isEmpty(translations["emailSendSuccess"])
							? removeTags(translations["emailSendSuccess"])
							: "",
                        textButton: translations && !isEmpty(translations["connectionButton"])
                            ? removeTags(translations["connectionButton"])
                            : "",
						type: "success",
					});
					break;
				case 3:
					//send mail fail
					setPopupContent({
						title: "Fail",
						text: translations && !isEmpty(translations["emailSendFail"])
							? removeTags(translations["emailSendFail"])
							: "",
                        textButton: translations && !isEmpty(translations["connectionButton"])
                            ? removeTags(translations["connectionButton"])
                            : "",
						type: "fail",
					});
					break;
				case 4:
                    //pass change success
					setPopupContent({
						title: "Confirmée",
						text: translations && !isEmpty(translations["passChangedSuccess"])
							? removeTags(translations["passChangedSuccess"])
							: "",
                        textButton: translations && !isEmpty(translations["connectionButton"])
                            ? removeTags(translations["connectionButton"])
                            : "",
						type: "success",
					});
					break;
				case 5:
                    //send validate mail
					setPopupContent({
						title: translations && !isEmpty(translations["confirmEmailLabel"])
                        ? removeTags(translations["confirmEmailLabel"])
                        : "",
						text: translations && !isEmpty(translations["needValidateEmail"])
							? removeTags(translations["needValidateEmail"])
							: "",
						sendValidate: true,
						sendValidateText: translations && !isEmpty(translations["newLinkLabel"])
                        ? removeTags(translations["newLinkLabel"])
                        : "",
						textButton: translations && !isEmpty(translations["connectionButton"])
                            ? removeTags(translations["connectionButton"])
                            : "",
						type: "fail",
					});
					break;
				case 6:
                    // validate mail success
					setPopupContent({
						title: "Confirmée",
						text: translations && !isEmpty(translations["validateEmailSuccess"])
							? removeTags(translations["validateEmailSuccess"])
							: "",
                        textButton: translations && !isEmpty(translations["connectionButton"])
                            ? removeTags(translations["connectionButton"])
                            : "",
						type: "success",
					});
					break;
				case 7:
                    // validate mail fail
					setPopupContent({
						title: translations && !isEmpty(translations["errorLabel"])
                            ? removeTags(translations["errorLabel"])
                            : "",
						text: translations && !isEmpty(translations["validateEmailError"])
							? removeTags(translations["validateEmailError"])
							: "",
                        textButton: translations && !isEmpty(translations["connectionButton"])
                            ? removeTags(translations["connectionButton"])
                            : "",
						type: "fail",
					});
					break;
				case 8:
                    // reset pass fail
					setPopupContent({
						title: "Fail",
						text: translations && !isEmpty(translations["resetLinkFail"])
							? removeTags(translations["resetLinkFail"])
							: "",
                        textButton: translations && !isEmpty(translations["connectionButton"])
                            ? removeTags(translations["connectionButton"])
                            : "",
						type: "fail",
					});
					break;
				default:
					return false;
			}
		} else {
			setPopupContent({ title: "", text: "", textButton: "", type: "" });
			document.querySelector(".hide-background").classList.remove("hover");
			document.querySelector(".hide-background-not").classList.remove("hover");
		}
	}, [popup, translations]);

	//CHANGE INPUT COLOR
	const activeInput = (id) => {
		let inputs = document.querySelectorAll(".account-page .input");
		inputs.forEach((input) => {
			input.classList.remove("select");
		});

		let input = document.getElementById(id);
		input.parentElement.classList.add("select");
	};

	const sendRecovery = async (event) => {
		event.preventDefault();
		if (isEmpty(event.target.email.value)) {
			return false;
		} else {
			let result = await axios.post(
				`${process.env.REACT_APP_API_URL}sendRecovery`,
				{
					email: event.target.email.value,
					lang: selectedLanguage,
				}
			);
			if (result.data.status === 200) {
				//success
				setPopup(2);
			} else if (result.data.status === 425) {
				//too early
				setResetPasswordEarly("Too early. Please try again after " + result.data.different_min + " min");
			} else {
				//fail
				setPopup(3);
			}
		}
	};

	const recoveryPassword = async (event) => {
		event.preventDefault();
		if (isEmpty(password) || isEmpty(passwordVerify) || !capital || !letterNumber || (password !== passwordVerify)) {
			return false;
		} else {
			let result = await axios.post(
				`${process.env.REACT_APP_API_URL}recoveryPassword`,
				{
					user: recoveryUser,
					key: recoveryKey,
					password: password,
					passwordConfirm: passwordVerify,
				}
			);
			if (result.data.status === 201) {
				setPassword(null);
				setPasswordVerify(null);
				setPopup(4);
			} else {
				setPopup(3);
			}
		}
	};

	const checkRecoveryLink = async (user, key) => {
		let result = await axios.post(
			`${process.env.REACT_APP_API_URL}checkRecoveryKey`,
			{
				user: user,
				key: key,
			}
		);
		return !isEmpty(result.data.id);
	};

	const sendValidate = async () => {
		if (!isEmpty(state.userInfo.email)) {
			let result = await axios.post(
				`${process.env.REACT_APP_API_URL}sendValidate`,
				{
					user: state.userInfo.email,
					lang: selectedLanguage,
				}
			);
			if (result.data.status === 200) {
				//success
				setPopup(2);
			} else if (result.data.status === 425) {
				//too early
				setPopup(false);
				let time = moment(result.data.exp_date).format("DD MMM, YYYY / HH:mm");
				setResetPasswordEarly("Too early. Please try again after " + time);
			} else {
				//fail
				setPopup(3);
			}
		}
	};

	const validateAccount = async (user, key) => {
		let result = await axios.post(
			`${process.env.REACT_APP_API_URL}validateAccount`,
			{
				user: user,
				key: key,
			}
		);
		return result.data.success === 1;
	};

	//On change Value
	const onChangeValue = (e) => {
		setErrorEmailLogin(false);
		setErrorPasswordLogin(false);
		setState({
			...state,
			userInfo: {
				...state.userInfo,
				[e.target.name]: e.target.value,
			},
		});
	};

	// On Submit Login From
	const submitForm = async (event) => {
		event.preventDefault();

		const data = await loginUser(state.userInfo);
		if (data.success && data.token) {
			setState({
				...initialState,
			});
			localStorage.setItem("loginToken", data.token);
			await isLoggedIn();
		} else if (!data.success && data.message === "accountNotValidation") {
			setPopup(5);
		} else {
			setErrorPasswordLogin(false);
			setErrorEmailLogin(false);
			setState({
				...state,
				successMsg: "",
				errorMsg: data.message,
			});
			if (data.message === "emailErrorLogin") {
				setErrorEmailLogin(true);
			}
			if (data.message === "passwordErrorLogin") {
				setErrorPasswordLogin(true);
			}
		}
	};

	useEffect(() => {
		async function fetchData() {
			setPopup(false);
			if (method === "recovery") {
				let user = query.get("user");
				let key = query.get("k");
				if (!isEmpty(user) && !isEmpty(key)) {
					if (await checkRecoveryLink(user, key)) {
						setRecoveryKey(key);
						setRecoveryUser(user);
					} else {
						setPopup(8);
					}
				}
			} else if (method === "verification") {
				let user = query.get("user");
				let key = query.get("k");
				if (!isEmpty(user) && !isEmpty(key)) {
					if (await validateAccount(user, key)) {
						setPopup(6);
					} else {
						setPopup(7);
					}
				}
			}
		}
		fetchData();
		// eslint-disable-next-line
	}, [method, isAuth]);

	//PAGE 2
	useEffect(() => {
		if (method === "forgot" || method === "recovery") {
			if (!isEmpty(email)) {
				email.length < 2 ||
				!email.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				)
					? document.querySelector("#email").parentElement.classList.add("error")
					: document.querySelector("#email").parentElement.classList.remove("error");
			}
			if (!isEmpty(password)) {
				password.length >= 8 ? setLetterNumber(true) : setLetterNumber(false);
				password.match(/^(?=.*[A-Z])[0-9a-zA-Z-+_!@#$%^&*.,?]{8,}$/)
					? setCapital(true)
					: setCapital(false);
			}
			if (!isEmpty(password) && !isEmpty(passwordVerify)) {
				passwordVerify !== password
					? setErrorPasswordVerify(true)
					: setErrorPasswordVerify(false)
			}
		}
	}, [email, password, passwordVerify, method]);


	useEffect(() => {
		if (searchState) {
			dispatch(toggleSearch(false));
		}
		// eslint-disable-next-line
	}, [location, dispatch]);

	return (
		<>
			<Helmet>
				<title>Fuchs Sports International</title>
				<meta property="og:image" content={`${process.env.REACT_APP_CACHE_SERVER}/img/icons/logo_fuchs_sports.png`} />
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
			</Helmet>

			<div className="account-page">
				{(isAuth !== null && isAuth === true) && <Redirect to="/" />}
				{searchState && <Search />}
				{!availableMethods.includes(method) && <Redirect to="/" />}

				{popup && (
					<SuccessError
						content={popupContent}
						close={setPopup}
						sendValidate={sendValidate}
					/>
				)}

				<div className="banner">
					<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/footages/banner-account.jpg`} alt="banner account" />
					<div className="dots">
						<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/dots1.png`} alt="decoration elements" />
						<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/dots2.png`} alt="decoration elements" />
					</div>
					<div className="decorations">
						<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/lb1.svg`} alt="decoration elements" />
						<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/lb2.svg`} alt="decoration elements" />
						<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/lb3.svg`} alt="decoration elements" />
						<img src={`${process.env.REACT_APP_CACHE_SERVER}/img/decorations/banner/rb.svg`} alt="decoration elements" />
					</div>
				</div>
				{method === undefined && (
					<section className="account">
						<div className="account-container">
							<h2 className="border untouch">
								{translations && !isEmpty(translations["accountWelcome"])
									? parse(translations["accountWelcome"])
									: "accountWelcome"
								}
							</h2>
							{translations && !isEmpty(translations["accountText"])
								? parse(translations["accountText"])
								: "accountText"
							}

							<NavLink to="/account/register">
								<article>
									<div className="svg">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
										>
											<path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z" />
										</svg>
									</div>
									<div className="text">
										<h3>
											{translations && !isEmpty(translations["register"])
												? parse(translations["register"])
												: "register"}
										</h3>
										{translations && !isEmpty(translations["registerText"])
											? parse(translations["registerText"])
											: "registerText"
										}
									</div>
									<div className="arrow">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
										>
											<path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
										</svg>
									</div>
								</article>
							</NavLink>
							<NavLink to="/account/login">
								<article>
									<div className="svg">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
										>
											<path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z" />
										</svg>
									</div>
									<div className="text">
										<h3>
											{translations && !isEmpty(translations["login"])
												? parse(translations["login"])
												: "login"
											}
										</h3>
										{translations && !isEmpty(translations["loginText"])
											? parse(translations["loginText"])
											: "loginText"
										}
									</div>
									<div className="arrow">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
										>
											<path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
										</svg>
									</div>
								</article>
							</NavLink>
						</div>
					</section>
				)}

				{(method === "login" ||
					method === "validate" ||
					method === "verification") && (
					<section className="account">
						<div className="account-container">
							<h2 className="border untouch">Se connecter</h2>
							<form onSubmit={submitForm} noValidate>
								<div className="input select">
									<label>
										{translations && !isEmpty(translations["loginEmailUsername"])
											? parse(translations["loginEmailUsername"])
											: "loginEmailUsername"}{" "}
										*
									</label>
									<input
										type="email"
										name="email"
										id="email"
										placeholder={
											translations && !isEmpty(translations["loginEmailUsernamePH"])
												? removeTags(
														translations["loginEmailUsernamePH"]
												)
												: "loginEmailUsernamePH"
										}
										value={state.userInfo.email}
										required
										autoFocus
										autoComplete="email"
										onClick={(e) => activeInput(e.target.id)}
										onChange={onChangeValue}
									/>
									{errorEmailLogin && (
										<div className="errorDisplay">
											{translations && !isEmpty(translations["loginEmailUsernameError"])
												? parse(translations["loginEmailUsernameError"])
												: "loginEmailUsernameError"}
										</div>
									)}
								</div>
								<div className="input">
									<label>
										{translations && !isEmpty(translations["registerPassword"])
											? parse(translations["registerPassword"])
											: "registerPassword"}{" "}
										*
									</label>
									<input
										type="password"
										name="password"
										id="password"
										placeholder={
											translations && !isEmpty(translations["registerPasswordPH"])
												? removeTags(
														translations["registerPasswordPH"]
												  )
												: "registerPasswordPH"
										}
										onClick={(e) => activeInput(e.target.id)}
										required
										autoComplete="current-password"
										value={state.userInfo.password}
										onChange={onChangeValue}
									/>
									{errorPasswordLogin && (
										<div className="errorDisplay">
											{translations && !isEmpty(translations["passwordError"])
												? parse(translations["passwordError"])
												: "passwordError"}
										</div>
									)}
								</div>
								{resetPasswordEarly && (
									<span className="reset_pass">{resetPasswordEarly}</span>
								)}
								<span className="reset_pass">
									<NavLink to="/account/forgot">
										{translations && !isEmpty(translations["forgotPassword"])
											? parse(translations["forgotPassword"])
											: "Forgot Password"}
									</NavLink>
								</span>

								<input
									type="submit"
									name="submit"
									id="submit"
									value={
										translations && !isEmpty(translations["loginSubmit"])
											? removeTags(
													translations["loginSubmit"]
											  )
											: "loginSubmit"
									}
								/>
							</form>
						</div>
					</section>
				)}
				{method === "forgot" && (
					<section className="account">
						<div className="account-container">
							<h2 className="border untouch">
								{translations && !isEmpty(translations["passwordRecovery"])
									? removeTags(translations["passwordRecovery"])
									: "Password recovery"}
							</h2>
							<form onSubmit={sendRecovery} noValidate>
								<div className="input select">
									<label>
										{translations && !isEmpty(translations["registerEmail"])
											? parse(translations["registerEmail"])
											: "registerEmail"
										}{" "}*
									</label>
									<input
										type="email"
										name="email"
										id="email"
										placeholder={
											translations && !isEmpty(translations["registerEmailPH"])
												? removeTags(
														translations["registerEmailPH"]
												  )
												: "registerEmailPH"
										}
										required
										autoFocus
										autoComplete="email"
										onChange={(e) => setEmail(e.target.value)}
									/>
								</div>
								{resetPasswordEarly && (
									<span className="reset_pass">{resetPasswordEarly}</span>
								)}
								<input
									type="submit"
									name="submit"
									id="submit"
									value={translations && !isEmpty(translations["recover"])
										? removeTags(translations["recover"])
										: "Recover"
									}
								/>
							</form>
						</div>
					</section>
				)}
				{method === "recovery" && (
					<section className="account">
						<div className="account-container">
							<h2 className="border untouch">
								{translations && !isEmpty(translations["passwordRecovery"])
									? parse(translations["passwordRecovery"])
									: "Password recovery"}
							</h2>
							<form onSubmit={recoveryPassword} noValidate>
								<div className="input select">
									<label>
										{translations && !isEmpty(translations["registerPassword"])
											? parse(translations["registerPassword"])
											: "registerPassword"}{" "}
										*
									</label>
									<input
										type="password"
										name="password"
										id="password"
										placeholder={
											translations && !isEmpty(translations["registerPasswordPH"])
												? removeTags(
														translations["registerPasswordPH"]
												)
												: "registerPasswordPH"
										}
										onFocus={(e) => activeInput(e.target.id)}
										required
										autoFocus
										autoComplete="new-password"
										onClick={(e) => activeInput(e.target.id)}
										onChange={(e) => setPassword(e.target.value)}
									/>
								</div>
								<div className={errorPasswordVerify ? "error input select" : "input select"}>
									<label>
										{translations && !isEmpty(translations["registerPasswordConfirm"])
											? parse(translations["registerPasswordConfirm"])
											: "registerPasswordConfirm"}{" "}
										*
									</label>
									<input
										type="password"
										name="passwordConfirm"
										id="passwordConfirm"
										placeholder={
											translations && !isEmpty(translations["registerPasswordConfirmPH"])
												? removeTags(
														translations["registerPasswordConfirmPH"]
												)
												: "registerPasswordConfirmPH"
										}
										onFocus={(e) => activeInput(e.target.id)}
										required
										autoFocus
										autoComplete="new-password"
										onClick={(e) => activeInput(e.target.id)}
										onChange={(e) => setPasswordVerify(e.target.value)}
									/>
								</div>
								<div className="error">
									{translations && !isEmpty(translations["registerPasswordCheck"])
										? parse(translations["registerPasswordCheck"])
										: "registerPasswordCheck"}
									<div className="validator">
										<div>
											{capital ? (
												<svg
													className="validate"
													xmlns="http://www.w3.org/2000/svg"
													width="24"
													height="24"
													viewBox="0 0 24 24"
												>
													<path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
												</svg>
											) : (
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="24"
													height="24"
													viewBox="0 0 24 24"
												>
													<path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
												</svg>
											)}
											{translations && !isEmpty(translations["registerPasswordCapital"])
												? parse(translations["registerPasswordCapital"])
												: "registerPasswordCapital"}
										</div>
										<div>
											{letterNumber ? (
												<svg
													className="validate"
													xmlns="http://www.w3.org/2000/svg"
													width="24"
													height="24"
													viewBox="0 0 24 24"
												>
													<path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
												</svg>
											) : (
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="24"
													height="24"
													viewBox="0 0 24 24"
												>
													<path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
												</svg>
											)}
											{translations && !isEmpty(translations["registerPasswordCharacters"])
												? parse(translations["registerPasswordCharacters"])
												: "registerPasswordCharacters"}
										</div>
									</div>
								</div>
								<input
									type="submit"
									name="submit"
									id="submit"
									disabled={isEmpty(password) || isEmpty(passwordVerify) || !capital || !letterNumber || errorPasswordVerify}
									value={
										translations && !isEmpty(translations["recover"])
											? removeTags(translations["recover"]) : "Recover"
									}
								/>
							</form>
						</div>
					</section>
				)}
			</div>
		</>
	);
};

export default Account;
