import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "../../components/Utils";
import { AdminContext } from "../../contexts/AdminContext";
import {
	Editor,
	EditorState,
	RichUtils,
	convertFromHTML,
	ContentState,
} from "draft-js";
import "draft-js/dist/Draft.css";
import { stateToHTML } from "draft-js-export-html";
const Status = () => {
	const status = useSelector((state) => state.statusReducer);

	useEffect(() => {
		if (!isEmpty(status)) {
			if (status.success === 1) {
				sendToEditor(status.message.value);
			}
		}
	}, [status]);
	//EDITOR
	const [editorState, setEditorState] = useState(EditorState.createEmpty());

	const handleKeyCommand = (command) => {
		const newState = RichUtils.handleKeyCommand(editorState, command);
		if (newState) {
			setEditorState(newState);
			return "handled";
		}
		return "not-handled";
	};
	const onItalicClick = () => {
		setEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
	};
	const onBoldClick = () => {
		setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
	};
	const onUnderlineClick = () => {
		setEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
	};
	const sendToEditor = (input) => {
		const blocksFromHTML = convertFromHTML(input);
		const state = ContentState.createFromBlockArray(
			blocksFromHTML.contentBlocks,
			blocksFromHTML.entityMap
		);

		setEditorState(EditorState.createWithContent(state));
	};

	//SAVE

	const { updateStatus } = useContext(AdminContext);
	const setStatus = async () => {
		let options = {
			defaultBlockTag: "li",
		};
		console.log(editorState.getCurrentContent());
		let html = stateToHTML(editorState.getCurrentContent(), options);
		html = html.replace("<ul>", "").replace("</ul>", "");
		console.log(html);

		const message = await updateStatus(html);

		console.log(message);
	};

	return (
		<div className="bo-status">
			<div className="sticky">
				<div className="submit">
					<button onClick={setStatus} className="loading-webtv">
						<span>Mettre à jour le status</span>
						<div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
							>
								<path d="M13.75 22c0 .966-.783 1.75-1.75 1.75s-1.75-.784-1.75-1.75.783-1.75 1.75-1.75 1.75.784 1.75 1.75zm-1.75-22c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm10 10.75c.689 0 1.249.561 1.249 1.25 0 .69-.56 1.25-1.249 1.25-.69 0-1.249-.559-1.249-1.25 0-.689.559-1.25 1.249-1.25zm-22 1.25c0 1.105.896 2 2 2s2-.895 2-2c0-1.104-.896-2-2-2s-2 .896-2 2zm19-8c.551 0 1 .449 1 1 0 .553-.449 1.002-1 1-.551 0-1-.447-1-.998 0-.553.449-1.002 1-1.002zm0 13.5c.828 0 1.5.672 1.5 1.5s-.672 1.501-1.502 1.5c-.826 0-1.498-.671-1.498-1.499 0-.829.672-1.501 1.5-1.501zm-14-14.5c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2zm0 14c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2z" />
							</svg>
						</div>
					</button>
				</div>
			</div>
			<div className="controls">
				<button onClick={onItalicClick} className="italic">
					I
				</button>
				<button onClick={onBoldClick} className="bold">
					B
				</button>
				<button onClick={onUnderlineClick} className="underline">
					U
				</button>
			</div>
			{!isEmpty(status) && status.success === 1 && (
				<Editor
					editorState={editorState}
					onChange={setEditorState}
					handleKeyCommand={handleKeyCommand}
				/>
			)}
		</div>
	);
};

export default Status;
