import { GET_SLIDESHOW, REORDER_SLIDESHOW } from "../actions/slideshow.action";

const initialState = [];

export default function slideShowReducer(state = initialState, action) {
	switch (action.type) {
		case GET_SLIDESHOW:
			return action.payload;
		case REORDER_SLIDESHOW:
			return action.payload;
		default:
			return state;
	}
}
