import { combineReducers } from "redux";
import {
	DELETE_SPORTS_EVENTS,
	GET_SPORTS_EVENTS,
	GET_SPORTS,
	GET_ALL_SPORTS,
} from "../actions/sports.action";

const initialState = [];
function eventsSportReducer(state = initialState, action) {
	switch (action.type) {
		case GET_SPORTS_EVENTS:
			return action.payload;
		case DELETE_SPORTS_EVENTS:
			return initialState;
		default:
			return state;
	}
}

function sportsTypesReducer(state = initialState, action) {
	switch (action.type) {
		case GET_SPORTS:
			return action.payload;
		default:
			return state;
	}
}

function sportsAllTypesReducer(state = initialState, action) {
	switch (action.type) {
		case GET_ALL_SPORTS:
			return action.payload;
		default:
			return state;
	}
}

export default combineReducers({ eventsSportReducer, sportsTypesReducer, sportsAllTypesReducer });
