import React, {useContext, useEffect, useState} from "react";
import EditSlide from "./EditSlide";
import HandleSlideShow from "./HandleSlideShow";
import SlideEditor from "./SlideEditor";
import Switch from "react-switch";
import {AdminContext} from "../../../contexts/AdminContext";
import {useDispatch, useSelector} from "react-redux";
import {getSettings} from "../../../actions/setting.action";
const Slideshow = () => {
	const dispatch = useDispatch();
	const [actualComponent, setActualComponent] = useState(<HandleSlideShow />);
	const [multiPerPage, setMultiPerPage] = useState(false);
	const { updateSlidePerPage } = useContext(AdminContext);
	const settingReducer = useSelector((state) => state.settingReducer);

	useEffect(() => {
		dispatch(getSettings())
	}, [dispatch]);

	useEffect(() => {
		if (settingReducer.slider_count_per_page) {
			setMultiPerPage(settingReducer.slider_count_per_page === "2")
		}
	}, [settingReducer]);

	const defaultSlide = {
		id: null,
		type: 1,
		team1: "TEAM 1",
		team2: "TEAM 2",
		img: `${process.env.REACT_APP_CACHE_SERVER}/img/footages/banner-event.png`,
		title: "TITRE",
		url: "",
		visible: "yes",
	};

	const updateCountPerPage = async (event) => {
		let slidePerPage = 1;
		if (event) {
			slidePerPage = 2;
		}
		const data = await updateSlidePerPage(slidePerPage);

		if (data.success) {
			setMultiPerPage(event);
		}
	};

	return (
		<div className="bo-slideshow">
			<nav>
				<ul>
					<li
						className="selected"
						onClick={(e) => {
							setActualComponent(<HandleSlideShow />);
							document.querySelectorAll(".bo-slideshow > nav>ul>li").forEach((elm) => {
								e.target === elm
									? elm.classList.add("selected")
									: elm.classList.remove("selected");
							});
						}}
					>
						Gérer le slideshow
					</li>
					<li
						onClick={(e) => {
							setActualComponent(<SlideEditor slide={defaultSlide} isNew={true} />);
							document.querySelectorAll(".bo-slideshow > nav>ul>li").forEach((elm) => {
								e.target === elm
									? elm.classList.add("selected")
									: elm.classList.remove("selected");
							});
						}}
					>
						Créer un slide
					</li>
					<li
						onClick={(e) => {
							setActualComponent(<EditSlide />);
							document.querySelectorAll(".bo-slideshow > nav>ul>li").forEach((elm) => {
								e.target === elm
									? elm.classList.add("selected")
									: elm.classList.remove("selected");
							});
						}}
					>
						Editer une slide
					</li>
					<li>
						<div className="switch-block">
							<Switch
								onChange={(event) => updateCountPerPage(event)}
								checked={multiPerPage}
								uncheckedIcon={false}
								checkedIcon={false}
								offColor="#d6d6d6"
								onColor="#679bff"
								id="countPerPage"
							/>
							<label htmlFor="countPerPage">2 diapositives par page</label>
						</div>
					</li>
				</ul>
			</nav>
			<div className="bo-slide-component">{actualComponent}</div>
		</div>
	);
};

export default Slideshow;
